import React from "react";
import {
  Button,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  ButtonProps,
} from "@material-ui/core";
import { ModalCloseButton } from "ui/components/mobile/TabletModal";

interface Props {
  onClose: () => void;
  innerOnly?: boolean;
  acceptable?: boolean;
  buttonText?: string;
  buttonProps?: ButtonProps;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    privacyPolicyCard: {
      "& .MuiPaper-root": {
        maxWidth: "1000px",
        position: "relative",
        maxHeight: "900px",
        width: "calc(100vw - 80px)",
        height: "calc(100vh - 80px)",
        backgroundColor: "white",
        borderRadius: "8px",

        [theme.breakpoints.down("xs")]: {
          left: 0,
          top: 0,
          position: "absolute",
          borderRadius: "0px",
          width: "100vw",
          height: "calc(100vh)",
        },
      },
    },
    privacyPolicyHeader: {
      margin: "60px 60px 0px 60px",

      [theme.breakpoints.down("sm")]: {
        margin: "40px 40px 0px 40px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "20px 20px 0px 20px",
      },
    },
    privacyPolicyScrollContent: {
      ...theme.typography.body1,

      "& b:before": {
        margin: "10px",
      },

      "& li": {
        marginBottom: "10px",
      },
      "& b": {
        fontFamily: "Gilroy Bold",
      },
      "& * h3": {
        display: "block",
        marginTop: "36px",
        marginBottom: "-10px",
        fontSize: "18px",
        fontFamily: "Gilroy Bold",
      },
      margin: "60px",
      [theme.breakpoints.down("sm")]: {
        margin: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "20px",
      },
    },
    underline: {
      display: "block",
      marginTop: "12px",
      marginBottom: "12px",
      textDecoration: "underline",
    },
    privacyPolicyFooter: {
      display: "flex",
      width: "100%",
      height: "80px",
      backgroundColor: "white",
      boxShadow: "0px -30px 15px white",
      position: "sticky",
      bottom: 0,
      paddingBottom: "20px",

      "& *": {
        margin: "auto",
      },
    },
    crossIcon: {
      position: "sticky",
      top: "15px",
      margin: "15px",
    },
    dashList: {
      listStyleType: "none",
      "& li": {
        textIndent: "-5px",
      },
    },
    dashItem: {
      "&:before": {
        textIndent: "-5px",
        content: "'-     '",
      },
    },
  })
);
const PrivacyPolicy: React.FC<Props> = (props) => {
  const classes = useStyles();
  if (props.innerOnly) {
    return Inner();
  } else {
    return (
      <Card>
        <div className={classes.crossIcon}>
          <ModalCloseButton onClose={props.onClose} />
        </div>
        {Inner()}
      </Card>
    );
  }

  function Inner() {
    return (
      <React.Fragment>
        <Typography
          variant="h4"
          align="center"
          className={classes.privacyPolicyHeader}
        >
          Terms and Conditions
        </Typography>
        <div className={classes.privacyPolicyScrollContent}>
          <div>
            <p>Ignite Aotearoa Terms and Conditions and Privacy Policy</p>
          </div>
          <div>
            <p>Updated May 2021</p>
          </div>
          <div></div>
          <div>
            <h3>About Ignite</h3>
            <p>
              <br />
              Ignite Aotearoa (Ignite) is a social enterprise, part of the
              Emerge Aotearoa Group. Ignite exists to create connections and
              opportunities that strengthen wellbeing. Our vision and goal is
              realising potential.
            </p>
          </div>
          <div>
            <h3>Use of the Platform</h3>
            <p>
              <br />
              Ignite has developed Ignite Wellbeing, a digital platform
              (Platform) to make accessing wellbeing information and support
              easy and timely. By using our website and its content (including
              but not limited to any education programmes and platforms) you
              agree to be bound by these Terms and Conditions.
            </p>
          </div>
          <div>
            <div>
              <p>The Platform contains:</p>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    Collated third party and Ignite produced general wellbeing
                    information and resources, and the ability to book workshops
                    run by Ignite or partner organisations
                  </p>
                </li>
                <li>
                  <p>
                    Interactive tools you may use to assess and monitor your
                    wellbeing
                  </p>
                </li>
                <li>
                  <p>
                    An introductory booking service to connect you with
                    providers of support who are independent contractors to
                    Ignite (Providers)
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <p>It does not provide:</p>
            </div>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  <p>Medical advice:</p>
                </li>
              </ul>
            </div>
            <div>
              <p>
                The Platform is not intended for the provision of clinical
                diagnosis and you should not use it if you need any official
                documentation or assessment. It is also not intended for any
                information regarding which medication or medical treatment may
                be appropriate for you and you should disregard any such advice
                if delivered via the Platform.
              </p>
            </div>
            <div></div>
            <div>
              <ul>
                <li>
                  <p>Crisis support:</p>
                </li>
              </ul>
            </div>
            <div>
              <p>
                If you are thinking about suicide or are considering harming
                yourself or others or feel that any other person may be in
                danger or if you have a medical emergency, you must immediately
                call 111 and notify the relevant authorities. Seek immediate in
                person assistance. The Platform is not designed for use in any
                of the aforementioned cases and the Providers cannot provide
                assistance in any of the aforementioned cases.
              </p>
            </div>
          </div>
          <div>
            <p>
              Do not disregard, avoid or delay obtaining in person care from
              your Doctor or other quailifed professional because of information
              or advice you received through the Platform.
            </p>
          </div>
          <div>
            <p>
              The right to use our website and its content (including but not
              limited to any education programmes and platforms) is personal to
              you and is not transferable nor assignable to any other person or
              entity. You may only use our website, product or services for
              informational and non-commercial purposes.
            </p>
          </div>
          <div></div>
          <div>
            <br />
          </div>
          <div>
            <p>Reasonable use of the Platform</p>
          </div>
          <div>
            <div>
              <p>
                You must not use our website and its content (including but not
                limited to any education programmes and platforms):
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    in any way or take any action that causes, or may cause,
                    damage or impairment of the performance, availability or
                    accessibility of our website and its content; or
                  </p>
                </li>
                <li>
                  <p>
                    in any way that is unlawful, illegal, fraudulent or harmful,
                    or in connection with any unlawful, illegal, fraudulent or
                    harmful purpose or activity; or
                  </p>
                </li>
                <li>
                  <p>
                    to copy, store, host, transmit, send, use, publish or
                    distribute any material which consists of (or is linked to)
                    any spyware, computer virus, Trojan horse, worm or other
                    malicious computer software; or
                  </p>
                </li>
                <li>
                  <p>
                    conduct any systemic or automated data collection activities
                    (including without limitation scraping, data mining, data
                    extraction and data harvesting) on or in relation to our
                    website and its content without our express written consent.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p>
              You must ensure that all information you supply to us through our
              website and its content or in relation to our website and its
              content (including but not limited to any education programmes and
              platforms) is true, accurate, current and non-misleading.
            </p>
          </div>
          <div>
            <p>Use on behalf of organisation:</p>
          </div>
          <div>
            <p>
              If you use our website and its content (including but not limited
              to any education programmes and platforms) in the course of a
              business or other organisation, then by doing so you bind both
              yourself and the company or other legal entity that operates that
              business to these Terms and Conditions and in these circumstances
              references to &#8220;you&#8221; in these Terms and Conditions are
              both the individual user and the relevant company or legal entity.
            </p>
          </div>
          <div>
            <h3>Privacy Policy</h3>
            <p>
              <br />
              Ignite recognises the importance of protecting your privacy and
              personal information. This is the current privacy policy relating
              to personal information we may collect, store, use and disclose
              regarding any individual who provides personal information to us
              during the course of using our website, products or services.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              By providing us with personal information, you consent to the
              collection, storage, use and disclosure of your personal
              information in accordance with this Privacy Policy or as otherwise
              agreed with you, or as required by law.
            </p>
          </div>
          <div>
            <p>
              You are not required to provide personal information to us,
              although in some cases if you choose not to do so then we will be
              unable to make certain sections of the Platform available to you.
              The Platform is also only able to personalise the supports and
              services suggested to you if you provide accurate information
              about your challenge, areas of interest or need.
            </p>
          </div>
          <div>
            <p>How and what information we collect:</p>
          </div>
          <div></div>
          <div>
            <p>
              The personal information you provide to us (including any
              information provided if you register for an account or interact
              with any of our features within the Platform) is collected and may
              be used for communicating with you, the Provider and services you
              book, statistical analysis, the recommendation of products and
              services to you, and research and development. Some examples of
              data are as follows:&#8239;
            </p>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  <p>Demographic&#8239;information</p>
                </li>
                <li>
                  <p>Mood and wellbeing plan scores</p>
                </li>
                <li>
                  <p>
                    Type of support booked (e.g. video call or in person etc.)
                  </p>
                </li>
                <li>
                  <p>Provider booked and time/date of bookings&#8239;</p>
                </li>
                <li>
                  <p>Consumption of tokens and additional token requests</p>
                </li>
              </ul>
            </div>
          </div>
          <div></div>
          <div>
            <p>
              Ignite collates aggregated&#8239;and/or annonymised data (that
              does not identify you personally) from the Platform
              database&#8239;and&#8239;interactive tools&#8239;(e.g.&#8239;mood
              diary) to share with your organisation (The Client) as part of our
              Account Management. This information is also used
              to&#8239;identify&#8239;trends to assist Ignite to
              improve&#8239;the Platform&#8239;and its content.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              We use Google Tag Manager and Google Analytics on this website.
              The information collected is not personally identifiable. We
              comply with the privacy policies implemented by Google Tag
              Manager. You can find out more about their privacy policies
              at&#8239;
              <a
                href="https://support.google.com/IgniteTagManager/answer/9323295?hl=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://support.google.com/IgniteTagManager/answer/9323295?hl=en
                <br />
              </a>
            </p>
          </div>
          <div>
            <p>
              We may also collect technical information whenever you log on to,
              or visit the public version of, our Platform. This may include
              information about the way users arrive at, browse through and
              interact with our Platform. We may collect this type of technical
              information through the use of cookies and other means. If you
              want to disable cookies, you may do so by changing the settings on
              your browser. However, if you do so, you may not be able to use
              all of the functions on the Platform. We may use the technical
              information we collect to have a better understanding of the way
              people use the Platform, to improve the way it works and to
              personalise it to be more relevant and useful to your particular
              needs.
            </p>
          </div>
          <div></div>
          <div>
            <p>Access</p>
          </div>
          <div></div>
          <div>
            <p>
              The data collected and accessibility of this data for the purposes
              of administering the Platform and Services is outlined in the
              table below:
            </p>
          </div>
          <div></div>
          <div></div>
          <div>
            <div>
              <div id="{61ee7e5a-a199-42e8-84e9-82efefece846}{35}"></div>
              <table
                style={{
                  borderWidth: "1px",
                  borderColor: "#aaaaaa",
                  borderStyle: "solid",
                }}
              >
                <tbody
                  style={{
                    borderWidth: "1px",
                    borderColor: "#aaaaaa",
                    borderStyle: "solid",
                  }}
                >
                  <tr>
                    <td>
                      <div>
                        <div></div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>Ignite Staff</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>Ignite Technology Partners staff</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>Ignite Service Providers</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>Organisation (The Client)</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>
                            Personal Information (name, email, phone number)
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>Demographic and Account Data</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>Mood &amp; Wellbeing Scores</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>Service Booking Information</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>Case Notes from booked Services</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div>
                          <p>
                            Aggregated anonymised trend reports on data
                            excluding case notes
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>no</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <p>yes</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <p>
                <br />
                We may also share your personal information in following
                circumstances:
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    The information is required by law, court order, regulatory
                    authority, to exercise our legal rights, in the event of a
                    dispute or legal claim, or to defend against legal
                    proceedings
                  </p>
                </li>
                <li>
                  <p>
                    Where you have agreed to share your personal information
                  </p>
                </li>
                <li>
                  <p>
                    Where it is necessary or required under the Privacy Act 2020
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div></div>
          <div>
            <p>How we store your information:</p>
          </div>
          <div></div>
          <div>
            <p>
              Any personal information you provide to us may be stored on the
              secure servers of our trusted service providers, which may be
              located outside New Zealand. We have reasonable safeguards in
              place to prevent, loss, theft, misuse, unauthorised access or
              disclosure of personal information. However, since no data
              transmission over the internet can be guaranteed completely
              secure, you acknowledge and accept that we cannot and do not
              guarantee the security of information you transmit through to us.
              We cannot accept responsibility for misuse or loss of, or
              unauthorised access to, your personal information where the
              security of information is not within our control.
              <br />
              <br />
              We keep information for the length of your personal or
              organisation&#8217;s subscription with us and then for 10 years.
              We take reasonable steps to destroy, erase or permanently
              de-identify personal information as soon as practicable if it is
              no longer required by us.
            </p>
          </div>
          <div></div>
          <div>
            <p>Correction of Information:</p>
          </div>
          <div></div>
          <div>
            <p>
              Under the Privacy Act, you have the right to request access to and
              correction of any of the personal information we hold about you.
              You can ask us to correct any errors or to delete the information
              we have about you. You may opt-out of any further contact from us.
              A request for information will be acknowledged in 5 working days
              and responded to within 20 working days. If there will be a delay
              in providing the information you will be notified within 10
              working days of the request. To protect your privacy and the
              privacy of others, we may have to verify that you are who you say
              you are before being able to provide you with any personal
              information. If you would like to exercise these rights, please
              get in touch with the Privacy Officer at&nbsp;
              <a
                href="mailto:hello@ignite.org.nz"
                target="_blank"
                rel="noreferrer noopener"
              >
                hello@ignite.org.nz
              </a>
            </p>
          </div>
          <div></div>
          <div>
            <h3>Intellectual property</h3>
            <p>
              <br />
              Ignite owns all proprietary and intellectual property rights in
              the Ignite website and Platform, including without limitation,
              trademarks, all non-third-party information, data, text, graphics,
              artwork, photographs, logos, icons, sound recordings, videos and
              look and feel, and the underlying systems. Any unauthorised use of
              such intellectual property is prohibited and is a breach of these
              Terms and Conditions.
            </p>
          </div>
          <div>
            <p>
              All third-party material contained in this website is the
              copyright of individual contributors and is made available for
              personal, non-commercial use only. It may not be further copied
              and distributed without permission from the copyright owners
              concerned.
              <br />
              <br />
              Where you are the owner of copyright work which you believe is
              available on this website in such a way that does not satisfy the
              fair dealing provisions of the Copyright Act 1994 or otherwise
              constitutes a copyright infringement, or a breach of an agreed
              license or contract, please notify us and we will remove it
              immediately.
            </p>
          </div>
          <div>
            <h3>Disclaimers</h3>
            <p>
              <br />
              Ignite functions to assist individuals looking to engage in
              preventive and proactive mental health care to achieve wellbeing.
              It is not a site suitable for those that are acutely distressed or
              in crisis and should not be used as such.
            </p>
          </div>
          <div>
            <p>
              Ignite requires that every Provider on the Platform has the
              appropriate training and experience for the services they are
              contracted to provide. They must operate in accordance with any
              applicable professional organisation&#8217;s code of ethics and
              meet their requirements for ongoing professional development
              and/or re-certification. Ignite itself is not a party to any
              services provided by Providers to users or any legal relationship
              between you and a Provider as a result of your use of the
              Platform. If you feel the services provided do not fit your needs
              or expectations, you may change to a different Provider who
              provides services through the Platform.
            </p>
          </div>
          <div>
            <p>
              We make no warranty as to the nature or behavior of the Providers
              who provide services through the Platform. We are not liable to
              you for any loss or damage you may incur as a result of
              introducing you to a Provider on the Platform.
            </p>
          </div>
          <div>
            <p>
              Ignite serves as an aggregator of third-party content of mental
              health and wellbeing resources. While care has been taken to
              curate these, the information and recommendations provided to you
              on the Ignite Platform is for informational purposes only and
              should not be construed as professional advice.
            </p>
          </div>
          <div>
            <p>
              Users of the information contained on this website must make their
              own assessment of the suitability and appropriateness of the
              products, services and information for their particular use. If
              you use the contents of this website, you do so at your own risk.
            </p>
          </div>
          <div>
            <p>
              No person should act solely in reliance on any statement contained
              in the information provided, it is not intended as a substitute
              for professional health advice. You should seek independent
              professional advice from a person who is licensed and/or qualified
              in the mental health field, this could include but is not limited
              to your GP, Psychiatrists and other mental health professionals.
            </p>
          </div>
          <div>
            <p>
              Ignite provides recommendations of useful mental health and
              wellbeing resources. These recommendations contain links to
              third-party internet websites. All links and references to other
              websites, organisations or people not within Ignite are provided
              for the user&#8217;s convenience only and should not be taken as
              endorsement of these sites, or of the information contained in
              those websites, nor of organisations or people referred to. Ignite
              is not responsible for hyperlinks and their content and assumes no
              responsibility for the correctness, completeness, legality, or
              quality of the hyperlinks. If you have comments or questions
              relating to sites linked to this site, they should be directed to
              the relevant organisation.
            </p>
          </div>
          <div>
            <p>
              The content of any third-party material provided on this website
              belongs to the individual authors of those material and is
              representative only of the respective author(s) opinions,
              positions/stand, practices, experiences, thoughts, views,
              concepts, perceptions, ideas and/or beliefs and as such does not
              represent the views of Ignite.
            </p>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <h3>Liability</h3>
            <p>
              <br />
              Ignite endeavours to present clinically accurate and up to date
              information on this website. However, Ignite makes no warranties
              or representations about accuracy, adequacy, correctness,
              reliability or completeness of the information provided. Ignite
              will endeavour to correct any errors on this website but does not
              accept any liability for mistakes or out-of-date information. You
              agree to access and use this website at your own risk. The
              information is provided in good faith with no guarantee as to
              outcomes.
              <br />
              <br />
              Neither Ignite, nor its respective officers, agents or affiliates
              or employees, accept any liability, direct or indirect, for any
              injury, loss, claim, damage or any incidental or consequential
              damage, arising out of or in any way connected with the use of
              this website or any information contained on these sites except
              for liability which cannot be excluded by law.
            </p>
          </div>
          <div>
            <p>
              Ignite assumes no responsibility for any technical malfunction,
              error, omission, interruption, deletion, delay in operation or
              transmission, communication line failure, virus, bug, tampering,
              theft or destruction or unauthorised access to the site, or any
              injury or damage to any person&#8217;s computer related to the use
              of the site, or the streaming of material or downloading of any
              material (where permitted) from this website.
            </p>
          </div>
          <div>
            <p>
              Ignite is not responsible for the content of websites linked to or
              from this website that are not owned by Ignite. Ignite has no
              responsibility for the condition, content or operation of any such
              other websites and shall not be liable for any damages or injury
              arising out of or resulting from access to or use of such
              websites. If you click on a link to another website, you do so
              entirely at your own risk.
            </p>
          </div>
          <div>
            <div>
              <h3>Ammendments</h3>
              <p>
                <br />
                We may change these Terms and Conditions, by adding, changing or
                removing terms at any time. You should check from time to time
                to see if these Terms and Conditions have changed. By continuing
                to use this website after any such amendment, you are deemed to
                have agreed to the amended terms of use.
              </p>
            </div>
            <div>
              <h3>Governing Law</h3>
              <p>
                <br />
                These Terms and Conditions are governed by an interpreted
                according to the laws of New Zealand.
              </p>
            </div>
            <div>
              <h3>Compliance with terms</h3>
              <p>
                <br />
                Without prejudice to your other rights under these Terms and
                Conditions, if you breach these Terms and Conditions in any way,
                or if we reasonably suspect that you have breached these Terms
                and Conditions in any way, we may:
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <p>Send you one or more formal warnings</p>
                </li>
                <li>
                  <p>
                    Temporarily suspend your access to our website and its
                    content (including programmes and platforms)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  <p>
                    Permanently prohibit you from accessing our website and its
                    content (including programmes and platforms)
                  </p>
                </li>
                <li>
                  <p>
                    Block computers using your IP address from accessing our
                    website and its content (including programmes and platforms)
                  </p>
                </li>
                <li>
                  <p>
                    Contact any or all of your internet service providers and
                    request that they block your access to our website and its
                    content (including programmes and platforms)
                  </p>
                </li>
                <li>
                  <p>
                    Commence legal action against you, whether for breach of
                    contract or otherwise; and/or
                  </p>
                </li>
                <li>
                  <p>
                    Suspend or delete your account on our website and its
                    content (including programmes and platforms)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <p>
                <strong>
                  While we hope the Ignite website, Platform and services are
                  beneficial to you, using the Platform you accept and agree to
                  the aforementioned terms and conditions, and:
                </strong>
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    To indemnify Ignite and its officers, agents or affiliates
                    or employees against all claims, suits, demands, damages,
                    liabilities, costs or expenses arising in any way out of or
                    in any way connected to your use of our website, breach of
                    these Terms and Conditions, or wilful or negligent acts.
                  </p>
                </li>
                <li>
                  <p>
                    You consent to Ignite to share collated/aggregate data
                    showing trends (that does not identify you personally)
                    within Ignite and with the organisation providing a
                    subscription to you. When we share this information we make
                    sure that the data does not identify you personally.
                  </p>
                </li>
                <li>
                  <p>
                    That Ignite is not responsible for the accuracy, usefulness,
                    safety, or effectiveness of any advice received from
                    Providers (whether via online, phone or in-person
                    sessions/appointment.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  <p>
                    Your use or reliance on of any advice or information is
                    entirely at your own risk, for which we shall not be liable.
                    It shall be your own responsibility to ensure that any
                    advice you receive through our website and its content
                    (including education programmes and platforms) meets your
                    specific requirements.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3>Contact us</h3>
            </div>
            <div>
              <p>
                If you have any questions about accessing and using any of the
                information contained on this page, please email us at&#8239;
                <a
                  href="mailto:hello@ignite.org.nz"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  hello@ignite.org.nz
                </a>
                .
              </p>
            </div>
            <div></div>
          </div>
        </div>
        <div className={classes.privacyPolicyFooter}>
          <Button
            variant="contained"
            color="secondary"
            {...props.buttonProps}
            onClick={props.onClose}
          >
            {props.acceptable
              ? props.buttonText
                ? props.buttonText
                : "Accept"
              : "Close"}
          </Button>
        </div>
      </React.Fragment>
    );
  }
};

export default PrivacyPolicy;
