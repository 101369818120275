import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Divider } from "@material-ui/core";
import Responsive from "ui/Responsive";
import DomainPriority from "./DomainPriority";
import DomainScore, { LargeDomainScore } from "./DomainScore";
import WellbeingHistoryTimeline from "./DateSlider";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import { DashboardActions } from "domain/dashboard/actions";
import { WellbeingActions } from "domain/wellbeing/actions";
import { useReduxState, useActions } from "re-reduced";
import {
  fetchWellbeingOverview,
  fetchWellbeingOverviewStatus,
  getWellbeingTimeline,
} from "domain/dashboard/reducer";

import CardHeading from "ui/components/dashboard/CardHeading";

import DomainsDesktop from "assets/illustrations/DomainsDesktop.svg";
import DomainsTablet from "assets/illustrations/DomainsTablet.svg";
import DomainsMobile from "assets/illustrations/DomainsMobile.svg";
import IgniteTagManager from "lib/analytics";
interface Props extends RouteComponentProps {}

const promptStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "40px 0px 40px 40px",
      minWidth: "410px",
      maxWidth: "508px",
      width: "auto",

      "& > *": {
        padding: "20px",
      },

      "@media(max-width: 1150px)": {
        "& > *": {
          paddingTop: "calc(1vw)",
          paddingBottom: "calc(1vw)",
        },
      },

      [theme.breakpoints.down("md")]: {
        padding: "0px 40px",
        margin: "auto",
        "& > *": {
          margin: "10px auto",
          textAlign: "center",
        },

        "& >:nth-child(2)": {
          marginBottom: "0px",
          marginTop: "0px",
          textAlign: "center",
        },
        "& >:last-child": {
          margin: "0px",
        },
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        minWidth: "unset",
        padding: "20px 20px",
        "& > *": {
          padding: "calc(1vw)",
          margin: "2vw auto",
          textAlign: "center",
        },
      },
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",

      "& > *": {
        marginRight: "16px",
        marginLeft: "0px",
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",

        "& button": {
          margin: "20px 2vw",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        "& button": {
          margin: "2vw",
        },
      },
    },
    imageDesktop: {
      "@media(max-width: 1150px)": {
        maxWidth: "55%",
        minHeight: "100%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    imageTablet: {
      display: "flex",
      width: "101%",
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    imageMobile: {
      display: "flex",
      width: "101%",
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",

      padding: "20px",

      "& .MuiDivider-root": {
        marginTop: "20px",
      },

      [theme.breakpoints.down("sm")]: {
        padding: "16px",
      },
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "100%",

      "& * button": {
        width: "230px",
        margin: "auto",
      },

      [theme.breakpoints.up("sm")]: {
        flexWrap: "wrap",
      },

      [theme.breakpoints.down("sm")]: {
        "& * button": {
          width: "100%",
        },
      },

      "& * p": {
        width: "100%",
      },
    },

    showMoreButton: {
      width: "100%",
      height: "100%",
    },
    content: {
      margin: "30px auto",
      "@media(max-width: 1190px)": {
        justifyContent: "center",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        maxHeight: "250px",
        transition: "max-height 2s ease-in-out",
        justifyContent: "flex-start",
        overflow: "hidden",
        flexWrap: "nowrap",
      },
    },
    contentExpanded: {
      maxHeight: "1500px",
    },
    largeChart: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "280px",
      },
    },
    column: {
      display: "block",
      flexDirection: "column",
    },
    spread: {
      justifyContent: "space-between",
      display: "flex",
    },
    itemContainer: {
      margin: "12px",
      [theme.breakpoints.down("xs")]: {
        margin: "auto",
        marginTop: "20px",
      },
    },
    priorities: {
      minWidth: "420px",

      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        marginTop: "25px",
        margin: "25px 15px 0px 15px",
        width: "calc(100%)",
        minWidth: "auto",
        "& * ": {
          textAlign: "left",
        },
      },
    },
    wedgeStyle: {
      [theme.breakpoints.down("xs")]: {
        width: "100px",
        height: "100px",
      },
    },
    heading: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  })
);
interface Props {}

interface MinMax {
  min: number;
  max: number;
}

interface WellbeingOverviewItem {
  date: Date;
  lifeScore: MinMax;
  healthScore: MinMax;
  meaning: MinMax;
  priorities: string[];
}

const WellbeingOverview: React.FC<Props> = (props) => {
  const classes = useStyles();
  const prompt = promptStyles();

  const [showMore, setShowMore] = React.useState<boolean>(false);

  const actions = useActions({ ...DashboardActions, ...WellbeingActions });

  const reduxState = useReduxState({
    items: fetchWellbeingOverview,
    dates: getWellbeingTimeline,
    status: fetchWellbeingOverviewStatus,
  });

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const [getFirstLoad, setFirstLoad] = React.useState<boolean>(true);

  useEffect(() => {
    if (getFirstLoad) {
      actions.getWellbeingOverviewList();
      setFirstLoad(false);
    }
  }, [getFirstLoad, setFirstLoad, actions]);

  function getOverview() {
    if (reduxState.status === "Fulfilled" && reduxState.items.length > 0) {
      return (
        <div className={classes.root} id="fade-in">
          <CardHeading
            heading="Wellbeing Overview"
            action="See Wellbeing plan"
            onClick={() => {
              const tagManagerArgs = {
                dataLayer: {
                  event: "WellbeingOverviewSeePlanClicked",
                },
              };

              IgniteTagManager.dataLayer(tagManagerArgs);
              let path = generatePath("/assessmentResults/:id", {
                id: reduxState.items[selectedIndex].wellbeingId,
              });
              props.history.push(path);
            }}
          />

          <Divider />
          <div
            className={`${classes.container} ${classes.content} ${
              showMore ? classes.contentExpanded : ""
            }`}
          >
            <div className={` ${classes.largeChart}`}>
              <LargeDomainScore
                svgStyle={classes.wedgeStyle}
                score={
                  reduxState.items[selectedIndex].domainScores.health.min +
                  reduxState.items[selectedIndex].domainScores.meaning.min +
                  reduxState.items[selectedIndex].domainScores.life.min
                }
                max={
                  reduxState.items[selectedIndex].domainScores.health.max +
                  reduxState.items[selectedIndex].domainScores.meaning.max +
                  reduxState.items[selectedIndex].domainScores.life.max
                }
                text="My Total Wellbeing Score"
              />
            </div>
            <div className={`${classes.column} ${classes.itemContainer}`}>
              <DomainScore
                score={reduxState.items[selectedIndex].domainScores.health.min}
                max={reduxState.items[selectedIndex].domainScores.health.max}
                domain={"life"}
              />
              <DomainScore
                score={reduxState.items[selectedIndex].domainScores.life.min}
                max={reduxState.items[selectedIndex].domainScores.life.max}
                domain={"health"}
              />
              <DomainScore
                score={reduxState.items[selectedIndex].domainScores.meaning.min}
                max={reduxState.items[selectedIndex].domainScores.meaning.max}
                domain={"meaning"}
              />
            </div>

            <div
              className={`${classes.column} ${classes.itemContainer} ${classes.priorities}`}
            >
              <Typography variant="body1">
                Your prioritised areas of wellbeing:
              </Typography>
              <Responsive displayIn={["Tablet", "Desktop"]}>
                <div className={classes.container}>
                  {reduxState.items[selectedIndex].priorities.map((item) => (
                    <DomainPriority
                      key={item}
                      domain={item}
                      variant="h6"
                      size="xl"
                    />
                  ))}
                </div>
              </Responsive>
              <Responsive displayIn={["Mobile", "MobileXS"]}>
                <div className={classes.column}>
                  {reduxState.items[selectedIndex].priorities.map((item) => (
                    <DomainPriority
                      key={item}
                      domain={item}
                      variant="h6"
                      size="lg"
                    />
                  ))}
                </div>
              </Responsive>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  actions.clearTest();
                  props.history.push("/assessment");
                }}
              >
                Retake Questionaire
              </Button>
            </div>
          </div>
          <div className={classes.container}>
            <Responsive displayIn={["MobileXS"]}>
              <Button
                className={classes.showMoreButton}
                variant="text"
                color="secondary"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </Button>
            </Responsive>
          </div>
          <Divider />

          <div className={classes.container} style={{ marginTop: "0px" }}>
            {reduxState.dates.length > 0 && (
              <WellbeingHistoryTimeline
                list={reduxState.dates}
                selectedDate={reduxState.dates[selectedIndex]}
                onChange={(date: Date) => {
                  const tagManagerArgs = {
                    dataLayer: {
                      event: "WellbeingTimelineChanged",
                    },
                  };

                  IgniteTagManager.dataLayer(tagManagerArgs);
                  setSelectedIndex(reduxState.dates.indexOf(date));
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={prompt.root}>
          <img alt="" src={DomainsDesktop} className={prompt.imageDesktop} />
          <img alt="" src={DomainsTablet} className={prompt.imageTablet} />
          <img alt="" src={DomainsMobile} className={prompt.imageMobile} />
          <div className={prompt.container}>
            <Typography variant="h4">Assess your wellbeing</Typography>
            <Typography variant="body1">
              Assess how your Life, Health and Meaning domains are balanced to
              create a personalised wellbeing plan.
            </Typography>
            <div className={prompt.buttonsContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  const tagManagerArgs = {
                    dataLayer: {
                      event: "WellbeingTestTakeQuestionaireClicked",
                    },
                  };

                  IgniteTagManager.dataLayer(tagManagerArgs);
                  props.history.push("/assessment");
                }}
              >
                Take Questionaire
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
  return getOverview();
};

export default withRouter(WellbeingOverview);
