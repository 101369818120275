export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

export const ENDPOINTS = {
  login: "/oauth/token",
  contactUs: "/contact",
  forgotPassword: "/dbconnections/change_password",
  profile: "/user",
  dismissTokenExplanation: "/user/tokenExplanation",
  updateName: "/user",
  updateContactInfo: "/user",
  updateSignupInfo: "/user",
  getAppointments: "/user/appointments",
  getLocations: "/provider/cities",
  requestTokens: "user/requestTokens",
} as const;
