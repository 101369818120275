import {
  PlatformBookingPayload,
  ProviderBookResponse,
} from "domain/providers/types";

import { APIErrorResponse } from "lib/apiClient/createClient";
import { createActions } from "re-reduced";
import { WorkshopProfile, Session } from "./types";

export const WorkshopActions = createActions("WORKSHOPS", (create) => ({
  getWorkshops: create.asyncAction<WorkshopProfile[], void>(),
  bookWorkshop: create.asyncAction<
    ProviderBookResponse & { calendarID: number },
    PlatformBookingPayload,
    APIErrorResponse
  >(),
  setSelectedWorkshop: create.action<WorkshopProfile, WorkshopProfile>(),
  setSelectedSession: create.action<Session, Session>(),
  clearSelected: create.action<void, void>(),
}));
