import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as IconRightArrow } from "assets/icons/Icon-Right-Arrow.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        "& >:last-child": {
          marginTop: "8px",
        },
      },
    },
    button: {
      "& * svg": {
        marginRight: "4px",
      },
    },
  })
);

interface Props {
  heading: string;
  action?: string;
  onClick?: () => void;
  disableAction?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
}
const CardHeading = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.heading}`}>
      <Typography variant="h6">{props.heading}</Typography>
      {!props.disableAction && (
        <Button
          className={classes.button}
          variant="text"
          color="secondary"
          onClick={props.onClick}
          {...(props.iconPosition === "start"
            ? { startIcon: props.icon ? props.icon : <IconRightArrow /> }
            : props.iconPosition === "end"
            ? { endIcon: props.icon ? props.icon : <IconRightArrow /> }
            : {
                endIcon: props.icon ? props.icon : <IconRightArrow />,
              })}
        >
          {props.action}
        </Button>
      )}
    </div>
  );
};

export default CardHeading;
