import React, { useEffect } from "react";
import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { ReactComponent as Chevron } from "assets/icons/LeftArrow.svg";
import ListItem from "@material-ui/core/ListItem";
import { withRouter, RouteComponentProps } from "react-router";
import AuthActions from "domain/core/auth/actions";
import { useActions, useReduxState } from "re-reduced";
import { ReactComponent as Token } from "assets/IgniteToken.svg";

import { getInitials } from "domain/core/auth/selectors";
import * as selectors from "domain/core/selector";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      ...theme.typography.subtitle1,
      color: theme.palette.text.primary,
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: "50%",
      width: "40px !important",
      minWidth: "40px",
      height: "40px !important",
      backgroundColor: "white",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      "& *": {
        margin: "auto",
        ...theme.typography.subtitle1,
        color: theme.palette.primary.light,
      },
    },
    scrolledButton: {
      backgroundColor: theme.palette.primary.light,
      "& *": {
        color: "white",
      },
    },
    menuButtonContainer: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      width: "60px",
      padding: "10px 0px",
      margin: "auto",
    },
    icon: {
      margin: "auto",
      marginRight: "0px",
      stroke: "white",
      transition: "all 1s cubic-bezier(0.19, 1, 0.22, 1)",
    },
    iconScrolled: {
      stroke: "black",
    },
    iconClosed: {
      transform: "rotate(-90deg)",
    },
    iconOpened: {
      transform: "rotate(90deg)",
    },
    menu: {
      boxShadow: theme.shadows[4],
    },
  })
);

interface InnerMenuProps extends RouteComponentProps {}

const InnerMenu: React.FC<InnerMenuProps> = ({ history }) => {
  const actions = useActions(AuthActions);
  const state = useReduxState({ ...selectors });

  function getItems() {
    if (state.getIsAuthenticated) {
      return (
        <React.Fragment>
          <ListItem>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <Token style={{ width: 20, height: 20 }} />
            </ListItemIcon>
            <ListItemText primary={state.getProfile.availableTokens}>
              {state.getProfile.availableTokens}
            </ListItemText>
          </ListItem>

          <StyledMenuItem onClick={() => history.push("/account")}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </StyledMenuItem>

          <StyledMenuItem onClick={() => actions.logout()}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </StyledMenuItem>
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
  return getItems();
};

export const InnerMenuWithRouter = withRouter(InnerMenu);
export const MenuItems = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref}>
    <InnerMenuWithRouter />
  </div>
));

interface Props extends RouteComponentProps {
  scrolled?: boolean;
}

const AvatarMenu = ({ scrolled, history }: Props) => {
  const classes = useStyles();

  const reduxState = useReduxState({ getInitials });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let listener = history.listen(() => {
      setAnchorEl(null);
    });

    return () => {
      listener();
    };
  }, [setAnchorEl, history]);

  return (
    <div>
      <div className={`${classes.menuButtonContainer} `} onClick={handleClick}>
        <div
          className={`${classes.button} ${
            scrolled ? classes.scrolledButton : ""
          }`}
        >
          <div>{reduxState.getInitials}</div>
        </div>
        <Chevron
          className={`${classes.icon} ${
            anchorEl != null ? classes.iconOpened : classes.iconClosed
          } ${scrolled ? classes.iconScrolled : ""}`}
        />
      </div>

      <Menu
        elevation={0}
        className={classes.menu}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="avatar-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItems />
      </Menu>
    </div>
  );
};

export default withRouter(AvatarMenu);
