import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      left: "50%",
      top: "-1px",
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "50%",
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const useStylesArrow = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      padding: "15px",
      marginLeft: "-1rem",
      position: "relative",
      color: "black",
      fontFamily: "Gilroy Regular",
      fontSize: "12px",
      maxWidth: "250px",
      height: "auto",
      borderRadius: "8px",
      textAlign: "center",
      backgroundColor: "white",
    },
    arrow: {
      position: "absolute",
      borderBottom: "none",
      color: "white",
      fontSize: 6,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    popper: arrowGenerator("white"),
  })
);

export default function ArrowTooltip(props: TooltipProps) {
  const { arrow, ...classes } = useStylesArrow();

  return (
    <Tooltip
      classes={classes}
      leaveDelay={1000}
      {...props}
      title={
        <React.Fragment>
          {props.title}
          <span className={arrow}></span>
        </React.Fragment>
      }
    />
  );
}
