export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

export const ENDPOINTS = {
  fetchSearchResults: "/provider/search",
  fetchCalendar: "/provider/times",
  fetchCalendarMonth: "/provider/dates",
  getProfile: "/provider",
  putConfirmationBooking: "/appointment/booking",
  fetchSpecialities: "/specialities",
} as const;
