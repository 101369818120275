import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { BookingType } from "domain/providers/types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import format from "date-fns/format";
import { MONTH_ACRONYMS, DAYS } from "utils/dates";
import BookingTypeIconRound from "ui/components/BookingTypeIcon";
import Tokens from "ui/components/Tokens";
import Responsive from "ui/Responsive";
import { ModalCloseButton } from "ui/components/mobile/TabletModal";
import { getPhoneErrorMsg, validPhone } from "utils/validation";
import FormInput from "ui/components/Input";
import { getProfile } from "domain/core/auth/selectors";
import { useReduxState } from "re-reduced";
import { addHours } from "date-fns";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "580px !important",
      height: "auto !important",
      minWidth: "320px",
      maxWidth: "calc(100vw) !important",
      // minHeight: "568px",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      [theme.breakpoints.down("xs")]: {
        height: "calc(100vh) !important",
      },
      [theme.breakpoints.down("sm")]: {
        "& *": {
          "& .MuiTypography-subtitle1": {
            fontSize: "14px",
          },
          "& .MuiTypography-body1": {
            fontSize: "14px",
          },

          "& #tokenIcon-inline": {
            width: "22px",
            height: "22px",
          },
        },
      },
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(2),

      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
      },
    },
    button: {
      margin: "auto",
    },
    textField: {
      marginTop: "40px",
      width: "100%",

      "& .MuiInputLabel-shrink": {
        transform: "translate(5px, -25px) scale(1)",

        "@media(max-width: 400px)": {
          transform: "translate(5px, -35px) scale(1)",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "70px !important",
      },
    },
    rootContainer: {
      margin: theme.spacing(2, 4, 2, 4),

      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      flex: "2 1 50px",
      justifyContent: "space-between",
    },
    switchDir: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    subHeading: {
      color: "#030822",

      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        "& *": {
          fontSize: "14px",
        },
      },
    },
    detailsCol: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginBottom: "12px",

      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        width: "100%",
        marginTop: 5,
        marginBottom: "8px",
      },
    },
    detailsRow: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      margin: "auto 0",
    },
    appointmentContainer: {
      padding: theme.spacing(1, 2, 1, 2),
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down("sm")]: {},
    },
    appointmentItem: {
      width: "50%",
      height: "75px",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    bold: {
      fontFamily: "Gilroy Bold",
      overflow: "nowrap",
    },
    container: {
      margin: theme.spacing(0, 2, 0, 2),
    },
    center: {
      textAlign: "center",
    },
    divider: {
      width: "100%",
      margin: "8px 0px",
    },
    fixedContainer: {
      height: "40px",
      marginTop: "0px",
    },
    iconTypography: {
      height: "auto",
      margin: theme.spacing(1),
    },

    section: {
      margin: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "auto",
    },
    title: {
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    icon: {
      marginLeft: 0,
      width: "auto",
    },
    formControl: {
      width: "100%",
      marginBottom: "15px",
      marginTop: "4px",
      minWidth: "320px",
      maxWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "100%",
      },
    },
  })
);

interface Props {
  providerId: string;
  firstName: string;
  lastName: string;
  date: Date;
  cost: number;
  address: string;
  type: BookingType;
  variant: "workshop" | "provider";
  title?: JSX.Element;
  duration: number;
  subtitle?: JSX.Element;
  disableOptions?: boolean;
  dismissModal(): void;
}

export const AppointmentDetail: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.rootContainer}>
        <ModalCloseButton onClose={props.dismissModal} />
        <Grid container direction="row">
          <Grid item className={classes.section}>
            {props.title}
          </Grid>
          {props.subtitle && (
            <Grid item className={classes.section}>
              {props.subtitle}
            </Grid>
          )}
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <BookingDetails
          variant={props.variant}
          type={props.type}
          address={props.address}
          cost={props.cost}
          date={props.date}
          duration={props.duration}
        />
        <Divider variant="middle" className={classes.divider} />

        <Grid
          container
          direction="column"
          className={classes.appointmentContainer}
        >
          {props.children}
        </Grid>
      </div>
    </Card>
  );
};

const BookingConfirmation = React.forwardRef(
  (
    props: Props & {
      variant: "workshop" | "provider";
      onConfirmBooking: (phone: string, message?: string) => void;
    },
    ref
  ) => {
    const classes = useStyles();

    const [textFieldValue, setState] = useState("");

    function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
      setState(e.target.value);
    }

    const { profile } = useReduxState({
      profile: getProfile,
    });

    function validate() {
      let failedPreValidation = false;

      if (!props.disableOptions) {
        if (!validPhone(phone) || phone.length < 8) {
          failedPreValidation = true;
          setPhoneInvalid(true);
        } else {
          setPhoneInvalid(false);
        }
        if (!failedPreValidation) {
          props.onConfirmBooking(phone, textFieldValue);
        }
      } else {
        props.onConfirmBooking("", "");
      }
    }
    const [phone, setPhone] = React.useState(profile.phone);
    const [phoneInvalid, setPhoneInvalid] = React.useState<boolean>(false);
    const getPhoneError = () => {
      return phoneInvalid && (!validPhone(phone) || phone.length < 8);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/\D/g, "");
      setPhone(value);
    };
    const onFocus = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      event.currentTarget.scrollIntoView();
    };

    const getTitle = () => {
      if (props.title) {
        return props.title;
      } else if (props.variant === "provider") {
        return (
          <Typography className={classes.title} variant="h4" gutterBottom>
            Confirm your booking with <br /> {props.firstName} {props.lastName}
          </Typography>
        );
      } else if (props.variant === "workshop") {
        return (
          <Typography className={classes.title} variant="h4" gutterBottom>
            Confirm your booking for <br /> {props.firstName} {props.lastName}
          </Typography>
        );
      }
    };

    return (
      <AppointmentDetail
        {...props}
        title={getTitle()}
        subtitle={
          props.variant === "workshop" ? undefined : props.variant ===
            "provider" ? (
            <Typography className={`${classes.center} ${classes.subHeading}`}>
              Please note all appointments are approximately{" "}
              <b className={classes.bold}>{"1 hour"}</b>{" "}
              {props.type === "In Person" && (
                <React.Fragment>
                  and please aim to{" "}
                  <b className={classes.bold}>arrive 15 minutes before</b> the
                  start time of your appointment.
                </React.Fragment>
              )}
            </Typography>
          ) : (
            <></>
          )
        }
      >
        {!props.disableOptions && (
          <React.Fragment>
            <Grid item>
              <FormInput
                label="Contact Phone Number"
                className={classes.formControl}
                value={phone}
                onChange={handlePhoneChange}
                error={getPhoneError()}
                onFocus={onFocus}
                errorMsg={getPhoneErrorMsg(phone)}
                type="tel"
                id="component-phone"
                required
                autoComplete="current-phone"
              ></FormInput>
            </Grid>
            <Grid item style={{ marginTop: "-15px" }}>
              <Responsive displayIn={["Mobile", "MobileXS"]}>
                <TextField
                  id="outlined-multiline-static"
                  placeholder={`Notes for ${props.firstName} to read before your appointment`}
                  label={`Optional booking notes (for Ignite administration and ${props.firstName})`}
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                  multiline
                  rows="2"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Responsive>
              <Responsive displayIn={["Desktop", "Tablet"]}>
                <TextField
                  id="outlined-multiline-static"
                  placeholder={`Notes for ${props.firstName} to read before your appointment`}
                  label={`Optional booking notes (for Ignite administration and ${props.firstName})`}
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                  multiline
                  rows="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Responsive>
            </Grid>
          </React.Fragment>
        )}
        <Grid item className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => validate()}
          >
            Confirm Booking
          </Button>
        </Grid>

        <Grid item xs={10} lg={10} style={{ alignSelf: "center" }}>
          <p
            className="MuiTypography-root MuiTypography-caption MuiTypography-alignCenter"
            style={{ marginTop: "16px" }}
          >
            You can cancel an appointment up to 24 hours before its due and
            receive a full token refund.
          </p>
        </Grid>
      </AppointmentDetail>
    );
  }
);

const useBookingDetailStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsContainer: {
      display: "flex",
      flexDirection: "row",
      flex: "2 1 50px",
      justifyContent: "space-between",
    },
    switchDir: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    subHeading: {
      color: "#030822",

      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        "& *": {
          fontSize: "14px",
        },
      },
    },
    iconTypography: {
      height: "auto",
      margin: theme.spacing(1),
    },
    title: {
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    icon: {
      marginLeft: 0,
      width: "auto",
    },

    detailsCol: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      marginBottom: "12px",

      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        width: "100%",
        marginTop: 5,
        marginBottom: "8px",
      },
    },
    detailsRow: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      margin: "auto 0",
    },
    appointmentContainer: {
      padding: theme.spacing(1, 2, 1, 2),

      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    appointmentItem: {
      width: "50%",
      height: "75px",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
      height: "40px",
      "& p": {
        lineHeight: "auto",
        margin: "auto 0px",
      },

      "& .MuiChip-root": {
        margin: "auto 10px",
      },
    },
    grid: {
      "& .MuiGrid-item": {
        "& >:first-child": {
          marginBottom: "10px",

          [theme.breakpoints.down("xs")]: {
            marginBottom: "6px",
          },
        },
      },
      "@media(max-width: 450px)": {
        "& .MuiGrid-item": {
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
    },
  })
);

interface BookingDetailsProps {
  type: string;
  cost: number;
  date: Date;
  address: string;
  duration: number;
  variant: "workshop" | "provider";
}

export const BookingDetails = (props: BookingDetailsProps) => {
  const classes = useBookingDetailStyles();

  const date = React.useMemo(() => {
    return new Date(props.date);
  }, [props.date]);
  return (
    <React.Fragment>
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.grid}
        style={{ padding: "10px 0px" }}
      >
        <Grid item xl={6} lg={6} sm={6} xs={5}>
          <Typography variant="subtitle1" color="textSecondary">
            Type
          </Typography>
          <div className={classes.textContainer}>
            <div className={classes.detailsRow}>
              <BookingTypeIconRound
                type={props.type}
                className={classes.icon}
              />
              <Typography className={classes.iconTypography} variant="body1">
                {props.type}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xl={6} lg={6} sm={6} xs={7} style={{ paddingRight: 0 }}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ color: "#5F6272" }}
          >
            Date &amp; Time
          </Typography>
          <div
            className={classes.textContainer}
            style={{ flexDirection: "column" }}
          >
            <Typography variant="body1">
              {DAYS[date.getDay()]}, {date.getDate()}{" "}
              {MONTH_ACRONYMS[date.getMonth()]} {date.getFullYear()} from
              <br />
              {format(date, "h:mm a") +
                " - " +
                format(
                  addHours(date, props.duration > 0 ? props.duration / 60 : 1),
                  "h:mm a"
                )}
            </Typography>
          </div>
        </Grid>
        <Grid item xl={6} lg={6} sm={6} xs={5}>
          <Typography variant="subtitle1" color="textSecondary">
            Cost
          </Typography>
          <div className={classes.textContainer}>
            <Tokens align="left" light>
              {props.cost}
            </Tokens>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BookingConfirmation;
