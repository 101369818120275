import React from "react";

import Filter from "./Filter";
import IconListItem from "./IconListItem";
import MediumIcon from "ui/components/MediumIcon";
import { FilterProps } from "ui/components/filters/Filter";

export const renderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <IconListItem
      key={option}
      option={option}
      selected={selected}
      onClick={callback}
    >
      <MediumIcon medium={option} size={30} />
    </IconListItem>
  );
};

const MediumFilter = (props: FilterProps) => {
  function getOptions(): string[] {
    return ["Read", "Watch", "Do"];
  }

  return (
    <Filter
      border={true}
      values={props.values}
      onFilterChange={props.onFilterChange}
      options={getOptions()}
      disableInputBorder
      disableInput={true}
      title="Type of Content"
      renderOptions={renderOption}
      placeHolder="View all types"
      id="content-type-filter"
    />
  );
};

export default MediumFilter;
