import axios from "axios";

import createClient from "./createClient";

const authClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_0_ENDPOINT,
  timeout: Number(process.env.REACT_APP_API_TIMEOUT || 10000),
  headers: {
    "Content-Type": "application/json",
  },
});

export default createClient(authClient);
