import React from "react";

import Filter from "./Filter";
import { FilterProps } from "ui/components/filters/Filter";
import CheckboxListItem from "./CheckboxListItem";
import { useReduxState } from "re-reduced";
import { getLocations } from "domain/core/app/reducer";

export const renderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <CheckboxListItem
      style={{ width: "97%" }}
      onClick={callback}
      key={option}
      option={option}
      selected={selected}
    />
  );
};
const LocationFilter = (props: FilterProps) => {
  const { locations } = useReduxState({ locations: getLocations });

  return (
    <Filter
      border={false}
      values={props.values}
      onFilterChange={props.onFilterChange}
      options={locations}
      title="Location"
      renderOptions={renderOption}
      disableInputBorder
      placeHolder="Show All Locations"
      id="locations-filter"
    />
  );
};

export default LocationFilter;
