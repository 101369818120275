import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Banner from "ui/components/Banner";
import { useActions, useReduxState } from "re-reduced";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { getResultsStatus, getSearchResults } from "domain/content/reducer";
import ContentCard, {
  WorkshopsCard,
} from "ui/compounds/Models/ContentCard/ContentCard";
import { ContentActions } from "domain/content/actions";
import { REQUEST_STATUS } from "lib/types";
import Button from "@material-ui/core/Button";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import queryString from "query-string";
import MediumFilter from "ui/components/filters/MediumFilter";
import SearchFilter from "ui/components/filters/SearchFilter";
import FilterContainer from "ui/components/filters/FilterContainer";
import DomainFilter, {
  ResourceDomains,
} from "ui/components/filters/DomainFilter";
import DomainIcon from "ui/components/DomainIcon";
import FilterChip from "ui/components/filters/FilterChip";
import MediumIcon from "ui/components/MediumIcon";
import { ReactComponent as SearchIcon } from "assets/icons/magnify.svg";
import { ReactComponent as EmptySearch } from "assets/icons/EmptySearch.svg";
import { Typography } from "@material-ui/core";
import IgniteTagManager from "lib/analytics";
import { Helmet } from "react-helmet";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    loaderContainer: {
      height: "300px",
    },
    chipContainer: {
      padding: "20px 0px 20px 0px",
    },
  })
);

interface FilterState {
  domainFilters: string[];
  contentTypeFilters: string[];
  searchFilters: string[];
}

interface SearchQuery {
  _pageSize: number;
  domainFilters: string[];
  contentTypeFilters: string[];
  searchFilters: string[];
}

const INITIAL_STATE: FilterState = {
  domainFilters: [],
  contentTypeFilters: [],
  searchFilters: [],
};

function Content(props: Props) {
  const [spacing] = React.useState<GridSpacing>(4);
  const classes = useStyles();
  const actions = useActions(ContentActions);

  const [page, setPage] = useState(1);

  function getUrlParams(): URLSearchParams {
    if (!props.location.search) return new URLSearchParams();
    return new URLSearchParams(props.location.search);
  }

  function toArray(obj: string | string[] | null | undefined): string[] {
    if (Array.isArray(obj)) {
      return obj;
    } else if (obj) {
      return new Array(obj);
    } else {
      return [];
    }
  }

  const {
    _pageSize,
    domainFilters,
    searchFilters,
    contentTypeFilters,
  } = getSearchQuery();

  function getSearchQuery(): SearchQuery {
    const ps = queryString.parse(props.location.search);
    let _pageSize = 12;

    const search = getUrlParams();
    _pageSize = parseInt(search.get("pageSize") || "12");
    const domainFilters = toArray(ps.domainFilters);
    const contentTypeFilters = toArray(ps.contentTypeFilters);
    const searchFilters = toArray(ps.searchFilters);
    return { _pageSize, domainFilters, searchFilters, contentTypeFilters };
  }

  const [pageSize] = React.useState<number>(_pageSize);
  const [filters, setFilters] = React.useState<FilterState>({
    domainFilters: domainFilters || INITIAL_STATE.contentTypeFilters,
    contentTypeFilters: contentTypeFilters || INITIAL_STATE.contentTypeFilters,
    searchFilters: searchFilters || [],
  });

  const reduxState = useReduxState({
    searchResults: getSearchResults,
    resultStatus: getResultsStatus,
  });

  const onSubmitSearch = React.useCallback(() => {
    actions.fetchContentSearchResults({
      page: page,
      pageLength: pageSize,
      domainsOfSupport: filters.domainFilters.filter(
        (value) => ResourceDomains.recommended.indexOf(value) === -1
      ),
      contentType: filters.contentTypeFilters,
      tags: filters.domainFilters
        .filter((value) => {
          return ResourceDomains.recommended.indexOf(value) !== -1;
        })
        .map((item) => item.toLowerCase()),
      searchText: filters.searchFilters,
    });

    const tagManagerArgs = {
      dataLayer: {
        event: "ResourceSearchFilters",
        details: {
          domains: filters.domainFilters,
          contentType: filters.contentTypeFilters,
          tags: filters.domainFilters
            .filter((value) => {
              return ResourceDomains.recommended.indexOf(value) !== -1;
            })
            .map((item) => item.toLowerCase()),
          searchText: filters.searchFilters,
        },
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);
  }, [page, pageSize, filters, actions]);

  useEffect(() => {
    onSubmitSearch();
    const search = "?" + queryString.stringify(filters);
    if (props.history.location.search !== search) {
      props.history.replace({
        pathname: "/learn",
        search: search,
      });
    }
  }, [onSubmitSearch, filters, page, props.history]);

  function trackFilter(event: string, delta: string) {
    const tagManagerArgs = {
      dataLayer: {
        event,
        details: {
          domainType: delta,
        },
      },
    };
    IgniteTagManager.dataLayer(tagManagerArgs);
  }

  function onDomainFilterChange(values: string[]) {
    const delta = values.filter(
      (item) => filters.domainFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ResourceFilterDomainAdded", delta[0]);
    }

    setFilters({ ...filters, domainFilters: values });
    setPage(1);
  }

  function onSearchFilterChange(values: string[]) {
    // Looks odd, but prevents removing a search value by entering it twice
    if (filters.searchFilters.length < values.length) {
      const delta = values.filter(
        (item) => filters.searchFilters.indexOf(item) === -1
      );

      if (delta.length !== 0) {
        trackFilter("ResourceFilterSearchAdded", delta[0]);
      }

      setFilters({ ...filters, searchFilters: values });
      setPage(1);
    }
  }

  function onMediumFilterChange(values: string[]) {
    const delta = values.filter(
      (item) => filters.contentTypeFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ResourceFilterContentTypeAdded", delta[0]);
    }

    setFilters({ ...filters, contentTypeFilters: values });
    setPage(1);
  }

  function onClickLoadMore() {
    setPage(page + 1);
  }

  function renderFilterChip() {
    return filters.domainFilters
      .map((domain) => {
        return (
          <FilterChip
            key={domain}
            label={domain}
            onDelete={() => {
              setFilters({
                ...filters,
                domainFilters: filters.domainFilters.filter(
                  (entry) => entry !== domain
                ),
              });

              setPage(1);
            }}
            icon={<DomainIcon key={domain} domain={domain} size="md" />}
          />
        );
      })
      .concat(
        filters.contentTypeFilters.map((type) => {
          return (
            <FilterChip
              key={type}
              label={type}
              onDelete={() => {
                setFilters({
                  ...filters,
                  contentTypeFilters: filters.contentTypeFilters.filter(
                    (entry) => entry !== type
                  ),
                });

                setPage(1);
              }}
              icon={<MediumIcon key={type} medium={type} size={30} />}
            />
          );
        })
      )
      .concat(
        filters.searchFilters.map((type) => {
          return (
            <FilterChip
              key={type}
              label={type}
              onDelete={() => {
                setFilters({
                  ...filters,
                  searchFilters: filters.searchFilters.filter(
                    (entry) => entry !== type
                  ),
                });

                setPage(1);
              }}
              icon={<SearchIcon />}
            />
          );
        })
      );
  }

  function getEmptySearch() {
    return (
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <Grid item>
            <EmptySearch />
          </Grid>
          <Grid item>
            <Typography variant="h5">Sorry, we didn't find anything</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Please try searching for something else
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getLoadingOrLoadMore() {
    if (reduxState.resultStatus === REQUEST_STATUS.Pending) {
      return (
        <Grid item>
          <Grid
            container
            className={classes.loaderContainer}
            alignContent="space-around"
          >
            <CircularIndeterminate />
          </Grid>
        </Grid>
      );
    }

    if (reduxState.searchResults.pagination.hasNext) {
      return (
        <Grid item>
          <Grid
            container
            className={classes.loaderContainer}
            alignContent="space-around"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onClickLoadMore()}
            >
              Load More
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  function renderContentSearchResults() {
    if (
      reduxState.searchResults.items.length === 0 &&
      reduxState.resultStatus === "Fulfilled"
    ) {
      return getEmptySearch();
    }

    if (
      filters.contentTypeFilters.length === 0 &&
      filters.domainFilters.length === 0 &&
      filters.searchFilters.length === 0
    ) {
      return (
        <React.Fragment>
          <Grid key={"Workshops Card"} item>
            <WorkshopsCard />
          </Grid>
          {reduxState.searchResults.items.map((item) => {
            return (
              <Grid key={item.id} item>
                <ContentCard {...item} />
              </Grid>
            );
          })}
        </React.Fragment>
      );
    }

    return reduxState.searchResults.items.map((item) => {
      return (
        <Grid key={item.id} item>
          <ContentCard {...item} />
        </Grid>
      );
    });
  }

  return (
    <div id="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ignite Aotearoa - Resources & Support - Browse Now </title>
        <meta
          name="description"
          content="Ignite Wellbeing is a modern online platform that gives people power to access their choice of support anywhere in NZ. Wellbeing support & mental health specialists. Request demo. "
        />
      </Helmet>
      <Banner
        src={require("assets/banner/ResourceBanner-2.png")}
        align="center"
        styleSheetClass="resource-banner"
      >
        <div>Resources</div>
        <p>A curated collection of wellbeing content</p>
      </Banner>
      <FilterContainer onSubmit={onSubmitSearch}>
        <DomainFilter
          enableCovid
          onFilterChange={onDomainFilterChange}
          values={filters.domainFilters}
        />

        <MediumFilter
          onFilterChange={onMediumFilterChange}
          values={filters.contentTypeFilters}
        />
        <SearchFilter
          onFilterChange={onSearchFilterChange}
          values={filters.searchFilters}
        />
      </FilterContainer>
      <Container maxWidth="xl">
        <div className={classes.chipContainer}>{renderFilterChip()}</div>

        <Grid
          className={classes.root}
          container
          justify="center"
          alignItems="flex-start"
          spacing={spacing}
        >
          {renderContentSearchResults()}
        </Grid>
        <Grid
          container
          justify="center"
          spacing={spacing}
          className={classes.root}
        >
          <Grid item>{getLoadingOrLoadMore()}</Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withRouter(Content);
