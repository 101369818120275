import React, { useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import isSameDay from "date-fns/isSameDay";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import Grid from "@material-ui/core/Grid";
import DatePicker from "ui/components/DatePicker";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Chip, Button } from "@material-ui/core";
import styled from "styled-components";
import { ProviderActions } from "domain/providers/actions";
import IgniteToken from "assets/IgniteToken.svg";
import {
  getCalendar,
  getMonthStatus,
  getDayStatus,
  getBookingStatus,
} from "domain/providers/reducer";
import { BookingType } from "domain/providers/types";
import Responsive from "ui/Responsive";
import TabletModal, {
  ModalCloseButton,
} from "ui/components/mobile/TabletModal";
import { useReduxState, useActions } from "re-reduced";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import * as selectors from "domain/core/selector";
import {
  // addMonths,
  isBefore,
  isSameMonth,
  // isToday
} from "date-fns";
import RequestMoreTokens from "../RequestMoreTokens";
import { FORMAT } from "utils/dates";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "382px",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",
    },
    rootContainer: {
      margin: theme.spacing(2),

      "& > *": {
        marginBottom: theme.spacing(2),
      },
    },
    section: {
      width: "auto",
    },
    availableTimes: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "300px",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    title: {
      textAlign: "center",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      marginBottom: "30px",
      marginTop: "30px",

      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    appointmentChip: {
      width: "105px",
      "& span": {
        overflow: "visible",
        textOverflow: "unset",
      },
    },
    timeChip: {
      width: "4.5rem",
      "& span": {
        overflow: "visible",
        textOverflow: "unset",
      },
    },
    chip: {
      margin: "5px 0px 0px 0px",
      borderRadius: "5px",
      backgroundColor: "#E4E8F4",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
      cursor: "pointer",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#c9bfee",
      },

      "&:hover div": {
        backgroundColor: "#7E49DD64",
      },
    },
    typeChip: {
      "& span": {
        margin: "auto",
        marginLeft: "0px",
      },
    },
    selectedChip: {
      backgroundColor: "#c9bfee",
    },
    icon: {
      margin: "auto",
    },
    bookButton: {
      fontFamily: "Gilroy Bold",
      fontSize: "14px",
      textTransform: "inherit",
      color: "white",
      backgroundColor: "#04aaf0",
      shadow: "none",
      boxShadow: "none",
      height: "40px",
      width: "189px",
    },
    bookingChipContainer: {
      marginBottom: "5px",
      marginTop: "5px",
      maxHeight: "90px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "center",

        "& .MuiGrid-item": {
          padding: "4px",
        },
      },
    },
    mobileFooter: {
      backgroundColor: theme.palette.background.paper,
      display: "none",
      height: 75,
      width: 320,
      zIndex: 9000,
      justifyContent: "space-between",
      boxShadow: `inset ${theme.shadows[4]}`,
      "@media (max-width: 810px)": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100vw",

        "& button": {
          marginRight: "30px",
        },

        "& >:first-child": {
          marginLeft: "30px",
        },
      },
    },
    divider: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    tokenText: {
      margin: "5px",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      color: "black",
    },

    desktop: {
      display: "flex",
      "@media(max-width: 810px)": {
        display: "none",
      },
    },
    loader: {
      margin: "auto",
    },
    tokenContainer: {
      display: "flex",
      margin: "auto 10px",
      maxHeight: "35px",
    },
    tokenTextContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "auto 15px",
      maxHeight: "35px",
      height: "24px",
    },
    tokenCost: {
      margin: "auto",
      ...theme.typography.h5,
      fontFamily: "Gilroy Bold",
      color: "#030822",
    },
    tokenBalance: {
      margin: "auto",
      ...theme.typography.subtitle1,
      fontFamily: "Gilroy Bold",
      color: "#030822",
      marginBottom: "0px",
    },
    tokenNotEnough: {
      color: theme.palette.error.main,
    },
  })
);

const BookingTypeWrapper = styled.div<{ selected: boolean }>`
  border-radius: 5px 0px 0px 5px;
  background-color: ${(props) => (props.selected ? "#7E49DD64" : "#d6dbe9")};
  transition: background-color 0.5s ease-out;
  display: flex;
  width: 30px;
  height: 100%;
`;

interface BookingTypeProps {
  image: string;
  onClick(): void;
  selected?: boolean;
}

function BookingTypeIcon(props: BookingTypeProps) {
  const classes = useStyles();
  return (
    <BookingTypeWrapper onClick={props.onClick} selected={!!props.selected}>
      <img
        className={classes.icon}
        src={require(`assets/booking/${props.image}`)}
        alt="O"
      />
    </BookingTypeWrapper>
  );
}

interface Props {
  providerId: string;
  calendarID: number;
  unitsPerSession: number;
  name: string;
  onConfirmBooking: (date: Date, type: BookingType) => void;
  onClose?: () => void;
  bookingTypes: string[];
}
function BookingCard({
  providerId,
  bookingTypes,
  calendarID,
  name,
  unitsPerSession,
  onConfirmBooking,
  onClose,
}: Props) {
  const classes = useStyles();

  const actions = useActions(ProviderActions);

  const {
    calendar,
    monthStatus,
    dayStatus,
    bookingStatus,
    tokenBalance,
    hasActiveTokenRequest,
    tokenRequestAmount,
    activeTokenRequestStatus,
  } = useReduxState({
    calendar: getCalendar,
    monthStatus: getMonthStatus,
    dayStatus: getDayStatus,
    bookingStatus: getBookingStatus,
    tokenBalance: selectors.getTokenBalance,
    tokenRequestAmount: selectors.getLatestRequestAmount,
    hasActiveTokenRequest: selectors.getHasPendingTokenRequest,
    activeTokenRequestStatus: selectors.getTokenRequestsStatus,
  });

  //eslint-disable-next-line
  useEffect(() => {}, [tokenBalance]);

  const [selectedType, setSelectedType] = React.useState<BookingType>(
    bookingTypes[0].trim() as BookingType
  );

  const [selectedDate, setSelectedDate] = React.useState<Date>(
    new Date(calendar.dates[0])
  );

  const startDate = new Date();
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null);
  const datePickerRef = React.useRef<HTMLDivElement>(null);
  const cardScrollRef = React.useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Date) => {
    if (isBefore(date, new Date())) {
      date = new Date();
    }

    setSelectedDate(date);
    if (!getHasTimeForGivenDate(date)) {
      actions.fetchCalendarDay({
        startDate: format(date, "yyyy-MM-dd"),
        calendarID: calendarID,
      });
    }
  };

  const handleBookingTypeChange = (type: BookingType) => {
    setSelectedType(type);
  };

  const handleTimeSelected = (date: string) => {
    setSelectedTime(date);
  };

  function getErrorMessage() {
    if (calendar.times.length === 0) {
      return (
        <Typography variant="body2" color="error" align="center">
          Sorry there is no available appointment on that day. Please select
          another day or month
        </Typography>
      );
    } else if (bookingStatus.status === "Fulfilled") {
      return <React.Fragment></React.Fragment>;
    }
  }

  function handleRangeChange(date: Date) {
    handleDateChange(date);
    if (
      monthDates().find((item) => isSameDay(new Date(item), date)) === undefined
    ) {
      actions.fetchCalendarMonth({
        startDate: format(date, "yyyy-MM-dd"),
        calendarID: calendarID,
      });
    }
    setCurrentMonth(date);
  }

  const [currentMonth, setCurrentMonth] = React.useState(new Date());

  const monthDates = React.useCallback(() => {
    return calendar.dates.filter((item) =>
      isSameMonth(new Date(item), new Date(currentMonth))
    );
  }, [calendar.dates, currentMonth]);

  function getDatePicker() {
    return (
      <DatePicker
        startDate={startDate}
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        onRangeChange={handleRangeChange}
        type={"month"}
        availableDates={calendar.dates.map((item) => new Date(item))}
      />
    );
  }

  function getTimeChipsBetweenHoursOf(min: number, max: number) {
    const cal = calendar.times;
    return cal
      .filter((item) => {
        const date = new Date(item);
        if (isSameDay(date, selectedDate)) {
          const hour = date.getHours();
          if (hour >= min && hour < max) {
            return true;
          }
        }

        return false;
      })
      .map((date) => {
        const isSelected = selectedTime === date;

        return (
          <div key={date} onClick={() => handleTimeSelected(date)}>
            <Chip
              className={
                isSelected
                  ? `${classes.selectedChip} ${classes.chip} ${classes.timeChip}`
                  : `${classes.chip} ${classes.timeChip}`
              }
              label={format(new Date(date), "h:mm a")}
            />
          </div>
        );
      });
  }

  function confirmBooking() {
    if (selectedTime !== null && isValid(new Date(selectedTime))) {
      onConfirmBooking(new Date(selectedTime), selectedType);
    }
  }

  function getTimes() {
    if (monthStatus.status === "Pending" || dayStatus.status === "Pending") {
      return (
        <div className={`${classes.section} ${classes.availableTimes}`}>
          <CircularIndeterminate className={classes.loader} />
        </div>
      );
    }
    return (
      <div className={`${classes.section} ${classes.availableTimes}`}>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.textCenter}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2" gutterBottom>
              Morning
            </Typography>
            {getTimeChipsBetweenHoursOf(0, 12)}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" gutterBottom>
              Afternoon
            </Typography>
            {getTimeChipsBetweenHoursOf(12, 17)}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" gutterBottom>
              Evening
            </Typography>
            {getTimeChipsBetweenHoursOf(17, 24)}
          </Grid>
        </Grid>
        <div className={classes.section}>{getErrorMessage()}</div>
      </div>
    );
  }

  function getBookingTypeChip(type: BookingType) {
    return (
      <div onClick={() => handleBookingTypeChange(type)}>
        <Chip
          key={type}
          className={
            selectedType === type
              ? `${classes.selectedChip} ${classes.chip} ${classes.typeChip} ${classes.appointmentChip}`
              : `${classes.chip} ${classes.typeChip} ${classes.appointmentChip}`
          }
          label={type}
          icon={
            <BookingTypeIcon
              selected={selectedType === type}
              image={`${type}.svg`}
              onClick={() => handleBookingTypeChange(type)}
            />
          }
        />
      </div>
    );
  }

  const [requestMoreOpen, setRequestMoreOpen] = React.useState(false);

  function getHasTimeForGivenDate(date: Date): boolean {
    const cal = calendar.times.map((item) => new Date(item));
    const array = cal.find((item) => isSameDay(item, date));
    return array === undefined ? false : true;
  }

  function renderTokens() {
    return (
      <div className={classes.tokenContainer}>
        <img
          style={{ width: 32, height: 32 }}
          src={IgniteToken}
          alt="tokens"
        />
        <div className={classes.tokenTextContainer}>
          <Typography
            className={`${classes.tokenCost} ${
              tokenBalance < unitsPerSession && classes.tokenNotEnough
            }`}
            variant="h5"
          >
            {unitsPerSession + "/"}
          </Typography>
          <Typography
            className={`${classes.tokenBalance} ${
              tokenBalance < unitsPerSession && classes.tokenNotEnough
            }`}
            variant="subtitle1"
          >
            {tokenBalance}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <TabletModal
        onClose={() => setRequestMoreOpen(false)}
        label="Request More Tokens"
        zIndex={1200}
        open={requestMoreOpen}
      >
        <RequestMoreTokens dismiss={() => setRequestMoreOpen(false)} />
      </TabletModal>
      <Card className={`${classes.root}`} ref={cardScrollRef}>
        <div className={classes.rootContainer}>
          <Responsive displayIn={["Mobile", "MobileXS"]}>
            <ModalCloseButton onClose={onClose} />
          </Responsive>
          <div className={classes.section}>
            <Typography className={classes.title} variant="h6" gutterBottom>
              Make a booking with {name}
            </Typography>

            <Typography color="textSecondary" variant="subtitle1">
              Type
            </Typography>
            <Grid
              container
              spacing={2}
              className={classes.bookingChipContainer}
            >
              {bookingTypes.map((item) => (
                <Grid item key={item}>
                  {getBookingTypeChip(item.trim() as BookingType)}
                </Grid>
              ))}
            </Grid>

            <Typography variant="caption" gutterBottom>
              Note: All appointments are approximately 1 hour
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div
            className={classes.section}
            id="booking-date-picker"
            ref={datePickerRef}
          >
            <Typography color="textSecondary" variant="subtitle1" gutterBottom>
              Date &amp; Time
            </Typography>
            {getDatePicker()}
          </div>
          {getTimes()}
          <Divider className={classes.desktop} />
          <Grid
            className={`${classes.section} ${classes.desktop}`}
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            spacing={3}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" gutterBottom>
                    Selected Date & Time
                  </Typography>
                </Grid>
                {selectedTime !== null && isValid(new Date(selectedTime)) && (
                  <Grid item>
                    <Typography variant="body1">
                      {selectedTime !== null
                        ? FORMAT(new Date(selectedTime)) +
                          " at " +
                          format(new Date(selectedTime), "h:mm:a")
                        : ""}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                {renderTokens()}
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.bookButton}
                onClick={confirmBooking}
                disabled={!selectedTime || tokenBalance < unitsPerSession}
              >
                Book Appointment
              </Button>
            </Grid>
          </Grid>
          {tokenBalance < unitsPerSession ? (
            !hasActiveTokenRequest &&
            activeTokenRequestStatus.status === "Fulfilled" ? (
              <div style={{ display: "block", width: "100%" }}>
                <Typography
                  variant="caption"
                  color="error"
                  className={classes.tokenNotEnough}
                >
                  You do not have enough tokens to book this appointment
                </Typography>
                <br />
                <Button
                  variant="text"
                  style={{ marginTop: "8px" }}
                  color="secondary"
                  onClick={() => setRequestMoreOpen(true)}
                >
                  Request More
                </Button>
              </div>
            ) : (
              <div style={{ display: "block", width: "100%" }}>
                <Typography variant="caption" color="error">
                  You do not have enough tokens to book this appointment. Good
                  news, your request for {tokenRequestAmount} tokens is
                  currently under review.
                </Typography>
              </div>
            )
          ) : null}
        </div>
      </Card>
      <div className={classes.mobileFooter}>
        {renderTokens()}
        <Button
          variant="contained"
          color="secondary"
          onClick={confirmBooking}
          disabled={!selectedTime || tokenBalance < unitsPerSession}
        >
          Book Appointment
        </Button>
      </div>
    </React.Fragment>
  );
}

export default BookingCard;
