import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { ReactComponent as Chevron } from "assets/icons/Icon-Left-Arrow.svg";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { keys as domainKeys } from "domain/core/app/utils";
import DomainIcon from "ui/components/DomainIcon";
import { Button, Typography } from "@material-ui/core";
import { kvp as DomainPairs } from "domain/core/app/utils";
import Rating from "ui/components/assessment/RatingButton";
import { WellbeingActions } from "domain/wellbeing/actions";
import { useActions, useReduxState } from "re-reduced";
import {
  getSaveStatus,
  getResultStatus,
  getTestResult,
  getAnswers,
} from "domain/wellbeing/reducer";
import Grid from "@material-ui/core/Grid";
import { REQUEST_STATUS } from "lib/types";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import DomainProgressBar from "ui/compounds/DomainProgressBar";
import ArrowTooltip from "ui/components/Tooltip";
import Responsive from "ui/Responsive";
import IgniteTagManager from "lib/analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
      minHeight: "600px",
    },
    grow: {
      flexGrow: 1,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    spreadContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    card: {
      width: "750px",
      height: "400px",
    },

    text: {
      color: "grey",
    },
    questionContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",

      "& p": {
        margin: "10px",
      },
    },
    questionDomainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",

      "& .MuiTypography-root": {
        height: "25px",
        margin: "auto",
      },
    },
    questionTitle: {
      textAlign: "center",
      margin: "20px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        margin: "20px 0px",
      },
    },
    questionSubtitle: {
      textAlign: "center",
    },

    ratingsContainer: {
      position: "relative",
      display: "flex",
      width: "auto",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: "auto",

      "& h6": {
        marginTop: "10px",
      },
    },
    ratingText: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "70px",
        textAlign: "center",
        "&:nth-of-type(1)": {
          marginLeft: "-10px",
        },
        "&:nth-of-type(2)": {
          marginRight: "-10px",
        },
      },
    },
    ratingSection: {
      marginTop: "25px",
      marginBottom: "25px",

      "& >:first-child": {
        marginLeft: "0px",
      },
      "& >:last-child": {
        marginRight: "0px",
      },
    },
    ratingsButton: {
      padding: "0px",
      margin: "20px",
      maxHeight: "50px",
      maxWidth: "50px",

      "& .MuiButton-label": {
        height: "50px",
        borderRadius: "100%",
        border: "1px solid #cfd8dc",
      },
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      minHeight: "20px",
    },
    skipButton: {
      margin: "auto",
      marginTop: "100px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "auto",
      },
      border: "0px solid transparent",

      "& span": {
        textTransform: "none",
      },
    },
    borderlessBackButton: {
      "& span": {
        textTransform: "none",
        justifyContent: "left",
      },
    },
    helpContainer: {
      display: "flex",
      flexDirection: "row",
      width: "140px",
      height: "20px",
      justifyContent: "center",
      alignItems: "center",

      borderBottom: "1px solid grey",
      "& > *": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        /* identical to box height, or 133% */

        textAlign: "center",
        letterSpacing: " 0.4px",

        /* ◇ Grey */

        color: "#5F6272",
      },
      "& svg": {
        margin: "10px",
      },
    },
  })
);

const ratings = [
  "Not Satisfied",
  "Slightly Satisfied",
  "Satisfied",
  "Very Satisfied",
  "Highly Satisfied",
];

interface QuestionSet {
  [index: string]: {
    [index: string]: {
      question: string;
      subCopy: string;
    };
  };
}
const questions: QuestionSet = {
  health: {
    mind: {
      question: "your Mind Health",
      subCopy:
        "Mind Health considers feelings of happiness, stress, anxiety, depression, grief, your overall mindset and motivation.",
    },
    body: {
      question: "your Body Health",
      subCopy:
        "Body Health considers your physical health, nutrition, exercise, sleep and the impact of any health conditions.",
    },
    habits: {
      question: "your Habits",
      subCopy:
        "Habits considers things you do that have an impact on your overall wellbeing when out of balance like alcohol, drugs, gambling, disrupted sleep patterns.",
    },
    home: {
      question: "your Home Life",
      subCopy:
        "Home Life considers your living situation and conditions such as affordability, security, comfort and your influence over where you live.",
    },
  },
  life: {
    relationships: {
      question: "your Relationships",
      subCopy:
        "Relationships considers friendships, family and household relationships, parenting, intimate relationships and break-ups.",
    },
    occupation: {
      question: "your Occupation",
      subCopy:
        "Occupation considers your education, training, career planning and jobs you fill your day with.",
    },
    finances: {
      question: "your Finances",
      subCopy:
        "Finances considers budgeting, management of debt, savings and investments, planning for retirement and your financial comfort.",
    },
    recreation: {
      question: "your Recreation",
      subCopy:
        "Recreation considers activites that bring you joy such as sports, hobbies, interests.",
    },
  },
  meaning: {
    identity: {
      question: "your Identity",
      subCopy:
        "Identity considers how you see yourself and your place in the world. It covers aspects of who you are like ethnicity, heritage, gender identity and sexuality.",
    },
    beliefs: {
      question: "your Beliefs",
      subCopy:
        "Beliefs considers your connection to meaning and purpose in your life through philosophy, religion, spirituality or nature.",
    },
    culture: {
      question: "your Culture",
      subCopy:
        "Culture considers your connection to and understanding of your ethnicity, heritage (whakapapa), cultural identity and practices (tikanga). ",
    },
    connectedness: {
      question: "your Connectedness",
      subCopy:
        "Connectedness considers your connection with community groups, friends and family, iwi, churches, nature and interest groups.",
    },
  },
};

interface Props extends RouteComponentProps {}

function Assessment(props: Props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentRating, setRating] = React.useState(-1);

  const reduxState = useReduxState({
    saveStatus: getSaveStatus,
    resultStatus: getResultStatus,
    testResult: getTestResult,
    answers: getAnswers,
  });

  const actions = useActions(WellbeingActions);
  const [kvp, setKvp] = React.useState(
    DomainPairs.map((pair) => {
      return {
        ...pair,
        rating: -1,
        priority: 0,
      };
    })
  );

  useEffect(() => {
    if (reduxState.resultStatus === REQUEST_STATUS.Fulfilled) {
      let path = generatePath("/assessmentResults/:id", {
        id: reduxState.testResult.id,
      });
      props.history.push(path);
    }
    if (
      reduxState.saveStatus === REQUEST_STATUS.Fulfilled &&
      reduxState.answers.length !== 0
    ) {
      props.history.push("/prioritise");
    }
  }, [reduxState, props]);

  const skipButton = () => {
    return (
      <Button
        className={classes.skipButton}
        variant="text"
        color="secondary"
        onClick={() => {
          const tagManagerArgs = {
            dataLayer: {
              event: "QuestionSkipped",
              details: {
                domain: kvp[activeStep].label,
                index: activeStep,
              },
            },
          };

          IgniteTagManager.dataLayer(tagManagerArgs);

          nextStep();
        }}
      >
        Skip Question
      </Button>
    );
  };
  const activeDomain = () => {
    let flat_key = kvp[activeStep];
    let index = domainKeys.indexOf(flat_key.key);

    return index;
  };

  function updateRating(value: number) {
    const tagManagerArgs = {
      dataLayer: {
        event: "QuestionAnswered",
        details: {
          domain: kvp[activeStep].label,
          rating: value,
        },
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);

    setRating(value);
    autoNext(value);
  }

  function autoNext(value: number) {
    if (activeStep < kvp.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        let newSet = kvp;
        let current = newSet[activeStep];
        current.rating = value;
        setKvp(newSet);
        setRating(kvp[activeStep + 1].rating);
      }, 500);
    } else {
      let newSet = kvp;
      let current = newSet[activeStep];
      current.rating = value;
      setKvp(newSet);
      actions.saveTest({
        answers: newSet,
      });

      const tagManagerArgs = {
        dataLayer: {
          event: "QuestionsFinished",
        },
      };

      IgniteTagManager.dataLayer(tagManagerArgs);
    }
  }

  function nextStep() {
    if (activeStep < kvp.length - 1) {
      setActiveStep(activeStep + 1);
      let newSet = kvp;
      newSet[activeStep].rating = currentRating;
      setKvp(newSet);
      setRating(-1);
    } else {
      let newSet = kvp;
      newSet[activeStep].rating = currentRating;
      setKvp(newSet);
      actions.saveTest({
        answers: newSet,
      });

      const tagManagerArgs = {
        dataLayer: {
          event: "QuestionsFinished",
        },
      };

      IgniteTagManager.dataLayer(tagManagerArgs);
    }
  }

  function backStep() {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setRating(-1);
      let newSet = kvp;
      kvp[activeStep - 1].rating = -1;
      setKvp(newSet);
    }
  }

  function getMain() {
    switch (reduxState.saveStatus) {
      case REQUEST_STATUS.Idle:
        return getTest();
      case REQUEST_STATUS.Pending:
        return getLoader();

      case REQUEST_STATUS.Fulfilled:
        return getLoader();

      default:
        return getTest();
    }
  }

  function getLoader() {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.questionContainer} id="questions-container">
          <div className={classes.questionDomainContainer}>
            <CircularIndeterminate />
          </div>
        </div>
      </Container>
    );
  }

  function getTest() {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.buttonsContainer}>
          {activeStep > 0 && (
            <Button
              className={classes.borderlessBackButton}
              variant="text"
              color="secondary"
              onClick={() => backStep()}
              startIcon={<Chevron />}
            >
              Back
            </Button>
          )}
        </div>
        <div className={classes.questionContainer} id="questions-container">
          <div className={classes.questionDomainContainer}>
            <DomainIcon
              borderless={true}
              domain={kvp[activeStep].label}
              size="lg"
            />
            <Typography variant="subtitle1" color="textSecondary">
              My {kvp[activeStep].label}
            </Typography>
          </div>
          <Typography className={classes.questionTitle} variant="h4">
            On a scale of 1 to 5, how satisfied are you with{" "}
            {
              questions[kvp[activeStep].key][
                kvp[activeStep].label.toLowerCase()
              ].question
            }
            ?
          </Typography>
          <Responsive displayIn={["Desktop", "Tablet"]}>
            <Typography variant="body1" align="center">
              {
                questions[kvp[activeStep].key][
                  kvp[activeStep].label.toLowerCase()
                ].subCopy
              }
            </Typography>
          </Responsive>
          <Responsive displayIn={["Mobile", "MobileXS"]}>
            <ArrowTooltip
              title={
                <Typography variant="body1">
                  {
                    questions[kvp[activeStep].key][
                      kvp[activeStep].label.toLowerCase()
                    ].subCopy
                  }
                </Typography>
              }
            >
              <div className={classes.helpContainer}>
                <Typography variant="caption">What does this mean?</Typography>
              </div>
            </ArrowTooltip>
          </Responsive>
        </div>
        <Grid
          className={classes.ratingSection}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {ratings.map((rating, index) => {
            return (
              <Grid
                item
                className={classes.ratingsContainer}
                key={rating + index}
              >
                <Rating
                  delay={(index + 1) * 0.08}
                  selected={index + 1 === currentRating}
                  onClick={() => updateRating(index + 1)}
                >
                  <p>{index + 1}</p>
                </Rating>
              </Grid>
            );
          })}
        </Grid>
        <Responsive displayIn={["Desktop", "Tablet"]}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="subtitle1" className={classes.ratingText}>
                {ratings[0]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" className={classes.ratingText}>
                {ratings[4]}
              </Typography>
            </Grid>
          </Grid>
        </Responsive>

        <Grid container justify="center">
          <Grid item>{skipButton()}</Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <DomainProgressBar
        {...props}
        progress={(activeStep / kvp.length) * 100}
        getActiveIndex={() => activeDomain()}
      />
      {getMain()}
    </React.Fragment>
  );
}
export default withRouter(Assessment);
