import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import TagManager from "react-gtm-module";
import App from "./ui/App";

import "./fonts/Gilroy-Medium.ttf";
import "./fonts/Gilroy-Bold.woff2";
import "./fonts/Gilroy-ExtraBold.woff2";
import "./fonts/Gilroy-Black.woff2";
import "./fonts/Gilroy-Heavy.woff2";
import "./fonts/Gilroy-Regular.ttf";
import "./fonts/Gilroy-RegularItalic.ttf";
import "./fonts/Gilroy-SemiBold.ttf";
import "./fonts/Gilroy-Thin.ttf";
import "./fonts/Gilroy-ThinItalic.ttf";
import "./index.scss";

const tagManagerArgs = {
  gtmId: "GTM-PMRGX6Q",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root")
);
