import * as React from "react";
import styled from "styled-components";
import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as RightIcon } from "assets/booking/RightIcon.svg";
import { ReactComponent as LeftIcon } from "assets/booking/LeftIcon.svg";
import { breakpointValues } from "theme";
import Responsive from "ui/Responsive";
import { differenceInDays } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    handleWrapper: {
      textAlign: "center",
      cursor: "pointer",
      margin: "auto",
      fontWeight: 400,
      fontFamily: "Gilroy Bold",
    },
    datePickerWrapper: {
      display: "flex",
      padding: theme.spacing(2),
    },
    dateSlotWrapper: {},
  })
);

const rng = (len: number) => [...new Array(len)];
const spacingUnit = 2;
const spacing = (n: number) => `${spacingUnit * n}rem`;
type Direction = "left" | "right";

interface HandleProps {
  enabled: boolean;
  direction: Direction;
  onClick(): void;
}
interface HandleWrapperProps {
  onClick(): void;
}

const HandleWrapper: React.FunctionComponent<HandleWrapperProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.handleWrapper} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export function Handle(props: HandleProps) {
  const getIcons = () =>
    props.direction === "right" ? (
      <RightIcon
        style={props.enabled ? { stroke: "#04aaf0" } : { stroke: "#B1B6CB" }}
      />
    ) : (
      <LeftIcon
        style={props.enabled ? { stroke: "#04aaf0" } : { stroke: "#B1B6CB" }}
      />
    );

  return <HandleWrapper onClick={props.onClick}>{getIcons()}</HandleWrapper>;
}
const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: ${spacing(0.1)};
`;

const DateSlotWrapper = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${spacing(0.05)};

  font-family: "Gilroy Regular";
  font-size: 14px;
  color: #030822;

  width: 41px;
  height: 61px;
  cursor: pointer;

  @media (max-width: ${breakpointValues.sm}px) {
    max-width: 40px;
    max-height: 53px;
  }

  justify-content: space-around;
  transition: background-color 0.2s ease-out;
  border-radius: 8px;
  ${(props) =>
    !props.active
      ? `
    &:hover {
      background-color: #c9bfeeaa;
      cursor: pointer;
    }`
      : ``}

  ${(props) =>
    props.active ? `background-color: #c9bfee` : `background-color: #ffffff00`}
  ${(props) => (props.disabled ? "opacity: .4" : "")};
`;

const SlotHeading = styled.div`
  padding-bottom: ${spacing(0.1)};
  text-align: center;
  font-family: "Gilroy Regular";
  padding-top: 8px;
  font-size: 14px;
  color: #030822;

  @media (max-width: ${breakpointValues.sm}px) {
    padding-top: 4px;
    padding-bottom: ${spacing(0.05)};
  }
`;

const SlotBody = styled.div`
  text-align: center;
  font-family: "Gilroy Regular";
  font-size: 14px;
  margin-bottom: 5px;
  color: #030822;
`;

const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateSlots: React.FC<{
  date: Date;
  active?: boolean;
  disabled: boolean;
  onClick(): void;
}> = (props) => {
  return (
    <DateSlotWrapper
      active={props.active}
      disabled={props.disabled}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <Typography variant="subtitle1">
        <SlotHeading>{WEEK_DAYS[props.date.getDay()]}</SlotHeading>
      </Typography>
      <Typography variant="subtitle1">
        <SlotBody>{props.date.getDate()}</SlotBody>
      </Typography>
    </DateSlotWrapper>
  );
};

interface DatePickerProps {
  start: Date;
  monthDate?: Date;
  selectedDate: Date;
  visibleDaysLength?: number;
  shouldDisableDate: (date: Date) => boolean;
  onChange?: (selectedDate: Date) => void;
  onRangeChange?: (date: Date) => void;
}

const WeekDatePicker: React.FC<DatePickerProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(
    differenceInDays(props.selectedDate, new Date())
  );

  const range = rng(props.visibleDaysLength || 7);

  const visibleDates: Date[] = React.useMemo(
    () => range.map((_, i) => addDays(props.start, i + selectedIndex)),
    [props.start, range, selectedIndex]
  );

  const handleSlotClick = React.useCallback(
    (date: Date) => () => {
      if (props.onChange) {
        props.onChange(date);
      }
    },
    [props]
  );

  React.useEffect(() => {
    setSelectedIndex(differenceInDays(props.selectedDate, new Date()));
  }, [setSelectedIndex, props.selectedDate]);

  const handleDirectionClick = React.useCallback(
    (direction: Direction) => () => {
      switch (direction) {
        case "left":
          setSelectedIndex((d) => (d >= 7 ? d - 7 : 0));
          break;
        case "right":
          if (props.onRangeChange) {
            props.onRangeChange(addDays(props.start, selectedIndex + 7));
          }
          setSelectedIndex((d) => d + 7);
          break;
      }
    },
    [props, selectedIndex]
  );

  function getMonthTitle() {
    return (
      MONTH_NAMES[props.selectedDate.getMonth()] +
      " " +
      props.selectedDate.getFullYear()
    );
  }

  const renderDateSlot = (date: Date, index: number) => {
    return (
      <DateSlots
        active={isSameDay(date, props.selectedDate)}
        disabled={props.shouldDisableDate(date)}
        key={date.toString()}
        date={date}
        onClick={handleSlotClick(date)}
      />
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Responsive displayIn={["Mobile", "MobileXS"]}>
          <Handle
            enabled={selectedIndex > 0}
            direction="left"
            onClick={handleDirectionClick("left")}
          />
        </Responsive>
        <Typography
          style={{
            textAlign: "center",
            margin: "5px auto",
            color: "black",
          }}
          variant="subtitle1"
        >
          {getMonthTitle()}
        </Typography>
        <Responsive displayIn={["Mobile", "MobileXS"]}>
          <Handle
            enabled={true}
            direction="right"
            onClick={handleDirectionClick("right")}
          />
        </Responsive>
      </div>

      <DatePickerWrapper>
        <Responsive displayIn={["Tablet", "Desktop"]}>
          <Handle
            enabled={selectedIndex > 0}
            direction="left"
            onClick={handleDirectionClick("left")}
          />
        </Responsive>

        {visibleDates.map(renderDateSlot)}
        <Responsive displayIn={["Tablet", "Desktop"]}>
          <Handle
            enabled={true}
            direction="right"
            onClick={handleDirectionClick("right")}
          />
        </Responsive>
      </DatePickerWrapper>
    </div>
  );
};

export default WeekDatePicker;
