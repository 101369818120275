import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete, {
  RenderOptionState,
  RenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { Typography, useMediaQuery } from "@material-ui/core";
import { ReactComponent as UpArrow } from "assets/icons/UpArrow.svg";
import { ReactComponent as DownArrow } from "assets/icons/DownArrow.svg";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

export interface FilterProps {
  onFilterChange: (selected: string[]) => void;
  values: string[];
  forceDesktop?: boolean;
  formControlClass?: string;
  disableWrapper?: boolean;
  disableInputBorder?: boolean;
  error?: boolean;
}
interface Props extends RouteComponentProps<{ max: string }> {
  border: boolean;
  options: string[];
  overrideIcon?: JSX.Element;
  title: string;
  groupBy?: (option: string) => string;
  placeHolder: string;
  freeSolo?: boolean;
  id: string;
  disableInput?: boolean;
  onFocus?: ((event: React.FocusEvent<HTMLDivElement>) => void) | undefined;
  singular?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      color: "black",
      fontSize: 100,
    },
    listRoot: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 300,
    },
    mobileList: {
      paddingBottom: "0rem",
    },
    hideInput: {
      "& *": {
        cursor: "pointer",
      },
      "& * input": {
        opacity: "0",
      },
    },
    disableLabelTransform: {
      "& .MuiInputLabel-shrink": {
        display: "none",
      },
    },

    errorInputBorder: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.error.dark} !important`,
      },
    },

    disableInputBorder: {
      "& * ": {
        "& .MuiInput-root": {
          border: "0px",
          padding: "0px",
        },

        "& * .MuiInputLabel-formControl": {
          transform: "translate(10px, 20px)",
        },
      },
    },
    containedWidth: {
      width: "calc(100% - 16px)",
    },
    multiselectOverride: {
      borderBottom: "0px solid rgba(0, 0, 0, 0.42)",

      "& *": {
        color: "black",
      },

      "& .MuiInput-root": {
        margin: "0px",
        paddingRight: "15px",
        maxWidth: "100%",
        color: "black",

        "& .MuiInputBase-inputAdornedEnd": {
          fontFamily: "Gilroy Regular",
          color: "black",
        },
      },

      "& .MuiInput-underline:before": {
        border: "0px",
      },

      "& svg": {
        float: "right",
      },
    },
    autocomplete: {
      border: "0px solid black",
      width: "100%",
    },
    listSubHeader: {
      padding: 0,
      backgroundColor: "white",
    },
    filterItem: {
      flex: "1 1 300px",
      minWidth: "300px",
      height: "auto",
      padding: "25px 10px 25px 30px",
      "& .MuiFormControl-root": {
        marginLeft: "0px",
      },
    },
    filterSection: {
      flex: "1 1 250px",
      minWidth: "250px",
      height: "auto",
      padding: "25px 0px",
      marginLeft: "25px",
      "& .MuiFormControl-root": {
        marginLeft: "0px",
      },
    },
    adjustedFormControlLabel: {
      "& .MuiInputLabel-formControl": {
        transform: "translate(15px, 20px)",
      },
    },
    filterBorder: {
      borderRight: "2px solid #D7DAE8",
    },
    cursor: {
      cursor: "pointer",
    },

    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      minWidth: 120,
    },
    shrinkHidden: {
      "& .MuiInputLabel-shrink": {
        display: "none !important",
      },
    },
  })
);
interface GroupedFilter {
  groupBy: string;
  jsx: JSX.Element;
}
const Filter = (
  props: Props &
    FilterProps & {
      renderOptions: (
        option: string,
        selected: boolean,
        callback?: () => void
      ) => JSX.Element;
    }
) => {
  const classes = useStyles();
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  const values = props.values;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const autoCompleteClass = clsx(
    classes.autocomplete,
    !props.disableInput ? classes.disableLabelTransform : "",
    props.disableInputBorder && classes.disableInputBorder
  );

  function onChangeValues(value: string) {
    let index = values.indexOf(value);

    let newFilterValues = values;
    if (index < 0) {
      newFilterValues = values.concat(value);
    } else {
      newFilterValues = values.filter((entry) => entry !== value);
    }

    props.onFilterChange(newFilterValues);

    if (!!props.singular) {
      handleOnOpen(false);
    }
  }

  const handleOnOpen = (open: boolean) => {
    if (!isMobile) {
      const appbar = document.getElementById("app-bar");
      if (appbar != null) {
        appbar.style.zIndex = open ? "1900" : "1300";
      }
    }

    setfilterOpen(open);
  };

  function interceptAutocompleteParams(params: RenderInputParams) {
    let override = { ...params };
    let jsxIcon = props.overrideIcon ? (
      props.overrideIcon
    ) : filterOpen ? (
      <UpArrow
        onClick={() => {
          setfilterOpen(!filterOpen);
        }}
      />
    ) : (
      <DownArrow
        onClick={() => {
          setfilterOpen(!filterOpen);
        }}
      />
    );
    override.InputProps.endAdornment = jsxIcon;

    //@ts-ignore
    if (props.disableInput && !!override.inputProps.ref.current) {
      //@ts-ignore
      override.inputProps.ref.current.readOnly = true;
      override.InputLabelProps = {
        ...override.InputLabelProps,
        shrink: false,
      };
    }

    return override;
  }

  const renderOption = (option: string, { selected }: RenderOptionState) => {
    let select = values.indexOf(option) !== -1;

    return props.renderOptions(option, select);
  };

  const mobile = () => {
    let options = props.options;

    let keys = [] as string[];
    let jsx = [] as JSX.Element[];

    if (props.groupBy !== undefined) {
      options.forEach((item) => {
        //@ts-ignore this has been checked above.
        let groupKey = props.groupBy(item);
        if (keys.indexOf(groupKey) === -1) {
          keys.push(groupKey);
          jsx.push(
            <ListSubheader className={classes.listSubHeader} key={groupKey}>
              {groupKey}
            </ListSubheader>
          );
        }

        jsx.push(
          props.renderOptions(item, props.values.indexOf(item) > -1, () =>
            onChangeValues(item)
          )
        );
      });

      return jsx;
    }

    let children = props.options.map((item) => {
      let filter = props.renderOptions(
        item,
        props.values.indexOf(item) > -1,
        () => onChangeValues(item)
      );

      return filter;
    });

    return (
      <React.Fragment>
        <Grid container className={classes.mobileList}>
          {children}
        </Grid>
      </React.Fragment>
    );
  };
  const desktop = () => {
    return props.singular ? singular() : multiple();
  };

  const singular = () => {
    return (
      <FormControl className={props.formControlClass || classes.formControl}>
        <Autocomplete
          freeSolo={props.freeSolo}
          value={props.values[0]}
          onClick={(e) => !!props.singular && handleOnOpen(!filterOpen)}
          onClose={(e) => handleOnOpen(false)}
          onOpen={(e) => !props.singular && handleOnOpen(true)}
          inputMode={!props.singular ? "search" : "none"}
          multiple={false}
          options={props.options}
          id={props.id}
          disableCloseOnSelect
          onChange={(e, val) => onChangeValues(!!val ? val.toString() : "")}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...interceptAutocompleteParams(params)}
              className={`${classes.multiselectOverride} ${
                props.disableInput ? classes.hideInput : ""
              } ${
                props.disableInputBorder
                  ? classes.containedWidth
                  : classes.adjustedFormControlLabel
              }
              ${props.error ? classes.errorInputBorder : ""}`}
              label={props.values[0]}
              variant="standard"
              fullWidth
            />
          )}
        />
      </FormControl>
    );
  };

  const multiple = () => {
    return (
      <FormControl className={props.formControlClass || classes.formControl}>
        <Autocomplete
          freeSolo={props.freeSolo}
          onFocus={props.onFocus}
          onClick={(e) => !!props.singular && handleOnOpen(!filterOpen)}
          onClose={(e) => handleOnOpen(false)}
          onOpen={(e) => !props.singular && handleOnOpen(true)}
          inputMode={!props.singular ? "search" : "none"}
          open={filterOpen}
          className={autoCompleteClass}
          autoComplete={!props.freeSolo}
          autoHighlight={!props.freeSolo}
          multiple
          disableCloseOnSelect
          id={props.id}
          value={[]}
          onChange={(e, val) => onChangeValues(!!val ? val.toString() : "")}
          options={props.options}
          groupBy={props.groupBy}
          getOptionLabel={(option: string) => {
            return option;
          }}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...interceptAutocompleteParams(params)}
              className={`${classes.multiselectOverride} ${
                props.disableInput ? classes.hideInput : ""
              } ${
                props.disableInputBorder
                  ? classes.containedWidth
                  : classes.adjustedFormControlLabel
              } 
              ${props.error ? classes.errorInputBorder : ""}`}
              label={
                !props.singular
                  ? filterOpen && !props.disableInput
                    ? ""
                    : props.placeHolder
                  : props.values[0]
              }
              variant="standard"
              fullWidth
            />
          )}
        />
      </FormControl>
    );
  };

  function render() {
    if (props.disableWrapper) {
      if (isMobile && !props.forceDesktop) return mobile();
      else return desktop();
    } else
      return (
        <div
          className={`${classes.filterSection} ${
            props.border && !isMobile ? classes.filterBorder : ""
          }`}
          key={props.id}
        >
          <Typography variant="h6">{props.title}</Typography>
          {isMobile && !props.forceDesktop ? mobile() : desktop()}
        </div>
      );
  }

  return <React.Fragment>{render()}</React.Fragment>;
};

export default withRouter(Filter);
