import { forwardWatchers } from "lib/sagas";
import core from "domain/core/sagas";
import content from "domain/content/sagas";
import providers from "domain/providers/sagas";
import dashboard from "domain/dashboard/sagas";
import wellbeing from "domain/wellbeing/sagas";
import workshops from "domain/workshops/sagas";

export default forwardWatchers([
  core,
  content,
  providers,
  dashboard,
  wellbeing,
  workshops,
]);
