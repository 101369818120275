import React, { FunctionComponent } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MobileFilterContainer, {
  Props as MobileProps,
} from "./MobileFilterContainer";
import Responsive from "ui/Responsive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "-110px",
      [theme.breakpoints.down("lg")]: {
        marginTop: "0px",
      },
      position: "relative",
    },
    card: {
      height: "131px",

      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },

      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      flexWrap: "wrap",

      backgroundColor: theme.palette.background.paper,
    },
    filterContainer: {
      marginTop: "2rem",
    },
  })
);

const FilterContainer: FunctionComponent<MobileProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Responsive displayIn={["Desktop"]}>
        <div className={classes.filterContainer}>
          <Container maxWidth="xl">
            <Card className={classes.card}>{props.children}</Card>
          </Container>
        </div>
      </Responsive>
      <Responsive displayIn={["MobileXS", "Mobile", "Tablet"]}>
        <MobileFilterContainer onSubmit={props.onSubmit}>
          {props.children}
        </MobileFilterContainer>
      </Responsive>
    </div>
  );
};

export default FilterContainer;
