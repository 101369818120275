import { SagaIterator } from "redux-saga";
import { takeLatest, all, put, call, delay } from "redux-saga/effects";
import { Action } from "re-reduced";

import { setToken } from "lib/apiClient/api";

import actions from "domain/core/actions";
import * as api from "domain/core/auth/api";
import {
  LoginPayload,
  Auth0Response,
  ForgotPasswordPayload,
  UserProfile,
  EditableAccountInfo,
  RequestTokenPayload,
} from "domain/core/auth/types";
import { REQUEST_STATUS } from "lib/types";
import AppActions from "domain/core/app/actions";
import AuthActions from "domain/core/auth/actions";
import { store } from "store/configureStore";
import { setDefaultArgs } from "lib/analytics";

import { INITIAL_STATE } from "domain/core/auth/reducer";
import { DashboardActions } from "domain/dashboard/actions";

export default function* AuthSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(actions.auth.dismissTokenExplanation.type, dismissTokenModal),
    takeLatest(actions.auth.login.type, login),
    takeLatest(actions.auth.logout.type, logout),
    takeLatest(actions.auth.forgotPassword.type, forgotPassword),
    takeLatest(actions.auth.updateName.type, updateName),
    takeLatest(actions.auth.updateContactInfo.type, updateContactInfo),
    takeLatest(actions.auth.updateSignupInfo.type, updateSignupInfo),
    takeLatest(actions.auth.requestTokens.type, requestTokens),
    takeLatest(
      actions.auth.getRequestTokenStatusAll.type,
      getTokenRequestStatuses
    ),
  ]);
}

export function* dismissTokenModal(action: Action): SagaIterator {
  try {
    yield call(api.dismissTokenExplanation, {
      tokenExplanation: true,
    });
    yield put(actions.auth.dismissTokenExplanation.success({}));
  } catch (error) {
    yield put(actions.auth.dismissTokenExplanation.success({}));
  }
}

export function* logout(action: Action): SagaIterator {
  yield put(actions.auth.logout.request());

  try {
    yield put(actions.auth.logout.success({}));
  } catch (error) {
    yield put(actions.auth.logout.success({}));
  }
  yield put(DashboardActions.resetDashboardState());
}

export function* login(action: Action<LoginPayload>): SagaIterator {
  yield put(actions.auth.login.request());

  try {
    const response: Auth0Response = yield call(api.login, {
      ...action.payload,
    });

    yield put(DashboardActions.resetDashboardState());

    yield call(setToken, response.access_token);

    // Chain call to get user profile using bearer token
    const profile: UserProfile = yield call(api.getProfile);
    yield call(setDefaultArgs, {
      organisation: profile.organisation ? profile.organisation : "unknown",
    });

    // Fire call to get status of activce token requests
    store.dispatch(AuthActions.getRequestTokenStatusAll());

    const name = action.payload.username.split("@")[0].split(".");

    // Fire call to get applications list of locations for providers
    store.dispatch(AppActions.getLocations());
    store.dispatch(
      AppActions.setRegexRoute({
        workshopsEnabled: profile.workshopsEnabled,
        providersEnabled: profile.providersEnabled,
      })
    );
    yield put(
      actions.auth.login.success({
        ...INITIAL_STATE,
        isAuthenticated: true,
        profile: {
          ...profile,
          name: name.join(" "),
          email: action.payload.username,
        },
        login: {
          status: REQUEST_STATUS.Fulfilled,
        },

        accessToken: response.access_token,
      })
    );
  } catch (error) {
    yield put(actions.auth.login.failure(error));
  }
}

export function* forgotPassword(
  action: Action<ForgotPasswordPayload>
): SagaIterator {
  yield put(actions.auth.forgotPassword.request());

  try {
    const response = yield call(api.forgotPassword, {
      email: action.payload.username,
    });

    yield put(actions.auth.forgotPassword.success(response));
  } catch (error) {
    yield put(actions.auth.forgotPassword.success());
  }
}

export function* updateName(action: Action<EditableAccountInfo>): SagaIterator {
  yield put(actions.auth.updateName.request());

  try {
    const response: UserProfile = yield call(api.updateName, action.payload);

    yield put(actions.auth.updateName.success(response));
  } catch (error) {
    yield put(actions.auth.updateName.failure(error));
  }
}

export function* updateContactInfo(
  action: Action<EditableAccountInfo>
): SagaIterator {
  yield put(actions.auth.updateContactInfo.request());

  try {
    const response: UserProfile = yield call(
      api.updateContactInfo,
      action.payload
    );

    yield put(actions.auth.updateContactInfo.success(response));
  } catch (error) {
    yield put(actions.auth.updateContactInfo.failure(error));
  }
}

export function* updateSignupInfo(
  action: Action<EditableAccountInfo>
): SagaIterator {
  yield put(actions.auth.updateSignupInfo.request());

  try {
    yield delay(2000);

    const response: UserProfile = yield call(
      api.updateSignupInfo,
      action.payload
    );

    yield put(actions.auth.updateSignupInfo.success(response));
  } catch (error) {
    yield put(actions.auth.updateSignupInfo.failure(error));
  }
}

export function* requestTokens(
  action: Action<RequestTokenPayload>
): SagaIterator {
  yield put(actions.auth.requestTokens.request());
  try {
    yield call(api.requestTokens, action.payload);
    yield put(actions.auth.requestTokens.success(action.payload));
  } catch (error) {
    yield put(actions.auth.requestTokens.failure(error));
  }
}

export function* getTokenRequestStatuses(
  action: Action<RequestTokenPayload>
): SagaIterator {
  yield put(actions.auth.getRequestTokenStatusAll.request());

  try {
    const response = yield call(api.getRequestTokenStatusAll);
    yield put(actions.auth.getRequestTokenStatusAll.success(response));
  } catch (error) {
    yield put(actions.auth.getRequestTokenStatusAll.failure(error));
  }
}
