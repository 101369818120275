import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/wellbeing/configuration";
import {
  WellbeingTestResult,
  WellbeingTestPayload,
} from "domain/wellbeing/types";
import { ContentProfile } from "domain/content/types";
import { ProviderSearchResult } from "domain/providers/types";

export const getWellbeingTestResults = client.post<
  {
    wellbeingPlanId: string;
    contentProfile: ContentProfile[];
    providerProfile: ProviderSearchResult;
    wellbeingTestId: string;
    focusDomains: string[];
    planDate: string;
  },
  WellbeingTestPayload,
  WellbeingTestResult
>(ENDPOINTS.getWellbeingTestResults, {
  transformResult: (result) => {
    return {
      content: result.contentProfile,
      providers: result.providerProfile,
      focus: result.focusDomains,
      id: result.wellbeingTestId,
      date: new Date(result.planDate),
    };
  },
});

export const getWellbeingPlan = client.post<
  {
    wellbeingPlanId: string;
    contentProfile: ContentProfile[];
    providerProfile: ProviderSearchResult;
    wellbeingTestId: string;
    focusDomains: string[];
    planDate: string;
  },
  string,
  WellbeingTestResult,
  { wellbeingTestId: string }
>(ENDPOINTS.getWellbeingPlan, {
  transformPayload: (payload) => {
    return {
      wellbeingTestId: payload,
    };
  },
  transformResult: (result) => {
    return {
      content: result.contentProfile,
      providers: result.providerProfile,
      focus: result.focusDomains,
      id: result.wellbeingTestId,
      date: new Date(result.planDate),
    };
  },
});
