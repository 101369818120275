import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { ReactComponent as LocationIcon } from "assets/icons/LocationIcon.svg";
import { ShortProfile } from "domain/providers/types";
import OverflowChipContainer from "ui/components/OverflowChipContainer";
import BookingTypeIconRound from "ui/components/BookingTypeIcon";
import DomainIcon from "ui/components/DomainIcon";
import Chip from "@material-ui/core/Chip";
import AvatarDefault from "assets/icons/AvatarDefault.svg";
import IgniteToken from "assets/IgniteToken.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      width: "382px",
      minHeight: "540px",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",

      "& > *": {
        margin: "auto 20px",
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "378px",
        width: "calc(100vw - 32px)",
      },

      transition: "all .25s linear",
      "@media (hover: hover)": {
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
    specContainer: {
      height: "84px",
      overflowY: "hidden",
    },
    timeContainer: {
      height: "40px",
      overflowY: "hidden",
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",

      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    chip2: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      backgroundColor: "#E1E8F7",
      borderColor: "transparent",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
      color: "#0B112C",
    },
    section1: {
      display: "flex",
      flexDirection: "row",

      "& > *": {
        margin: "5px",
      },
    },
    avatar: {
      width: "81px",
      height: "81px",
      "@media (max-width: 410px)": {
        // width: "70px",
        // height: "70px",
      },
    },
    avatarContainer: {
      width: "auto",
      height: "auto",
      "@media (max-width: 410px)": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
    nameContainer: {
      "@media (max-width: 410px)": {
        marginTop: "9px",
        maxWidth: "calc(100vw - 150px)",
      },
    },
    name: {
      fontFamily: "Gilroy Bold",
      fontSize: "20px",
      margin: "auto",
    },
    type: {
      margin: "auto",
      marginTop: "-2px",
      maxWidth: "200px",
      "@media ( max-width: 410px)": {
        // fontSize: "12px",
        maxWidth: "150px",
      },
    },
    address: {
      marginTop: "-2px",
      "@media ( max-width: 410px)": {
        "& *": {
          // fontSize: "12px",
        },
      },
    },
    profileDetails: {
      height: "81px",
      width: "calc(100% - 20px)",
    },
    token: {
      width: "40px",
      height: "40px",
    },
    bottomSection: {
      display: "flex",
      flexDirection: "row",
      "& *": {
        margin: "2px 4px 2px 0px",
      },
    },
    specialties: {
      minHeight: "110px",
    },
    iconContainer: {
      display: "flex",
      marginRight: "5px",
      marginTop: "-1px",
    },
    icon: {
      margin: "auto",
    },
    introduction: {
      minHeight: "100px",
      height: "auto",
      display: "flex",

      "& p": {
        margin: "auto",
      },
    },
  })
);

interface Props {
  profile: ShortProfile;
  onSelect?: Function;
}

export default function ProfileCard({ profile, onSelect }: Props) {
  const classes = useStyles();

  const shortAddress = (city: string, suburb: string) => {
    suburb = suburb == null || suburb.length === 0 ? "" : suburb + ", ";
    city = city == null ? "" : city;
    return suburb + city;
  };

  function selectProvider() {
    if (onSelect !== undefined) {
      onSelect(profile);
    }
  }

  function getDomainChip(item: string): JSX.Element {
    return (
      <Chip
        key={item}
        className={classes.chip}
        label={item}
        variant="outlined"
        icon={<DomainIcon key={item} domain={item} borderless={true} />}
      />
    );
  }

  return (
    <Card className={classes.root} onClick={() => selectProvider()}>
      <div className={classes.section1}>
        <div className={`${classes.avatarContainer} `}>
          <Avatar
            alt={profile.firstName}
            className={classes.avatar}
            src={profile.avatar}
          >
            <img alt="" src={AvatarDefault} style={{ width: "100%" }} />
          </Avatar>
        </div>
        <Grid
          container
          alignItems="flex-start"
          direction="column"
          justify="space-between"
          className={classes.profileDetails}
        >
          <Grid item className={classes.nameContainer}>
            <Typography variant="h5" noWrap className={classes.name}>
              {profile.firstName} {profile.lastName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" noWrap className={classes.type}>
              {profile.type}
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Grid container direction="row" className={classes.address}>
              <Grid item className={classes.iconContainer}>
                <LocationIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1" noWrap>
                  {shortAddress(profile.city, profile.suburb)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider variant="middle" />
      <div className={classes.introduction}>
        <Typography gutterBottom variant="body1">
          {profile.shortDescription}
        </Typography>
      </div>
      <Divider variant="middle" />

      <React.Fragment>
        <div>
          <Typography gutterBottom color="textSecondary" variant="subtitle1">
            Domains
          </Typography>

          <div className={classes.timeContainer}>
            <OverflowChipContainer
              className={classes.timeContainer}
              listIdPrefix={profile.providerId.split("-")[0] + "-domains"}
              chipContent={profile.domains}
              displayItem={getDomainChip}
            />
          </div>
        </div>
      </React.Fragment>
      <div className={classes.specialties}>
        <div>
          <Typography gutterBottom color="textSecondary" variant="subtitle1">
            Specialties
          </Typography>
        </div>
        {profile.specialties != null &&
          profile.specialties !== undefined &&
          profile.specialties.length > 0 && (
            <OverflowChipContainer
              className={classes.specContainer}
              listIdPrefix={profile.providerId}
              chipContent={profile.specialties}
            ></OverflowChipContainer>
          )}
      </div>
      <Divider variant="middle" />
      <div className={classes.bottomSection}>
        {(profile.bookingTypesAvailable
          ? profile.bookingTypesAvailable
          : ["In Person"]
        ).map((type) => (
          <div key={type}>
            <BookingTypeIconRound type={type.trim()} />
          </div>
        ))}
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <Typography align="center" variant="body1">
              {profile.unitsPerSession}
            </Typography>
          </div>
          <img
            src={IgniteToken}
            alt="tokens"
            style={{ width: "32px", height: "32px" }}
          />
        </div>
      </div>
    </Card>
  );
}
