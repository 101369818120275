import { utcToZonedTime } from "date-fns-tz";
import parseISO from "date-fns/parseISO";

export const getTimestamp = () => Date.now().toString();

export const getNow = () => Date.now();

export const getISOTimeStamp = () => new Date().toISOString();

export const toLocaleString = (date: Date | string) =>
  new Date(date).toLocaleTimeString();

export const parseDate = (date: string | Date) =>
  typeof date === "string" ? parseISO(date) : date;

export const toNewZealandTime = (date: Date) =>
  utcToZonedTime(date, "Pacific/Auckland");
