import { DashboardState } from "./types";
import { IgniteState } from "domain/types";
import { DashboardActions } from "./actions";
import { REQUEST_STATUS } from "lib/types";
import { createReducer, match, composeReducers } from "re-reduced";
import { createSelector } from "reselect";
import {
  isFuture,
  isPast,
  isToday,
  isAfter,
  isSameDay,
  parseISO,
} from "date-fns";
import { GenericAppointment } from "domain/providers/types";

export const initialState: DashboardState = {
  wellbeingComponent: {
    items: [],
    dates: [],
  },

  wellbeingAnswers: [],
  moodDiary: [],

  diaryRequest: { status: REQUEST_STATUS.Idle },
  setMoodRequest: { status: REQUEST_STATUS.Idle },
  cancelAppointmentRequest: { status: REQUEST_STATUS.Idle },
  appointmentsStatus: { status: REQUEST_STATUS.Idle },
  hotContentStatus: { status: REQUEST_STATUS.Idle },

  requests: {
    wellbeingComponent: { status: REQUEST_STATUS.Idle },
  },
};

export const getDashboardState = (state: IgniteState) => state.dashboard;

export const fetchWellbeingOverview = createSelector(
  getDashboardState,
  (dashboard) => dashboard.wellbeingComponent.items
);

export const getCancelRequest = createSelector(
  getDashboardState,
  (dashboard) => dashboard.cancelAppointmentRequest.status
);

export const getWellbeingTestAnswers = createSelector(
  getDashboardState,
  (dashboard) => dashboard.wellbeingAnswers
);

export const getWellbeingTimeline = createSelector(
  getDashboardState,
  (dashboard) => dashboard.wellbeingComponent.dates
);
export const fetchWellbeingOverviewStatus = createSelector(
  getDashboardState,
  (dashboard) => {
    if (dashboard.requests.wellbeingComponent) {
      return dashboard.requests.wellbeingComponent.status;
    }
  }
);

export const getMoodDiary = createSelector(getDashboardState, (dashboard) => {
  return dashboard.moodDiary;
});

export const getMoodDiaryRequest = createSelector(
  getDashboardState,
  (dashboard) => {
    return dashboard.diaryRequest.status;
  }
);

export const getHotContent = createSelector(
  getDashboardState,
  (dashboard) => dashboard.hotContent
);

export const getHotContentStatus = createSelector(
  getDashboardState,
  (dashboard) => {
    if (dashboard.hotContentStatus) {
      return dashboard.hotContentStatus.status;
    }
  }
);

export const getNextAppointment = createSelector(
  getDashboardState,
  (dashboard) => {
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      const future = dashboard.appointments.appointments.filter(
        (item) => item.date.getTime() - Date.now() > 0
      );

      future.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      if (future.length > 0) {
        return future[0];
      }
    }
  }
);

export const getNextGenericAppointment = createSelector(
  getDashboardState,
  (dashboard) => {
    let genericAppointmentList = [] as GenericAppointment[];
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      const _appointments = dashboard.appointments.appointments
        .filter(
          (item) =>
            isFuture(item.date) ||
            (isToday(item.date) && isAfter(item.date, Date.now()))
        )
        .map((item) => {
          return {
            id: item.providerId,
            displayName: item.firstName + " " + item.lastName,
            address: item.address,
            city: item.city,
            suburb: item.suburb,
            type: item.type,
            avatar: item.avatar,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.bookingType,
            date: item.date,
            duration: item.duration,
            variant: "provider",
          } as GenericAppointment;
        });

      genericAppointmentList = [..._appointments];
    }
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.workshops.length > 0
    ) {
      const _appointments = dashboard.appointments.workshops
        .filter(
          (item) =>
            isFuture(item.appointmentDate) ||
            (isToday(item.appointmentDate) &&
              isAfter(item.appointmentDate, Date.now()))
        )
        .map((item) => {
          return {
            id: item.workshopId,
            displayName: item.title,
            address: item.addressLine1,
            city: item.city,
            suburb: item.suburb,
            type: "",
            avatar: item.imageUrl,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.appointmentType,
            date: item.appointmentDate,
            duration: item.duration,
            variant: "workshop",
          } as GenericAppointment;
        });

      genericAppointmentList = [...genericAppointmentList, ..._appointments];
    }

    genericAppointmentList.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    if (genericAppointmentList.length > 0) {
      return genericAppointmentList[0];
    }
  }
);

export const getAllFutureAppointments = createSelector(
  getDashboardState,
  (dashboard) => {
    let genericAppointmentList = [] as GenericAppointment[];
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      const _appointments = dashboard.appointments.appointments
        .filter(
          (item) =>
            isFuture(item.date) ||
            (isToday(item.date) && isAfter(item.date, Date.now()))
        )
        .map((item) => {
          return {
            ...item,
            id: item.providerId,
            displayName: item.firstName + " " + item.lastName,
            address: item.address,
            city: item.city,
            suburb: item.suburb,
            type: item.type,
            avatar: item.avatar,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.bookingType,
            date: item.date,
            variant: "provider",
          } as GenericAppointment;
        });

      genericAppointmentList = [..._appointments];
    }
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.workshops.length > 0
    ) {
      const _appointments = dashboard.appointments.workshops
        .filter(
          (item) =>
            isFuture(item.appointmentDate) ||
            (isToday(item.appointmentDate) &&
              isAfter(item.appointmentDate, Date.now()))
        )
        .map((item) => {
          return {
            ...item,
            id: item.workshopId,
            displayName: item.title,
            address: item.addressLine1,
            city: item.city,
            suburb: item.suburb,
            type: "",
            avatar: item.imageUrl,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.appointmentType,
            date: item.appointmentDate,
            variant: "workshop",
          } as GenericAppointment;
        });

      genericAppointmentList = [...genericAppointmentList, ..._appointments];
    }

    return genericAppointmentList;
  }
);

export const getAllPastAppointments = createSelector(
  getDashboardState,
  (dashboard) => {
    let genericAppointmentList = [] as GenericAppointment[];
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      const _appointments = dashboard.appointments.appointments
        .filter((item) => isPast(item.date))
        .map((item) => {
          return {
            ...item,
            id: item.providerId,
            displayName: item.firstName + " " + item.lastName,
            address: item.address,
            city: item.city,
            suburb: item.suburb,
            type: item.type,
            avatar: item.avatar,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.bookingType,
            date: item.date,
            variant: "provider",
          } as GenericAppointment;
        });

      genericAppointmentList = [..._appointments];
    }
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.workshops.length > 0
    ) {
      const _appointments = dashboard.appointments.workshops
        .filter((item) => isPast(item.appointmentDate))
        .map((item) => {
          return {
            id: item.workshopId,
            displayName: item.title,
            address: item.addressLine1,
            city: item.city,
            suburb: item.suburb,
            type: "",
            avatar: item.imageUrl,
            unitsPerSession: item.unitsPerSession,
            bookingType: item.appointmentType,
            date: item.appointmentDate,
            variant: "workshop",
          } as GenericAppointment;
        });

      genericAppointmentList = [...genericAppointmentList, ..._appointments];
    }

    return genericAppointmentList;
  }
);

export const getFutureAppointments = createSelector(
  getDashboardState,
  (dashboard) => {
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      const future = dashboard.appointments.appointments.filter(
        (item) =>
          isFuture(item.date) ||
          (isToday(item.date) && isAfter(item.date, Date.now()))
      );

      return future.sort((a, b) => a.date.getTime() - b.date.getTime());
    }

    return [];
  }
);

export const getPastAppointments = createSelector(
  getDashboardState,
  (dashboard) => {
    if (
      dashboard.appointments !== undefined &&
      dashboard.appointments.appointments.length > 0
    ) {
      return dashboard.appointments.appointments.filter((item) =>
        isPast(item.date)
      );
    }
    return [];
  }
);

export const getAppointmentStatus = createSelector(
  getDashboardState,
  (dashboard) => {
    if (dashboard.appointmentsStatus) {
      return dashboard.appointmentsStatus.status;
    }
  }
);

const removeAppointment = createReducer<DashboardState>(
  [
    match(DashboardActions.removeAppointmentLocal, (state, payload) => {
      if (state.appointments) {
        let workshops = state.appointments.workshops;
        if (payload.variant === "workshop") {
          const index = state.appointments.workshops.findIndex(
            (item) =>
              item.workshopId === payload.id &&
              item.appointmentDate === payload.date
          );

          const array = state.appointments.workshops;
          array.splice(index, 1);
          workshops = array;
        }
        return {
          ...state,
          cancelAppointmentRequest: {
            status: REQUEST_STATUS.Idle,
          },
          appointments: {
            appointments: state.appointments.appointments.filter(
              (item) =>
                item.date.toUTCString() !== payload.date.toUTCString() &&
                item.providerId === payload.id
            ),
            workshops: workshops,
          },
        };
      }
      return state;
    }),
  ],
  initialState
);

const cancelReducer = createReducer<DashboardState>(
  [
    match(DashboardActions.cancelAppointment.request, (state, _) => {
      return {
        ...state,
        cancelAppointmentRequest: {
          status: REQUEST_STATUS.Pending,
        },
      };
    }),
    match(DashboardActions.cancelAppointment.success, (state, payload) => {
      if (state.appointments) {
        return {
          ...state,
          cancelAppointmentRequest: {
            status: REQUEST_STATUS.Fulfilled,
          },
        };
      } else {
        // We should never be here.
        return {
          ...state,
          cancelAppointmentRequest: {
            status: REQUEST_STATUS.Failed,
          },
        };
      }
    }),
    match(DashboardActions.cancelAppointment.failure, (state, error) => {
      return {
        ...state,
        cancelAppointmentRequest: {
          status: REQUEST_STATUS.Failed,
          error: error,
        },
      };
    }),
  ],
  initialState
);
const reducer = createReducer<DashboardState>(
  [
    match(DashboardActions.getWellbeingOverviewList.request, (state, _) => {
      return {
        ...state,
        wellbeingAnswers: initialState.wellbeingAnswers,
        wellbeingComponent: initialState.wellbeingComponent,
        requests: {
          ...state.requests,
          wellbeingComponent: { status: REQUEST_STATUS.Fulfilled },
        },
      };
    }),
    match(
      DashboardActions.getWellbeingOverviewList.success,
      (state, payload) => {
        return {
          ...state,
          wellbeingComponent: {
            items: [...payload.overview],
            dates: payload.overview.map((item) => item.date),
          },
          wellbeingAnswers: [...payload.answers],
          requests: {
            ...state.requests,
            wellbeingComponent: { status: REQUEST_STATUS.Fulfilled },
          },
        };
      }
    ),
    match(DashboardActions.getWellbeingOverviewList.failure, (state, error) => {
      return {
        ...state,
        requests: {
          ...state.requests,
          wellbeingComponent: { status: REQUEST_STATUS.Failed, error: error },
        },
      };
    }),

    match(DashboardActions.getAppointments.request, (state, _) => {
      return {
        ...state,
        appointment: undefined,
        appointmentsStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(DashboardActions.getAppointments.success, (state, payload) => {
      const appointments = payload.appointments.map((item) => {
        return {
          ...item,
          date: new Date(item.date),
        };
      });

      const workshops = payload.workshops.map((item) => {
        return {
          ...item,
          date: new Date(item.appointmentDate),
        };
      });

      return {
        ...state,
        appointments: { appointments, workshops },
        appointmentsStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(DashboardActions.getAppointments.failure, (state, error) => {
      return {
        ...state,
        appointmentsStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),

    match(DashboardActions.getHotContent.request, (state, _) => {
      return {
        ...state,
        hotContentStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(DashboardActions.getHotContent.success, (state, payload) => {
      return {
        ...state,
        hotContent: payload,
        hotContentStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(DashboardActions.getHotContent.failure, (state, error) => {
      return {
        ...state,
        hotContentStatus: { status: REQUEST_STATUS.Failed },
      };
    }),

    match(DashboardActions.getMoodList.request, (state, _) => {
      return {
        ...state,
        diaryRequest: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(DashboardActions.getMoodList.success, (state, payload) => {
      return {
        ...state,
        moodDiary: payload.map((item) => {
          return {
            ...item,
            date: parseISO(item.date.toString()),
          };
        }),
        diaryRequest: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(DashboardActions.getMoodList.failure, (state, error) => {
      return {
        ...state,
        diaryRequest: { status: REQUEST_STATUS.Failed },
      };
    }),

    match(DashboardActions.setMood.request, (state, _) => {
      return {
        ...state,
        setMoodRequest: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(DashboardActions.setMood.success, (state, payload) => {
      return {
        ...state,
        moodDiary: [
          ...state.moodDiary.filter(
            (item) => !isSameDay(item.date, payload.date)
          ),
          payload,
        ],
        setMoodRequest: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(DashboardActions.setMood.failure, (state, error) => {
      return {
        ...state,
        setMoodRequest: { status: REQUEST_STATUS.Failed },
      };
    }),
    match(DashboardActions.resetDashboardState, (state, error) => {
      return initialState;
    }),
  ],
  initialState
);

export default composeReducers(reducer, cancelReducer, removeAppointment);
