import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CTA } from "domain/core/app/utils";
import { ReactComponent as Slider } from "assets/icons/Slider.svg";
import { Typography, Card, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      height: "50px",
      zIndex: 4000,
    },
    card: {
      height: "500px",
      overflow: "scroll",
      zIndex: 3500,
      width: "380px",

      [theme.breakpoints.down("xs")]: {
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },

      "&>:last-child": {
        paddingBottom: "5rem",
      },
    },
    container: {},
    filterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      color: "white",
      width: "100vw",
      height: "50px",
      overflow: "visisble",
      backgroundColor: CTA,
    },
    item: {
      margin: "auto 10px",
    },
  })
);

export interface Props {
  onSubmit?: () => void;
}

const MobileFilterContainer: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function submit() {
    !!props.onSubmit && props.onSubmit();
    handleClose();
  }

  return (
    <React.Fragment>
      <div className={classes.filterContainer} onClick={handleOpen}>
        <div className={classes.item}>
          <Slider></Slider>
        </div>
        <div className={classes.item}>
          <Typography variant="subtitle1" color="inherit">
            Search & Filter Providers
          </Typography>
        </div>
      </div>
      <Modal

        className={classes.modal}
        open={open}
        onClose={handleClose}
        disableAutoFocus={true}
      >
        <React.Fragment>
          <Card className={classes.card}>{props.children}</Card>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={submit}
          >
            Apply Filters
          </Button>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default MobileFilterContainer;
