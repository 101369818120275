import { createSelector } from "reselect";
import decodeJWT from "jwt-decode";
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";
import addDays from "date-fns/addDays";

import { getNow } from "lib/date-time";

import { IgniteState } from "domain/types";
import { decodeToken, decodeJWTData } from "domain/core/auth/helpers";
import { setToken } from "lib/apiClient/api";
import { capitalize } from "lib/string";
import { setDefaultArgs } from "lib/analytics";

export const getAuthState = (state: IgniteState) => state.core.auth;
export const getAppState = (state: IgniteState) => state;

export const getRouteRegex = createSelector(
  getAppState,
  (state) => state.core.app.routesRegex
);

export const getProfile = createSelector(getAuthState, (state) => {
  return state.profile;
});

export const getTokenBalance = createSelector(getAuthState, (state) => {
  return state.profile.availableTokens;
});

export const accountInfoName = createSelector(getAuthState, (state) => {
  return state.accountInfoName;
});

export const accountInfoContact = createSelector(getAuthState, (state) => {
  return state.accountInfoContact;
});

export const accountSignupInfo = createSelector(getAuthState, (state) => {
  return state.accountSignupInfo;
});

export const getTokenExplanation = createSelector(getAuthState, (state) => {
  if (state.profile != null) {
    return state.profile.tokenExplanation;
  }

  return true;
});

export const getRequestTokenStatus = createSelector(
  getAuthState,
  (state) => state.requestTokens
);

export const getTokenRequestsStatus = createSelector(
  getAuthState,
  (state) => state.tokenRequests.requestStatus
);

export const getHasPendingTokenRequest = createSelector(
  getAuthState,
  (state) => state.tokenRequests.hasPendingRequest
);

export const getLatestRequestAmount = createSelector(getAuthState, (state) =>
  state.tokenRequests.requests.length > 0
    ? state.tokenRequests.requests.sort(
        (a, b) =>
          new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()
      )[0].tokenAmount
    : 0
);
export const getEmail = createSelector(getAuthState, (state) => {
  if (state.profile != null) {
    return state.profile.email;
  }

  return "";
});

export const getSecondaryEmail = createSelector(getAuthState, (state) => {
  if (state.profile != null && state.profile.secondaryEmail) {
    return state.profile.secondaryEmail;
  }

  return "";
});

export const getPhone = createSelector(getAuthState, (state) => {
  if (state.profile != null && state.profile.phone) {
    return state.profile.phone;
  }

  return "";
});

export const getName = createSelector(getAuthState, (state) => {
  if (state.profile != null) {
    return capitalize(state.profile.firstName + " " + state.profile.lastName);
  } else {
    return "";
  }
});

export const getFirstName = createSelector(getAuthState, (state) => {
  if (state.profile !== null && state.profile.firstName) {
    return state.profile.firstName;
  } else {
    return "";
  }
});

export const getLastName = createSelector(getAuthState, (state) => {
  if (state.profile !== null && state.profile.lastName) {
    return state.profile.lastName;
  } else {
    return "";
  }
});

export const getInitials = createSelector(getAuthState, (state) => {
  if (state.profile != null) {
    const names = [state.profile.firstName, state.profile.lastName];
    let initials = "";
    names.forEach((item) => {
      if (item !== null) {
        initials += item.charAt(0);
      }
    });

    return initials.toUpperCase();
  } else {
    return "JD";
  }
});

export const getToken = createSelector(getAuthState, (state) => {
  return decodeToken(state.accessToken || "");
});

export const getaccessTokenExpiry = createSelector(getToken, (token) => {
  try {
    const decoded = decodeJWT<{ exp: number }>(token);
    return decoded.exp;
  } catch (error) {
    return 0;
  }
});

export const getIsTokenExpiryWithinNDays = (numberOfDays: number) =>
  createSelector(getaccessTokenExpiry, getNow, (tokenExpiry, now) => {
    const expDate = parse(String(tokenExpiry), "t", new Date());
    const adjustedDate = addDays(now, numberOfDays);

    return isBefore(adjustedDate, expDate);
  });

export const getIsTokenExpiryWithin7Days = getIsTokenExpiryWithinNDays(7);

export const getIsAuthenticated = createSelector(getAuthState, (state) => {
  if (state.isAuthenticated && state.accessToken && getIsOnboarded) {
    setToken(state.accessToken);
    setDefaultArgs({
      organisation: state.profile.organisation
        ? state.profile.organisation
        : "unknown",
    });
    return true;
  }
  return false;
});

export const getIsOnboarded = createSelector(getAuthState, (state) => {
  return state.profile.userOnboarded;
});

export const getDecodedJWTData = createSelector(getToken, decodeJWTData);

export const getLoginRequest = createSelector(
  getAuthState,
  (state) => state.login
);

export const getForgotPasswordRequest = createSelector(
  getAuthState,
  (state) => state.forgotPassword
);
