import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import LocationIcon from "assets/icons/LocationIcon.svg";
import { Button } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { ReactComponent as Chevron } from "assets/icons/Icon-Left-Arrow.svg";
import {
  Session,
  WorkshopProfile as TWorkshopProfile,
} from "domain/workshops/types";
import DomainIcon from "ui/components/DomainIcon";
import { BookingTypePair } from "ui/components/BookingTypeIcon";
import { ReactComponent as WorkshopDateIcon } from "assets/workshops/WorkshopDateIcon.svg";
import { addMinutes, format } from "date-fns";
import { getSlots } from "ui/compounds/WorkshopBookingCard";
import Responsive from "ui/Responsive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "790px",
      backgroundColor: theme.palette.background.paper,
      transition: "all 1s ease",

      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        borderRadius: 0,
      },
    },

    flexBreak: {
      "@media(max-width: 740px)": {
        width: "auto",
        margin: "auto",
        justifyContent: "center",
      },

      "@media(max-width: 330px)": {
        "& .MuiTypography-root": {
          fontSize: "14px",
        },
      },
    },
    imageContainer: {
      maxHeight: "360px",
      overflow: "hidden",
    },
    image: {
      opacity: 0,
      maxWidth: "790px",
      width: "calc(100%)",
      backgroundColor: theme.palette.background.paper,
      transition: "all 1s ease",

      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        borderRadius: 0,
      },
    },
    cardInner: {
      margin: "30px",
    },
    responsive: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    locationContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    centerOnMobile: {
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        textAlign: "center",
      },
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      borderColor: "#B1B6CB",
      height: "31px",
      fontFamily: "Gilroy Regular",
      fontSize: "15px",
    },
    chip2: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      backgroundColor: "#E1E8F7",
      fontFamily: "Gilroy Regular",
      fontSize: "15px",
    },
    avatar: {
      width: "107px",
      height: "107px",
    },
    header: {
      margin: theme.spacing(2, 0),
      position: "relative",
    },
    times: {
      margin: theme.spacing(2, 0),
    },
    credentials: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    domains: {
      minHeight: "100px",
      margin: theme.spacing(2, 0),
    },
    bio: {
      margin: theme.spacing(2, 0),
    },
    sectionBody: {
      marginTop: "10px",
      height: "auto",
      paddingBottom: "25px",
    },
    bullets: {
      margin: "0px",
      paddingInlineStart: "20px",
      [theme.breakpoints.down("sm")]: {
        listStyleType: "none",
      },
    },

    bulletItem: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    divider: {
      marginBottom: "25px",
      marginLeft: "0px",
      marginRight: "0px",
    },
    iconContainer: {
      display: "flex",
      margin: "5px",
      marginTop: "-1px",
    },
    uppercase: {
      textTransform: "uppercase",
    },
    icon: {
      marginTop: 5,
      marginBottom: "auto",
      position: "relative",
      top: "-1px",
      left: "-5px",

      [theme.breakpoints.down("sm")]: {},
    },
    backContainer: {
      display: "none",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "10px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    workshop: {
      whiteSpace: "pre-line",
      overflow: "hidden",
      transition: "max-height 1s ease-in-out",
      maxHeight: "unset",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "310px",
      },
    },
    workshopExpanded: {
      maxHeight: "1000px",
    },
    showMore: {
      margin: "auto",
      display: "none",
      padding: "5px",
      boxShadow: "0px -10px 15px white",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      width: "100%",
    },
    showLess: {
      boxShadow: "none !important",
    },
    profileDetails: {
      height: "125px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    detailsRow: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      margin: "auto 0",
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
      height: "40px",
      "& p": {
        lineHeight: "auto",
        margin: "auto 0px",
      },

      "& .MuiChip-root": {
        margin: "auto 10px",
      },
    },
    iconTypography: {
      height: "auto",
      margin: theme.spacing(1),
    },
  })
);

interface Props extends RouteComponentProps {
  profile: TWorkshopProfile;
  session?: Session;
}

function WorkshopProfile({ profile, session, history }: Props) {
  const [showMore, setShowMore] = useState(false);
  //eslint-disable-next-line
  function renderCredentials(_qualifications: string[]) {
    let qualifications = _qualifications;
    if (!Array.isArray(_qualifications)) {
      try {
        qualifications = (_qualifications as string).split("\n");
      } catch (error) {
        qualifications = new Array(_qualifications) as string[];
      }
    }
    return (
      <ul className={classes.bullets}>
        {qualifications.map((item, index) => {
          return item !== null ? (
            <li
              className={classes.bulletItem}
              key={profile.workshopId + "-spec-chip" + index}
            >
              <Typography variant="body1">{item.trim()}</Typography>
            </li>
          ) : null;
        })}
      </ul>
    );
  }

  function renderDomainChips(domains: string[]) {
    if (domains == null || domains === undefined || domains.length === 0) {
      domains = [];
    }
    return domains.map((item, index) => {
      return (
        <Chip
          key={profile.workshopId + "spec-chip" + index}
          className={classes.chip}
          label={item}
          variant="outlined"
          icon={<DomainIcon key={item} domain={item} />}
        />
      );
    });
  }

  const classes = useStyles();

  //eslint-disable-next-line
  const shortAddress = (city: string, suburb: string, start: string) => {
    suburb = suburb == null || suburb.length === 0 ? "" : suburb + ", ";
    city = city == null ? "" : city;
    if ((suburb + city).length > 0) {
      return start + suburb + city;
    }
    return suburb + city;
  };

  //eslint-disable-next-line
  function getAddress() {
    if (
      profile.address !== null &&
      profile.address !== undefined &&
      profile.address.length > 1
    ) {
      return (
        <Grid item>
          <Grid container direction="row" className={classes.locationContainer}>
            <Grid item className={classes.iconContainer}>
              <img className={classes.icon} alt="location" src={LocationIcon} />
              <Typography variant="body1">{profile.address}</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  function backToSearch() {
    if (
      history.location.state &&
      history.location.state.from === "/workshops"
    ) {
      history.goBack();
    } else {
      history.push("/workshops");
    }
  }

  return (
    <Card className={classes.root}>
      <div
        className={classes.imageContainer}
        style={{
          background: `url(${profile.imageUrl})`,
          backgroundSize: "cover",
          backgroundPositionY: "50%",
        }}
      >
        <img
          className={classes.image}
          src={profile.imageUrl}
          alt={profile.title}
        />
      </div>

      <div className={classes.cardInner}>
        <div className={classes.header}>
          <Grid
            container
            alignItems="flex-start"
            direction="row"
            spacing={2}
            className={classes.responsive}
          >
            <Grid item className={classes.backContainer}>
              <Button
                variant="text"
                color="secondary"
                onClick={backToSearch}
                startIcon={<Chevron />}
              >
                Back to Workshops
              </Button>
            </Grid>

            <Grid item className={classes.centerOnMobile}>
              <Typography variant="h4" gutterBottom>
                {profile.title}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {profile.shortDescription}
              </Typography>
            </Grid>
            <Responsive displayIn={["Mobile", "MobileXS"]}>
              <Grid
                item
                className={classes.centerOnMobile}
                style={{ width: "100%" }}
              >
                {session && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        padding: "6px 0px",
                      }}
                      className={classes.flexBreak}
                    >
                      <WorkshopDateIcon />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ alignSelf: "flex-start", margin: "auto 10px" }}
                      >
                        <b style={{ fontFamily: "Gilroy Medium" }}>
                          {" "}
                          {format(new Date(session.time), "iii, d MMMM")}{" "}
                        </b>
                        {format(new Date(session.time), "h:mm a") +
                          " - " +
                          format(
                            addMinutes(
                              new Date(session.time),
                              session.duration
                            ),
                            "h:mm a"
                          )}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        padding: "6px 0px",
                      }}
                      className={classes.flexBreak}
                    >
                      {getSlots(session.slots, session.slotsAvailable)}
                      <Typography
                        variant="body1"
                        style={{ paddingLeft: "12px" }}
                      >
                        {session.slotsAvailable} Available!
                      </Typography>
                    </div>
                  </div>
                )}
              </Grid>
            </Responsive>
          </Grid>
        </div>
        <Divider className={classes.divider} variant="middle" />

        <div className={classes.domains}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Domains
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              {renderDomainChips(profile.domains)}
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} variant="middle" />
        <div className={classes.credentials}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Type
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              <BookingTypePair type={profile.appointmentType} />
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} variant="middle" />

        <div className={classes.bio}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Description
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              <Typography
                className={`${classes.workshop} ${
                  showMore && classes.workshopExpanded
                }`}
                variant="body1"
              >
                {profile.description.split("\\n\\n").join("\n\n").trim()}
              </Typography>
              <Button
                className={`${classes.showMore} ${
                  showMore && classes.showLess
                }`}
                variant="text"
                color="secondary"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Card>
  );
}

export default withRouter(WorkshopProfile);
