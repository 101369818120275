export const ROUTES = {
  root: "/",
  home: "/home",
  learn: "/learn",
  login: "/login",
  book: "/book/:id",
  search: "/search",
  workshops: "/workshops",
  workshop: "/workshop/:id/:time",
  assessment: "/assessment",
  assessmentResults: "/assessmentResults/:id",
  prioritise: "/prioritise",
  crisis: "/crisis",
  dashboard: "/dashboard",
  appointments: "/appointments",
  account: "/account",
  contact: "/contact",
  forgotPassword: "/forgotPassword",
  onboarding: "/onboarding",
  aboutUs: "/about",
  ourApproach: "/approach",
} as const;
