import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export const ocean = {
  primary: "#04AAF0",
  darkest: "#0088C1",
  dark: "#0095D4",
  light: "#2AB7F2",
  lightest: "#43BFF3",
};
export const rasberry = {
  primary: "#F34258",
  darkest: "#CC2A34",
  dark: "#F13540",
  light: "#FF4F5A",
  lightest: "#FF636C",
};

export const fern = {
  primary: "#49DBA3",
  darkest: "#29B17D",
  dark: "#29C88B",
  light: "#47EDAF",
  lightest: "#6FEFBD",
};

export const colorGuide = {
  fern,
  rasberry,
  ocean,
};

export const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
};

const breakpoints = createBreakpoints({ values: breakpointValues });
const baseTheme = createMuiTheme({
  breakpoints,

  palette: {
    action: {
      disabled: "#9297AD",
    },
    text: {
      secondary: "#5F6272",
      primary: "#030822",
      disabled: "#9297AD",
    },
    background: {
      default: "#F2F4FE",
    },
    //Purple
    primary: {
      light: "#6F39D4",
      // light: will be calculated from palette.primary.main,
      main: "#6020D4",
      // darkest: '#3F1788',
      dark: "#4C1DA1",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    //blue
    secondary: {
      main: "#04AAF0",
      light: "#2AB7F2",
      dark: "#0095D4",
      contrastText: "#ffffff",
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },

  typography: {
    h1: {
      fontSize: "75px",
      fontFamily: "Gilroy ExtraBold",
      color: "#030822",
      textTransform: "capitalize",
    },
    h2: {
      fontSize: "60px",
      fontFamily: "Gilroy ExtraBold",
      color: "#030822",
      textTransform: "capitalize",
    },
    h3: {
      fontSize: "48px",
      fontFamily: "Gilroy ExtraBold",
      textTransform: "capitalize",
    },
    h4: {
      fontSize: "34px",
      fontFamily: "Gilroy Bold",
    },
    h5: {
      fontSize: "24px",
      fontFamily: "Gilroy Bold",
    },
    h6: {
      fontSize: "16px",
      fontFamily: "Gilroy Bold",
      textTransform: "capitalize",
    },
    subtitle1: {
      fontSize: "16px",
      fontFamily: "Gilroy Bold",
      color: "#5F6272",
      lineHeight: "auto",
      textTransform: "capitalize",
    },

    subtitle2: {
      fontSize: "14px",
      fontFamily: "Gilroy Medium",
      letterSpacing: "0.5px",
      color: "#5F6272",
      textTransform: "capitalize",
    },

    body1: {
      fontSize: "16px",
      fontFamily: "Gilroy Regular",
    },

    body2: {
      fontSize: "14px",
      fontFamily: "Gilroy Regular",
      lineHeight: "24px",
    },

    caption: {
      fontSize: "12px",
      fontFamily: "Gilroy Regular",
    },

    overline: {
      fontSize: "10px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        "& span": {
          fontFamily: "Gilroy Bold",
          fontSize: "14px",
        },
      },
      text: {
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },

      containedSecondary: {
        width: "170px",
        height: "50px",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        margin: "auto",
        textTransform: "inherit",
        boxShadow: "none",
      },
      outlinedSecondary: {
        width: "170px",
        height: "50px",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },
    },
  },
});

const shadows = baseTheme.shadows;
shadows[4] =
  "0px 3px 5px rgba(68, 53, 86, 0.05), 0px 1px 10px rgba(68, 53, 86, 0.05), 0px 6px 10px rgba(68, 53, 86, 0.04)";

const theme = createMuiTheme({
  ...baseTheme,
  // shadows,
  overrides: {
    MuiIconButton: {},
    MuiCard: {
      root: {
        borderRadius: "8px",
        boxShadow:
          "0px 2px 4px rgba(68, 53, 86, 0.05), 0px 4px 10px rgba(68, 53, 86, 0.05), 0px 4px 5px rgba(68, 53, 86, 0.04)",
      },
    },

    MuiCheckbox: {},

    MuiChip: {
      root: {
        margin: "5px 0px 0px 0px",
        borderRadius: "5px",
        backgroundColor: "#E4E8F4",
        fontFamily: "Gilroy Regular",
        fontSize: "14px",
        textAlign: "left",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        "& span": {
          textTransform: "capitalize",
          fontFamily: "Gilroy Bold",
          fontSize: "14px",
        },
      },
      containedSecondary: {
        width: "170px",
        height: "50px",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        margin: "auto",
        textTransform: "inherit",
        boxShadow: "none",
        "& .MuiButton-endIcon ": {
          position: "absolute",
          right: "5px",
        },
      },
      outlinedSecondary: {
        width: "172px",
        height: "50px",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        border: "2px solid #04AAF0",
        textTransform: "none",
      },

      textSecondary: {
        padding: 0,
        textTransform: "none",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        border: "none",
        "& *": {
          fill: ocean.primary,
        },
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "transparent !important",
          color: ocean.dark,
          "& *": {
            fill: ocean.darkest,
          },
        },
      },
    },
    MuiOutlinedInput: {
      multiline: {
        border: `1px solid #DEE0E6`,

        fontFamily: "Gilroy Regular",
        fontSize: "16px",
        "&$focused": {
          border: `1px solid ${baseTheme.palette.secondary.main} !important`,
        },
        "&$error": {
          border: `1px solid ${baseTheme.palette.error.dark}`,
        },
      },
      notchedOutline: {
        display: "none",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "none !important",
      },
    },
    MuiPaper: {
      root: {
        position: "relative",
        outline: "none !important",
      },
    },
    MuiInput: {
      root: {
        border: `1px solid #DEE0E6`,
        borderRadius: "5px",
        padding: "10px 12px",
        height: "55px",
        fontFamily: "Gilroy Regular",
        fontSize: "16px",
        "&$focused": {
          border: `1px solid ${baseTheme.palette.secondary.main}`,
        },
        "&$error": {
          border: `1px solid ${baseTheme.palette.error.dark}`,
        },
      },
    },

    MuiLink: {
      root: {
        textAlign: "center",
        margin: "auto",

        fontFamily: "Gilroy Regular",
        fontSize: "14px",
        color: "#030822",
        borderBottom: "1px solid",
        paddingBottom: "3px",
        textDecoration: "none !important",
        "&:hover": {
          cursor: "pointer",
          color: baseTheme.palette.secondary.main,
          borderBottom: `1px solid ${baseTheme.palette.secondary.main}`,
        },
      },
    },

    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#9297AD",
        },
        "&$error": {},
      },
      marginDense: {
        color: "black",
        transform: "translate(15px, 36px) scale(1)",
      },
      shrink: {
        transform: "translate(5px, -5px) scale(1)",
        color: "black !important",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },
      formControl: {
        transform: "translate(5px, 20px)",
        color: "#9297AD !important",
      },
      outlined: {
        "&$shrink": {
          transform: "translate(5px, -10px) scale(1)",
          color: "black !important",
          fontFamily: "Gilroy Bold",
          fontSize: "14px",
        },
      },
    },

    MuiTextField: {
      root: {
        "& textarea": {
          fontFamily: "Gilroy Regular",
          fontSize: "16px",
        },
        fontFamily: "Gilroy Regular",
        fontSize: "16px",
      },
    },

    MuiList: {},

    MuiListSubheader: {
      root: {
        paddingRight: "0px",
        width: "calc(100% - 10px)",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },
    },

    MuiSelect: {
      root: {},
    },
  },
});

export default theme;
