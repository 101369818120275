import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import TabletModal, {
  ModalCloseButton,
} from "ui/components/mobile/TabletModal";
import AuthActions from "domain/core/auth/actions";
import { getTokenExplanation } from "domain/core/auth/selectors";
import { useActions, useReduxState } from "re-reduced";
import TokenExplanationImage from "assets/illustrations/TokenExplanation.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "580px !important",
      // minHeight: "320px",
      height: "650px !important",

      textAlign: "center",
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 16px) !important",
        height: "auto !important",

        "& * h4": {
          fontSize: "30px",
        },
      },

      [theme.breakpoints.down("xs")]: {
        width: "calc(100%) !important",
        height: "calc(100%) !important",
      },
    },

    button: {
      width: "230px",
    },
    rootContainer: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    container: {
      margin: "0px",
    },
    imageItem: {
      paddingBottom: "0px !important",
      maxWidth: "calc(100% - 60px)",
      "& img": {
        width: "100%",
      },
    },
    closeButton: {
      marginRight: "0px",
      marginLeft: "auto",
      width: "30px",
      height: "30px",
      display: "flex",

      "& img": {
        margin: "auto",
      },
    },
    loader: {
      margin: "auto auto !important",
    },
    bold: {
      fontFamily: "Gilroy Bold",
    },
  })
);
interface TokenExplanationProps {
  overrideOpen?: boolean;
  onDismiss?: () => void;
}
const TokenExplanation = ({
  overrideOpen,
  onDismiss,
}: TokenExplanationProps) => {
  const classes = useStyles();
  const { tokenExplanation } = useReduxState({
    tokenExplanation: getTokenExplanation,
  });
  const [tokenExplanationModalOpen, setTokenExplanationModalOpen] = useState(
    !tokenExplanation
  );

  const actions = useActions({ ...AuthActions });

  function getImage() {
    return (
      <img
        src={TokenExplanationImage}
        alt=""
        id="fade-in"
      />
    );
  }

  function dissmissTokenExplanation() {
    setTokenExplanationModalOpen(false);
    if (!!onDismiss) {
      onDismiss();
    } else {
      actions.dismissTokenExplanation();
    }
  }

  return (
    <TabletModal
      onClose={() => dissmissTokenExplanation()}
      cardClass={classes.root}
      label="Tablet-Explanation-Modal"
      zIndex={9999}
      open={tokenExplanationModalOpen}
    >
      <Card className={classes.root}>
        <Grid container direction="row" className={classes.rootContainer}>
          <Grid
            container
            className={classes.container}
            direction="column"
            spacing={4}
            alignItems="center"
          >
            <ModalCloseButton onClose={() => dissmissTokenExplanation()} />
            <Grid item className={classes.imageItem}>
              {getImage()}
            </Grid>
            <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4" id="fade-in">
                    Exchange Tokens For Appointments
                  </Typography>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                  <Typography variant="body1">
                    Here you can browse, get to know and book <br />
                    appointments with our support providers. <br />
                    Take your time to find the right provider just for you
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dissmissTokenExplanation()}
                className={classes.button}
              >
                Start Browsing
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </TabletModal>
  );
};

export default TokenExplanation;
