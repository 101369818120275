import { isAfter } from "date-fns";
import {
  PlatformBookingPayload,
  ProviderBookResponse,
} from "domain/providers/types";
import client from "lib/apiClient/api";

import { ENDPOINTS } from "./configuration";
import { WorkshopProfile } from "./types";

export const getWorkshops = client.get<WorkshopProfile[], void>(
  ENDPOINTS.getWorkshops,
  {
    transformResult: (result) => {
      return result.map((w) => {
        w.sessions = w.sessions.filter((s) =>
          isAfter(new Date(s.time), new Date())
        );
        return w;
      });
    },
  }
);

export const bookWorkshop = client.post<
  void,
  PlatformBookingPayload,
  ProviderBookResponse
>(ENDPOINTS.bookWorkshop);
