import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/dashboard/configuration";
import {
  WellbeingOverview,
  MinMax,
  TransformedWellbeing,
  DateAndMood,
} from "./types";
import { WellbeingOverviewResult } from "domain/wellbeing/types";
import {
  AggregatedAppoinments,
  Appointment,
  ProviderAppointmentDTO,
  WorkshopAppointmentDTO,
} from "domain/providers/types";
import { ContentProfile, ContentSearchResult } from "domain/content/types";
import { PaginatedResult } from "lib/types";
import { MoodDiaryResponse } from "domain/dashboard/types";

export const fetchWellbeingOverview = client.get<
  WellbeingOverviewResult[],
  void,
  TransformedWellbeing
>(ENDPOINTS.fetchWellbeingOverview, {
  transformResult: (result) => {
    const overview = result
      .map((payload) => {
        const domainScores: {
          health: MinMax;
          life: MinMax;
          meaning: MinMax;
        } = {
          health: { min: 0, max: 0 },
          life: { min: 0, max: 0 },
          meaning: { min: 0, max: 0 },
        };

        payload.answers.forEach((item) => {
          if (item.key === "health") {
            domainScores.health.max += item.rating !== 0 ? 5 : 0;
            domainScores.health.min += item.rating;
          }
          if (item.key === "life") {
            domainScores.life.max += item.rating !== 0 ? 5 : 0;
            domainScores.life.min += item.rating;
          }
          if (item.key === "meaning") {
            domainScores.meaning.max += item.rating !== 0 ? 5 : 0;
            domainScores.meaning.min += item.rating;
          }
        });

        const priorities = payload.answers
          .sort((a, b) => b.priority - a.priority)
          .slice(0, 3)
          .map((item) => item.label);

        const wellbeingId = payload.wellbeingTestId;
        const date = payload.testDate;

        return {
          domainScores,
          priorities,
          wellbeingId,
          date: new Date(date),
        } as WellbeingOverview;
      })
      .sort((a, b) => b.date.getTime() - a.date.getTime());
    return {
      overview,
      answers: result,
    };
  },
});

export const getAppointments = client.get<
  {
    appointments: ProviderAppointmentDTO[];
    workshops: WorkshopAppointmentDTO[];
  },
  void,
  AggregatedAppoinments
>(ENDPOINTS.getAppointments, {
  transformResult: (result) => {
    const appointments = result.appointments.map((apt) => {
      return {
        providerId: apt.providerId,
        firstName: apt.providerFirstName,
        lastName: apt.providerLastName,
        date: apt.appointmentDate,
        type: apt.providerType,
        address: apt.addressLine1 + ", " + apt.city,
        avatar: apt.providerImageUrl,
        unitsPerSession: apt.unitsPerSession,
        bookingType: apt.appointmentType,
        duration: apt.duration,
      } as Appointment;
    }) as Appointment[];

    const workshops = result.workshops.map((apt) => {
      return {
        ...apt,
        duration: apt.duration,
        appointmentDate: new Date(apt.appointmentDate),
      } as WorkshopAppointmentDTO;
    }) as WorkshopAppointmentDTO[];

    return {
      appointments: appointments,
      workshops: workshops,
    } as AggregatedAppoinments;
  },
});

export const getHotContent = client.post<
  PaginatedResult<ContentSearchResult>,
  void,
  ContentProfile,
  {
    domains: string[];
    contentTypes: string[];
    tags: string[];
    searchText: string[];
    page: number;
    pageLength: number;
  }
>(ENDPOINTS.getHotContent, {
  transformPayload: (payload) => {
    return {
      page: 1,
      pageLength: 1,
      domains: [],
      contentTypes: [],
      tags: ["featured in platform"],
      searchText: [],
    };
  },
  transformResult: (result) => {
    return result.items[0];
  },
});

export const getMoodList = client.get<MoodDiaryResponse, void>(
  ENDPOINTS.getMoodDiary
);

export const setMood = client.post<void, DateAndMood>(ENDPOINTS.setMood);

export const cancelAppointment = client.post<void, { id: string; date: Date }>(
  ENDPOINTS.cancelAppointment
);
