import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

type DeviceType = "Desktop" | "Tablet" | "Mobile" | "MobileXS";

interface Props {
  displayIn: DeviceType[];
}

const Responsive: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const Desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const Tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const Mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const MobileXS = useMediaQuery(theme.breakpoints.down("xs"));

  const shouldRenderChildren = shouldRender(props.displayIn);

  function shouldRender(display: string[]): boolean {
    if (MobileXS) {
      return display.indexOf("MobileXS") !== -1;
    }
    if (Mobile) {
      return display.indexOf("Mobile") !== -1;
    }
    if (Tablet) {
      return display.indexOf("Tablet") !== -1;
    }
    if (Desktop) {
      return display.indexOf("Desktop") !== -1;
    }

    return false;
  }

  return (
    <React.Fragment>
      {shouldRenderChildren ? props.children : null}
    </React.Fragment>
  );
};

Responsive.defaultProps = { displayIn: [] };

export default Responsive;
