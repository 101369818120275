import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useReduxState, useActions } from "re-reduced";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  getNextAppointment,
  getAppointmentStatus,
  getAllPastAppointments,
  getAllFutureAppointments,
} from "domain/dashboard/reducer";
import { DashboardActions } from "domain/dashboard/actions";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  AppointmentCard,
  NoAppointments,
  AppointmentHistory,
  NoPastAppointments,
} from "ui/compounds/dashboard/NextAppointment";
import { Typography, Button, Card } from "@material-ui/core";
import { ReactComponent as Chevron } from "assets/icons/Icon-Left-Arrow.svg";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    backContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "40px 0px 20px 0px",
    },
    header: {
      [theme.breakpoints.down("md")]: {
        ...theme.typography.h5,
      },
    },
    gridContainer: {
      width: "100%",
      "& > *": {
        marginBottom: "16px",
      },
    },
    container: {},

    card: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      height: "calc(100% - 40px)",
      flexBasis: "100%",
    },
  })
);

const Appointments = (props: Props) => {
  const classes = useStyles();
  const reduxState = useReduxState({
    appointment: getNextAppointment,
    pastAppointments: getAllPastAppointments,
    futureAppointments: getAllFutureAppointments,
    status: getAppointmentStatus,
  });

  const actions = useActions(DashboardActions);

  useEffect(() => {
    if (reduxState.status === "Idle") {
      actions.getAppointments();
    }
  }, [actions, reduxState.status]);

  function backToDashboard() {
    props.history.push("/dashboard");
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.backContainer}>
          <Button
            variant="text"
            color="secondary"
            onClick={backToDashboard}
            startIcon={<Chevron />}
          >
            Back to Dashboard
          </Button>
        </div>
        <Grid container spacing={4} justify="space-between">
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid
              container
              direction="column"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography variant="h4" className={classes.header}>
                  My Bookings
                </Typography>
              </Grid>
              {reduxState.futureAppointments.map((item) => (
                <Grid item key={item.date + item.id}>
                  <AppointmentCard appointment={item} />
                </Grid>
              ))}

              {reduxState.futureAppointments.length === 0 && (
                <Grid item>
                  <Card className={classes.card}>
                    <NoAppointments
                      {...props}
                      loading={reduxState.status === "Pending"}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid
              container
              direction="column"
              className={classes.gridContainer}
            >
              <Grid item>
                <Typography
                  variant="h4"
                  className={classes.header}
                  align="left"
                >
                  Booking History
                </Typography>
              </Grid>
              {reduxState.pastAppointments.length !== 0 && (
                <Grid item>
                  <AppointmentHistory
                    {...props}
                    previousAppointments={reduxState.pastAppointments}
                  />
                </Grid>
              )}

              {reduxState.pastAppointments.length === 0 && (
                <Grid item>
                  <Card className={classes.card}>
                    <NoPastAppointments
                      {...props}
                      loading={reduxState.status === "Pending"}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(Appointments);
