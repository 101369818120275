import React, { useRef, useEffect, useCallback } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ArrowTooltip from "ui/components/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: "hidden",
      width: "100%",
    },
    more: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      borderColor: "transparent",
      backgroundColor: "transparent",
      ...theme.typography.subtitle2,

      "& .MuiChip-label": {
        paddingRight: "0px !important",
      },
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  })
);

interface Props {
  listIdPrefix: string;
  chipContent: string[];
  className: string;
  chipStyle?: string;
  displayItem?: (item: string) => JSX.Element;
}

function OverflowChipContainer({
  listIdPrefix,
  chipContent,
  className,
  chipStyle,
  displayItem,
}: Props) {
  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);
  const [overflowCount, setOverflowCount] = React.useState(-1);

  function isOverflowing(element: HTMLElement) {
    if (container.current != null) {
      return (
        element.offsetTop >
        container.current.offsetTop + container.current.clientHeight
      );
    }
    return false;
  }
  const overFlowCheck = useCallback(() => {
    if (container.current != null) {
      const childNodes = container.current.childNodes;
      let overflowCount = 0;
      childNodes.forEach((child) => {
        overflowCount += isOverflowing(child as HTMLElement) ? 1 : 0;
      });

      setOverflowCount(overflowCount);
    }
  }, [container]);

  useEffect(() => {
    if (container.current != null) {
      overFlowCheck();
    }
  }, [overFlowCheck]);

  function renderSpecialtyChips(specialties: string[]) {
    if (
      specialties == null ||
      specialties === undefined ||
      specialties.length === 0
    ) {
      specialties = [];
    }
    return specialties.map((item, index) => {
      if (
        overflowCount > 0 &&
        index === specialties.length - 1 - overflowCount
      ) {
        return (
          <ArrowTooltip
            key={listIdPrefix + "more" + index + "-tooltip"}
            title={specialties
              .slice(index, specialties.length)
              .join(", ")
              .toString()}
          >
            <Chip
              key={listIdPrefix + "more" + index}
              className={classes.more}
              label={`+${overflowCount + 1} more`}
            ></Chip>
          </ArrowTooltip>
        );
      } else if (index < specialties.length - overflowCount) {
        if (displayItem) {
          return displayItem(item);
        } else
          return (
            <Chip
              key={listIdPrefix + "chip" + index}
              className={chipStyle ? chipStyle : classes.chip}
              label={item}
              variant="outlined"
            />
          );
      }

      return null;
    });
  }

  return (
    <div className={className} ref={container}>
      {renderSpecialtyChips(chipContent)}
    </div>
  );
}

export default OverflowChipContainer;
