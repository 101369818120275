import React, { useEffect, useState } from "react";
import ProviderProfile from "ui/compounds/Models/ProviderProfile/ProviderProfile";
import Container from "@material-ui/core/Container";
import { withRouter, RouteComponentProps } from "react-router";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IgniteToken from "assets/IgniteToken.svg";
import {
  getSelectedProvider,
  getSelectedProviderStatus,
  getBookingStatus,
} from "domain/providers/reducer";

import {
  getEmail,
  getFirstName,
  getLastName,
  getTokenBalance,
  getProfile,
  getHasPendingTokenRequest,
  getLatestRequestAmount,
} from "domain/core/selector";

import { useReduxState, useActions } from "re-reduced";
import { ProviderActions } from "domain/providers/actions";
import BookingCard from "ui/compounds/providers/BookingCard";
import { BookingType, PlatformBookingPayload } from "domain/providers/types";
import BookingConfirmation from "ui/compounds/providers/BookingConfirmation";
import BookingSuccess from "ui/compounds/providers/BookingSuccess";
import Button from "@material-ui/core/Button";
import Responsive from "ui/Responsive";
import TabletModal from "ui/components/mobile/TabletModal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import IgniteTagManager from "lib/analytics";

import { REQUEST_STATUS } from "lib/types";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { ReactComponent as IconLeftArrow } from "assets/icons/Icon-Left-Arrow.svg";
import RequestMoreTokens from "ui/compounds/RequestMoreTokens";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      minHeight: "700px",
    },
    root: {
      flexGrow: 1,
    },
    backContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "40px 0px 20px 0px",

      "@media(max-width: 600px)": {
        display: "none",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      zIndex: 5000,

      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "100%",
      },
    },
    mobileFooter: {
      backgroundColor: theme.palette.background.paper,
      display: "none",
      height: 75,
      width: 320,
      zIndex: 999,
      justifyContent: "space-between",
      boxShadow: theme.shadows[8],
      "@media (max-width: 810px)": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100vw",

        "& button": {
          marginRight: "30px",
        },

        "& >:first-child": {
          marginLeft: "30px",
        },
      },
    },
    mobileFooterAndError: {
      backgroundColor: theme.palette.background.paper,
      display: "none",
      zIndex: 999,
      height: 120,
      width: 320,
      justifyContent: "space-between",
      boxShadow: theme.shadows[8],
      "@media (max-width: 810px)": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100vw",
      },
    },
    footerContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      margin: "auto",
      width: "100%",
      paddingTop: "10px",

      "& button": {
        margin: "auto 30px auto auto ",
        [theme.breakpoints.down("xs")]: {
          maxHeight: "40px",
          marginRight: "10px",
          marginTop: "10px",
        },
      },

      "& >:first-child": {
        marginLeft: "30px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px",
        },
      },
    },
    footerErrors: {
      margin: "10px",
      marginLeft: "30px",
      marginRight: "30px",

      [theme.breakpoints.down("sm")]: {
        marginLeft: "30px",
        marginRight: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "10px",
        marginRight: "10px",
      },
      display: "flex",
      "& > *": {},
      flexDirection: "column",
      width: "100vw",
    },
    tokenText: {
      margin: "5px",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      color: "black",
    },
    loading: {
      margin: "auto",
    },
    tokenContainer: {
      display: "flex",
      margin: "auto 10px",
      maxHeight: "35px",
    },
    tokenTextContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "auto 15px",
      maxHeight: "35px",
      height: "24px",
    },
    tokenCost: {
      margin: "auto",
      ...theme.typography.h5,
      fontFamily: "Gilroy Bold",
      color: "#030822",
    },
    tokenBalance: {
      margin: "auto",
      ...theme.typography.subtitle1,
      fontFamily: "Gilroy Bold",
      color: "#030822",
      marginBottom: "0px",
    },
    tokenNotEnough: {
      color: theme.palette.error.main,
    },
  })
);

interface Props extends RouteComponentProps<{ id: string }> {}

function Book(props: Props) {
  const classes = useStyles();
  const actions = useActions(ProviderActions);

  const reduxState = useReduxState({
    selectedProvider: getSelectedProvider,
    selectedProviderStatus: getSelectedProviderStatus,
    profile: getProfile,
    userEmail: getEmail,
    firstName: getFirstName,
    lastName: getLastName,
    bookingStatus: getBookingStatus,
    tokenBalance: getTokenBalance,
    hasPendingRequest: getHasPendingTokenRequest,
    tokenRequestAmount: getLatestRequestAmount,
  });

  const [
    selectedBooking,
    setSelectedBooking,
  ] = useState<PlatformBookingPayload | null>(null);

  const [openBookingConfirmation, setBookingConfirmationOpen] = React.useState(
    false
  );
  const [openBookingCard, setBookingCardOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);

  const profile = reduxState.selectedProvider;

  const handleConfirmBooking = (phone: string, message?: string) => {
    if (selectedBooking) {
      actions.putConfirmationBooking({
        ...selectedBooking,
        message: message,
        phone: phone,
      });
      handleClose();
      setSuccessOpen(true);
    }
  };

  const handleSelectBooking = (date: Date, type: BookingType) => {
    if (profile != null) {
      setSelectedBooking({
        calendarID: profile.calendarID,
        datetime: date,
        timezone: "Pacific/Auckland",
        appointmentType: type,
        email: reduxState.userEmail,
        firstName: reduxState.firstName,
        lastName: reduxState.lastName,
        phone: reduxState.profile.phone,
        appointmentTypeId: 0,
      });
      handleOpen();
    }
  };

  const closeBookingCard = () => {
    setBookingCardOpen(false);
  };

  const closeConfirmation = () => {
    setBookingCardOpen(true);
    setBookingConfirmationOpen(false);
  };

  const handleOpen = () => {
    setBookingCardOpen(false);
    setBookingConfirmationOpen(true);
  };

  const handleClose = () => {
    setBookingCardOpen(false);
    setBookingConfirmationOpen(false);
    setSelectedBooking(null);
  };

  const [unfound, setUnfound] = React.useState(false);

  useEffect(() => {
    if (reduxState.selectedProviderStatus === "Failed") {
      setUnfound(true);
    }
    if (
      (reduxState.selectedProvider == null &&
        reduxState.selectedProviderStatus !== "Pending" &&
        reduxState.selectedProviderStatus !== "Fulfilled") ||
      (reduxState.selectedProvider != null &&
        reduxState.selectedProvider.providerId !== props.match.params.id)
    ) {
      if (!unfound) {
        actions.getProviderById({ providerId: props.match.params.id });
      }
    }
  }, [reduxState, props, actions, unfound, setUnfound]);

  const bookingError = React.useMemo<boolean>(() => {
    return reduxState.bookingStatus.status === "Failed";
  }, [reduxState.bookingStatus]);

  const bookingSuccess = React.useMemo<boolean>(() => {
    return reduxState.bookingStatus.status === "Fulfilled";
  }, [reduxState.bookingStatus]);

  useEffect(() => {
    if (reduxState.selectedProvider !== null) {
      const details = {
        id: reduxState.selectedProvider.providerId,
        firstName: reduxState.selectedProvider.firstName,
        lastName: reduxState.selectedProvider.lastName,
        type: reduxState.selectedProvider.type,
        specialties: reduxState.selectedProvider.specialties,
        qualifications: reduxState.selectedProvider.qualifications,
        domains: reduxState.selectedProvider.domains,
        bookingTypes: reduxState.selectedProvider.bookingTypesAvailable,
        calendarId: reduxState.selectedProvider.calendarID,
        cost: reduxState.selectedProvider.unitsPerSession,
      };

      if (bookingSuccess) {
        const tagManagerArgs = {
          dataLayer: {
            event: "ProviderBookingSuccessful",
            details,
          },
        };

        IgniteTagManager.dataLayer(tagManagerArgs);
      }
      if (bookingError) {
        const tagManagerArgs = {
          dataLayer: {
            event: "ProviderBookingFailed",
            details,
          },
        };

        IgniteTagManager.dataLayer(tagManagerArgs);
      }
    }
  }, [reduxState.selectedProvider, bookingError, bookingSuccess]);

  function renderProfile() {
    return profile && <ProviderProfile profile={profile}></ProviderProfile>;
  }

  function renderBookingCard() {
    return (
      profile && (
        <BookingCard
          providerId={profile.providerId}
          calendarID={profile.calendarID}
          bookingTypes={profile.bookingTypesAvailable}
          name={profile.firstName}
          unitsPerSession={profile.unitsPerSession}
          onConfirmBooking={handleSelectBooking}
          onClose={closeBookingCard}
        />
      )
    );
  }

  function renderTokens() {
    return (
      <div className={classes.tokenContainer}>
        <img
          style={{ width: 32, height: 32 }}
          src={IgniteToken}
          alt="tokens"
        />
        {profile && (
          <div className={classes.tokenTextContainer}>
            <Typography
              className={`${classes.tokenCost} ${
                reduxState.tokenBalance < profile.unitsPerSession &&
                classes.tokenNotEnough
              }`}
              variant="h5"
            >
              {profile.unitsPerSession + "/"}
            </Typography>
            <Typography
              className={`${classes.tokenBalance} ${
                reduxState.tokenBalance < profile.unitsPerSession &&
                classes.tokenNotEnough
              }`}
              variant="subtitle1"
            >
              {reduxState.tokenBalance}
            </Typography>
          </div>
        )}
      </div>
    );
  }
  const [requestMoreOpen, setRequestMoreOpen] = React.useState(false);

  function getBookingConfirmation() {
    return (
      profile &&
      selectedBooking && (
        <BookingConfirmation
          providerId={profile.providerId}
          variant="provider"
          date={new Date(selectedBooking.datetime)}
          firstName={profile.firstName}
          lastName={profile.lastName}
          cost={profile.unitsPerSession}
          address={profile.address}
          duration={60}
          type={selectedBooking.appointmentType}
          onConfirmBooking={handleConfirmBooking}
          dismissModal={closeConfirmation}
        ></BookingConfirmation>
      )
    );
  }

  function getBookingConfirmationModal() {
    return profile && selectedBooking && getBookingConfirmation();
  }

  function getFooter() {
    if (profile !== null && reduxState.tokenBalance < profile.unitsPerSession) {
      return (
        <div className={classes.mobileFooterAndError}>
          <div className={classes.footerContainer}>
            {renderTokens()}

            <Button
              disabled={
                profile !== null &&
                reduxState.tokenBalance < profile.unitsPerSession
              }
              variant="contained"
              color="secondary"
              onClick={() => setBookingCardOpen(true)}
            >
              Book Appointment
            </Button>
          </div>

          <div className={classes.footerErrors}>
            {!reduxState.hasPendingRequest && (
              <React.Fragment>
                <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
                  <Typography
                    variant="caption"
                    className={classes.tokenNotEnough}
                  >
                    You do not have enough tokens for booking this appointment
                  </Typography>
                </Responsive>
                <Responsive displayIn={["MobileXS"]}>
                  <Typography
                    variant="caption"
                    className={classes.tokenNotEnough}
                  >
                    You do not have enough tokens for a booking
                  </Typography>
                </Responsive>
                <div style={{ display: "block", width: "100%" }}>
                  <Button
                    variant="text"
                    style={{ marginTop: "8px" }}
                    color="secondary"
                    onClick={() => setRequestMoreOpen(true)}
                  >
                    Request More
                  </Button>
                </div>
              </React.Fragment>
            )}
            {reduxState.hasPendingRequest && (
              <Typography variant="caption" className={classes.tokenNotEnough}>
                You do not have enough tokens, but your request for{" "}
                {reduxState.tokenRequestAmount} tokens is currently under
                review.
              </Typography>
            )}
          </div>
        </div>
      );
    } else
      return (
        <div className={classes.mobileFooter}>
          {renderTokens()}
          <Button
            disabled={
              profile !== null &&
              reduxState.tokenBalance < profile.unitsPerSession
            }
            variant="contained"
            color="secondary"
            onClick={() => setBookingCardOpen(true)}
          >
            Book Appointment
          </Button>
        </div>
      );
  }

  const handleCloseSuccess = () => {
    if (reduxState.bookingStatus.status !== REQUEST_STATUS.Pending) {
      setBookingCardOpen(false);
      setBookingConfirmationOpen(false);
      setSuccessOpen(false);
      actions.clearBookingStatus();
    }
  };

  function backToSearch() {
    if (
      props.history.location.state &&
      props.history.location.state.from === "/search"
    ) {
      props.history.goBack();
    } else {
      props.history.push("/search");
    }
  }

  function getBookingSuccess() {
    if (
      reduxState.bookingStatus.status !== REQUEST_STATUS.Idle &&
      reduxState.selectedProvider !== null
    )
      return (
        <Modal
          className={classes.modal}
          open={successOpen}
          onClose={handleCloseSuccess}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={successOpen}>
            <BookingSuccess
              variant="provider"
              email={reduxState.userEmail}
              providerName={reduxState.selectedProvider.firstName}
              dismissModal={handleCloseSuccess}
            ></BookingSuccess>
          </Fade>
        </Modal>
      );
    else if (reduxState.bookingStatus.status === REQUEST_STATUS.Failed)
      return (
        <Modal
          className={classes.modal}
          open={successOpen}
          onClose={handleCloseSuccess}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={successOpen}>
            <BookingSuccess
              variant="provider"
              email={reduxState.userEmail}
              dismissModal={handleCloseSuccess}
            ></BookingSuccess>
          </Fade>
        </Modal>
      );
  }
  return (
    <React.Fragment>
      <Responsive displayIn={["Tablet", "Desktop"]}>
        <Container maxWidth="xl" className={classes.rootContainer}>
          <div className={classes.backContainer}>
            <Button
              variant="text"
              color="secondary"
              onClick={backToSearch}
              startIcon={<IconLeftArrow />}
            >
              Back to Support
            </Button>
          </div>
          {reduxState.selectedProviderStatus === "Fulfilled" && (
            <Grid
              container
              className={classes.root}
              direction="row"
              spacing={4}
            >
              <Grid item>{renderProfile()}</Grid>
              <Grid item>{renderBookingCard()}</Grid>
            </Grid>
          )}

          {reduxState.selectedProviderStatus === "Pending" && (
            <Grid
              container
              className={classes.root}
              direction="row"
              spacing={4}
              justify="center"
            >
              <Grid item xs={4}>
                <CircularIndeterminate className={classes.loading} />
              </Grid>
            </Grid>
          )}
        </Container>
      </Responsive>
      <Responsive displayIn={["Mobile", "MobileXS"]}>
        {renderProfile()}
        <TabletModal
          open={openBookingCard}
          zIndex={3400}
          label="bookingCard"
          onClose={() => setBookingCardOpen(false)}
        >
          {renderBookingCard()}
        </TabletModal>

        {getFooter()}
      </Responsive>
      <TabletModal
        open={openBookingConfirmation}
        zIndex={3500}
        label="bookingConfirmation"
        onClose={() => setBookingConfirmationOpen(false)}
      >
        {getBookingConfirmationModal()}
      </TabletModal>
      {getBookingSuccess()}
      <TabletModal
        onClose={() => setRequestMoreOpen(false)}
        label="Request More Tokens"
        zIndex={1200}
        open={requestMoreOpen}
      >
        <RequestMoreTokens dismiss={() => setRequestMoreOpen(false)} />
      </TabletModal>
    </React.Fragment>
  );
}

export default withRouter(Book);
