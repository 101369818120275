import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const listStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "20px"
    },
    listItem: {
      margin: "0px 0px 30px 0px"
    },
    header: {
      fontFamily: "Gilroy Bold",
      fontSize: "16px"
    },
    mainHeading: {
      fontFamily: "Gilroy Bold",
      fontSize: "18px"
    },
    text: {
      fontFamily: "Gilroy Regular",
      fontSize: "16px",
      marginTop: "10px",

      "& b": {
        fontFamily: "Gilroy Bold"
      }
    }
  })
);

export const ContactListContainer: React.FC<{}> = props => {
  const classes = listStyles();

  return <div className={classes.list}>{props.children}</div>;
};

interface ListItemProp {
  header: string;
  body: string | string[];
  type?: "email" | "phone" | "text";
}
export const ContactListItem: React.FC<ListItemProp> = props => {
  const classes = listStyles();

  function getInnerType(text: string) {
    if (props.type === "email") {
      return (
        <a href={`mailto:${text}`} key={text}>
          {text}
        </a>
      );
    }

    if (props.type === "phone") {
      return (
        <a href={`tel:${text}`} key={text}>
          {text}
        </a>
      );
    }
    return text;
  }

  function getBody() {
    if (Array.isArray(props.body)) {
      return props.body.map((item, index) => (
        <div className={classes.text} key={index}>
          {getInnerType(item)}
        </div>
      ));
    }

    return <div className={classes.text}>{getInnerType(props.body)}</div>;
  }
  return (
    <div className={classes.listItem}>
      <div className={classes.header}>{props.header}</div>
      {getBody()}
    </div>
  );
};
