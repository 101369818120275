import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { REQUEST_STATUS } from "lib/types";
import { BookingType, PlatformBookingPayload } from "domain/providers/types";

import { WorkshopActions } from "domain/workshops/actions";
import { ProviderActions } from "domain/providers/actions";
import { DashboardActions } from "domain/dashboard/actions";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import BookingSuccess from "ui/compounds/providers/BookingSuccess";
import { ReactComponent as IconLeftArrow } from "assets/icons/Icon-Left-Arrow.svg";

import {
  getEmail,
  getFirstName,
  getLastName,
  getTokenBalance,
  getProfile,
  getHasPendingTokenRequest,
  getLatestRequestAmount,
} from "domain/core/selector";
import { useActions, useReduxState } from "re-reduced";

import {
  getAllFutureAppointments,
  getAppointmentStatus,
} from "domain/dashboard/reducer";
import {
  getWorkshops,
  getWorkshopsResultStatus,
  getWorkshopBookingStatus,
} from "domain/workshops/reducer";
import {
  Session,
  WorkshopProfile as TWorkshopProfile,
} from "domain/workshops/types";
import { WorkshopSingleCard } from "ui/compounds/WorkshopBookingCard";
import TabletModal from "ui/components/mobile/TabletModal";
import BookingConfirmation from "ui/compounds/providers/BookingConfirmation";
import WorkshopProfile from "ui/compounds/Models/WorkshopProfile/WorkshopProfile";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { Button, Typography } from "@material-ui/core";
import Responsive from "ui/Responsive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      minHeight: "700px",

      [theme.breakpoints.down("sm")]: {
        padding: "0px !important",
      },
    },

    root: {
      flexGrow: 1,
    },
    loaderContainer: {
      height: "300px",
    },
    chipContainer: {
      padding: "20px 0px 20px 0px",
    },
    backContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "40px 0px 20px 0px",

      "@media(max-width: 600px)": {
        display: "none",
      },
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      zIndex: 5000,

      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "100%",
      },
    },

    sale: {
      maxWidth: 382,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
        margin: "auto",
      },
    },
  })
);

interface Props extends RouteComponentProps<{ id: string; time: string }> {}
const Workshop: React.FC<Props> = (props) => {
  const [spacing] = React.useState<GridSpacing>(4);
  const classes = useStyles();
  // NEVER DO THIS..>>>!!!!
  const actions = useActions({ ...WorkshopActions, ...ProviderActions });
  // DO THIS!!!!
  const workshopActions = useActions(WorkshopActions);
  const dashboardActions = useActions(DashboardActions);

  const reduxState = useReduxState({
    searchResults: getWorkshops,
    resultStatus: getWorkshopsResultStatus,
    bookingStatus: getWorkshopBookingStatus,
    profile: getProfile,
    userEmail: getEmail,
    firstName: getFirstName,
    lastName: getLastName,
    tokenBalance: getTokenBalance,
    hasPendingRequest: getHasPendingTokenRequest,
    tokenRequestAmount: getLatestRequestAmount,
    appointments: getAllFutureAppointments,
    appointmentStatus: getAppointmentStatus,
  });

  const [profile, setProfile] = React.useState<TWorkshopProfile>();
  const [session, setSession] = React.useState<Session>();

  const disableBooking = React.useMemo(() => {
    if (!session || !profile) {
      return true;
    }
    const appointment = reduxState.appointments.find(
      (t) =>
        t.id === profile.workshopId &&
        t.date.toUTCString() === session.time.toUTCString()
    );

    if (appointment) return true;

    return false;
  }, [reduxState.appointments, session, profile]);
  useEffect(() => {
    if (reduxState.resultStatus.status === "Idle") {
      workshopActions.getWorkshops();
    } else if (reduxState.appointmentStatus === "Idle") {
      dashboardActions.getAppointments();
    }
  }, [
    reduxState.resultStatus,
    reduxState.appointmentStatus,
    dashboardActions,
    workshopActions,
  ]);

  useEffect(() => {
    if (reduxState.searchResults) {
      const t = reduxState.searchResults.find(
        (t) => t.workshopId === props.match.params.id
      );

      if (t) {
        const s = t.sessions.find(
          (s) =>
            s.time.toISOString() ===
            new Date(props.match.params.time).toISOString()
        );
        setProfile(t);
        if (s) setSession(s);
      } else {
        props.history.push("/error/404");
      }
    }
  }, [reduxState.searchResults, reduxState.resultStatus, actions, props]);

  function getBookingSuccess() {
    if (reduxState.bookingStatus.status !== REQUEST_STATUS.Idle && profile)
      return (
        <Modal
          className={classes.modal}
          open={successOpen}
          onClose={handleCloseSuccess}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={successOpen}>
            <BookingSuccess
              variant="workshop"
              email={reduxState.userEmail}
              providerName={profile.title}
              dismissModal={handleCloseSuccess}
              apiStatus={reduxState.bookingStatus.status}
            ></BookingSuccess>
          </Fade>
        </Modal>
      );
    else if (reduxState.bookingStatus.status === REQUEST_STATUS.Failed)
      return (
        <Modal
          className={classes.modal}
          open={successOpen}
          onClose={handleCloseSuccess}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableAutoFocus={true}
        >
          <Fade in={successOpen}>
            <BookingSuccess
              variant="workshop"
              email={reduxState.userEmail}
              dismissModal={handleCloseSuccess}
            ></BookingSuccess>
          </Fade>
        </Modal>
      );
  }

  function getLoading() {
    if (reduxState.resultStatus.status === REQUEST_STATUS.Idle) {
      return (
        <Grid
          container
          className={classes.root}
          direction="row"
          spacing={4}
          justify="center"
        >
          <Grid item>
            <CircularIndeterminate />
          </Grid>
        </Grid>
      );
    }
  }

  const [
    selectedBooking,
    setSelectedBooking,
  ] = useState<PlatformBookingPayload | null>(null);

  const [openBookingConfirmation, setBookingConfirmationOpen] = React.useState(
    false
  );

  //eslint-disable-next-line
  const [openBookingCard, setBookingCardOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);

  const handleConfirmBooking = (phone: string, message?: string) => {
    if (selectedBooking) {
      actions.bookWorkshop({
        ...selectedBooking,
        message: message,
        phone: phone,
      });
      handleClose();
      setSuccessOpen(true);
    }
  };

  const handleSelectBooking = (date: Date, type: BookingType) => {
    if (profile && session) {
      setSelectedBooking({
        calendarID: session.calendarId,
        datetime: date,
        timezone: "Pacific/Auckland",
        appointmentType: type,
        email: reduxState.userEmail,
        firstName: reduxState.firstName,
        lastName: reduxState.lastName,
        phone: reduxState.profile.phone,
        appointmentTypeId: session.appointmentTypeId,
        duration: session.duration,
      });
      handleOpen();
    }
  };

  const handleCloseSuccess = () => {
    if (reduxState.bookingStatus.status !== REQUEST_STATUS.Pending) {
      setBookingCardOpen(false);
      setBookingConfirmationOpen(false);
      setSuccessOpen(false);
      actions.clearBookingStatus();
    }
  };

  function getBookingConfirmation() {
    return (
      profile &&
      selectedBooking && (
        <BookingConfirmation
          providerId={profile.workshopId}
          variant="workshop"
          date={new Date(selectedBooking.datetime)}
          firstName={profile.title}
          lastName={""}
          cost={profile.unitsPerSession}
          address={profile.address}
          duration={selectedBooking.duration ? selectedBooking.duration : 60}
          type={selectedBooking.appointmentType}
          onConfirmBooking={handleConfirmBooking}
          dismissModal={closeConfirmation}
          disableOptions
        ></BookingConfirmation>
      )
    );
  }

  const closeBookingCard = () => {
    setBookingCardOpen(false);
  };

  const closeConfirmation = () => {
    setBookingCardOpen(true);
    setBookingConfirmationOpen(false);
  };

  const handleOpen = () => {
    setBookingCardOpen(false);
    setBookingConfirmationOpen(true);
  };

  const handleClose = () => {
    setSelectedBooking(null);
    setBookingConfirmationOpen(false);
  };

  function backToSearch() {
    if (
      props.history.location.state &&
      props.history.location.state.from === "/workshops"
    ) {
      props.history.goBack();
    } else {
      props.history.push("/workshops");
    }
  }

  const renderBookingCard = () => {
    if (profile && session)
      return (
        <Grid
          className={`${classes.root} ${classes.sale}`}
          container
          justify="center"
          alignItems="flex-start"
          direction="column"
          spacing={spacing}
        >
          <Grid item>
            <WorkshopSingleCard
              profile={profile}
              session={session}
              disabled={disableBooking}
              onConfirmBooking={handleSelectBooking}
              onClose={closeBookingCard}
            />
          </Grid>
          <Grid item className={classes.sale}>
            <Typography variant="body1" align="center">
              Interested in holding this workshop exclusively for your people?
              Contact{" "}
              <b>
                <a href="mailto:hello@ignite.org.nz">hello@ignite.org.nz</a>
              </b>
            </Typography>
          </Grid>
        </Grid>
      );
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.rootContainer}>
        <Responsive displayIn={["Tablet", "Desktop"]}>
          <div className={classes.backContainer}>
            <Button
              variant="text"
              color="secondary"
              onClick={backToSearch}
              startIcon={<IconLeftArrow />}
            >
              Back to Workshops
            </Button>
          </div>

          {getLoading()}
          {profile && session && (
            <Grid
              className={classes.root}
              container
              alignItems="flex-start"
              spacing={spacing}
            >
              <Grid item>
                <WorkshopProfile profile={profile} session={session} />
              </Grid>
              <Grid item className={classes.sale}>
                {renderBookingCard()}
              </Grid>
            </Grid>
          )}
        </Responsive>
        <Responsive displayIn={["Mobile", "MobileXS"]}>
          {getLoading()}

          {profile && session && (
            <WorkshopProfile profile={profile} session={session} />
          )}
          <Grid
            className={classes.root}
            style={{ padding: 12 }}
            container
            alignItems="flex-start"
            spacing={spacing}
          >
            <Grid item>{renderBookingCard()}</Grid>
          </Grid>
        </Responsive>
      </Container>

      <TabletModal
        open={openBookingConfirmation}
        zIndex={3500}
        label="bookingConfirmation"
        onClose={() => setBookingConfirmationOpen(false)}
      >
        {getBookingConfirmation()}
      </TabletModal>
      {getBookingSuccess()}
    </React.Fragment>
  );
};

export default withRouter(Workshop);
