import { ProvidersState } from "./types";
import { REQUEST_STATUS } from "lib/types";
import { createReducer, match } from "re-reduced";
import { ProviderActions } from "./actions";
import { createSelector } from "reselect";
import { IgniteState } from "domain/types";
import { uniqueArray } from "domain/core/app/utils";
import isSameDay from "date-fns/isSameDay";
import isSameHour from "date-fns/isSameHour";
import isSameMinute from "date-fns/isSameMinute";

export const initialState: ProvidersState = {
  searchResults: {
    items: [],
    pagination: { pageIndex: 0, pageSize: 0, total: 0 },
  },
  resultStatus: { status: REQUEST_STATUS.Idle },
  bookingStatus: { status: REQUEST_STATUS.Idle },
  bookingResponse: {
    balance: 0,
    datetime: "",
  },
  currentCalendar: {
    dates: [],
    times: [],
    calendarID: 0,
  },
  selectedProfile: null,
  selectedProfileStatus: { status: REQUEST_STATUS.Idle },
  monthStatus: { status: REQUEST_STATUS.Idle },
  dayStatus: { status: REQUEST_STATUS.Idle },
};

export const getProvidersState = (state: IgniteState) => state;

export const getResultsStatus = createSelector(
  getProvidersState,
  (providers) => providers.providers.resultStatus.status
);

export const getBookingStatus = createSelector(
  getProvidersState,
  (providers) => providers.providers.bookingStatus
);

export const getSearchResults = createSelector(
  getProvidersState,
  (providers) => {
    return providers.providers.searchResults;
  }
);

export const getCalendar = createSelector(getProvidersState, (providers) => {
  return providers.providers.currentCalendar;
});

export const getMonthStatus = createSelector(getProvidersState, (providers) => {
  return providers.providers.monthStatus;
});

export const getDayStatus = createSelector(getProvidersState, (providers) => {
  return providers.providers.dayStatus;
});

export const getSelectedProvider = createSelector(
  getProvidersState,
  (providers) => {
    return providers.providers.selectedProfile;
  }
);

export const getSelectedProviderStatus = createSelector(
  getProvidersState,
  (providers) => {
    return providers.providers.selectedProfileStatus.status;
  }
);

export const getBookingBalance = createSelector(
  getProvidersState,
  (providers) => {
    return providers.providers.bookingResponse.balance;
  }
);

export default createReducer<ProvidersState>(
  [
    match(ProviderActions.clearBookingStatus, (state) => {
      return {
        ...state,
        bookingStatus: { status: REQUEST_STATUS.Idle },
      };
    }),
    match(ProviderActions.fetchProviderSearchResults.request, (state) => {
      return {
        ...state,
        resultStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(ProviderActions.fetchProviderSearchResults.failure, (state) => {
      return {
        ...state,
        searchResults: state.searchResults,
        resultStatus: { status: REQUEST_STATUS.Failed },
      };
    }),
    match(
      ProviderActions.fetchProviderSearchResults.success,
      (prevState, payload) => {
        const items = payload.items.map((item) => {
          item.domains = uniqueArray(item.domains);

          return item;
        });

        payload.items = items;

        if (payload.pagination == null || payload.pagination.pageIndex === 0) {
          return {
            ...prevState,
            searchResults: {
              items: payload.items,
              pagination: payload.pagination,
            },
            resultStatus: { status: REQUEST_STATUS.Fulfilled },
          };
        } else {
          return {
            ...prevState,
            searchResults: {
              items: [...prevState.searchResults.items, ...payload.items],
              pagination: payload.pagination,
            },
            resultStatus: { status: REQUEST_STATUS.Fulfilled },
          };
        }
      }
    ),

    match(ProviderActions.putConfirmationBooking.request, (state, _) => {
      return {
        ...state,
        bookingStatus: {
          status: REQUEST_STATUS.Pending,
        },
      };
    }),
    match(ProviderActions.putConfirmationBooking.success, (state, payload) => {
      const date = new Date(payload.datetime);
      const times = state.currentCalendar.times.filter((a) => {
        const _date = new Date(a);
        return !(
          isSameDay(new Date(_date), date) &&
          isSameHour(_date, date) &&
          isSameMinute(_date, date)
        );
      });

      return {
        ...state,
        currentCalendar: {
          ...state.currentCalendar,
          times: [...times],
        },
        bookingResponse: {
          ...payload,
        },
        bookingStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(ProviderActions.putConfirmationBooking.failure, (state, error) => {
      return {
        ...state,
        bookingStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),

    match(ProviderActions.getProviderById.request, (state, _) => {
      return {
        ...state,
        selectedProfile: null,
        selectedProfileStatus: {
          status: REQUEST_STATUS.Pending,
        },
        currentCalendar: initialState.currentCalendar,
        monthStatus: initialState.monthStatus,
        dayStatus: initialState.dayStatus,
      };
    }),
    match(ProviderActions.getProviderById.success, (state, payload) => {
      return {
        ...state,
        selectedProfile: payload,
        selectedProfileStatus: { status: REQUEST_STATUS.Fulfilled },
        currentCalendar: {
          dates: [...payload.availableDates],
          times: [...payload.availableTimes],
          calendarID: payload.calendarID,
        },
        monthStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(ProviderActions.getProviderById.failure, (state, error) => {
      return {
        ...state,
        selectedProfileStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),

    match(ProviderActions.fetchCalendarMonth.request, (state, _) => {
      return {
        ...state,
        monthStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(ProviderActions.fetchCalendarMonth.success, (state, payload) => {
      return {
        ...state,
        currentCalendar: {
          dates: uniqueArray([
            ...state.currentCalendar.dates,
            ...payload.availableTimes,
          ]),
          times: [...state.currentCalendar.times],
          calendarID: state.currentCalendar.calendarID,
        },
        monthStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(ProviderActions.fetchCalendarMonth.failure, (state, error) => {
      return {
        ...state,
        monthStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),

    match(ProviderActions.fetchCalendarDay.request, (state, _) => {
      return {
        ...state,
        currentCalendar: {
          ...state.currentCalendar,
          times: [],
        },
        dayStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(ProviderActions.fetchCalendarDay.success, (state, payload) => {
      return {
        ...state,
        currentCalendar: {
          ...state.currentCalendar,
          times: uniqueArray([...payload.availableTimes]),
          calendarID: state.currentCalendar.calendarID,
        },
        dayStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    match(ProviderActions.fetchCalendarDay.failure, (state, error) => {
      return {
        ...state,
        currentCalendar: {
          ...state.currentCalendar,
          times: [],
        },
        dayStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),
  ],
  initialState
);
