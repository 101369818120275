import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/providers/configuration";
import {
  ProviderSearchPayload,
  ProviderSearchResult,
  PlatformBookingPayload,
  ProviderBookResponse,
  ProfileExtendedInfo,
  CalendarPayload,
  Calendar,
} from "domain/providers/types";
import { APIErrorResponse } from "lib/apiClient/createClient";
import { PaginatedResult } from "lib/types";

export const fetchProvidersSearchResults = client.post<
  ProviderSearchResult,
  ProviderSearchPayload,
  PaginatedResult<ProviderSearchResult>,
  {
    domains: string[];
    specialities: string[];
    bookingTypes: string[];
    cities: string[];
  }
>(ENDPOINTS.fetchSearchResults, {
  transformPayload: (payload) => {
    return {
      domains: payload.domainsOfSupport,
      specialities: payload.specialitiesOfSupport,
      bookingTypes: payload.appointmentType,
      cities: payload.cities,
    };
  },
  transformResult: (result) => {
    return {
      items: result,
      pagination: {
        total: result.length,
        pageSize: 12,
        pageIndex: 0,
      },
    };
  },
});
export const fetchSpecialities = client.get<string[], void, string[]>(
  ENDPOINTS.fetchSpecialities
);
export const getProviderById = client.get<
  ProfileExtendedInfo,
  { providerId: string },
  ProfileExtendedInfo
>(ENDPOINTS.getProfile, {
  transformEndpoint: (payload) => {
    return (
      process.env.REACT_APP_API_ENDPOINT +
      ENDPOINTS.getProfile +
      `?providerId=${payload.providerId}`
    );
  },
});

export const putConfirmationBooking = client.post<
  void,
  PlatformBookingPayload,
  ProviderBookResponse
>(ENDPOINTS.putConfirmationBooking);

export const fetchCalendar = client.post<
  Calendar,
  CalendarPayload,
  APIErrorResponse
>(ENDPOINTS.fetchCalendar, {
  transformPayload: (payload) => {
    return {
      calendarID: payload.calendarID,
      startDate: payload.startDate,
    };
  },
});

export const fetchCalendarMonth = client.get<Calendar, CalendarPayload>(
  ENDPOINTS.fetchCalendarMonth,
  {
    transformEndpoint: (payload) => {
      return (
        process.env.REACT_APP_API_ENDPOINT +
        ENDPOINTS.fetchCalendarMonth +
        `?calendarId=${payload.calendarID}&startDate=${payload.startDate}`
      );
    },
  }
);

export const fetchCalendarDay = client.post<
  Calendar,
  CalendarPayload,
  APIErrorResponse
>(ENDPOINTS.fetchCalendar, {
  transformPayload: (payload) => {
    return {
      calendarID: payload.calendarID,
      startDate: payload.startDate,
    };
  },
});
