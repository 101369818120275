import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";
import Container from "@material-ui/core/Container";
import Banner from "ui/components/Banner";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { getContactUsStatus } from "domain/core/app/reducer";
import { useReduxState, useActions } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import Button from "@material-ui/core/Button";
import * as selectors from "domain/core/auth/selectors";
import IgniteToken from "assets/IgniteToken.svg";
import { Typography, Divider, CircularProgress } from "@material-ui/core";
import FormInput from "ui/components/Input";
import ContactSuccess from "assets/states/success/ContactSuccess.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as ResetPassword } from "assets/icons/Replay.svg";
import {
  getNameErrorMsg,
  validPhone,
  getPhoneErrorMsg,
  validEmail,
  getEmailErrorMsg,
} from "utils/validation";
import TabletModal from "ui/components/mobile/TabletModal";
import RequestMoreTokens from "ui/compounds/RequestMoreTokens";

const useStylez = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "30px",
      [theme.breakpoints.down("sm")]: {
        margin: "20px",
      },
    },

    divider: {
      margin: "20px 0px",
    },

    emailBannerWrapper: {
      width: "100%",
      maxWidth: "461px",
      minHeight: "94px",
      display: "flex",
      margin: theme.spacing(1),
      marginBottom: "18px",

      height: "auto",

      "& h6": {
        fontFamily: "Gilroy Regular",
        color: "black",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        margin: "0px",
        marginTop: "-22px",
      },
    },
    emailBanner: {
      width: "100%",
      maxWidth: "461px",
      margin: "auto",
      display: "flex",
      backgroundColor: "#F2F4FE",
      borderLeft: "5px solid #C6CCE9",

      height: "55px",

      "& *": {
        margin: "auto 10px",
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        margin: "0px",
      },
    },

    rowFlex: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    saveChangesContainer: {
      marginTop: "-7px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "auto",
      justifyContent: "flex-end",
      alignContent: "flex-end",

      "& > *": {
        margin: "15px",
        marginBottom: "0px",
        marginTop: "0px",
        marginRight: "0px",
      },

      [theme.breakpoints.down("xs")]: {
        "& > *": {
          margin: "5px",
        },
        "& >:first-child": {
          marginLeft: "0px",
        },
      },

      "@media(max-width: 420px)": {
        flexDirection: "column",
        "&  > *": {
          width: "100%",
          marginLeft: "0px",
          marginRight: "0px",
        },
      },
    },

    resetPasswordContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > *": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },

    resetPasswordContainerMobileWithSuccess: {
      "@media(max-width: 560px)": {
        height: "100px",
        flexDirection: "column",
        "& h6": {
          marginLeft: "0px",
        },
        "& > *": {
          margin: "auto",
          marginBottom: "15px",
        },
        "&:first-child": {
          marginLeft: "0px",
        },
      },
      "@media(max-width: 480px)": {
        flexDirection: "column",
        height: "auto",

        "& > *": {
          display: "flex",
          flexDirection: "column !important",
          textAlign: "center",
        },
      },
    },
    resetPasswordSuccessContainer: {
      display: "flex",
      flexDirection: "row",
      maxWidth: "420px",
      "& > *": {
        maxHeight: "54px",
        margin: "auto 10px",
      },
    },
    deleteButton: {
      marginTop: "33px",
      height: "22px",

      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
        // position: "absolute",
        // right: "40%",
        // top: "33px",
      },
    },
    loader: {
      marginLeft: "15px",
    },
    commsFieldContainer: {
      position: "relative",
      flexDirection: "row",
      marginTop: "15px",
      marginLeft: "-3px",
      display: "flex",
      columnCount: 2,
      "& >:first-child": {
        minWidth: "300px",
        maxWidth: "50%",
      },

      [theme.breakpoints.down("xs")]: {
        marginBottom: "15px",
        display: "flex",

        flexDirection: "column",
        alignItems: "flex-start",

        "& >:first-child": {
          marginBottom: "0px",
          minWidth: "240px",
          maxWidth: "100%",
          width: "100%",
        },

        "& :nth-child(1)": {
          order: 1,
        },

        "& :nth-child(2)": {
          order: 3,
        },
        "& :nth-child(3)": {
          order: 2,
        },
        "& :nth-child(4)": {
          order: 4,
        },
      },
    },
    grid: {
      maxWidth: "810px",
    },

    tokenCard: {
      maxWidth: "381px",
      width: "381px",
      height: "auto",

      flexGrow: 1,
      flexDirection: "row",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 30px)",
      },
    },
    tokenContainer: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      margin: "30px",
      [theme.breakpoints.down("sm")]: {
        margin: "20px",
      },

      "& button": {
        width: "auto",
        margin: "30px",
      },
    },

    tokenDisplay: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "10px auto 10px auto",

      "& > *": {
        marginLeft: "5px",
        marginRight: "5px",
      },

      "& img": {
        width: "60px",
        height: "60px",
      },

      "& .MuiTypography-root": {
        height: "60px",
        fontSize: "60px",
        marginTop: "auto",
        marginBottom: "auto",
      },
    },

    root: {
      paddingBottom: "5rem",
    },

    twoColumnLayout: {
      position: "relative",
      flexDirection: "row",
      columnCount: 2,

      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& :nth-child(1)": {
          order: 1,
        },

        "& :nth-child(2)": {
          order: 3,
        },
        "& :nth-child(3)": {
          order: 2,
        },
        "& :nth-child(4)": {
          order: 4,
        },
      },
    },
    pageContentContainer: {
      marginTop: "-110px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-50px",
      },
    },

    formControl: {
      width: "100%",
      marginBottom: "15px",
      marginTop: "4px",
      minWidth: "320px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "100%",
      },
    },

    card: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },

      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      flexWrap: "wrap",

      backgroundColor: theme.palette.background.paper,
    },
    descriptorCard: {
      maxWidth: "800px",
      width: "800px",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 30px)",
      },
    },
  })
);

interface Props extends RouteComponentProps {}

function AccountPage(props: Props) {
  const classes = useStylez();

  const [secondaryEmailInvalid, setSecondaryEmailInvalid] = React.useState<
    boolean
  >(false);
  const [firstNameInvalid, setFirstNameInvalid] = React.useState<boolean>(
    false
  );

  const [lastNameInvalid, setLastNameInvalid] = React.useState<boolean>(false);
  const [phoneInvalid, setPhoneInvalid] = React.useState<boolean>(false);

  const actions = useActions(AuthActions);

  const reduxState = useReduxState({
    getContactUsStatus,
    ...selectors,
  });

  const [firstName, setFirstName] = React.useState(reduxState.getFirstName);
  const [lastName, setLastName] = React.useState(reduxState.getLastName);

  const [phone, setPhone] = React.useState<string>(reduxState.getPhone);

  const [setType, setSetType] = React.useState("");

  const [fpls, setFPLS] = React.useState(false);

  const [secondaryEmail, setSecondaryEmail] = React.useState<string>(
    reduxState.getSecondaryEmail
  );

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    if (fpls) {
      setFPLS(false);
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    if (fpls) {
      setFPLS(false);
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setPhone(value);
  };

  const handleSecondaryEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSecondaryEmail(event.target.value);
  };

  const getFirstNameError = () => {
    return firstNameInvalid && !validName(firstName);
  };

  const getLastNameError = () => {
    return lastNameInvalid && !validName(lastName);
  };

  function getPhoneError() {
    return phoneInvalid;
  }

  function getSecondaryEmailError() {
    return secondaryEmailInvalid && !validEmail(secondaryEmail);
  }

  const cancelNameChanges = () => {
    setFirstName(reduxState.getFirstName);
    setLastName(reduxState.getLastName);
  };

  const cancelContactInfo = () => {
    setSecondaryEmail(reduxState.getSecondaryEmail as string);
    setPhone(reduxState.getPhone as string);
  };

  const getNameHasChanged = () => {
    return (
      firstName !== (reduxState.getFirstName as string) ||
      lastName !== (reduxState.getLastName as string)
    );
  };

  const getCommunicationHasChanged = () => {
    return (
      secondaryEmail !== reduxState.getSecondaryEmail ||
      phone !== reduxState.getPhone
    );
  };

  const submitNameChanges = (event: React.ChangeEvent<EventTarget>) => {
    let failedPreValidation = false;

    if (!validName(firstName)) {
      failedPreValidation = true;
      setFirstNameInvalid(true);
    } else {
      setFirstNameInvalid(false);
    }

    if (!validName(lastName)) {
      failedPreValidation = true;
      setLastNameInvalid(true);
    } else {
      setLastNameInvalid(false);
    }

    if (
      !failedPreValidation &&
      reduxState.accountInfoName.status !== "Pending"
    ) {
      actions.updateName({ firstName, lastName });
    }
  };

  const submitContactInfo = (event: React.ChangeEvent<EventTarget>) => {
    let failedPreValidation = false;

    if (!validEmail(secondaryEmail)) {
      failedPreValidation = true;
      setSecondaryEmailInvalid(true);
    } else {
      setSecondaryEmailInvalid(false);
    }

    if (!validPhone(phone)) {
      failedPreValidation = true;
      setPhoneInvalid(true);
    } else {
      setPhoneInvalid(false);
    }

    if (
      !failedPreValidation &&
      reduxState.accountInfoName.status !== "Pending"
    ) {
      setSetType("");
      actions.updateContactInfo({
        secondaryEmail: secondaryEmail,
        phone: phone,
      });
    }
  };

  const deletePhone = () => {
    setSetType("delete-phone");
    actions.updateContactInfo({
      secondaryEmail: reduxState.getSecondaryEmail,
      phone: "",
    });
  };

  const deleteEmail = () => {
    setSetType("delete-email");

    actions.updateContactInfo({
      secondaryEmail: "",
      phone: reduxState.getPhone,
    });
  };

  const validName = (name: string) => {
    return (
      name.length > 1 &&
      /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g.test(name) &&
      !/^[0-9]*$/gm.test(name)
    );
  };

  const resetPassword = () => {
    actions.forgotPassword({ username: reduxState.getEmail });
    setFPLS(true);
  };

  useEffect(() => {
    actions.getRequestTokenStatusAll();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (reduxState.accountInfoContact.status === "Fulfilled") {
      setSecondaryEmail(reduxState.getSecondaryEmail);
      setPhone(reduxState.getPhone);
    }
  }, [
    reduxState.accountInfoContact,
    reduxState.getPhone,
    reduxState.getSecondaryEmail,
  ]);

  const [isOpen, setOpen] = React.useState(false);

  function dismiss() {
    setOpen(false);
  }

  function MoreTokensView() {
    return (
      <TabletModal
        onClose={() => dismiss()}
        label="Request More Tokens"
        zIndex={1200}
        open={isOpen}
      >
        <RequestMoreTokens dismiss={dismiss} />
      </TabletModal>
    );
  }

  return (
    <div className={classes.root}>
      <Banner variant="secondary">
        <div>My Account</div>
      </Banner>
      <div className={classes.pageContentContainer}>
        <Container maxWidth="xl">
          <Grid container direction="row" spacing={2}>
            <Grid item className={classes.grid}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Card
                      className={`${classes.card} ${classes.descriptorCard}`}
                    >
                      <div className={classes.container}>
                        <Typography variant="h6">Account Details</Typography>
                        <Divider className={classes.divider} />

                        <div
                          className={classes.rowFlex}
                          style={{ marginTop: "3px" }}
                        >
                          <div className={classes.twoColumnLayout}>
                            <FormInput
                              alwaysExpanded
                              shrink
                              label="Work Email"
                              className={classes.formControl}
                              endAdornment={<LockIcon />}
                              disabled
                              value={reduxState.getEmail}
                              id="component-work-email"
                              autoComplete="current-work-email"
                            />
                            <FormInput
                              alwaysExpanded
                              label="First Name"
                              shrink
                              required
                              className={classes.formControl}
                              value={firstName}
                              onChange={handleFirstNameChange}
                              error={getFirstNameError()}
                              errorMsg={getNameErrorMsg(firstName)}
                              id="component-first-name"
                              autoComplete="current-first-name"
                            />

                            <div className={classes.emailBannerWrapper}>
                              <div className={classes.emailBanner}>
                                <Typography variant="caption">
                                  You can't change your work email because your
                                  account is connected to it.
                                </Typography>
                              </div>
                            </div>

                            <FormInput
                              alwaysExpanded
                              label="Last Name"
                              className={classes.formControl}
                              value={lastName}
                              required
                              shrink
                              onChange={handleLastNameChange}
                              error={getLastNameError()}
                              errorMsg={getNameErrorMsg(lastName)}
                              id="component-last-name"
                              autoComplete="current-last-name"
                            ></FormInput>
                          </div>
                        </div>

                        {!getNameHasChanged() ? (
                          <React.Fragment>
                            <Divider
                              style={{
                                marginBottom: "20px",
                                marginTop: "-3px",
                              }}
                            />
                            <div
                              className={`${classes.resetPasswordContainer}
                      ${
                        reduxState.getForgotPasswordRequest.status ===
                          "Fulfilled" &&
                        classes.resetPasswordContainerMobileWithSuccess
                      }`}
                              id="fade-in"
                            >
                              <Typography variant="h6">Password</Typography>

                              {reduxState.getForgotPasswordRequest.status ===
                                "Fulfilled" && fpls ? (
                                <div
                                  className={
                                    classes.resetPasswordSuccessContainer
                                  }
                                >
                                  <img alt="" src={ContactSuccess} />
                                  <Typography variant="body1">
                                    Email with password reset instructions was
                                    sent to <b>{reduxState.getEmail}</b>
                                  </Typography>
                                </div>
                              ) : (
                                <Button
                                  variant="text"
                                  color="secondary"
                                  endIcon={<ResetPassword />}
                                  onClick={resetPassword}
                                >
                                  Reset Password
                                </Button>
                              )}
                            </div>
                          </React.Fragment>
                        ) : (
                          <div
                            className={classes.saveChangesContainer}
                            id="fade-in"
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={cancelNameChanges}
                            >
                              Cancel
                            </Button>

                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={submitNameChanges}
                            >
                              {reduxState.accountInfoName.status ===
                              "Pending" ? (
                                <CircularProgress size={18} color="inherit" />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      className={`${classes.card} ${classes.descriptorCard}`}
                    >
                      <div className={classes.container}>
                        <Typography variant="h6">
                          Personal Communications
                        </Typography>
                        <Divider className={classes.divider} />

                        <Typography variant="body1">
                          Receive confirmations and provider correspondence
                          privately instead of using your work email.
                        </Typography>
                        <div className={classes.rowFlex}>
                          <div className={classes.commsFieldContainer}>
                            <FormInput
                              alwaysExpanded
                              shrink
                              label="Communication Email"
                              className={classes.formControl}
                              value={secondaryEmail}
                              onChange={handleSecondaryEmailChange}
                              error={getSecondaryEmailError()}
                              errorMsg={getEmailErrorMsg(secondaryEmail)}
                              type="email"
                              id="component-secondary-email"
                              autoComplete="current-secondary-email"
                            />
                            {reduxState.getSecondaryEmail !== null &&
                              reduxState.getSecondaryEmail.length !== 0 &&
                              reduxState.accountInfoContact.status !==
                                "Pending" && (
                                <Button
                                  className={classes.deleteButton}
                                  variant="text"
                                  color="secondary"
                                  onClick={deleteEmail}
                                >
                                  Delete
                                </Button>
                              )}
                            {reduxState.accountInfoContact.status ===
                              "Pending" &&
                              setType === "delete-email" && (
                                <CircularProgress
                                  size={16}
                                  color="secondary"
                                  className={`${classes.deleteButton} ${classes.loader}`}
                                />
                              )}
                          </div>
                        </div>

                        <Typography variant="body1">
                          Only your the support providers that you’ve booked
                          will be able to see your phone number.
                        </Typography>

                        <div className={classes.rowFlex}>
                          <div className={classes.commsFieldContainer}>
                            <FormInput
                              alwaysExpanded
                              shrink
                              label="Phone Number"
                              className={classes.formControl}
                              value={phone}
                              onChange={handlePhoneChange}
                              error={getPhoneError()}
                              errorMsg={getPhoneErrorMsg(phone)}
                              type="tel"
                              id="component-phone"
                              autoComplete="current-phone"
                            />
                            {reduxState.getPhone !== null &&
                              reduxState.getPhone.length !== 0 &&
                              reduxState.accountInfoContact.status !==
                                "Pending" && (
                                <Button
                                  className={classes.deleteButton}
                                  variant="text"
                                  color="secondary"
                                  onClick={deletePhone}
                                >
                                  Delete
                                </Button>
                              )}
                            {reduxState.accountInfoContact.status ===
                              "Pending" &&
                              setType === "delete-phone" && (
                                <CircularProgress
                                  size={16}
                                  color="secondary"
                                  className={`${classes.deleteButton} ${classes.loader}`}
                                />
                              )}
                          </div>
                        </div>

                        {getCommunicationHasChanged() && (
                          <div
                            className={classes.saveChangesContainer}
                            id="fade-in"
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={cancelContactInfo}
                            >
                              Cancel
                            </Button>

                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={submitContactInfo}
                            >
                              {reduxState.accountInfoContact.status ===
                              "Pending" ? (
                                <CircularProgress size={18} color="inherit" />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item>
              <Card className={classes.tokenCard}>
                <div className={classes.tokenContainer}>
                  <Typography variant="h6">Your Tokens</Typography>
                  <Divider className={classes.divider} />

                  <div className={classes.tokenDisplay}>
                    <Typography variant="h3">
                      {reduxState.getTokenBalance}
                    </Typography>
                    <img alt="" src={IgniteToken} />
                  </div>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginBottom: "12px" }}
                  >
                    {reduxState.getTokenRequestsStatus.status === "Fulfilled" &&
                    reduxState.getHasPendingTokenRequest ? (
                      <React.Fragment>
                        You currently have a request <br /> for{" "}
                        {reduxState.getLatestRequestAmount} tokens under review
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        Exchange tokens to book
                        <br /> provider appointments
                      </React.Fragment>
                    )}
                  </Typography>
                  {reduxState.getTokenRequestsStatus.status === "Fulfilled" &&
                    !reduxState.getHasPendingTokenRequest && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpen(true)}
                      >
                        Request More Tokens
                      </Button>
                    )}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      {MoreTokensView()}
    </div>
  );
}

export default withRouter(AccountPage);
