import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IgniteToken from "assets/IgniteToken.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tokenText: {
      margin: "5px",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      color: "black",
    },
    tokenContainer: {
      display: "flex",
      margin: "auto 0",
      "& img": {
        marginRight: "15px",
        maxWidth: "30px",
        maxHeight: "30px",
      },
    },
    light: {
      fontFamily: "Gilroy Regular",
      fontSize: "16px",
    },
    marginAuto: {
      margin: "auto",
    },
  })
);

interface Props {
  align: "left" | "right";
  light?: boolean;
  margin?: boolean;
  tokenStyle?: React.CSSProperties;
}

const Tokens: React.FC<Props> = (props) => {
  const classes = useStyles();

  if (props.align === "left") {
    return (
      <div className={classes.tokenContainer}>
        <img
          style={{ ...props.tokenStyle }}
          id="tokenIcon-inline"
          src={IgniteToken}
          alt="tokens"
        />
        <Typography
          variant="body1"
          color="primary"
          className={`${classes.tokenText} ${props.light && classes.light} ${
            props.margin && classes.marginAuto
          }`}
        >
          {props.children}
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.tokenContainer}>
      <p className={`${classes.tokenText} ${props.light && classes.light}`}>
        {props.children}
      </p>
      <img
        style={props.tokenStyle}
        src={IgniteToken}
        alt="tokens"
      />
    </div>
  );
};

export default Tokens;
