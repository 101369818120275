import { Modal, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CloseToken from "assets/booking/Close.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    card: {
      "& .MuiPaper-root": {
        height: "500px",
        overflow: "scroll",
        zIndex: 3500,
        width: "380px",
        outline: "none !important",
        [theme.breakpoints.down("xs")]: {
          left: 0,
          top: 0,
          position: "absolute",
          borderRadius: "0px",
          width: "100vw",
          height: "calc(100vh - 65px)",
        },
      },
    },
  })
);

const useStylesCloseButton = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      marginRight: "0px",
      marginLeft: "auto",
      width: "30px",
      height: "30px",
      display: "flex",
      cursor: "pointer",

      "& img": {
        margin: "auto",
      },
    },
  })
);

interface Props {
  cardClass?: string;
  onClose?: () => void;
  zIndex?: number;
  open: boolean;
  label: string;
}

//Tablet Modal that fullscreens for mobile devices to fake flow control.
const TabletModal: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      style={{ zIndex: props.zIndex, outline: "none" }}
      open={props.open}
      onClose={props.onClose}
      disableAutoFocus={true}
    >
      <div
        className={`${classes.card} ${props.cardClass}`}
        style={{ zIndex: props.zIndex }}
      >
        {props.children}
      </div>
    </Modal>
  );
};

export const ModalCloseButton = (props: { onClose?: () => void }) => {
  const classes = useStylesCloseButton();
  return (
    <Grid container alignContent="flex-end">
      <Grid item className={classes.closeButton} onClick={props.onClose}>
        <img   src={CloseToken} alt="close" />
      </Grid>
    </Grid>
  );
};

export default TabletModal;
