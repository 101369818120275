import { SagaIterator } from "redux-saga";
import { put, takeLatest, all, call } from "redux-saga/effects";

import * as api from "./api";

import { Action } from "re-reduced";
import AppActions from "./actions";
import { ContactUsPayload } from "./types";

export default function* sagaWatcher() {
  yield all([
    takeLatest(AppActions.submitContact.type, submitContactUs),
    takeLatest(AppActions.getLocations.type, getLocations),
  ]);
}

export function* submitContactUs(
  action: Action<ContactUsPayload>
): SagaIterator {
  yield put(AppActions.submitContact.request());

  try {
    yield call(api.submitContactUs, action.payload);

    yield put(AppActions.submitContact.success());
  } catch (error) {
    yield put(AppActions.submitContact.failure(error));
  }
}

export function* getLocations(action: Action<void>): SagaIterator {
  yield put(AppActions.getLocations.request());

  try {
    const locationsList = yield call(api.getLocations);
    yield put(AppActions.getLocations.success(locationsList));
  } catch (error) {
    yield put(AppActions.getLocations.failure(error));
  }
}
