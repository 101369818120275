import React from "react";

import Filter from "./Filter";
import { ListItem } from "./CheckboxListItem";
import { FilterProps } from "./Filter";
import { ReactComponent as SearchIcon } from "assets/icons/SearchIconSecondary.svg";

export const renderOption = (option: string, selected: boolean) => {
  return <ListItem option={option} selected={selected} />;
};
const SearchFilter = (props: FilterProps) => {
  function getOptions(): string[] {
    return [
      "Stress",
      "Social",
      "Health",
      "Spirituality",
      "Anxiety",
      "Mental health",
      "Ageing",
      "Work",
      "Mind",
      "Parenting",
      "Relationships",
      "Abuse",
      "Grief",
      "Addiction",
      "Help",
      "Mental illness",
      "Community",
      "Depression",
      "Sleep",
      "Drinking",
      "Drugs",
      "Weight",
      "Exercise",
      "Nutrition",
      "Sex",
      "Maori",
      "Nature",
      "Debt",
      "Housing",
    ];
  }

  return (
    <Filter
      border={false}
      freeSolo
      values={props.values}
      overrideIcon={<SearchIcon />}
      onFilterChange={props.onFilterChange}
      options={getOptions()}
      title="I am looking for..."
      renderOptions={renderOption}
      disableInputBorder
      forceDesktop={true}
      placeHolder="Type anything"
      id="speciality-filter"
    />
  );
};

export default SearchFilter;
