import { AxiosResponse, AxiosError, AxiosInstance } from "axios";
import IgniteTagManager from "lib/analytics";

export interface APIErrorData {
  status: number;
  type: string;
  title: string;
  detail: string;
}

export type APIErrorResponse = AxiosResponse<APIErrorData>;

export type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export const parseAxiosRequest = <TResult, TPayload>(
  method: HTTPMethod,
  endpoint: string,
  payload: TPayload,
  axiosInstance: AxiosInstance
) => {
  switch (method) {
    case "POST":
      return axiosInstance.post<TResult>(endpoint, payload);
    case "PUT":
      return axiosInstance.put<TResult>(endpoint, payload);
    case "PATCH":
      return axiosInstance.patch<TResult>(endpoint, payload);
    case "DELETE":
      return axiosInstance.delete<TResult>(endpoint, payload);
    default:
      return axiosInstance.get<TResult>(endpoint);
  }
};

type RequestFn<TPayload, TNewResult> = TPayload extends void
  ? () => Promise<TNewResult>
  : (payload: TPayload) => Promise<TNewResult>;

export interface RequestConfig<
  TPayload = any,
  TResult = any,
  TNewPayload = TPayload,
  TNewResult = TResult
> {
  transformPayload?: (payload: TPayload) => TNewPayload;
  transformResult?: (result: TResult, payload: TNewPayload) => TNewResult;
  transformEndpoint?: (payload: TNewPayload, endpoint: string) => string;
}

/**
 * Creates a request function with the given HTTPVerb,
 * endpoint and optional config
 *
 * @param method - HTTP Method ("GET", "POST", "PUT", "DELETE", "PATCH")
 */
export const request = (method: HTTPMethod, axiosInstance: AxiosInstance) => <
  TResult = any,
  TPayload = void,
  TNewResult = TResult,
  TNewPayload = TPayload
>(
  endpoint: string,
  config?: RequestConfig<TPayload, TResult, TNewPayload, TNewResult>
): RequestFn<TPayload, TNewResult> => {
  /**
   * Fires an http request and returns a promise of type T or an Error
   * @param payload
   */
  async function requestFn(payload: TPayload): Promise<TNewResult> {
    const $config = {
      transformPayload: (p: TPayload) => p,
      transformResult: (result: TResult) => result,
      transformEndpoint: (_: TNewPayload, oldEndpoint: string) => oldEndpoint,
      ...(config || {}),
    };

    const $payload = $config.transformPayload(payload) as TNewPayload;
    const $endpoint = $config.transformEndpoint($payload, endpoint);

    try {
      const axiosRequest = parseAxiosRequest<TResult, TNewPayload>(
        method,
        $endpoint,
        $payload,
        axiosInstance
      );

      const $response: AxiosResponse<TResult> = await axiosRequest;

      return $config.transformResult($response.data, $payload) as TNewResult;
    } catch (error) {
      if (error.isAxiosError) {
        const axiosError: AxiosError<APIErrorData> = error;

        if (axiosError.response) {
          const tagManagerArgs = {
            dataLayer: {
              event: "API Error",
              status: axiosError.response.status,
              statusText: axiosError.response.statusText,
              requestURL: axiosError.response.request.responseURL,
              title: axiosError.response.data.title,
              detail: axiosError.response.data.detail,
            },
          };
          IgniteTagManager.dataLayer(tagManagerArgs);
        }

        throw axiosError.response ? axiosError.response : axiosError;
      }

      throw error;
    }
  }

  return requestFn as RequestFn<TPayload, TNewResult>;
};

export default function createClient(axiosInstance: AxiosInstance) {
  return {
    get: request("GET", axiosInstance),
    post: request("POST", axiosInstance),
    put: request("PUT", axiosInstance),
    patch: request("PATCH", axiosInstance),
    remove: request("DELETE", axiosInstance),
  };
}
