import { RequestState } from "lib/types";
import { APIErrorResponse } from "lib/apiClient/createClient";

export interface ContactUsPayload {
  email: string;
  phone: string;
  name: string;
  company: string;
  enquiryType: string;
  notes: string;
}

export interface AppState {
  requests: {
    contactUsStatus?: RequestState<APIErrorResponse>;
  };
  locations?: string[];
  routesRegex: string;
}

export interface Domains {
  health: string[];
  life: string[];
  meaning: string[];
}

export enum EnquiryType {
  "General",
  "Feedback",
  "Request Demo",
}

export const EnquiryTypes: string[] = Object.keys(EnquiryType).filter(
  (k) => typeof EnquiryType[k as any] === "number"
);

export type DomainKey = "health" | "life" | "meaning";

export const domains: Domains = {
  health: ["Mind", "Body", "Habits", "Home"],
  life: ["Relationships", "Occupation", "Finances", "Recreation"],
  meaning: ["Identity", "Beliefs", "Culture", "Connectedness"],
};

export const specialities = [
  "Abuse",
  "Addiction",
  "Adjustment",
  "Alcohol",
  "Anger",
  "Anger Management",
  "Anxiety",
  "Attention Deficit Disorder",
  "Attention Deficit Hyperactivity Disorder",
  "Autism Spectrum Disorder",
  "Behavioural Issues",
  "Bereavement",
  "Budgeting",
  "Bullying",
  "Career Development",
  "Career Guidance",
  "Child or Adolescent",
  "Chronic Illness",
  "Chronic Impulsivity",
  "Chronic Pain",
  "Chronic Relapse",
  "Coming Out",
  "Community",
  "Coping Skills",
  "Couples",
  "Court",
  "Cultural Identity",
  "Death",
  "Depression",
  "Divorce",
  "Domestic Abuse",
  "Domestic Violence",
  "Drug Abuse",
  "Drug Use",
  "Drugs",
  "Dual Diagnosis",
  "Eating Disorders",
  "Emotional Abuse",
  "Emotional Disturbance",
  "Family Conflict",
  "Family/Children",
  "Financial",
  "Gambling",
  "Gender identity",
  "Grief",
  "Harassment",
  "Identity",
  "Infertility",
  "Injury",
  "Intellectual Disability",
  "Investment",
  "Learning Disabilities",
  "LGBTQIA+ Issues",
  "Life Coaching",
  "Life Transitions",
  "Loneliness",
  "Marriage",
  "Men's Issues",
  "Mental Illness",
  "Mindset",
  "Neurodiversity",
  "Nutrition",
  "Obesity",
  "Obsessive-Compulsive (OCD)",
  "Oppositional Defiance",
  "Parenting",
  "Peer Relationships",
  "Post-Natal Depression",
  "Purpose",
  "Racial Identity",
  "Redundancy",
  "Relationship Issues",
  "Retirement",
  "Savings",
  "School Issues",
  "Self Esteem",
  "Self-Harming",
  "Sex Therapy",
  "Sexual Abuse",
  "Sexual Addiction",
  "Sexual Assault",
  "Sexual Identity",
  "Shame",
  "Sleep or Insomnia",
  "Spirituality",
  "Sports Performance",
  "Stress",
  "Substance Abuse",
  "Suicidal Ideation",
  "Supervision",
  "Teen Violence",
  "Testing and Evaluation",
  "Tikanga",
  "Transgender",
  "Transgender Issues",
  "Trauma and PTSD",
  "Traumatic Brain Injury",
  "Weight Loss",
  "Wellbeing",
  "Whakapapa",
  "Women's Issues",
  "Work Life Balance",
  "Work Trauma",
  "Workplace Issues",
  "Youth (16-24yrs)",
];
