import React, { CSSProperties } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        margin: "auto",
        //marginLeft: theme.spacing(2)
      },
    },
  })
);

export default function CircularIndeterminate({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={className}
        style={{ width: "100px", height: "100px", ...style }}
      />
    </div>
  );
}
