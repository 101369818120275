import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/core/configuration";
import { ContactUsPayload } from "./types";
import { APIErrorResponse } from "lib/apiClient/createClient";

export const submitContactUs = client.post<
  APIErrorResponse,
  ContactUsPayload,
  APIErrorResponse,
  {
    name: string;
    email: string;
    organisation: string;
    phone: string;
    enquiryType: string;
    enquiryText: string;
  }
>(ENDPOINTS.contactUs, {
  transformPayload: (payload) => {
    return {
      name: payload.name,
      email: payload.email,
      organisation: payload.company,
      phone: payload.phone,
      enquiryType: payload.enquiryType,
      enquiryText: payload.notes,
    };
  },
});

export const getLocations = client.get<string[], void>(ENDPOINTS.getLocations);
