import {
  Container,
  createStyles,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import React from "react";
import SectionBanner from "ui/compounds/SectionBanner";
import sectionLeftGon from "assets/banner/SectionPatternLeft.svg";
import sectionRightGon from "assets/banner/SectionPatternRight.svg";
import PlayIcon from "assets/home/play-circle.svg";
const useStyles = makeStyles((theme) =>
  createStyles({
    leftGon: {
      zIndex: 0,
      position: "absolute",
      left: 0,
      bottom: 0,
    },
    rightGon: {
      zIndex: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },

    marketingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
      paddingTop: 60,
    },
    marketingHeader: {
      display: "flex",
      alignSelf: "center",
      maxWidth: "800px",
      color: "white",
      textAlign: "center",
      paddingBottom: "34px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    marketingBody: {
      display: "flex",
      alignSelf: "center",
      maxWidth: "800px",
      color: "white",
      textAlign: "center",
      paddingBottom: "34px",
    },

    marketingVideoContainer: {
      position: "relative",
      width: "100%",
      justifyContent: "center",
    },
    marketingImage: {
      display: "block",
      margin: "auto",
      maxWidth: "1000px",

      width: "100%",
      height: "auto",
    },
    marketingPlayButton: {
      opacity: 0.5,
      cursor: "pointer",
      position: "absolute",
      height: "100px",
      width: "100px",
      left: "50%",
      marginLeft: "-50px",
      top: "50%",
      marginTop: "-50px",

      "&:hover": {
        transform: "scale(1.1)",
        opacity: 1,
      },

      transition: "all 1s ease",

      [theme.breakpoints.down("sm")]: {
        height: "60px",
        width: "60px",
        marginLeft: "-30px",
        marginTop: "-30px",
      },
    },

    videoPlayer: {
      "@media(max-width: 1252)": {
        maxWidth: "calc(80vw)",
        maxHeight: "calc(100vh)",
        width: "80%",
      },
    },
  })
);

const VideoBanner = () => {
  const classes = useStyles();

  const [isVideoOpen, setIsVideoOpen] = React.useState(false);

  function Video() {
    return (
      <Modal
        open={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        disableAutoFocus={true}
      >
        <div
          style={{ width: "100vw", height: "100vw" }}
          onClick={() => setIsVideoOpen(false)}
        >
          <div className="video-container">
            <iframe
              title="Marketing Add"
              width="1252"
              height="704"
              src="https://www.youtube.com/embed/y4y_Hgqc5mc"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <SectionBanner>
      <section className="padded-section">
        <img alt="" className={classes.leftGon} src={sectionLeftGon} />
        <img alt="" className={classes.rightGon} src={sectionRightGon} />
        <Container maxWidth="xl">
          <div className={classes.marketingContainer}>
            <Typography variant="h3" className={classes.marketingHeader}>
              Access Fast, Proactive Wellbeing Support Now
            </Typography>
            <Typography variant="body1" className={classes.marketingBody}>
              Ignite is a flexible, proactive wellbeing platform for your
              business. We give your people the power to access their choice of
              support anytime, anywhere. Choose from our extensive range of
              resources and online workshops, or book online with one of our
              mental health and wellbeing specialists and coaches.
            </Typography>

            <div className={classes.marketingVideoContainer}>
              <img
                className={classes.marketingImage}
                src={require("assets/home/MarketingVideo.png")}
                alt=""
              />
              <img
                className={classes.marketingPlayButton}
                src={PlayIcon}
                alt=""
                onClick={() => setIsVideoOpen(true)}
              />
            </div>
          </div>

          {Video()}
        </Container>
      </section>
    </SectionBanner>
  );
};

export default VideoBanner;
