import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import { ReactComponent as CloseIcon } from "assets/icons/CloseIcon.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterChip: {
      height: "42px",
      backgroundColor: "white",
      borderRadius: "5px",
      margin: "5px 10px 5px 0px",
      boxShadow:
        "0px 5px 5px rgba(68, 53, 86, 0.05), 0px 3px 14px rgba(68, 53, 86, 0.05), 0px 8px 10px rgba(68, 53, 86, 0.04)",

      "& span": {
        fontFamily: "Gilroy Regular",
        fontSize: "14px",
      },

      [theme.breakpoints.down("lg")]: {
        maxHeight: "30px",
      },

      "& >:first-child": {
        marginRight: "-6px !important",
      },
    },
    closeIcon: {
      width: "20px",
      height: "20px",
      margin: "11px 8px 11px 5px",
    },
  })
);

interface Props {
  label: string;
  onDelete: () => void;
  icon?: JSX.Element;
  classNames?: string;
}
const FilterChip = (props: Props) => {
  const classes = useStyles();

  return (
    <Chip
      className={`${classes.filterChip} ${props.classNames}`}
      label={props.label}
      deleteIcon={<CloseIcon className={classes.closeIcon} />}
      onDelete={props.onDelete}
      onClick={props.onDelete}
      icon={props.icon}
    ></Chip>
  );
};

export default FilterChip;
