import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";
import Container from "@material-ui/core/Container";
import Banner from "ui/components/Banner";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import EnquiryFilter from "ui/components/filters/EnquiryFilter";
import clsx from "clsx";
import { getContactUsStatus } from "domain/core/app/reducer";
import { useReduxState, useActions } from "re-reduced";
import AppActions from "domain/core/app/actions";
import Button from "@material-ui/core/Button";
import { REQUEST_STATUS } from "lib/types";
import { EnquiryTypes } from "domain/core/app/types";

import { ContactListContainer, ContactListItem } from "ui/components/Contact";
import { Typography, FormHelperText } from "@material-ui/core";
import FormInput from "ui/components/Input";
import ContactSuccess from "assets/states/success/ContactSuccess.svg";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "5rem",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "40px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        margin: "20px",
      },
    },
    twoColumnLayout: {
      flexDirection: "row",
      columnCount: 2,

      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& :nth-child(1)": {
          order: 1,
        },

        "& :nth-child(2)": {
          order: 3,
        },
        "& :nth-child(3)": {
          order: 2,
        },
        "& :nth-child(4)": {
          order: 4,
        },
      },
    },
    forceColumnBreak: {
      columnCount: 1,
    },
    button: {
      margin: "auto",
      "&:disabled": {
        backgroundColor: "#B1B6CB !important",
        color: "white !important",
      },
    },

    textField: {
      width: "100%",
      marginTop: "25px",
    },

    textFieldError: {
      "& .MuiOutlinedInput-root": {
        border: `1px solid ${theme.palette.error.dark}`,
      },
    },

    textFieldColorFocus: {},

    textFieldContainerError: {
      "& >:first-child": {
        marginBottom: "15px",
        "& >:first-child": {
          marginBottom: "0px",
        },
      },
    },
    textFieldContainer: {
      "& >:first-child": {
        "& >:first-child": {},
        [theme.breakpoints.up("sm")]: {
          maxWidth: "calc(50% - 8px)",
        },
        marginBottom: "0px",
      },
      width: "100%",
    },

    errorText: {
      marginBottom: "15px",
    },

    formControl: {
      width: "100%",
      marginBottom: "30px",
      marginTop: "4px",
      minWidth: "320px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "100%",
      },
    },
    rowFlex: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    card: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },

      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      flexWrap: "wrap",

      backgroundColor: theme.palette.background.paper,
    },
    descriptorCard: {
      maxWidth: "800px",
      width: "800px",
      height: "auto",
      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 42px)",
      },
    },
    pageContentContainer: {
      marginTop: "-110px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-50px",
      },
    },

    reversePageContentMargin: {
      marginTop: "110px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "50px",
      },
    },
    notchedOutline: {
      border: `1px solid ${theme.palette.secondary.main} !important`,
    },
    loadingContainer: {
      display: "flex",
      margin: "auto",
    },
    successContainer: {
      display: "flex",
      animation: "fadein 0.5s ease 0s 1 normal forwards",
      flexDirection: "row",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        "& > *": {
          margin: "auto",
          marginBottom: "10px !important",
          textAlign: "center",
        },
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "auto 15px",
      "@media(max-width: 420px)": {
        width: "220px",
        margin: "auto",
        "& *": {
          textAlign: "center",
          margin: "10px auto",
        },
      },
    },
    successButton: {
      marginLeft: "auto",
    },
  })
);

function ContactPage(props: RouteComponentProps) {
  const classes = useStyles();

  function getUrlParams(): URLSearchParams {
    if (!props.location.search) return new URLSearchParams();
    return new URLSearchParams(props.location.search);
  }

  function getEnquiryType() {
    const params = getUrlParams();
    const type = params.get("enquiryType");
    if (type && EnquiryTypes.indexOf(type)) {
      return [type];
    } else {
      return ["Type of Enquiry *"];
    }
  }
  const [enquiryType, setEnquiryType] = React.useState<string[]>(
    getEnquiryType()
  );

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [message, setMessage] = React.useState<string>("");

  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false);
  const [nameInvalid, setNameInvalid] = React.useState<boolean>(false);
  const [companyInvalid, setCompanyInvalid] = React.useState<boolean>(false);
  const [phoneInvalid, setPhoneInvalid] = React.useState<boolean>(false);
  const [enquiryTypeInvalid, setEnquiryTypeInvalid] = React.useState<boolean>(
    false
  );
  const [textFieldInvalid, setTextFieldInvalid] = React.useState<boolean>(
    false
  );

  const actions = useActions(AppActions);

  const reduxState = useReduxState({
    getContactUsStatus,
  });

  const placeholder = "What would you like to ask us? We're all ears!";

  const textFieldClass = clsx(
    classes.textField,
    message !== placeholder && classes.textFieldColorFocus
  );

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setPhone(value);
  };

  const validPhone = () => {
    //TODO: NZ Phone Validation on front-end.
    return /^[0-9]*$/gm.test(phone);
  };

  const validEmail = () => {
    const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  function getPhoneError() {
    return phoneInvalid;
  }

  function getPhoneErrorMsg() {
    if (!validPhone()) {
      return "Phone must only contain numbers";
    }
  }

  function getNameError() {
    return nameInvalid && !validName();
  }

  function getCompanyError() {
    return companyInvalid && !validCompany();
  }

  function getCompanyErrorMsg() {
    if (!validCompany()) {
      const re = /[!@#$%^&*(),.?":{}|<>]/g;
      if (re.test(company)) {
        return "Company can not contain: " + re.exec(company);
      }
    }

    return "";
  }

  function getNameErrorMsg() {
    if (!validName()) {
      if (name.length === 0) {
        return "Please enter your name";
      }
      const re = /[!@#$%^&*(),.?":{}|<>]/g;
      if (re.test(name)) {
        return "Name can not contain: " + re.exec(name);
      }
      if (/[0-9]+/gm.test(name)) {
        return "Name can not contain numbers";
      }
    }

    return "";
  }

  function getEmailError() {
    return emailInvalid && !validEmail();
  }
  function getEmailErrorMsg() {
    if (!validEmail()) {
      if (email.length === 0) {
        return "Please enter your email";
      }
      if (email.indexOf("@") === -1) {
        return "An email must contain a single @";
      }
      if (email.indexOf("@") === 0) {
        return "An email cannot start with @";
      }
      if (email.indexOf(".") === -1) {
        return "An email must contain a single '.' e.g '.com'";
      }
    }
    const re = /[!#$%^&*(),?":{}|<>]/;
    if (re.test(email)) {
      return "Email cannot contain: " + re.exec(email);
    }
    return "";
  }

  const submitContact = (event: React.ChangeEvent<EventTarget>) => {
    let failedPreValidation = false;
    if (!validEmail()) {
      failedPreValidation = true;
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
    if (!validName()) {
      failedPreValidation = true;
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    if (!validPhone()) {
      failedPreValidation = true;
      setPhoneInvalid(true);
    } else {
      setPhoneInvalid(false);
    }

    if (!validCompany()) {
      failedPreValidation = true;
      setCompanyInvalid(true);
    } else {
      setCompanyInvalid(false);
    }

    if (message.length === 0) {
      failedPreValidation = true;
      setTextFieldInvalid(true);
    } else {
      setTextFieldInvalid(false);
    }

    if (enquiryType[0] === "Type of Enquiry *") {
      failedPreValidation = true;
      setEnquiryTypeInvalid(true);
    } else {
      setEnquiryTypeInvalid(false);
    }

    if (!failedPreValidation && reduxState.getContactUsStatus !== "Pending") {
      const lintrk = (window as any).lintrk;
      if (lintrk) {
        lintrk("track", { conversion_id: 11162948 });
      }
      const fbq = (window as any).fbq;
      if (fbq) {
        fbq("track", "Contact");
      }

      actions.submitContact({
        email: email,
        name: name,
        company: company,
        phone: phone,
        enquiryType: enquiryType[0],
        notes: message,
      });
    }
  };
  const validName = () => {
    return (
      name.length > 1 &&
      /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g.test(name) &&
      !/^[0-9]*$/gm.test(name)
    );
  };

  const validCompany = () => {
    return (
      !/[!@#$%^&*(),.?":{}|<>]/g.test(company)
    );
  };

  function onFilterChange(values: string[]) {
    setEnquiryType(values.filter((a) => enquiryType.indexOf(a) === -1));
  }

  function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMessage(e.target.value);
  }

  function clear() {
    setMessage("");
    setEnquiryType(["Type of Enquiry *"]);
    actions.clear();
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ignite Aotearoa - Get in Touch</title>
        <meta
          name="description"
          content="To speak to someone about our platform, providers or for general enquiries, contact us here or at hello@ignite.org.nz"
        />
      </Helmet>
      <Banner>
        <div>Get in Touch</div>
        <p>
          To speak to someone about our platform, providers or for general
          enquiries, contact us below.
        </p>
      </Banner>
      <div className={classes.pageContentContainer}>
        <Container maxWidth="xl">
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Card className={`${classes.card} ${classes.descriptorCard}`}>
                <div className={classes.container}>
                  <div className={classes.rowFlex}>
                    <div className={classes.twoColumnLayout}>
                      <FormInput
                        alwaysExpanded
                        label="Name"
                        required
                        className={classes.formControl}
                        value={name}
                        onChange={handleChangeName}
                        error={getNameError()}
                        errorMsg={getNameErrorMsg()}
                        id="component-name"
                        autoComplete="current-name"
                      />
                      <FormInput
                        alwaysExpanded
                        label="Company (Optional)"
                        className={classes.formControl}
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        error={getCompanyError()}
                        errorMsg={getCompanyErrorMsg()}
                        id="component-company"
                        autoComplete="current-company"
                      />
                      <FormInput
                        alwaysExpanded
                        label="Email Address"
                        required
                        className={classes.formControl}
                        value={email}
                        onChange={handleChangeEmail}
                        error={getEmailError()}
                        errorMsg={getEmailErrorMsg()}
                        id="component-email"
                        autoComplete="current-email"
                      />

                      <FormInput
                        alwaysExpanded
                        label="Phone Number (Optional)"
                        className={classes.formControl}
                        value={phone}
                        onChange={onPhoneChange}
                        error={getPhoneError()}
                        errorMsg={getPhoneErrorMsg()}
                        type="tel"
                        id="component-phone"
                        autoComplete="current-phone"
                      ></FormInput>
                    </div>

                    <div
                      className={`${classes.textFieldContainer} ${
                        enquiryTypeInvalid
                          ? classes.textFieldContainerError
                          : ""
                      }`}
                    >
                      <FormControl
                        className={classes.formControl}
                        error={
                          enquiryTypeInvalid &&
                          enquiryType[0] === "Type of Enquiry *"
                        }
                      >
                        {enquiryType[0] !== "Type of Enquiry *" && (
                          <label
                            data-shrink="true"
                            htmlFor="enquiry-filter"
                            className="MuiInputLabel-shrink"
                          >
                            Type of Enquiry *
                          </label>
                        )}
                        <EnquiryFilter
                          formControlClass={classes.formControl}
                          onFilterChange={onFilterChange}
                          values={enquiryType}
                          error={
                            enquiryTypeInvalid &&
                            enquiryType[0] === "Type of Enquiry *"
                          }
                        />
                        {enquiryTypeInvalid &&
                          enquiryType[0] === "Type of Enquiry *" && (
                            <FormHelperText
                              id="fade-in"
                              className={classes.errorText}
                            >
                              Please select a Type of Enquiry *
                            </FormHelperText>
                          )}
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        error={textFieldInvalid && message.length === 0}
                      >
                        <TextField
                          placeholder={placeholder}
                          id="outlined-multiline-static"
                          label="Your Message"
                          required
                          value={message}
                          onChange={handleTextFieldChange}
                          multiline
                          rows="8"
                          className={`${textFieldClass} ${
                            textFieldInvalid && message.length === 0
                              ? classes.textFieldError
                              : ""
                          }`}
                          margin="dense"
                          variant="outlined"
                        />
                        {textFieldInvalid && message.length === 0 && (
                          <FormHelperText id="fade-in">
                            Please tell us the nature of your enquiry
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  {reduxState.getContactUsStatus === REQUEST_STATUS.Idle ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={submitContact}
                    >
                      Send Enquiry
                    </Button>
                  ) : reduxState.getContactUsStatus ===
                    REQUEST_STATUS.Pending ? (
                    <div className={classes.loadingContainer}>
                      <CircularIndeterminate />
                    </div>
                  ) : (
                    <div className={classes.successContainer}>
                      <img alt="" src={ContactSuccess} />
                      <div className={classes.textContainer}>
                        <Typography variant="h6">
                          Your enquiry was successfully submitted!
                        </Typography>
                        <Typography variant="body1">
                          We will get back to you as soon as we can
                        </Typography>
                      </div>
                      <Button
                        variant="text"
                        color="secondary"
                        className={classes.successButton}
                        onClick={() => clear()}
                      >
                        Submit Another
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
            </Grid>
            <Grid item className={classes.reversePageContentMargin}>
              <div className={classes.container}>
                <ContactListContainer>
                  <ContactListItem
                    header="Give us a call (NZ Wide)"
                    body="09 834 0556"
                    type="phone"
                  />
                  <ContactListItem
                    header="Email Enquires"
                    body={[
                      "hello@ignite.org.nz",
                      "platformsupport@ignite.org.nz",
                    ]}
                    type="email"
                  />

                  <ContactListItem
                    header="Postal Address"
                    body={["PO Box 2322", "Christchurch 8140", "New Zealand"]}
                  />
                </ContactListContainer>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withRouter(ContactPage);
