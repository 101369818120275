import { createReducer, reduce, composeReducers } from "re-reduced";

import { REQUEST_STATUS } from "lib/types";
import { createSelector } from "reselect";
import { AppState } from "domain/core/app/types";
import actions from "domain/core/app/actions";
import { IgniteState } from "domain/types";
import always from "ramda/src/always";
import { persistReducer, PersistConfig } from "redux-persist";
import { PERSIST_KEY } from "store/configuration";
import storage from "redux-persist/lib/storage";
import { assoc } from "ramda";

export const initialState: AppState = {
  requests: {
    contactUsStatus: { status: REQUEST_STATUS.Idle },
  },
  locations: [],
  routesRegex:
    "/(request-demo|workshops|workshop|home|about|approach|contact|learn|crisis|dashboard|search|book|account|appointments)/",
};

export const getAppState = (state: IgniteState) => state;

export const getContactUsStatus = createSelector(getAppState, (state) => {
  if (state.core.app.requests.contactUsStatus !== undefined) {
    return state.core.app.requests.contactUsStatus.status;
  }
  return { status: REQUEST_STATUS.Idle };
});

export const getLocations = createSelector(getAppState, (state) => {
  if (state.core.app.locations) {
    return state.core.app.locations;
  } else {
    return [
      "Auckland",
      "Wellington",
      "Christchurch",
      "Waitakere",
      "Northcote",
      "Hamilton",
      "Tauranga",
      "Dunedin",
      "Lower Hutt",
      "Palmerston North",
      "Nelson",
      "Napier",
      "Porirua",
      "New Plymouth",
      "Invercargill",
      "Wanganui",
      "Gisborne",
      "Timaru",
      "Pukekohe",
      "Paraparaumu",
      "Taupo",
      "Masterton",
      "Levin",
      "Whakatane",
      "Tokoroa",
      "Hawera",
      "Greymouth",
      "Thames",
      "Kerikeri",
      "Wanaka",
      "Westport",
      "Turangi",
      "Hokitika",
      "Waioruarangi",
      "Te Anau",
      "Waitangi",
    ];
  }
});

const clearReducer = createReducer(
  [reduce(actions.clear, always(initialState))],
  initialState
);

const baseReducer = createReducer<AppState>(
  [
    reduce(actions.submitContact.request, (state, _) => ({
      ...state,
      requests: {
        contactUsStatus: { status: REQUEST_STATUS.Pending },
      },
    })),
    reduce(actions.submitContact.success, (state, _) => ({
      ...state,
      requests: {
        contactUsStatus: { status: REQUEST_STATUS.Fulfilled },
      },
    })),
    reduce(actions.submitContact.failure, (state, error) => ({
      ...state,
      requests: {
        contactUsStatus: { status: REQUEST_STATUS.Failed, error: error },
      },
    })),

    reduce(actions.getLocations.request, (state, _) => ({
      ...state,
    })),
    reduce(actions.getLocations.success, (state, payload) => ({
      ...state,
      locations: payload,
    })),
    reduce(actions.getLocations.failure, (state, _) => ({
      ...state,
    })),
    reduce(actions.setRegexRoute, (state, config) => {
      let route =
        "/(request-demo|workshops|workshop|home|about|approach|contact|learn|crisis|dashboard|search|book|account|appointments)/";
      if (config.workshopsEnabled && config.providersEnabled) {
        route =
          "/(request-demo|workshops|workshop|home|about|approach|contact|learn|crisis|dashboard|search|book|account|appointments)/";
      } else if (!config.workshopsEnabled && config.providersEnabled) {
        route =
          "/(request-demo|home|about|approach|contact|learn|crisis|dashboard|search|book|account|appointments)/";
      } else if (config.workshopsEnabled && !config.providersEnabled) {
        route =
          "/(request-demo|workshops|workshop|home|about|approach|contact|learn|crisis|dashboard|account|appointments)/";
      } else if (!config.workshopsEnabled && !config.providersEnabled) {
        route =
          "/(request-demo|home|about|approach|contact|learn|crisis|dashboard|account|appointments)/";
      }
      return assoc("routesRegex", route, state);
    }),
  ],
  initialState
);

export const persistConfig: PersistConfig<AppState> = {
  storage,
  key: `@user:${PERSIST_KEY}:core/app`,
  version: 2,
  blacklist: ["requests"],
};

export default persistReducer(
  persistConfig,
  composeReducers(baseReducer, clearReducer)
);
