import React from "react";
import {
  Button,
  Container,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import IgniteYourWellbeing from "assets/home/IgniteYourWellbeing.png";
import IgniteYourWellbeingMobile from "assets/home/IgniteYourWellbeingMobile.png";
import { RouteComponentProps, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    igniteWellbeingCard: {
      zIndex: 1,
      display: "block",
      height: "fit-content",
      width: "100%",

      [theme.breakpoints.down("md")]: {
        height: "300px",
      },

      "@media(max-width: 785px)": {
        flexDirection: "row",
        height: "auto",
        justifyContent: "flex-start",
      },
    },

    igniteWellbeingCardContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",

      [theme.breakpoints.down("md")]: {
        height: "300px",
      },

      "@media(max-width: 785px)": {
        flexDirection: "column-reverse",
        height: "fit-content",
        justifyContent: "unset",
      },
    },
    igniteWellbeingContentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto 20px",
      padding: "20px",
      paddingRight: "0px",
      "@media(max-width: 785px)": {
        paddingRight: "20px",
        marginL: "auto",
      },
      "& > *": {
        marginTop: "10px",
        marginBottom: "10px",
      },
    },

    igniteWellbeingTitle: {
      textAlign: "left",
      maxWidth: "450px",
      marginTop: "0px",
      width: "100%",

      [theme.breakpoints.down("sm")]: {
        alignSelf: "center",
      },
      "@media(max-width: 1160px)": {
        fontSize: 34,
      },
      "@media(max-width: 785px)": {
        textAlign: "center",
      },
    },
    igniteWellbeingButton: {
      margin: "0px",
      alignSelf: "left",
      "@media(max-width: 785px)": {
        margin: "auto",
        width: "200px",
      },
    },
    igniteWellbeingImageContainer: {
      // overflow: "hidden",
      alignSelf: "start",
      width: "100%",
      display: "none",
      "@media(max-width: 785px)": {
        display: "block",
      },
    },
    igniteWellbeingImage: {
      height: "100%",

      "@media(max-width: 785px)": {
        display: "none",
      },
    },

    igniteWellbeingImageMobile: {
      width: "100%",
      display: "none",
      float: "right",
      "@media(max-width: 785px)": {
        display: "block",
      },
    },
  })
);

const RequestDemoCard: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <Card className={classes.igniteWellbeingCard}>
        <div className={classes.igniteWellbeingCardContainer}>
          <div className={classes.igniteWellbeingContentContainer}>
            <Typography
              variant="h3"
              style={{ marginBottom: "16px" }}
              className={classes.igniteWellbeingTitle}
            >
              Ignite Your People's Wellbeing Now
            </Typography>
            <Button
              variant="contained"
              className={classes.igniteWellbeingButton}
              color="secondary"
              onClick={() => props.history.push("/request-demo")}
            >
              Request Demo Now
            </Button>
          </div>
          <img
            alt=""
            src={IgniteYourWellbeing}
            className={classes.igniteWellbeingImage}
          />
          <div className={classes.igniteWellbeingImageContainer}>
            <img
              alt=""
              src={IgniteYourWellbeingMobile}
              className={classes.igniteWellbeingImageMobile}
            />
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default withRouter(RequestDemoCard);
