import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import DomainIcon from "ui/components/DomainIcon";
import OverflowChipContainer from "ui/components/OverflowChipContainer";
import Chip from "@material-ui/core/Chip";
import ContentPlaceholder from "assets/placeholder/ContentPlaceholder.png";
import { ReactComponent as WorkshopDateIcon } from "assets/workshops/WorkshopDateIcon.svg";
import { Divider } from "@material-ui/core";
import { WorkshopProfile, Session } from "domain/workshops/types";
import { addMinutes, format } from "date-fns";
import BookingTypeIconRound from "ui/components/BookingTypeIcon";
import IgniteTagManager from "lib/analytics";
import IgniteToken from "assets/IgniteToken.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      animation: "fadein 0.5s ease 0s 1 normal forwards",

      width: "382px",

      minHeight: "560px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      "@media( max-width: 410px)": {
        width: "calc(100vw - 32px)",
      },

      transition: "all 0.25s linear",

      "@media (hover: hover)": {
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
    },
    timeChip: {
      margin: "5px 0px 0px 0px",
      borderRadius: "5px",
      backgroundColor: "#E4E8F4",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
      cursor: "pointer",
      textAlign: "left",
      alignSelf: "flex-end",
      width: "150px",
      "& span": {
        overflow: "visible",
        textOverflow: "unset",
        color: theme.palette.text.secondary,
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      margin: "15px 15px 10px 15px",
    },

    img: {
      width: "100%",
      margin: "0 auto",
      height: "230px",
      backgroundSize: "cover",
      objectFit: "cover",
      overflow: "hidden",
      position: "absolute",
      left: 0,
      top: 0,
    },
    imgContainer: {
      width: "100%",
      margin: "0 auto",
      position: "relative",
      height: "230px",
      backgroundSize: "cover",
      objectFit: "cover",
      overflow: "hidden",
      background: `url(${ContentPlaceholder}) no-repeat center`,
    },
    iconContainer: {
      width: "calc(100% + 16px)",
      height: "40px",
      margin: "5px 0px 0px 0px",
    },
    overflowContainer: {
      maxWidth: "calc(100%)",
      height: "40px",
      overflowY: "hidden",
    },

    title: {
      display: "inline-block",
      width: "calc(100% - 32px)",
      "& *": {
        display: "block",
        width: "100%",
      },
      margin: "5px 0px 10px 0px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    medium: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      "& >:first-child": {
        marginLeft: "-5px",
        marginRight: "0px",
      },
    },
    contentContainer: {
      padding: "5px",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    coronaBanner: {
      width: "100%",
      height: "50px",
      position: "absolute",
      bottom: 0,
      backgroundColor: "#FF7A00",
      display: "flex",
      flexDirection: "row",
    },
    coronaChip: {
      margin: "auto auto auto 12px",
      border: "none",
      background: "transparent",
      stroke: "white",
      color: "white",
      " & *": {
        stroke: "white",
        color: "white",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },
    },
    bottomSection: {
      display: "flex",
      flexDirection: "row",
      "& *": {
        margin: "2px 4px 2px 0px",
      },
    },
  })
);

type Profile = Omit<WorkshopProfile, "sessions">;
interface Props {
  profile: Omit<WorkshopProfile, "sessions">;
  session: Session;
  onSelect?: Function;
}
export default function WorkshopProfileCard({
  profile,
  session,
  onSelect,
}: Props) {
  const classes = useStyles();

  const [errored, setErrored] = React.useState(false);

  const onClickCard = (): void => {
    if (onSelect) onSelect(profile, session);
    // window.open(profile.url, "_blank");
    const tagManagerArgs = {
      dataLayer: {
        event: "WorkshopsCardClicked",
        details: {
          workshop: profile.title,
        },
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);
  };

  function onErrored() {
    setErrored(true);
  }

  function getDomainChip(item: string): JSX.Element {
    return (
      <Chip
        key={item}
        className={classes.chip}
        label={item}
        variant="outlined"
        icon={<DomainIcon key={item} domain={item} borderless={true} />}
      />
    );
  }

  return (
    <Card className={classes.root} onClick={onClickCard}>
      <div className={classes.imgContainer}>
        {!errored && (
          <img
            src={profile.imageUrl}
            alt=""
            onError={onErrored}
            className={classes.img}
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div style={{ display: "flex", paddingBottom: "12px" }}>
            <WorkshopDateIcon
              style={{ alignSelf: "flex-start", margin: "5px 0px 0px 0px" }}
            />
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ alignSelf: "flex-start", margin: "auto 10px" }}
            >
              <b style={{ fontFamily: "Gilroy Medium" }}>
                {" "}
                {format(new Date(session.time), "iii, d MMMM")}
              </b>
              <br />
              {format(new Date(session.time), "h:mm a") +
                " - " +
                format(
                  addMinutes(new Date(session.time), session.duration),
                  "h:mm a"
                )}
            </Typography>
          </div>

          <div className={classes.title}>
            <Typography variant="h5">{profile.title}</Typography>
          </div>

          <Typography variant="body2" style={{ hyphens: "auto" }}>
            {profile.shortDescription}
          </Typography>
          <div className={classes.iconContainer} style={{ marginTop: "auto" }}>
            <OverflowChipContainer
              className={classes.overflowContainer}
              listIdPrefix="tags"
              chipContent={profile.domains}
              displayItem={getDomainChip}
            ></OverflowChipContainer>
          </div>
          <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />
          <div className={classes.bottomSection}>
            <div style={{ display: "flex" }}>
              <BookingTypeIconRound type={profile.appointmentType.trim()} />
              <Typography
                variant="body2"
                style={{
                  height: "25px",
                  margin: "auto",
                }}
              >
                {profile.appointmentType.trim()}
              </Typography>
            </div>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <div style={{ margin: "auto" }}>
                <Typography align="center" variant="body1">
                  {profile.unitsPerSession}
                </Typography>
              </div>
              <img
                src={IgniteToken}
                alt="tokens"
                style={{ width: "32px", height: "32px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
