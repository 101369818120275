import { RequestState } from "lib/types";
import { APIErrorResponse } from "lib/apiClient/createClient";
import { PaginatedResult } from "lib/types";

export interface ProvidersState {
  selectedProfile: ProfileExtendedInfo | null;
  selectedProfileStatus: RequestState<APIErrorResponse>;
  searchResults: PaginatedResult<ProviderSearchResult>;
  resultStatus: RequestState<APIErrorResponse>;
  bookingStatus: RequestState<APIErrorResponse>;
  bookingResponse: ProviderBookResponse;
  currentCalendar: {
    dates: string[];
    times: string[];
    calendarID: number;
  };
  monthStatus: RequestState<APIErrorResponse>;
  dayStatus: RequestState<APIErrorResponse>;
}

export type ProviderSearchResult = ShortProfile[];

export type ShortProfile = ProfileExtendedInfo;

export interface ProfileBasicInfo {
  providerId: string;
  firstName: string;
  lastName: string;
  type: string;
  qualifications: string[];
  address: string;
  suburb: string;
  city: string;
  avatar: string;
}

export interface ProfileExtendedInfo extends ProfileBasicInfo {
  specialties: string[];
  bookingTypesAvailable: string[];
  biography: string;
  qualificationsExtended: string[];
  unitsPerSession: number;
  availableTimes: string[];
  availableDates: string[];
  domains: string[];
  shortDescription: string;
  calendarID: number;
}

export interface ProviderAppointmentDTO {
  addressLine1: string;
  appointmentDate: Date;
  city: string;
  providerFirstName: string;
  providerId: string;
  providerImageUrl: string;
  providerLastName: string;
  providerType: string;
  suburb: string;
  unitsPerSession: number;
  appointmentType: string;
  duration: number;
}

export interface AggregatedAppoinments {
  appointments: Appointment[];
  workshops: WorkshopAppointmentDTO[];
}
export interface Appointment {
  providerId: string;
  firstName: string;
  lastName: string;
  type: string;
  address: string;
  qualifications: string[];
  suburb: string;
  city: string;
  avatar: string;
  unitsPerSession: number;
  bookingType: string;
  duration: number;
  date: Date;
}
export type AppointmentVariant = "provider" | "workshop";
export interface GenericAppointment {
  id: string;
  displayName: string;
  type: string;
  address: string;
  suburb: string;
  city: string;
  avatar: string;
  unitsPerSession: number;
  bookingType: string;
  date: Date;
  variant: AppointmentVariant;
  duration: number;
}
export interface WorkshopAppointmentDTO {
  workshopId: string;
  title: string;
  unitsPerSession: number;
  addressLine1: string;
  city: string;
  suburb: string;
  duration: number;
  appointmentType: string;
  appointmentDate: Date;
  imageUrl: string;
}

export interface CalendarSlots {
  inPerson: AvailibilityType;
  video: AvailibilityType;
  phone: AvailibilityType;
}

type AvailibilityType = string[];

export interface Calendar {
  availableTimes: string[];
}

export interface CalendarPayload {
  startDate: string;
  calendarID: number;
}

export interface ProviderSearchPayload {
  page: number;
  pageLength: number;
  id?: string;
  domainsOfSupport: string[];
  specialitiesOfSupport: string[];
  appointmentType: string[];
  cities: string[];
}

export interface ProviderBookingState {
  id: string;
  date: Date;
  type: BookingType;
  status: RequestState<APIErrorResponse>;
}

export type ProviderBookResponse = {
  balance: number;
  datetime: Date | string;
};

export interface PlatformBookingPayload {
  calendarID: number;
  timezone: string;
  appointmentType: BookingType;
  datetime: Date | string;
  message?: string;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  appointmentTypeId: number;
  duration?: number;
}

export type BookingType =
  | ("In Person" | "inPerson")
  | ("Video" | "video")
  | ("Phone" | "phone");

export const bookingTypes: BookingType[] = ["In Person", "Video", "Phone"];
export type BookingKey = "inPerson" | "video" | "phone";
export const BookingKeys = ["inPerson", "video", "phone"] as BookingKey[];

export function typeToKey(type: BookingType): BookingKey {
  switch (type) {
    case "In Person":
      return "inPerson";
    case "Video":
      return "video";
    case "Phone":
      return "phone";
    default:
      return "inPerson";
  }
}

export interface ProviderSelectPayload {
  selectedProfile: ShortProfile;
}
