import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  delay: number;
  url: string;
}

const DelayedRedirect: React.FC<Props> = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.history.push(props.url);
    }, props.delay);
  });
  return <div></div>;
};

export default withRouter(DelayedRedirect);
