import React, { useEffect } from "react";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useReduxState, useActions } from "re-reduced";
import * as selectors from "domain/core/auth/selectors";
import {
  getTestResult,
  getResultStatus,
  getAnswers,
} from "domain/wellbeing/reducer";

import { getWellbeingTestAnswers } from "domain/dashboard/reducer";
import { REQUEST_STATUS } from "lib/types";
import { WellbeingActions } from "domain/wellbeing/actions";
import { Container, Grid, Button } from "@material-ui/core";
import ContentCard from "ui/compounds/Models/ContentCard/ContentCard";
import ProfileCard from "ui/compounds/Models/ProfileCard/ProfileCard";
import { ShortProfile } from "domain/providers/types";
import DomainIcon from "ui/components/DomainIcon";
import Banner from "ui/components/Banner";
import Card from "@material-ui/core/Card";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { ReactComponent as IconRightArrow } from "assets/icons/Icon-Right-Arrow.svg";
import { isToday } from "date-fns";
import IgniteTagManager from "lib/analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
      animationName: "fadeIn",
      animationDuration: "450ms",
      animationDelay: "0s",
      animationTimingFunction: "linear",
      animationFillMode: "forwards",
    },
    grow: {
      flexGrow: 1,
    },
    headerBackground: {
      width: "100%",
      minHeight: "300px",
      backgroundColor: "white",
      boxShadow: theme.shadows[2],
    },
    headerContainer: {
      marginTop: "-70px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-50px",
      },
      display: "flex",
      flexDirection: "column",

      "& >:first-child": {
        margin: "30px",
      },

      "& *.makeStyles-spreadContainer-190": {
        margin: "0px",
      },
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    card: {
      width: "750px",
      height: "400px",
    },
    contentContainer: {
      marginBottom: "4rem",
    },
    providerContainer: {
      marginBottom: "4rem",
    },
    loader: {
      margin: "auto",
    },
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      padding: "1rem",
    },
    group1: {
      marginBottom: "24px",
    },
    spreadContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "column",

        "& button": {
          display: "none",
        },
      },
    },
    mobileSpan: {
      [theme.breakpoints.down("lg")]: {
        float: "left",
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
      },
    },
    extraPadding: {
      margin: "auto",
      padding: "1.0rem 1.0rem 1.5rem 0rem",
    },
    title: {},
    subtitleContainer: {
      marginTop: "1rem",
      marginBottom: "1.5rem",
      textAlign: "left",
    },
    prioritiesText: {
      display: "flex",
      flexDirection: "column",
      color: "black",
      marginBottom: "10px",

      [theme.breakpoints.down("lg")]: {
        textAlign: "center",
      },

      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    heading: {
      fontFamily: "Gilroy Bold",
      fontSize: "46px",
    },
    domainText: {
      height: "27px",
      margin: "auto",

      [theme.breakpoints.down("lg")]: {
        margin: "auto auto auto 0px",
      },
    },

    focusItem: {
      flexDirection: "row",
      display: "flex",
      minWidth: "100px",
      height: "38px",
      marginRight: "15px",
      paddingRight: "20px",
      borderRight: "2px solid #D7DAE8",
      "& p": {
        maxHeight: "25px",
        margin: "auto",
        textAlign: "center",
      },
      "&:last-child": {
        border: "none",
      },
    },
    focusContainer: {
      transition: "all 1s ease",
      margin: "auto auto auto 0px",

      [theme.breakpoints.down("lg")]: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "-12px",
      },

      maxHeight: "100px",
    },
    desktopOnly: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    mobileOnly: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    mobileButton: {
      maxWidth: "380px",
      width: "calc(100vw - 64px)",
      margin: "auto",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "auto 0px auto auto",
      [theme.breakpoints.down("lg")]: {
        margin: "auto",
        width: 400,
        marginTop: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "auto",
        "& button": {
          margin: "10px auto",
          width: "calc(100vw - 96px)",
        },
      },
    },
    buttons: {
      minWidth: 190,
      minHeight: 50,
      "& span": {
        textTransform: "none",
      },
      margin: "1rem",
    },
    topBar: {
      display: "flex",
      flexDirection: "row",
      height: "132px",
      width: "100%",
      boxShadow: theme.shadows[4],
      transition: "all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
      background: "#ffffff",
    },
    text: {
      color: "grey",
    },
    borderless: {
      border: "none",
    },
    titleArea: {
      display: "flex",
      flexDirection: "column",
      height: "300px",
      width: "100%",
      margin: "auto",
    },
    block: {
      display: "inline-block",
    },
  })
);

interface Props extends RouteComponentProps<{ id: string }> {}

function AssessmentResults(props: Props) {
  const classes = useStyles();
  const actions = useActions(WellbeingActions);
  const reduxState = useReduxState({
    name: selectors.getName,
    requestStatus: getResultStatus,
    results: getTestResult,
    wellbeingTests: getWellbeingTestAnswers,
    currentAnswers: getAnswers,
  });

  const tagManagerArgs = {
    dataLayer: {
      event: "WellbeingPlanViewed",
    },
  };

  IgniteTagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    if (
      reduxState.requestStatus !== REQUEST_STATUS.Failed ||
      reduxState.results.id !== props.match.params.id
    ) {
      if (reduxState.requestStatus === REQUEST_STATUS.Idle) {
        actions.fetchWellbeingResult(props.match.params.id);
      } else if (
        reduxState.results.id !== props.match.params.id &&
        reduxState.requestStatus !== REQUEST_STATUS.Pending
      ) {
        actions.fetchWellbeingResult(props.match.params.id);
      }
    }
  }, [props.history, actions, props.match.params.id, reduxState]);

  function retakeTest() {
    actions.clearTest();
    actions.clearStatus();

    props.history.push("/assessment");
  }

  function onSelectProfile(profile: ShortProfile) {
    const path = generatePath("/book/:id", { id: profile.providerId });
    props.history.push(path);
  }

  function reprioritise() {
    let answers = reduxState.currentAnswers;
    if (!answers) {
      const stored = reduxState.wellbeingTests.find(
        (a) => a.wellbeingTestId === reduxState.results.id
      );

      if (stored) {
        answers = stored.answers;
      }
    }

    if (answers) {
      actions.clearStatus();
      actions.reprioritise({ answers: answers });
      props.history.push("/prioritise");
    }
  }

  function moreProviders() {
    let path = "";
    reduxState.results.focus.map((item) => (path += "&domainFilters=" + item));
    path = generatePath("/search?pageSize=12" + path);
    props.history.push(path);
  }

  function moreContent() {
    let path = "";
    reduxState.results.focus.map((item) => (path += "&domainFilters=" + item));
    path = generatePath("/learn?pageSize=12" + path);
    props.history.push(path);
  }

  return (
    <div>
      <Banner src={require("../../assets/banner/WellbeingBanner-2.png")}>
        <div>My Wellbeing Plan</div>
        <p>
          This plan has been created for you based on your assessment of your
          wellbeing.
        </p>
      </Banner>
      <Container maxWidth="xl">
        <Card className={classes.headerContainer}>
          {reduxState.requestStatus === REQUEST_STATUS.Fulfilled && (
            <div>
              <Typography
                variant="subtitle1"
                className={classes.prioritiesText}
              >
                Your chosen priorities
              </Typography>
              <div className={`${classes.detailsContainer}`}>
                <div className={classes.focusContainer}>
                  {reduxState.results.focus.map((item, index) => {
                    return (
                      <div key={item} className={classes.block}>
                        <div key={item} className={`${classes.focusItem} `}>
                          <DomainIcon domain={item} borderless={true} />
                          <Typography
                            variant="h6"
                            className={classes.domainText}
                          >
                            {item}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.buttonsContainer}>
                  <Button
                    className={classes.buttons}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      const tagManagerArgs = {
                        dataLayer: {
                          event: "WellbeingRetakeQuestionaireClicked",
                        },
                      };

                      IgniteTagManager.dataLayer(tagManagerArgs);
                      retakeTest();
                    }}
                  >
                    Retake Questionnaire
                  </Button>
                  {reduxState.results.date && isToday(reduxState.results.date) && (
                    <Button
                      className={classes.buttons}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        const tagManagerArgs = {
                          dataLayer: {
                            event: "WellbeingTestReprioritised",
                          },
                        };

                        IgniteTagManager.dataLayer(tagManagerArgs);
                        reprioritise();
                      }}
                    >
                      Review my results
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
          {reduxState.requestStatus === REQUEST_STATUS.Pending && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CircularIndeterminate className={classes.loader} />
            </div>
          )}

          {reduxState.requestStatus === REQUEST_STATUS.Failed && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  actions.fetchWellbeingResult(props.match.params.id)
                }
              >
                Try Again
              </Button>
            </div>
          )}
        </Card>
      </Container>
      {reduxState.requestStatus === REQUEST_STATUS.Fulfilled && (
        <Container maxWidth="xl" className={classes.root}>
          <div className={classes.contentContainer}>
            <div className={classes.title}>
              <Typography variant="h4">Resources</Typography>
            </div>
            <div
              className={`${classes.spreadContainer} ${classes.extraPadding}`}
            >
              <Typography variant="body1">
                Below are some resources that might help you with your{" "}
                {reduxState.results.focus.join(", ")}
              </Typography>
              <Button
                className={classes.desktopOnly}
                variant="text"
                color="secondary"
                onClick={moreContent}
                endIcon={<IconRightArrow />}
              >
                See More Resources
              </Button>
            </div>

            <Grid container direction="row" alignItems="center" spacing={4}>
              {reduxState.results.content.map((item) => (
                <Grid item key={item.id}>
                  <ContentCard key={item.id} {...item} />
                </Grid>
              ))}
              <Grid item className={classes.mobileOnly} key={"more-content"}>
                <Button
                  className={classes.mobileButton}
                  variant="outlined"
                  color="secondary"
                  onClick={moreContent}
                  endIcon={<IconRightArrow />}
                >
                  See more resources
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.providerContainer}>
            <div className={classes.title}>
              <Typography variant="h4">Support Providers</Typography>
            </div>

            <div
              className={`${classes.spreadContainer} ${classes.extraPadding}`}
            >
              <Typography variant="body1">
                Below are some Support Providers that might help you with your{" "}
                {reduxState.results.focus.join(", ")}
              </Typography>
              <Button
                className={classes.desktopOnly}
                variant="text"
                color="secondary"
                onClick={moreProviders}
                endIcon={<IconRightArrow />}
              >
                See more providers
              </Button>
            </div>

            <Grid container direction="row" alignItems="center" spacing={4}>
              {reduxState.results.providers.map((item) => (
                <Grid item key={item.providerId}>
                  <ProfileCard profile={item} onSelect={onSelectProfile} />
                </Grid>
              ))}
              <Grid item className={classes.mobileOnly} key={"more-providers"}>
                <Button
                  className={classes.mobileButton}
                  variant="outlined"
                  color="secondary"
                  onClick={moreProviders}
                  endIcon={<IconRightArrow />}
                >
                  See more providers
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      )}
    </div>
  );
}
export default withRouter(AssessmentResults);
