import React from "react";
import Modal from "@material-ui/core/Modal";

interface Props {
  open: boolean;
  onClose: () => void;
}
export const Video: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus={true}>
      <div style={{ width: "100vw", height: "100vw" }} onClick={onClose}>
        <div className="video-container">
          <iframe
            title="Marketing Add"
            width="1252"
            height="704"
            src="https://www.youtube.com/embed/y4y_Hgqc5mc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default Video;
