import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Divider } from "@material-ui/core";

import { withRouter, RouteComponentProps } from "react-router";

import A from "assets/Mood/Awful.svg";
import B from "assets/Mood/Bad.svg";
import C from "assets/Mood/Okay.svg";
import D from "assets/Mood/Good.svg";
import E from "assets/Mood/Great.svg";

import MoodGraph from "./MoodGraph";
import { ReactComponent as RightIcon } from "assets/booking/RightIcon.svg";
import { ReactComponent as LeftIcon } from "assets/booking/LeftIcon.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil-icon.svg";
import CardHeading from "ui/components/dashboard/CardHeading";
import { useActions, useReduxState } from "re-reduced";
import { DashboardActions } from "domain/dashboard/actions";
import { getMoodDiary, getMoodDiaryRequest } from "domain/dashboard/reducer";
import { addDays, isSameDay, endOfWeek } from "date-fns";
import { startOfWeek, addWeeks, isAfter, isBefore } from "date-fns/esm";
import { DateAndMood } from "domain/dashboard/types";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import Responsive from "ui/Responsive";
import { toNewZealandTime } from "lib/date-time";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      margin: "0px",
      minHeight: "420px",
      position: "relative",

      maxHeight: "999px",
      transition: "all 1s ease",

      background: "white",
      // background: `url(${Mood}) no-repeat center`,

      "& .MuiDivider-root": {
        marginTop: "10px",
      },

      [theme.breakpoints.down("sm")]: {
        padding: "16px",
        height: "calc(100% - 32px)",
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: "230px",
        padding: "8px",
        height: "calc(100% - 16px)",
      },

      height: "100%",
    },

    faceIcon: {
      animation: "bounceIn 1s ease 0s normal forwards",
      margin: "auto",
      transition: "all 1s ease",

      [theme.breakpoints.down("xs")]: {
        minWidth: 35,
      },

      width: "calc(100% - 30px)",
      height: "auto",
      opacity: 1,
      zIndex: 1,
    },

    moodRatingContainer: {
      display: "flex",
      flexDirection: "column",
      width: "135%",
      transition: "all 1s ease",

      maxWidth: 95,
      [theme.breakpoints.down("md")]: {
        maxWidth: 105,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: 52,
        maxWidth: 65,
        width: "calc(100% - 10px)",
      },

      height: "auto",

      cursor: "pointer",
      "&:focus >:first-child::before": {
        backgroundColor: "rgba(255, 255, 255, 1)",
        transform: "scale(1.1)",
        [theme.breakpoints.down("sm")]: {
          transform: "scale(0.95)",
        },
      },
      "&:hover >:first-child::before": {
        backgroundColor: "rgba(255, 255, 255, 1)",
        transform: "scale(1.1)",
        [theme.breakpoints.down("sm")]: {
          transform: "scale(0.95)",
        },
      },
    },
    moodButton: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      height: 103,
      maxWidth: 95,
      width: "100%",
      transition: "all 1s ease",

      [theme.breakpoints.down("xs")]: {
        height: 56,
        width: 56,
      },

      "&::before": {
        cursor: "pointer",
        transition: "all 0.5s ease",
        content: '" "',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(3, 8, 34, 0.301)",
        borderRadius: "8px",
      },
    },

    container: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      padding: "10px",
      maxWidth: "578px",
      width: "calc(100% - 30px)",
      transition: "all 1s ease",

      [theme.breakpoints.down("xs")]: {
        padding: 0,
        width: "100%",
      },
    },
    graphFlex: {
      flexDirection: "row",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    moodTitle: {
      ...theme.typography.h5,
      color: "white",
      textAlign: "center",
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        ...theme.typography.subtitle1,
        color: "white",
      },
    },
    moodIconsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: "37px",
      transition: "all 1s ease",

      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
      "& >:first-child": {
        marginLeft: 0,
      },
      "& >:last-child": {
        marginRight: 0,
      },
      "& > *": {
        margin: "4px",
        [theme.breakpoints.down("xs")]: {
          margin: "2px",
        },
      },
    },
    titleContainer: {
      ...theme.typography.h4,
      color: "white",
      textAlign: "center",
      width: "100%",
      height: "50px",
      marginBottom: "0px",

      [theme.breakpoints.down("xs")]: {
        ...theme.typography.h5,
        color: "white",
        fontSize: "20px",
      },
    },

    awfulImage: {
      width: "120px",
      height: "120px",
      [theme.breakpoints.down("xs")]: {
        height: "80px",
        width: "80px",
      },
    },
    awfulContainer: {
      display: "flex",
      flexDirection: "row",
      animation: "fadeInLeft 1s ease 0s 1 normal forwards",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      height: "auto",
      justifyContent: "space-evenly",

      "& > *": {
        margin: 8,
      },
    },

    helpBox: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      justifyContent: "center",
      backgroundColor: "#03082266",
      borderRadius: 8,
      [theme.breakpoints.up("xs")]: {
        width: "100%",
        maxWidth: 324,
        height: 75,

        justifyContent: "center",

        "& > *": {
          margin: "auto",
        },
      },
      [theme.breakpoints.down("xs")]: {
        alignSelf: "center",
        flexDirection: "column",
        width: "100%",
        maxWidth: 324,
        height: "auto",
        "& > button": {
          margin: "auto",
          marginBottom: "12px",
        },
        "& > *:first-child": {
          marginTop: 12,
          marginBottom: 4,
        },
        "& > *:last-child": {
          marginBottom: 12,
          marginTop: 6,
        },
        "& > *": {
          margin: 6,
        },
      },
    },

    section1: {
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
        marginTop: "0px",
        "& >:last-child": {
          marginTop: "12px",
        },
      },
    },

    section2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
      maxWidth: "340px",
      "& > *": {
        marginTop: "16px",
      },

      [theme.breakpoints.down("xs")]: {
        margin: "auto",
        "& *": {
          textAlign: "center",
          justifyContent: "center",
        },

        "& > button": {
          margin: "16px auto 0px auto",
        },
      },
    },

    closeButton: {
      borderColor: "white",
      color: "#04aaf0",
      backgroundColor: "white",
      boxShadow: "none",
      width: 180,
      height: 40,

      [theme.breakpoints.down("xs")]: {
        margin: "16px auto",
      },
    },

    graphContainer: {
      display: "flex",
      flexDirection: "row",
      minHeight: "220px",
      height: "100%",
      width: "80%",
      margin: "auto",
      animation: "fadeInLeft 1s ease 0s 1 normal forwards",

      [theme.breakpoints.down("sm")]: {
        minHeight: "180px",
      },

      [theme.breakpoints.down("xs")]: {
        width: "80%",
      },
    },

    graphScreen: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },

    graphDirectionalButton: {
      height: "100%",
      display: "flex",
      "& svg": {
        // margin: "auto",
      },
      "& *": {
        fill: "none !important",
      },

      "& .MuiButton-textSecondary *": {
        fill: "none !important",
      },

      "& .Mui-disabled *": {
        fill: "none !important",
      },
    },

    buttonChildOverride: {
      "& *": {
        fill: "none !important",
      },

      "& .MuiButton-textSecondary *": {
        fill: "none !important",
      },

      "& .Mui-disabled *": {
        fill: "none !important",
      },
    },
  })
);
interface Props {}

interface MinMax {
  min: number;
  max: number;
}

interface WellbeingOverviewItem {
  date: Date;
  lifeScore: MinMax;
  healthScore: MinMax;
  meaning: MinMax;
  priorities: string[];
}

type Screen = "Rate" | "Awful" | "Graph";

const WellbeingOverview: React.FC<Props> = (props) => {
  const classes = useStyles();

  const actions = useActions(DashboardActions);

  const { moodList, diaryRequest } = useReduxState({
    moodList: getMoodDiary,
    diaryRequest: getMoodDiaryRequest,
  });
  const rootRef = React.useRef<HTMLDivElement>(null);

  const [index, setIndex] = React.useState(0);

  const todaysMood = React.useCallback(() => {
    if (diaryRequest === "Fulfilled") {
      const today = moodList.filter((a) => isSameDay(a.date, new Date()));
      if (today.length > 0) {
        return today[0].mood;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [moodList, diaryRequest]);

  const [mood, setMoodState] = React.useState(0);
  const [screenState, setScreenState] = React.useState<Screen>(
    todaysMood() === 0 ? "Rate" : "Graph"
  );

  const activeMoodList = React.useCallback(() => {
    let set = moodList
      .map((item) => {
        return { mood: item.mood, date: toNewZealandTime(item.date) };
      })
      .filter((item) => {
        const start = addWeeks(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          index
        );
        const endDate = endOfWeek(start, { weekStartsOn: 1 });
        return isAfter(item.date, start) && isBefore(item.date, endDate);
      });
    if (set.length !== 7) {
      const start = addWeeks(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        index
      );
      const allDays = [] as DateAndMood[];

      for (let i = 0; i < 7; i++) {
        const date = addDays(start, i);
        const match = set.filter((item) => isSameDay(item.date, date));
        let mood = 0;
        if (match.length > 0) {
          mood = match[0].mood;
        }
        allDays.push({
          date: addDays(start, i),
          mood: mood,
        });
      }

      set = allDays;
    }

    return set.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [moodList, index]);

  const hasMore = React.useCallback(() => {
    return (
      moodList.filter((item) =>
        isAfter(
          startOfWeek(addWeeks(new Date(), index), { weekStartsOn: 1 }),
          item.date
        )
      ).length > 0
    );
  }, [moodList, index]);

  const hasLess = React.useCallback(() => {
    return (
      moodList.filter((item) =>
        isBefore(
          endOfWeek(addWeeks(new Date(), index), { weekStartsOn: 1 }),
          item.date
        )
      ).length > 0
    );
  }, [moodList, index]);

  useEffect(() => {
    actions.getMoodList();
  }, [actions]);

  useEffect(() => {
    if (diaryRequest === "Fulfilled") {
      switch (todaysMood()) {
        case 0:
          setScreenState("Rate");
          break;
        case 1:
          setScreenState("Awful");
          break;
        default:
          setScreenState("Graph");
      }
    }
  }, [diaryRequest, moodList, todaysMood]);

  function setMood(x: number) {
    if (mood === x) {
      return;
    }
    setMoodState(x);
    let ss = "Rate" as Screen;
    switch (x) {
      case 0:
        ss = "Rate";
        setIndex(0);
        break;
      case 1:
        ss = "Awful";
        break;
      default:
        ss = "Graph";
        break;
    }
    if (x !== 0) {
      actions.setMood({ date: new Date(), mood: x });
    }
    setScreenState(ss);
  }

  function getScreenByState() {
    if (screenState === "Rate" || screenState === "Awful")
      return (
        <React.Fragment>
          <div
            className={classes.container}
            style={{ zIndex: 1, padding: "12px", width: "calc(100% - 24px)" }}
          >
            <div className={classes.titleContainer}>
              How are you feeling today?
            </div>
            {screenState === "Rate" ? getMoodButtons() : getAwfulMood()}
          </div>
        </React.Fragment>
      );
    else if (screenState === "Graph") {
      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "calc(100% - 40px)",
              padding: "20px",
            }}
          >
            <CardHeading
              heading="Your Mood Insights"
              action="Edit Today's Mood"
              iconPosition="start"
              icon={<PencilIcon />}
              onClick={() => {
                setMood(0);

                setScreenState("Rate");
              }}
            />
            <Divider style={{ margin: "20px 0px 0px 0px" }} />
          </div>
          <div
            className={classes.container + " " + classes.graphFlex}
            style={{ zIndex: 1 }}
          >
            {getGraph()}
          </div>
        </React.Fragment>
      );
    }
  }

  function getMoodButtons() {
    return (
      <div className={classes.moodIconsContainer}>
        <button
          className={"MuiButtonBase-root " + classes.moodRatingContainer}
          onClick={() => setMood(1)}
        >
          <div className={classes.moodButton}>
            <img alt="" src={A} className={classes.faceIcon} />
          </div>
          <div className={classes.moodTitle}>Awful</div>
        </button>

        <button
          className={"MuiButtonBase-root " + classes.moodRatingContainer}
          onClick={() => setMood(2)}
        >
          <div className={classes.moodButton}>
            <img alt="" src={B} className={classes.faceIcon} />
          </div>
          <div className={classes.moodTitle}>Bad</div>
        </button>

        <button
          className={"MuiButtonBase-root " + classes.moodRatingContainer}
          onClick={() => setMood(3)}
        >
          <div className={classes.moodButton}>
            <img alt="" src={C} className={classes.faceIcon} />
          </div>
          <div className={classes.moodTitle}>Okay</div>
        </button>

        <button
          className={"MuiButtonBase-root " + classes.moodRatingContainer}
          onClick={() => setMood(4)}
        >
          <div className={classes.moodButton}>
            <img alt="" src={D} className={classes.faceIcon} />
          </div>
          <div className={classes.moodTitle}>Good</div>
        </button>

        <button
          className={"MuiButtonBase-root " + classes.moodRatingContainer}
          onClick={() => setMood(5)}
        >
          <div className={classes.moodButton}>
            <img alt="" src={E} className={classes.faceIcon} />
          </div>
          <div className={classes.moodTitle}>Great</div>
        </button>
      </div>
    );
  }

  function getAwfulMood() {
    return (
      <div className={classes.awfulContainer}>
        <div className={classes.section1}>
          <img
            alt=""
            src={A}
            className={classes.awfulImage}
            style={{ margin: "auto" }}
          />
          <div
            style={{
              fontFamily: "Gilroy Bold",
              fontSize: 24,
              color: "white",
              textAlign: "center",
            }}
          >
            Awful
          </div>
        </div>
        <div className={classes.section2}>
          <div
            style={{
              fontFamily: "Gilroy Bold",
              fontSize: 14,
              color: "white",
            }}
          >
            We are sorry to hear that. If you need help,
            <br /> please call or text:
          </div>
          <div className={classes.helpBox}>
            <div
              style={{
                fontFamily: "Gilroy Bold",
                fontSize: 32,
                color: "white",
                textAlign: "center",
              }}
            >
              1737
            </div>
            <div
              style={{
                fontFamily: "Gilroy Regular",
                fontSize: 14,
                color: "white",
                textAlign: "center",
                maxHeight: "18px",
              }}
            >
              or
            </div>
            <Button
              variant="outlined"
              color="default"
              style={{
                borderColor: "white",
                color: "white",
                width: 176,
                height: 40,
              }}
              onClick={() => {
                props.history.push("/crisis");
              }}
            >
              View Other Helplines
            </Button>
          </div>
          <Button
            variant="contained"
            color="default"
            className={classes.closeButton}
            onClick={() => {
              setScreenState("Graph");
            }}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }

  function getGraph() {
    return (
      <>
        <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
          <Button
            variant="text"
            color="secondary"
            disabled={!hasMore()}
            className={classes.graphDirectionalButton}
            onClick={() => setIndex(index - 1)}
          >
            <LeftIcon
              style={hasMore() ? { stroke: "#04aaf0" } : { stroke: "#B1B6CB" }}
            />
          </Button>
        </Responsive>

        <div className={classes.graphContainer}>
          <MoodGraph moods={activeMoodList()} />
        </div>
        <Responsive displayIn={["MobileXS"]}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "calc(100% - 30px)",
              justifyContent: "center",
              padding: "15px",
            }}
          >
            <Button
              variant="text"
              color="secondary"
              className={classes.buttonChildOverride}
              disabled={!hasMore()}
              onClick={() => setIndex(index - 1)}
              style={{ width: 80, color: hasMore() ? "#04AAF0" : "#B1B6CB" }}
              startIcon={
                <LeftIcon
                  style={
                    hasMore() ? { stroke: "#04aaf0" } : { stroke: "#B1B6CB" }
                  }
                />
              }
            >
              Back
            </Button>
            <Button
              variant="text"
              disabled={!hasLess()}
              color="secondary"
              className={classes.buttonChildOverride}
              onClick={() => setIndex(index + 1)}
              style={{ width: 80, color: hasLess() ? "#04AAF0" : "#B1B6CB" }}
              endIcon={
                <RightIcon
                  style={
                    hasLess()
                      ? { stroke: "#04aaf0" }
                      : {
                          stroke: "#B1B6CB",
                        }
                  }
                />
              }
            >
              Next
            </Button>
          </div>
        </Responsive>
        <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
          <Button
            variant="text"
            disabled={!hasLess()}
            color="secondary"
            className={classes.graphDirectionalButton}
            onClick={() => setIndex(index + 1)}
          >
            <RightIcon
              style={
                hasLess()
                  ? {
                      stroke: "#04aaf0",
                    }
                  : {
                      stroke: "#B1B6CB",
                    }
              }
            />
          </Button>
        </Responsive>
      </>
    );
  }

  function getLoading() {
    return (
      <div style={{ display: "flex", margin: "auto" }}>
        <CircularIndeterminate style={{ color: "white" }} />
      </div>
    );
  }
  function getOverview() {
    return (
      <div
        className={
          (screenState !== "Graph" ? "moodDiaryRootBefore " : "") + classes.root
        }
        ref={rootRef}
      >
        {diaryRequest === "Idle" || diaryRequest === "Pending"
          ? getLoading()
          : getScreenByState()}
      </div>
    );
  }
  return getOverview();
};

export default withRouter(WellbeingOverview);
