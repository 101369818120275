import React from "react";
import Filter from "./Filter";
import IconListItem from "./IconListItem";
import BookingTypeIconRound from "ui/components/BookingTypeIcon";
import { bookingTypes } from "domain/providers/types";
import { FilterProps } from "ui/components/filters/Filter";

export const renderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <IconListItem
      option={option}
      selected={selected}
      key={option}
      onClick={callback}
    >
      <BookingTypeIconRound type={option} />
    </IconListItem>
  );
};
const BookingTypeFilter = (props: FilterProps) => {
  function getOptions(): string[] {
    return bookingTypes;
  }

  return (
    <Filter
      border={true}
      values={props.values}
      onFilterChange={props.onFilterChange}
      options={getOptions()}
      title="Appointment Type"
      disableInputBorder
      renderOptions={renderOption}
      disableInput={true}
      placeHolder="All appointment types"
      id="appointment-type-filter"
    />
  );
};

export default BookingTypeFilter;
