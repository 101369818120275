import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as CheckboxIconFilled } from "assets/icons/CheckboxIcon.svg";
import { ReactComponent as CheckBoxIconEmpty } from "assets/icons/CheckboxEmpty.svg";
import { Checkbox as MuiCheckbox } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiselectCheckbox: {
      marginRight: "8px",
      marginLeft: "auto"
    },
    checkBoxOutline: {
      stroke: "#5F6272",
      width: "24px",
      height: "24px",

      "&:hover": {
        stroke: theme.palette.primary.light
      }
    },
    checkBoxChecked: {
      stroke: "white !important"
    }
  })
);

export const CheckboxIcon = () => {
  const classes = useStyles();

  return (
    <CheckboxIconFilled fontSize="small" className={classes.checkBoxChecked} />
  );
};

export const CheckBoxOutlineBlankIcon = () => {
  const classes = useStyles();

  return (
    <CheckBoxIconEmpty fontSize="small" className={classes.checkBoxOutline} />
  );
};

interface Props {
  checked: boolean;
}
export const Checkbox = ({ checked }: Props) => {
  const classes = useStyles();
  return (
    <MuiCheckbox
      className={classes.multiselectCheckbox}
      color="primary"
      icon={CheckBoxOutlineBlankIcon()}
      checked={checked}
    />
  );
};
