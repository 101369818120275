import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Checkbox } from "ui/components/checkbox/Checkbox";

interface Props {
  option: string;
  selected: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiselectItem: {
      display: "flex",
      width: "100%",
      maxHeight: "47px",
      marginLeft: "-12px"
    },
    unCheckedHover: {
      "&:hover": {
        "& .MuiCheckbox-root": {
          "& * svg": {
            stroke: theme.palette.primary.dark
          }
        }
      }
    },
    multiselectTypography: {
      marginLeft: "0px",
      margin: "12px",
      textAlign: "left"
    }
  })
);

const IconListItem: React.FunctionComponent<Props> = ({
  option,
  selected,
  children,
  onClick
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.multiselectItem} ${
        selected ? "" : classes.unCheckedHover
      }`}
      onClick={onClick}
      key={option}
    >
      {children}
      <Typography variant="body1" className={classes.multiselectTypography}>
        {option}
      </Typography>
      <Checkbox checked={selected} />
    </div>
  );
};

export default IconListItem;
