import styled from "styled-components";
import { breakpointValues } from "theme";
const Rating = styled.div<{ selected: boolean; delay: number }>`
width: 80px;
height: 80px;
@media(max-width: ${breakpointValues.sm}px) {
    width: 40px;
    height: 40px;
}
border-radius: 50%;
border: 2px solid ${(props) => (props.selected ? "#6020D4" : "white")};
display: flex;
margin: auto;
transition: all .25s ease;
background-color: ${(props) => (props.selected ? "#6020D4" : "white")};
box-shadow: 0px 5px 5px rgba(68, 53, 86, 0.05),
  0px 3px 14px rgba(68, 53, 86, 0.05), 0px 8px 10px rgba(68, 53, 86, 0.04);

opacity: 0;
animation-name: bounceIn;
animation-duration: 450ms;
animation-delay: ${(props) => props.delay}s;
animation-timing-function: linear;
animation-fill-mode: forwards;
cursor: pointer;

&:hover {
  width: 78px;
  height: 78px;

  @media(max-width: ${breakpointValues.sm}px) {
    width: 40px;
    height: 40px;
    border: none;
  }

  border: 2px solid #6020d4;

  & p {
    color: ${(props) => (props.selected ? "white" : "#030822")};
  }
}

& p {
transition: color 0s ease-in-out;
width: 100%;
  height: 20px;
  margin: auto;
  padding: 0px;
  color: ${(props) => (props.selected ? "white" : "#030822")}
  text-align: center;
  font-family: Gilroy Bold;
  font-size: 20px;
}


@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
`;

export default Rating;
