import React, { Suspense, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import SectionBanner from "ui/compounds/SectionBanner";

import { Container, Typography, Button, Card } from "@material-ui/core";
import CovidIllustrationDesktop1D from "assets/illustrations/COVID-19-Illustration-1D.svg";
import CovidIllustrationDesktop2D from "assets/illustrations/COVID-19-Illustration-2D.svg";
import CovidIllustrationDesktop3D from "assets/illustrations/COVID-19-Illustration-3D.svg";
import CovidIllustrationTablet from "assets/illustrations/COVID-19-Illustration-Tablet.svg";
import CovidIllustrationMobile from "assets/illustrations/COVID-19-Illustration-Mobile.svg";
import BGPicture from "assets/illustrations/BGPicture.png";

import { ReactComponent as Analytics } from "assets/home/Analytics.svg";
import { ReactComponent as People } from "assets/home/People.svg";
import { ReactComponent as World } from "assets/home/World.svg";
import { ReactComponent as Smile } from "assets/home/Smile.svg";
import QuoteLeft from "assets/home/QuoteLeft.svg";
import QuoteRight from "assets/home/QuoteRight.svg";
import WholeGon from "assets/home/WholeGon.png";

import CoronaVirus from "assets/domains/Coronavirus.svg";

import { ReactComponent as NextArrow } from "assets/home/Next.svg";
import { ReactComponent as BackArrow } from "assets/home/Back.svg";

import clsx from "clsx";
import Video from "ui/components/Video";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& * .MuiContainer-root": {
        zIndex: 1,
        display: "flex",
      },
    },
    heroTitle: {
      color: "white",
      "@media(max-width: 680px)": {
        fontSize: "50px",
      },
      [theme.breakpoints.down("xs")]: {
        ...theme.typography.h4,
        fontSize: "30px",
        color: "white",
      },
      "@media(max-width: 400px)": {
        ...theme.typography.h5,
      },
    },
    heroButton: {
      // color: "white",
      [theme.breakpoints.down("xs")]: {
        width: "151px",
        height: "40px",
        padding: "5px",
        "& *": {
          fontSize: "14px",
        },
      },
    },
    heroText: {
      color: "white",
      "@media(max-width: 680px)": {
        fontSize: "25px",
      },
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        ...theme.typography.subtitle1,
        fontSize: "16px",
        color: "white",
        textTransform: "none",
      },
    },
    iFrameContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minHeight: "750px",
      paddingBottom: "20px",

      "@media(max-width: 1048px)": {
        minHeight: "1000px",
      },
    },

    bookDemoText: {
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: "20px",
      },
    },

    iFrameCell: {
      position: "relative",
      height: "calc(100%)",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      paddingTop: "50px",
    },
    container: {
      position: "relative",
      height: "calc(100%)",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      paddingTop: "50px",
    },
    bannerContentContainer: {
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      height: "60%",
      justifyContent: "center",

      "&>:first-child": {
        marginBottom: "15px",
      },
      "&>:nth-child(2)": {
        marginTop: "0px",
      },
      "&>:last-child": {
        marginTop: "60px",
      },
      [theme.breakpoints.down("lg")]: {
        height: "70%",
      },
      [theme.breakpoints.down("md")]: {
        height: "80%",

        "&>:first-child": {
          marginBottom: "15px",
        },
        "&>:nth-child(2)": {
          marginTop: "0px",
        },
        "&>:last-child": {
          marginTop: "40px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        height: "100%",

        "&>:first-child": {
          marginBottom: "15px",
        },
        "&>:nth-child(2)": {
          marginTop: "0px",
        },
        "&>:last-child": {
          marginTop: "30px",
        },
      },
      "& > *": {
        margin: "0px",
      },
    },
    section: {
      width: "100%",
      position: "relative",
      overflow: "hidden",
      display: "flex",

      paddingTop: "40px",
      paddingBottom: "40px",
    },
    bannerContainer: {
      maxWidth: "1920px",
      margin: "auto",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      height: "700px",

      "@media(max-width: 1660px)": {
        height: "700px",
      },

      "@media(max-width: 810px)": {
        height: "575px",
        // height: "calc(100vw - 100px)"
      },

      [theme.breakpoints.down("xs")]: {
        backgroundSize: "stretch",
        height: "100vw",
        maxHeight: "320px",
        width: "100%",
      },
    },
    leftGon: {
      zIndex: 0,
      position: "absolute",
      left: 0,
      bottom: 0,
    },
    rightGon: {
      zIndex: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    mobileLeftGon: {
      zIndex: "inherit",
      position: "absolute",
      left: 0,
      bottom: 0,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    mobileRightGon: {
      zIndex: "inherit",
      position: "absolute",
      right: 0,
      bottom: 0,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    ipadLeftGon: {
      zIndex: "inherit",
      position: "absolute",
      overflow: "hidden",

      left: 0,
      bottom: 0,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },

      "@media(min-width: 810px)": {
        display: "none",
      },
    },
    covidCard: {
      zIndex: 1,
      width: "100%",
      minHeight: "503px",
      position: "relative",
      margin: "auto",
    },
    covidCardIllustrationTablet: {
      zIndex: -1,
      width: "101%",
      height: "100%",
      "@media(min-width: 810px)": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    c1: {
      zIndex: -1,
      "@media(max-width: 1150px)": {
        display: "none",
      },
    },
    c2: {
      zIndex: -1,
      "@media(max-width: 1020px)": {
        display: "none",
      },
    },
    c3: {
      zIndex: -1,
    },
    covidCardIllustration: {
      zIndex: -1,
      position: "absolute",
      right: 0,
      bottom: 0,
      animation: "fadeInLeft 1s ease 0s 1 normal forwards",

      "@media(max-width: 810px)": {
        display: "none",
      },
    },
    covidCardHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "auto",
      maxWidth: "calc(100vw - 50px)",
      marginBottom: "10px",

      "& img": {
        width: "60px",
        height: "60px",

        "@media(max-width: 900px)": {
          width: "52px",
          height: "52px",
        },
        "@media(max-width: 750px)": {
          width: "42px",
          height: "42px",
        },
        "@media(max-width: 600px)": {
          width: "32px",
          height: "32px",
        },
      },

      "& .MuiTypography-root": {
        height: "40px",
        margin: "auto 10px",
        "@media(max-width: 900px)": {
          fontSize: "28px",
          height: "35px",
        },
        "@media(max-width: 750px)": {
          fontSize: "24px",
          height: "30px",
        },
        "@media(max-width: 600px)": {
          fontSize: "20px",
          height: "25px",
        },
      },
    },
    covidCardBody: {
      marginBottom: "25px",
    },
    covidMobileIllustration: {
      width: "101%",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    covidCardButtons: {
      display: "flex",
      flexDirection: "row",

      "& > *": {
        width: "210px",
      },

      "@media(max-width: 810px)": {
        width: "100%",
        "& > *": {
          margin: "5px auto",
          minWidth: "210px",
          maxWidth: "280px",
          width: "calc(100% - 50px)",
        },
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        "& > *": {},
      },
    },
    covidCardContent: {
      zIndex: 12,
      display: "flex",
      flexDirection: "column",
      width: "500px",
      height: "calc(100%)",
      margin: "42px",
      "@media(max-width: 1150px)": {},

      "@media(max-width: 810px)": {
        margin: "15px auto 30px auto",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        "& *": {
          textAlign: "center",
        },
      },
    },
    futureCard: {
      zIndex: 1,
      width: "100%",
      position: "relative",
      margin: "auto",
      minHeight: "503px",
    },
    futureCardContainer: {
      display: "flex",
      flexDirection: "row",
      "@media(max-width: 810px)": {
        flexDirection: "column",
      },
    },
    futureContent: {
      order: 1,
      maxWidth: "calc(50% - 60px)",
      display: "flex",
      flexDirection: "column",
      margin: "30px",
      justifyContent: "center",
      "& > *": {
        marginTop: "20px",
        marginBottom: "20px",
      },
      "@media(max-width: 810px)": {
        order: 2,
        width: "100%",
        maxWidth: "calc(100% - 60px)",
      },
    },
    futureBody: {
      "@media(max-width: 810px)": {
        textAlign: "center",
        width: "100%",
      },
    },
    futureTitle: {
      "@media(max-width: 810px)": {
        textAlign: "center",
        width: "100%",
      },
    },
    futureImageContainer: {
      "@media(max-width: 810px)": {
        order: 1,
        width: "auto",
        height: "auto",
      },
      overflow: "hidden",
      order: 2,
      display: "flex",

      // backgroundColor: theme.palette.primary.light,
      background: `url("${BGPicture}") no-repeat left`,
      backgroundSize: "cover",
      justifyContent: "center",
    },
    futureImage: {
      width: "100%",
      margin: "auto",
      "@media(max-width: 810px)": {
        display: "none",
      },
    },
    futureImageTablet: {
      display: "none",
      "@media(max-width: 810px)": {
        display: "flex",
        width: "100%",
        margin: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    futureImageMobile: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        width: "100%",
        margin: "auto",
      },
    },

    communitiesContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },

    communitiesHeading: {
      fontSize: 48,

      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
      },
    },
    communitiesGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      columnGap: 10,

      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    communitiesListContainer: {
      width: "auto",
      maxWidth: "48%",
      display: "flex",
      flexDirection: "column",
      columnCount: 2,
      flexBasis: "content",
      flexWrap: "wrap",
      minWidth: "250px",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },

    communitiesContainerSpaced: {
      height: "80%",
      justifyContent: "space-around",
    },
    communitiesH4: {
      color: "#6020d4",
      fontSize: 24,
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
      },
    },
    communitiesListItem: {
      marginBottom: "10px",
      marginTop: "10px",

      "&:first-child": {
        marginTop: "0px",
        marginBottom: "4px",
      },
      "&:last-child": {
        marginBottom: "0px",
      },
      "& >:first-child": {
        marginTop: 0,
        marginBottom: 10,
      },
      "& >:last-child": {
        marginTop: 10,
        marginBottom: 0,
      },
    },

    communitiesParagrpah: {
      padding: "20px 0px",
    },
    communitiesFooter: {},

    marketingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
    },
    marketingHeader: {
      display: "flex",
      alignSelf: "center",
      maxWidth: "800px",
      color: "white",
      textAlign: "center",
      paddingBottom: "34px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    marketingBody: {
      display: "flex",
      alignSelf: "center",
      maxWidth: "800px",
      color: "white",
      textAlign: "center",
      paddingBottom: "34px",
    },

    marketingVideoContainer: {
      position: "relative",
      width: "100%",
      justifyContent: "center",
    },
    marketingImage: {
      display: "block",
      margin: "auto",
      maxWidth: "1000px",

      width: "100%",
      height: "auto",
    },
    marketingPlayButton: {
      opacity: 0.5,
      cursor: "pointer",
      position: "absolute",
      height: "100px",
      width: "100px",
      left: "50%",
      marginLeft: "-50px",
      top: "50%",
      marginTop: "-50px",

      "&:hover": {
        transform: "scale(1.1)",
        opacity: 1,
      },

      transition: "all 1s ease",

      [theme.breakpoints.down("sm")]: {
        height: "60px",
        width: "60px",
        marginLeft: "-30px",
        marginTop: "-30px",
      },
    },

    videoPlayer: {
      "@media(max-width: 1252)": {
        maxWidth: "calc(80vw)",
        maxHeight: "calc(100vh)",
        width: "80%",
      },
    },
    igniteForMainContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    igniteForContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: "150px",
      width: "100%",
      flexWrap: "wrap",
      columnCount: 2,
      columnGap: 20,
      position: "relative",

      [theme.breakpoints.down("sm")]: {
        marginTop: "100px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "50px",
      },
    },

    igniteForYou: {
      display: "flex",
      flexDirection: "row",
      columnCount: 2,
      columnGap: 10,
      justifyContent: "space-between",
    },
    igniteForOrg: {
      display: "flex",
      flexDirection: "row",
      columnCount: 2,
      justifyContent: "space-between",
    },

    igniteForImageContainer: {
      position: "relative",
      width: "48%",
      maxWidth: "600px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "0px",
        maxWidth: "479px",
        maxHeight: "500px",
        overflow: "hidden",
        width: "100%",
        borderRadius: "8px",
        marginBottom: "20px",
      },

      [theme.breakpoints.down("xs")]: {
        margin: "auto",
        maxWidth: "100%",
        marginBottom: "20px",
      },
    },
    igniteForImage: {
      // display: "block",
      width: "100%",
      height: "auto",
      maxWidth: "481px",

      maxHeight: "599px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "auto",
        maxWidth: "unset",
        width: "100%",
      },
    },
    igniteForGon: {
      position: "absolute",
      width: "auto",
      top: "-200px",
      left: "-200px",
      zIndex: -1,

      [theme.breakpoints.down("sm")]: {
        // display: "none",
        width: "80%",
      },

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    igniteForYouGon: {
      position: "absolute",
      width: "auto",
      top: "-200px",
      right: "-100px",
      zIndex: -1,

      [theme.breakpoints.down("sm")]: {
        // display: "none",
        width: "80%",
      },

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    igniteForTextContainer: {
      display: "flex",
      flexDirection: "column",
      float: "right",
      width: "48%",
      maxWidth: "600px",
      justifyContent: "center",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
      },

      "& button": {
        margin: "0px",
      },
    },
    igniteForHeader: {
      fontSize: 48,
      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    igniteForBody1: {
      marginTop: "24px",
    },
    igniteForBullets: {
      marginTop: "6px",
      paddingInlineStart: "20px",
      ...theme.typography.body1,
      lineHeight: "32px",

      fontFamily: "Gilroy Bold",
      fontWeight: 600,
      "& li": {
        textDecoration: "disc",
      },
    },
    igniteForButton: {},

    tileGroupContainer: {
      display: "block",
      width: "100%",
      height: "100%",
      paddingTop: "50px",
    },
    tileGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    tileItem: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "280px",
      minHeight: "250px",
      padding: "20px 10px",

      "& :first-child": {
        display: "flex",

        "& :first-child": {
          marginTop: "auto",
        },
      },

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
    tileImage: {
      display: "block",
      width: "100px",
      height: "100px",
      maxWidth: "150px",
      float: "left",
    },
    tileTitle: {
      marginTop: "10px",
      marginBottom: "8px",
    },
    tileBody: {
      marginTop: "0px",
    },

    carouselMainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    carouselContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "800px",
    },
    relative: {
      position: "relative",
      marginTop: "25px",
    },
    quoteLeft: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      position: "absolute",
      top: "-17px",
      left: "-35px",
    },
    quoteRight: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      position: "absolute",
      bottom: "-17px",
      right: "-35px",
    },
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "1000px",
      alignSelf: "center",
      opacity: 1,
      transition: "all 1s ease",
    },
    messageTitle: {
      textAlign: "center",
      padding: "10px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    messageBody: {
      textAlign: "center",
      padding: "10px",

      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
      },
    },
    messageAuthor: {
      textAlign: "center",
      color: "#6020D4",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    buttonContainer: {
      width: "200px",
      display: "flex",
      alignSelf: "center",

      "& :hover": {},
    },

    featuredResourcesMainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    featuredResourcesTitle: {
      alignSelf: "center",
      textAlign: "center",
      maxWidth: "1000px",

      [theme.breakpoints.down("xs")]: {
        fontSize: 34,
      },
    },
    featuredResourcesBody: {
      alignSelf: "center",
      maxWidth: "800px",
      textAlign: "center",
    },
    featuredResourcesButton: {
      alignSelf: "center",
    },
    featuredResourcesSubContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      paddingTop: "20px",
      paddingBottom: "20px",

      "& >:first-child": {
        marginBottom: "20px",
      },
      "& > *": {
        marginBottom: "20px",
      },
      "& >:last-child": {
        marginTop: "20px",
        marginBottom: "20px",
      },
    },
  })
);

function LandingPage(props: RouteComponentProps) {
  const classes = useStyles();

  const TileGroup: React.FC<{
    title: string;
    src: JSX.Element;
    body: string;
  }> = (props) => {
    return (
      <div className={classes.tileItem}>
        <div style={{ minHeight: 110 }}>{props.src}</div>

        <Typography variant="h6" className={classes.tileTitle}>
          {props.title}
        </Typography>
        <Typography variant="body1" className={classes.tileBody}>
          {props.body}
        </Typography>
      </div>
    );
  };

  //eslint-disable-next-line
  const CovidCard: React.FC = () => {
    return (
      <div className={classes.section}>
        <Container maxWidth="xl">
          <Card className={classes.covidCard}>
            <img
              alt=""
              src={CovidIllustrationTablet}
              className={classes.covidCardIllustrationTablet}
            />
            <img
              alt=""
              src={CovidIllustrationMobile}
              className={classes.covidMobileIllustration}
            />
            <div className={classes.covidCardContent}>
              <div className={classes.covidCardHeader}>
                <img alt="" src={CoronaVirus} style={{ marginLeft: "-12px" }} />
                <Typography variant="h4">Getting through Covid-19</Typography>
              </div>
              <div className={classes.covidCardBody}>
                <Typography variant="body1">
                  <br />
                  Our work and home lives will look different for many months to
                  come, bringing a range of unique stressors, challenges and
                  emotions. Ignite is here to help, with strategies and tools
                  from experts in mental health and wellbeing.
                  <br />
                  <br />
                  Our clinical team have hand-picked a range of Covid-19
                  relevant resources and developed the LiveWell video series,
                  which offers advice from leaders in wellbeing on how to stay
                  well while facing the ongoing challenges of an uncertain
                  future.
                  <br />
                  <br />
                </Typography>
              </div>
              <div className={classes.covidCardButtons}>
                <Button
                  variant="outlined"
                  color="secondary"
                  href="/crisis"
                  onClick={() => props.history.push("/crisis")}
                >
                  COVID-19 Help
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  href="/learn?domainFilters=COVID-19"
                  onClick={() =>
                    props.history.push("/learn?domainFilters=COVID-19")
                  }
                >
                  View COVID-19 Resources
                </Button>
              </div>
            </div>

            <img
              alt=""
              src={CovidIllustrationDesktop3D}
              className={`${classes.covidCardIllustration} ${classes.c1}`}
            />
            <img
              alt=""
              src={CovidIllustrationDesktop2D}
              className={`${classes.covidCardIllustration} ${classes.c2}`}
            />
            <img
              alt=""
              src={CovidIllustrationDesktop1D}
              className={classes.covidCardIllustration}
            />
          </Card>
        </Container>
      </div>
    );
  };

  interface Testimonial {
    title: string;
    quote: string;
    author: string;
  }

  type TestimonialList = Testimonial[];

  const testimonials = [
    {
      quote:
        "The Ignite Wellbeing platform has complimented our in-house wellness programme perfectly. Being able to offer our staff a one-stop hub for their wellbeing needs, with the option to book confidential counselling & coaching sessions has been really beneficial. I would recommend this platform to any business.",
      author: "Tracey, Ignite customer",
    },
    {
      quote:
        "Thank you so much for providing free access to the resource library. This means we can expand on our wellbeing programme in our work area and I am so thrilled to know this. Thank you for the great work you and your team are doing.",
      author: "Yoko, Ignite customer",
    },
    {
      quote:
        "I consider myself resilient and resourceful, but lately, I have been feeling anxious about everything. My employer provided me with access to the Ignite Platform and it’s made a world of difference.",
      author: "Mark, Ignite platform user",
    },
    {
      quote:
        "I found it incredibly useful! I’m supporting a person who is really struggling to balance work, children and self-care during lockdown. I spent some time exploring the Ignite resources and managed to find a guide/tools/articles that are directly related to this situation. This is awesome, great mahi.",
      author: "Karissa, Ignite platform user",
    },
    {
      quote:
        "I have been using some of the support providers, I have seen one coach and one psychologist which have been great. I've really enjoyed the variety of providers and that you can book them yourself.",
      author: "Steve, Ignite platform user",
    },
    {
      quote:
        "I like the profiles with a picture. I struggled with traditional OCP with not having a choice of a counsellor or any clue what they specialised in. I like the choice aspect. Clear profiles with a taste of the individual",
      author: "Melinda, Ignite platform user",
    },
  ] as Testimonial[];

  const TestimonialItem: React.FC<{ item: Testimonial; unshow: boolean }> = ({
    item,
    unshow,
  }) => {
    const containerId = "testimonial-container";

    useEffect(() => {
      if (!unshow) {
        const el = document.getElementById(containerId);
        if (!el) return;

        el.style.animation = "fadeInLeft 1s ease";

        return function cleanup() {
          if (!el) return;
          el.style.animation = "fadeOutLeft 1s ease";
        };
      } else {
        const el = document.getElementById(containerId);
        if (!el) return;

        el.style.animation = "fadeOutLeft 1s ease";
      }
    }, [item, unshow]);

    function removeAnimation() {
      const el = document.getElementById(containerId);
      if (!el) return;

      el.style.animation = "";
    }

    return (
      <div className={classes.messageContainer}>
        <Typography variant="h3" className={classes.messageTitle}>
          We Make Real Impact
        </Typography>
        <div
          className={clsx(classes.messageContainer, classes.relative)}
          style={{ minHeight: "280px" }}
        >
          <div
            className={clsx(classes.messageContainer, classes.relative)}
            id={containerId}
            onAnimationEnd={removeAnimation}
            style={{ minHeight: "280px" }}
          >
            <Typography variant="h4" className={classes.messageBody}>
              {item.quote}
            </Typography>
            <Typography variant="h5" className={classes.messageAuthor}>
              {"- "}
              {item.author}
            </Typography>
          </div>
          <div
            className={classes.relative}
            style={{ position: "absolute", top: "0px", minHeight: "280px" }}
          >
            <img src={QuoteLeft} alt="" className={classes.quoteLeft} />
            <img src={QuoteRight} alt="" className={classes.quoteRight} />
            <Typography
              variant="h4"
              className={classes.messageBody}
              style={{ visibility: "hidden" }}
            >
              “{item.quote}”
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const wordsPerSecond = 200 / 60;

  const TestimonialCarousel: React.FC<{
    list: TestimonialList;
  }> = (props) => {
    const [index, setIndex] = React.useState<number>(0);
    const [shouldNext, setShouldNext] = React.useState(false);
    useEffect(() => {
      if (shouldNext) {
        const interval = setInterval(() => {
          setIndex(index + 1);
          setShouldNext(false);
        }, 500);

        return () => clearInterval(interval);
      } else if (!shouldNext) {
        const number_of_words = testimonials[
          Math.abs(index) % testimonials.length
        ].quote.split(" ").length;

        const seconds = number_of_words / wordsPerSecond;
        const interval = setInterval(() => {
          setShouldNext(true);
        }, 1000 * seconds);

        return () => clearInterval(interval);
      }
    }, [index, setIndex, shouldNext]);

    return (
      <div className={classes.carouselContainer}>
        <TestimonialItem
          item={props.list[Math.abs(index) % testimonials.length]}
          unshow={shouldNext}
        />
        <div className={classes.buttonContainer}>
          <Button
            aria-label="Back"
            id="testimonial-back-button"
            color="secondary"
            variant="contained"
            style={{
              width: "56px",
              height: "56px",
              minWidth: "unset",
              padding: "0px",
              borderRadius: "100px",
            }}
            onClick={() => setIndex(index - 1)}
          >
            <BackArrow />
          </Button>
          <Button
            aria-label="Next"
            id="testimonial-back-button"
            color="secondary"
            variant="contained"
            style={{
              width: "56px",
              height: "56px",
              minWidth: "unset",
              padding: "0px",
              borderRadius: "100px",
            }}
            onClick={() => setIndex(index + 1)}
          >
            <NextArrow />
          </Button>
        </div>
      </div>
    );
  };

  const [videoOpen, setVideoOpen] = React.useState(false);
  return (
    <div id="page" className={classes.root}>
      <Suspense fallback={<div></div>}>
        <SectionBanner
          style={{
            minHeight: "0px",
            marginBottom: "-70x",
            paddingTop: "70px",
          }}
        ></SectionBanner>
      </Suspense>
      {videoOpen && (
        <Video open={videoOpen} onClose={() => setVideoOpen(false)} />
      )}
      <section className="padded-section white" id="ignite-for-org">
        <Container maxWidth="xl" className={classes.igniteForMainContainer}>
          <div className={classes.igniteForContainer}>
            <img src={WholeGon} alt="" className={classes.igniteForGon} />
            <div className={classes.igniteForImageContainer}>
              <img
                alt=""
                src={require("assets/home/MarketingVideo.png")}
                className={classes.igniteForImage}
              />
            </div>

            <div className={classes.igniteForTextContainer}>
              <Typography variant="h3" className={classes.igniteForHeader}>
                Ignite for your business
              </Typography>
              <Typography className={classes.igniteForBody1}>
                By giving your people, members, or clients choice and
                flexibility, you’ll set your organisation up to reap the
                attraction, retention, and productivity rewards on offer. You
                will also be improving the health and wellbeing outcomes of all
                New Zealanders.
                <br />
                <br />
                Why Ignite is more effective than your current EAP:
              </Typography>
              <ul className={classes.igniteForBullets}>
                <li>
                  24/7 support access to our resources, tools, workshops and
                  support
                </li>
                <li>60% average engagement, more than 10 x traditional EAPs</li>
                <li>
                  Proactive access and choice with no triage or referral needed
                </li>
                <li>Flexible subscription options</li>
                <li>
                  Build your wellbeing programme with aggregated and impact data
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  maxWidth: "380px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  minHeight: "100px",
                }}
              >
                <Button
                  variant="outlined"
                  className={classes.heroButton}
                  color="secondary"
                  onClick={() => setVideoOpen(true)}
                >
                  Watch our Trailer
                </Button>
                <Button
                  variant="contained"
                  className={classes.heroButton}
                  color="secondary"
                  onClick={() => props.history.push("/contact")}
                >
                  Get In Touch
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.tileGroupContainer}>
            <div className={classes.tileGroup}>
              <TileGroup
                src={<Smile />}
                title="Happiness & Productivity"
                body="Your employees will be more productive, will stay with you longer, and spend less on health care overall."
              />
              <TileGroup
                src={<People />}
                title="Reduce Absence"
                body="Employees could have up to three times fewer absentee days resulting from support in all areas of their life"
              />
              <TileGroup
                src={<Analytics />}
                title="Outcome Transparency"
                body="Anonymised analytics data will inform you of wellbeing trends in your organisation to drive more empathetic and informed decisions."
              />
              <TileGroup
                src={<World />}
                title="Social Responsibility"
                body="By supporting a social enterprise like Ignite, you will support your own goals around social responsibility as you modernise your own response to health, safety and wellbeing."
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="padded-section">
        <Container maxWidth="xl" className={classes.carouselMainContainer}>
          <TestimonialCarousel list={testimonials} />
        </Container>
      </section>
    </div>
  );
}

export default withRouter(LandingPage);
