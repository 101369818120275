import { createActions } from "re-reduced";

import { ContentState, ContentSearchPayload } from "./types";

export const ContentActions = createActions("CONTENT", create => ({
  fetchContentSearchResults: create.asyncAction<
    ContentState,
    ContentSearchPayload
  >()
}));
