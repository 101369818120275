import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as selectors from "domain/core/selector";
import { useReduxState } from "re-reduced";

import {
  Route,
  RouteComponentProps,
  withRouter,
  Switch,
  Redirect,
} from "react-router";
import { ROUTES } from "./routes";

import Content from "./Content";

import Appbar from "ui/compounds/Appbar";

import Home from "./Home";
import CrisisPage from "./Crisis";
import Book from "./Book";
import Search from "./Search";
import ContactPage from "./Contact";
import Footer from "./Footer";
import IgniteTagManager from "lib/analytics";
import UnAuthed from "./UnAuthed";
import Assessment from "./Assessment";
import Prioritise from "./Prioritise";
import Dashboard from "./Dashboard";
import AssessmentResults from "./AssessmentResults";
import AccountPage from "./Account";
import Appointments from "./Appointments";
import Error404 from "./Error404";
import AboutUs from "./AboutUs";
import OurApproach from "./OurApproach";
import LandingPage from "./LandingPage";
import Workshops from "./Workshops";
import Workshop from "./Workshop";
import DelayRedirect from "ui/components/DelayRedirect";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    maxWidth: "1920px",
    margin: "auto",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    overscrollBehavior: "none", // disables pull-to-refresh
  },
}));

function Main({ history }: RouteComponentProps) {
  const classes = useStyles();

  const state = useReduxState({ ...selectors });
  const [lastLocation, setLastLocation] = React.useState("/dashboard");

  const allowed_last_locations = [
    ROUTES.search.toString(),
    ROUTES.book.toString(),
    ROUTES.appointments.toString(),
    ROUTES.dashboard.toString(),
    ROUTES.prioritise.toString(),
    ROUTES.assessment.toString(),
    ROUTES.assessmentResults.toString(),
  ];

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history, lastLocation]);

  const logCompanyParamsInUrl = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const companyParam = urlParams.get("company");
    if (companyParam) {
      const tagManagerArgs = {
        dataLayer: {
          event: "WebPageLoadedWithCompanyParam",
          company: companyParam,
        },
      };

      IgniteTagManager.dataLayer(tagManagerArgs);
    }
  };
  logCompanyParamsInUrl();

  return (
    <div className={classes.root}>
      <Switch>
        <Route path="/redirect/thanks">
          <Appbar changeOnScroll={false} />
          <img
            src={require(`assets/states/success/BookingSuccess.svg`)}
            alt="Success"
          />
          <DelayRedirect url="/home" delay={3000} />
          <Footer />
        </Route>
        <Route path="/error/404">
          <Appbar changeOnScroll={false} />
          <Error404 />
          <Footer />
        </Route>
        <Route
          path={"/(assessment|prioritise|assessmentResults)/"}
          render={(props) => {
            if (state.getIsAuthenticated)
              return (
                <React.Fragment>
                  <Route path={`/(assessment|prioritise)/`}>
                    <Appbar changeOnScroll={false} noShadow static />

                    <Route path={ROUTES.assessment}>
                      <Assessment />
                    </Route>
                    <Route path={ROUTES.prioritise}>
                      <Prioritise />
                    </Route>
                  </Route>

                  <Route path={ROUTES.assessmentResults}>
                    <Appbar changeOnScroll />
                    <AssessmentResults />
                    <Footer />
                  </Route>
                </React.Fragment>
              );

            if (
              allowed_last_locations.indexOf(props.location.pathname) === -1
            ) {
              setLastLocation("/dashboard");
            } else {
              setLastLocation(props.location.pathname);
            }
            return <Redirect to="/login" />;
          }}
        />

        <Route path={state.getRouteRegex}>
          <Appbar
            changeOnScroll={
              !/(book|workshop\/|appointments)/.test(history.location.pathname)
            }
          />
          <Route path={"/request-demo"}>
            <LandingPage />
          </Route>
          <Route path={ROUTES.contact}>
            <ContactPage />
          </Route>
          <Route path={ROUTES.learn}>
            <Content></Content>
          </Route>
          <Route path={ROUTES.crisis}>
            <CrisisPage />
          </Route>
          <Route path={ROUTES.home}>
            <Home />
          </Route>
          <Route path={ROUTES.aboutUs}>
            <AboutUs />
          </Route>
          <Route path={ROUTES.ourApproach}>
            <OurApproach />
          </Route>

          <Route
            path={
              "/(workshops|search|workshop|book|dashboard|account|appointments)/"
            }
            render={(props) => {
              if (state.getIsAuthenticated)
                return (
                  <React.Fragment>
                    <Route path={ROUTES.workshop}>
                      <Workshop />
                    </Route>
                    <Route path={ROUTES.workshops} exact>
                      <Workshops />
                    </Route>

                    <Route path={ROUTES.search}>
                      <Search />
                    </Route>

                    <Route path={ROUTES.book}>
                      <Book />
                    </Route>

                    <Route path={ROUTES.dashboard}>
                      <Dashboard />
                    </Route>

                    <Route path={ROUTES.account}>
                      <AccountPage />
                    </Route>

                    <Route path={ROUTES.appointments}>
                      <Appointments />
                    </Route>
                  </React.Fragment>
                );
              setLastLocation(props.location.pathname);
              return <Redirect to="/login" />;
            }}
          />
          <Footer />
        </Route>

        <Route path={"/(login|forgotPassword|onboarding)/"}>
          <UnAuthed lastLocation={lastLocation} />
        </Route>

        <Redirect to={ROUTES.home} />
      </Switch>
    </div>
  );
}

export default withRouter(Main);
