import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import sectionLeftGon from "assets/banner/SectionPatternLeft.svg";
import sectionRightGon from "assets/banner/SectionPatternRight.svg";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionBanner: {
      width: "100%",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      minHeight: "830px",
      background: "linear-gradient(90deg, #6020D4 0%, #7E49DD 97.4%)",
    },
    leftGon: {
      zIndex: 0,
      position: "absolute",
      left: 0,
      bottom: 0,
    },
    rightGon: {
      zIndex: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  })
);

const SectionBanner: React.FC<{ style?: CSSProperties }> = (props) => {
  const classes = useStyles();
  return (
    <div style={props.style} className={classes.sectionBanner}>
      <img alt="" className={classes.leftGon} src={sectionLeftGon} />
      <img alt="" className={classes.rightGon} src={sectionRightGon} />
      {props.children}
    </div>
  );
};

export default SectionBanner;
