import { SagaIterator } from "redux-saga";
import { put, takeLatest, all, call } from "redux-saga/effects";
import { WellbeingActions } from "./actions";
import * as api from "./api";
import { WellbeingTestPayload, WellbeingTestResult } from "./types";
import { REQUEST_STATUS } from "lib/types";
import { Action } from "re-reduced";
import { initialState } from "./reducer";
import IgniteTagManager from "lib/analytics";

export default function* sagaWatcher() {
  yield all([
    takeLatest(
      WellbeingActions.getWellbeingResults.type,
      sendTestAndGetResults
    ),
    takeLatest(WellbeingActions.fetchWellbeingResult.type, getTestResultById),
    takeLatest(WellbeingActions.saveTest.type, saveTest),
  ]);
}

export function* saveTest(action: Action<WellbeingTestPayload>): SagaIterator {
  yield put(WellbeingActions.saveTest.request());

  try {
    yield put(
      WellbeingActions.saveTest.success({
        answers: action.payload.answers,
        testResult: initialState.testResult,
        requests: {
          answersStatus: { status: REQUEST_STATUS.Fulfilled },
        },
      })
    );
  } catch (error) {
    yield put(WellbeingActions.saveTest.failure(error));
  }
}

export function* sendTestAndGetResults(
  action: Action<WellbeingTestPayload>
): SagaIterator {
  yield put(WellbeingActions.getWellbeingResults.request());
  try {
    const result: WellbeingTestResult = yield call(
      api.getWellbeingTestResults,
      action.payload
    );

    const tagManagerArgs = {
      dataLayer: {
        event: "WellbeingTestSaved",
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);

    yield put(
      WellbeingActions.getWellbeingResults.success({
        answers: action.payload.answers,
        testResult: result,
        requests: {
          resultStatus: { status: REQUEST_STATUS.Fulfilled },
        },
      })
    );
  } catch (error) {
    yield put(WellbeingActions.getWellbeingResults.failure(error));
  }
}

export function* getTestResultById(action: Action<string>): SagaIterator {
  yield put(WellbeingActions.fetchWellbeingResult.request());

  try {
    const result: WellbeingTestResult = yield call(
      api.getWellbeingPlan,
      action.payload
    );

    yield put(
      WellbeingActions.getWellbeingResults.success({
        answers: [],
        testResult: result,
        requests: {
          resultStatus: { status: REQUEST_STATUS.Fulfilled },
        },
      })
    );
  } catch (error) {
    yield put(WellbeingActions.fetchWellbeingResult.failure(error));
  }
}
