import React from "react";
import styled from "styled-components";
import { ReactComponent as Home } from "assets/domains/Home.svg";
import { ReactComponent as Habits } from "assets/domains/Habits.svg";
import { ReactComponent as Mind } from "assets/domains/Mind.svg";
import { ReactComponent as Body } from "assets/domains/Body.svg";
import { ReactComponent as Relationships } from "assets/domains/Relationships.svg";
import { ReactComponent as Occupation } from "assets/domains/Occupation.svg";
import { ReactComponent as Finances } from "assets/domains/Finances.svg";
import { ReactComponent as Recreation } from "assets/domains/Recreation.svg";
import { ReactComponent as Identity } from "assets/domains/Identity.svg";
import { ReactComponent as Beliefs } from "assets/domains/Beliefs.svg";
import { ReactComponent as Culture } from "assets/domains/Culture.svg";
import { ReactComponent as Connectedness } from "assets/domains/Connectedness.svg";
import { domainToColor, itemToDomainKey } from "domain/core/app/utils";
import { keys } from "domain/core/app/utils";
import { DomainKey } from "domain/core/app/types";
import { breakpointValues } from "theme";
import { ReactComponent as COVID19 } from "assets/domains/Coronavirus.svg";
import { ReactComponent as COVID19WHITE } from "assets/domains/CoronavirusWhite.svg";
import { ReactComponent as Wellplace } from "assets/domains/Wellplace.svg";

interface DomainIconProps {
  domain: string;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  borderless?: boolean;
}

const DomainWrapper = styled.div<{ color: string; size: number }>`
  display: flex;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};

  border-radius: 200px;
  margin: auto 9px auto 9px;

  background-color: ${(props) => props.color};
  & * > + *: {
    margin: auto;
  }
`;

const LargeDomainWrapper = styled.div<{ color: string; size: number }>`
  display: flex;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};

  @media (max-width: ${breakpointValues.sm}px) {
    width: 40px;
    height: 40px;
  }

  border-radius: 200px;
  margin: auto 9px auto 9px;

  background-color: ${(props) => props.color};
  & * > + *: {
    margin: auto;
  }
`;

interface IDictionary {
  [index: string]: string;
}

//eslint-disable-next-line
const icons = {
  Mind: "Mind",
  Body: "Body",
  Habits: "Habits",
  Home: "Home",
  Relationships: "Relationships",
  Occupation: "Occupation",
  Finances: "Finances",
  Recreation: "Recreation",
  Identity: "Identity",
  Beliefs: "Beliefs",
  Culture: "Culture",
  Connectedness: "Connectedness",
} as IDictionary;

function getStyle(color: string, size: number) {
  return {
    stroke: color,
    strokeWidth: "0",
    margin: "auto",
    width: size,
    height: size,
  };
}

export function getInner(icon: string, color: string, size: number) {
  switch (icon) {
    case "Home":
      return <Home style={getStyle(color, size)} />;
    case "Body":
      return <Body style={getStyle(color, size)} />;
    case "Habits":
      return <Habits style={getStyle(color, size)} />;
    case "Mind":
      return <Mind style={getStyle(color, size)} />;
    case "Relationships":
      return <Relationships style={getStyle(color, size)} />;
    case "Occupation":
      return <Occupation style={getStyle(color, size)} />;
    case "Finances":
      return <Finances style={getStyle(color, size)} />;
    case "Recreation":
      return <Recreation style={getStyle(color, size)} />;
    case "Identity":
      return <Identity style={getStyle(color, size)} />;
    case "Beliefs":
      return <Beliefs style={getStyle(color, size)} />;
    case "Culture":
      return <Culture style={getStyle(color, size)} />;
    case "Connectedness":
      return <Connectedness style={getStyle(color, size)} />;
    case "COVID-19":
      return <COVID19 style={getStyle("FF7A00", size)} />;
    case "COVID-19-WHITE":
      return <COVID19WHITE style={getStyle("white", size)} />;
    case "Wellplace.nz":
      return <Wellplace style={getStyle("1FA6CE", size)} />;
    default:
      return <div></div>;
  }
}

const DomainIcon = (props: DomainIconProps) => {
  function renderDomainIcon() {
    let domainKey: DomainKey = props.domain as DomainKey;
    if (keys.indexOf(domainKey) === -1) {
      domainKey = itemToDomainKey(props.domain);
    }

    const color = domainToColor[domainKey];
    let size = 30;

    if (props.size === "xl") {
      size = 60;
      return (
        <LargeDomainWrapper
          color={"transparent"}
          size={size}
          className={props.className}
        >
          {getInner(props.domain, color.dark, 60)}
        </LargeDomainWrapper>
      );
    }

    return (
      <DomainWrapper
        color={"transparent"}
        size={size}
        className={props.className}
      >
        {getInner(props.domain, color.dark, size)}
      </DomainWrapper>
    );
  }

  return renderDomainIcon();
};

export default DomainIcon;
