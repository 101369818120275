import { SagaIterator } from "redux-saga";
import { put, takeLatest, call, all } from "redux-saga/effects";
import { DashboardActions } from "./actions";
import * as api from "./api";
import { Action } from "re-reduced";

import { TransformedWellbeing, MoodDiaryResponse, DateAndMood } from "./types";
import {
  AggregatedAppoinments,
  GenericAppointment,
} from "domain/providers/types";
import { ContentProfile } from "domain/content/types";
import AuthActions from "domain/core/auth/actions";

export default function* sagaWatcher() {
  yield all([
    takeLatest(
      DashboardActions.getWellbeingOverviewList.type,
      getWellbeingOverviewList
    ),
    takeLatest(DashboardActions.getAppointments.type, getAppointments),
    takeLatest(DashboardActions.getHotContent.type, getHotContent),
    takeLatest(DashboardActions.getMoodList.type, getMoodDiary),
    takeLatest(DashboardActions.setMood.type, setMood),
    takeLatest(DashboardActions.cancelAppointment.type, cancelAppointment),
  ]);
}

export function* getWellbeingOverviewList(action: Action<void>): SagaIterator {
  yield put(DashboardActions.getWellbeingOverviewList.request());
  try {
    const result: TransformedWellbeing = yield call(api.fetchWellbeingOverview);

    yield put(DashboardActions.getWellbeingOverviewList.success(result));
  } catch (error) {
    yield put(DashboardActions.getWellbeingOverviewList.failure(error));
  }
}

export function* getAppointments(action: Action<void>): SagaIterator {
  yield put(DashboardActions.getAppointments.request());

  try {
    const result: AggregatedAppoinments = yield call(api.getAppointments);

    yield put(DashboardActions.getAppointments.success(result));
  } catch (error) {
    yield put(DashboardActions.getAppointments.failure(error));
  }
}

export function* getHotContent(action: Action<void>): SagaIterator {
  yield put(DashboardActions.getHotContent.request());

  try {
    const result: ContentProfile = yield call(api.getHotContent);

    yield put(DashboardActions.getHotContent.success(result));
  } catch (error) {
    yield put(DashboardActions.getHotContent.failure(error));
  }
}

export function* getMoodDiary(action: Action<void>): SagaIterator {
  yield put(DashboardActions.getMoodList.request());

  const result: MoodDiaryResponse = yield call(api.getMoodList);

  try {
    yield put(DashboardActions.getMoodList.success(result));
  } catch (error) {
    yield put(DashboardActions.getMoodList.failure(error));
  }
}

export function* cancelAppointment(
  action: Action<GenericAppointment>
): SagaIterator {
  yield put(DashboardActions.cancelAppointment.request());

  try {
    const result: number = yield call(api.cancelAppointment, {
      id: action.payload.id,
      date: action.payload.date,
    });
    yield put(AuthActions.updateBalance(result));
    yield put(DashboardActions.cancelAppointment.success(action.payload));
  } catch (error) {
    yield put(DashboardActions.cancelAppointment.failure(error));
  }
}

export function* setMood(action: Action<DateAndMood>): SagaIterator {
  yield put(DashboardActions.setMood.request());

  try {
    yield call(api.setMood, action.payload);
    yield put(DashboardActions.setMood.success(action.payload));
  } catch (error) {
    yield put(DashboardActions.setMood.failure(error));
  }
}
