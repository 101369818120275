import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useActions, useReduxState } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import { getLoginRequest } from "domain/core/auth/selectors";
import { useUnAuthedStyles } from "ui/pages/UnAuthed";
import FormInput from "ui/components/Input";
import { Link } from "@material-ui/core";

interface Props extends RouteComponentProps {}

function Login(props: Props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const classes = useUnAuthedStyles();
  const actions = useActions(AuthActions);
  const { login } = useReduxState({ login: getLoginRequest });
  const [passwordInvalid, setPasswordInvalid] = React.useState<boolean>(false);
  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const submitLogin = (event: React.ChangeEvent<EventTarget>) => {
    let failedPreValidation = false;
    if (!validEmail()) {
      failedPreValidation = true;
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
    if (!validPassword()) {
      failedPreValidation = true;
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
    if (!failedPreValidation && login.status !== "Pending") {
      actions.login({ username: email, password: password });
    }
  };

  const validPassword = () => {
    return password.length >= 6;
  };

  const badRequest = React.useMemo(
    () => login.error !== undefined && login.error.status === 400,
    [login]
  );

  useEffect(() => {
    if (login.status === "Fulfilled") {
      props.history.push("/onboarding");
    }
  }, [login, props.history]);

  const validEmail = () => {
    var re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  function handleKeydownPassword(
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    if (e.keyCode === 13) {
      e.currentTarget.blur();
      actions.login({ username: email, password: password });
    }
  }

  function getEmailError() {
    return (
      emailInvalid || login.status === "Failed" || login.status === "Fulfilled"
    );
  }

  function getEmailErrorMsg() {
    if (!validEmail()) {
      if (email.length === 0) {
        return "Please enter your email";
      }
      if (email.indexOf("@") === -1) {
        return "An email must contain a single @";
      }
      if (email.indexOf("@") === 0) {
        return "An email cannot start with @";
      }
      if (email.indexOf(".") === -1) {
        return "An email must contain a single '.' e.g '.com'";
      }
    }
    if (badRequest) {
      return "Please enter your email";
    } else if (login.status === "Failed") {
      return "Wrong email or password";
    } else if (login.status === "Fulfilled") {
      return "You should be logged in, but aren't? hmmm";
    } else {
      return " ";
    }
  }

  function getPasswordError() {
    return badRequest || passwordInvalid || login.status === "Failed";
  }

  function getPasswordErrorMsg() {
    if (passwordInvalid) {
      return "Please enter your password";
    }
    return undefined;
  }

  return (
    <div className={classes.loginFormWrapper} id="fade-in-form">
      <form className={classes.loginForm}>
        <Typography variant="h2">Kia Ora!</Typography>
        <Typography variant="body1" align="center">
          Please sign in below
        </Typography>
        <FormInput
          label="Email Address"
          value={email}
          onChange={handleChangeEmail}
          error={getEmailError()}
          errorMsg={getEmailErrorMsg()}
          id="component-email"
          autoComplete="current-email"
        />
        <FormInput
          label="Password"
          value={password}
          type="password"
          error={getPasswordError()}
          errorMsg={getPasswordErrorMsg()}
          onChange={handleChangePassword}
          onKeyDown={handleKeydownPassword}
          id="component-password"
          autoComplete="current-password"
        >
          <Link
            className={classes.forgotPassword}
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password?
          </Link>
        </FormInput>

        <Button variant="contained" color="secondary" onClick={submitLogin}>
          {login.status === "Pending" ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            "Sign In"
          )}
        </Button>
      </form>
    </div>
  );
}

export default withRouter(Login);
