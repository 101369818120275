import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducers from "domain/reducer";
import rootSaga from "./middleware/sagas";
import { persistStore } from "redux-persist";
import { PERSIST_KEY } from "./configuration";

const sagaMiddleware = createSagaMiddleware();

const middlewareStack = [sagaMiddleware];
const withMiddleware = applyMiddleware(...middlewareStack);
export const store = createStore(rootReducers, withMiddleware);
export const persistor = persistStore(store);

window.addEventListener("storage", (e: StorageEvent) => {
  if (e.key === `persist:@user:${PERSIST_KEY}:core/auth`) {
    if (e.oldValue !== e.newValue) {
      document.location.reload();
    }
  }
});

sagaMiddleware.run(rootSaga);
