import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { getBookingStatus } from "domain/providers/reducer";
import { getTokenBalance } from "domain/core/auth/selectors";
import { useReduxState } from "re-reduced";
import Tokens from "ui/components/Tokens";
import CloseToken from "assets/booking/Close.svg";
import SuccessImg from "assets/states/success/BookingSuccess.svg";
import ErrorImg from "assets/states/success/BookingError.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "580px",
      minHeight: "320px",

      textAlign: "center",
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 16px) !important",
        height: "auto !important",

        "& * h4": {
          fontSize: "30px",
        },
      },
    },

    rootContainer: {
      margin: theme.spacing(2, 2, 2, 2),
    },
    container: {
      margin: "0px",
    },
    imageItem: {
      paddingBottom: "0px !important",
      maxWidth: "calc(100% - 60px)",
      "& img": {
        width: "100%",
      },
    },
    closeButton: {
      marginRight: "0px",
      marginLeft: "auto",
      width: "30px",
      height: "30px",
      display: "flex",

      "& img": {
        margin: "auto",
      },
    },
    loader: {
      margin: "auto auto !important",
    },
    bold: {
      fontFamily: "Gilroy Bold",
    },
  })
);

interface Props {
  email: string;
  providerName?: string;
  error?: boolean;
  apiStatus?: string;
  variant: "workshop" | "provider";
  dismissModal(): void;
}
const BookingSuccess = React.forwardRef(
  ({ variant, email, dismissModal, providerName, apiStatus }: Props, ref) => {
    const classes = useStyles();

    const { bookingStatus, bookingBalance } = useReduxState({
      bookingStatus: getBookingStatus,
      bookingBalance: getTokenBalance,
    });

    const status = React.useMemo<string>(() => {
      return apiStatus ? apiStatus : bookingStatus.status;
    }, [apiStatus, bookingStatus]);

    const loading = React.useMemo<boolean>(() => {
      return status === "Pending";
    }, [status]);

    const error = React.useMemo<boolean>(() => {
      return status === "Failed";
    }, [status]);

    const success = React.useMemo<boolean>(() => {
      return status === "Fulfilled";
    }, [status]);

    function getImage() {
      if (error) {
        return (
          <img
            src={ErrorImg}
            alt="Error"
          />
        );
      } else if (success) {
        return (
          <img
            src={SuccessImg}
            alt="Success"
          />
        );
      } else if (loading) {
        return <CircularIndeterminate className={classes.loader} />;
      }
    }

    return (
      <Card className={classes.root}>
        <Grid
          container
          direction="row"
          className={classes.rootContainer}
          id={success ? "fade-in" : ""}
        >
          <Grid container alignContent="flex-end">
            {!loading && (
              <Grid item className={classes.closeButton} onClick={dismissModal}>
                <img  src={CloseToken} alt="close" />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            className={classes.container}
            direction="column"
            spacing={4}
            alignItems="center"
          >
            <Grid item className={classes.imageItem}>
              {getImage()}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4" id="fade-in">
                    {loading
                      ? "We are processing your booking"
                      : success
                      ? "Your booking is successful!"
                      : error
                      ? "Oops, Something Went Wrong"
                      : ""}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {loading
                      ? "We'll send you an email when it is done."
                      : success
                      ? "A booking confirmation has been sent to "
                      : error
                      ? "We weren't able to complete your booking.\n\nPlease refresh the page and try again."
                      : ""}
                    {success && <b className={classes.bold}>{email}</b>}
                  </Typography>
                </Grid>

                {success && (
                  <React.Fragment>
                    <Grid item>
                      <Typography variant="body1">
                        {variant === "provider"
                          ? `${providerName} has been notified of your booking and
                        will contact you with the details of your session using
                        your email above.`
                          : `Ignite has been notified of your booking for ${providerName} and
                          the details for your session has been emailed to you.`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tokens align="right" light>
                        Your updated balance is: {bookingBalance}
                      </Tokens>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>

            {!loading && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={dismissModal}
                >
                  Close
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default BookingSuccess;
