import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Typography, Button } from "@material-ui/core";
import { RouteComponentProps, withRouter, generatePath } from "react-router";
import { GenericAppointment } from "domain/providers/types";
import AvatarDefault from "assets/icons/AvatarDefault.svg";
const useProfileHeadingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      "& >:first-child": {
        marginRight: "15px",
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: "200px",
      },
    },

    name: {
      fontFamily: "Gilroy Bold",
      fontSize: "20px",
    },
    type: {
      fontSize: "14px",
      fontFamily: "Gilroy Regular",
    },
    avatar: {
      width: "80px",
      height: "80px",
    },
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      "& .MuiButton-label": {
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  })
);

const ProfileHeading = (
  props: GenericAppointment &
    RouteComponentProps & {
      button?: JSX.Element;
      disableButton?: boolean;
      typeOverride?: string;
      nameOverride?: string;
      justify?: "space-between" | "space-evenly" | "";
      avatarSize?: "60px" | "70px" | "80px";
      qualifications?: string[] | undefined | null;
    }
) => {
  const classes = useProfileHeadingStyles();

  function onClick() {
    if (props.variant === "provider") {
      const path = generatePath("/book/:id", { id: props.id });
      props.history.push(path);
    } else if (props.variant === "workshop") {
      const path = generatePath("/workshop/:id/:time", {
        id: props.id,
        time: props.date.toISOString(),
      });
      props.history.push(path);
    }
  }
  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        src={props.avatar}
        style={
          props.avatarSize
            ? { width: props.avatarSize, height: props.avatarSize }
            : {}
        }
      >
        <img alt="" src={AvatarDefault} style={{ width: "100%" }} />
      </Avatar>

      <div
        className={classes.infoContainer}
        style={{
          justifyContent: "space-evenly",
          zIndex: 1,
        }}
      >
        <Typography variant="h5" noWrap className={classes.name}>
          {props.nameOverride ? props.nameOverride : `${props.displayName} `}
        </Typography>
        <Typography variant="body1" noWrap>
          {props.typeOverride
            ? props.typeOverride
            : props.variant === "workshop"
            ? "Workshop"
            : props.type}
        </Typography>
        {!props.disableButton ? (
          <Button variant="text" color="secondary" onClick={onClick}>
            View More
          </Button>
        ) : props.button !== null ? (
          props.button
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(ProfileHeading);
