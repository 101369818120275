import axios, { AxiosResponse } from "axios";

import AuthActions from "domain/core/auth/actions";
import { store } from "store/configureStore";
import createClient from "./createClient";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: Number(process.env.REACT_APP_API_TIMEOUT || 15000),
});

client.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(AuthActions.logout());
    }
    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      // window.location.assign("/error/404");
    }
  }
);

/**
 * Sets token to client's Authorization header
 *
 * @param {string} token - api bearer token
 */
export function setToken(token: string) {
  client.defaults.headers = {
    Authorization: `Bearer ${token}`,
  };
}

export default createClient(client);
