import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useActions, useReduxState } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import * as selectors from "domain/core/auth/selectors";
import { AlertPopup } from "ui/pages/UnAuthed";
import FormInput from "ui/components/Input";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import WellbeingWheel from "assets/illustrations/WellbeingWheel.svg";
import SetupSuccess from "assets/states/success/SetupSuccess.svg";
import {
  validName,
  getNameErrorMsg,
  validEmail,
  validPhone,
  getEmailErrorMsg,
  getPhoneErrorMsg,
} from "utils/validation";
import Responsive from "ui/Responsive";
import { ReactComponent as IconRightArrow } from "assets/icons/Icon-Right-Arrow-White.svg";
import PrivacyPolicy from "../PrivacyPolicy";
import { ReactComponent as IconLeftArrow } from "assets/icons/Icon-Left-Arrow.svg";
import DropdownClass from "ui/components/dropdown/Dropdown";
import { MenuItem } from "@material-ui/core";
import queryString from "query-string";

interface Props extends RouteComponentProps {}

const ageGroups = [
  "Under 18",
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
];

const genderOpttions = ["Female", "Male", "Gender Diverse"];

const ethnicGroups = [
  "New Zealand European",
  "Māori",
  "Pacifika",
  "European",
  "Asian",
  "MELAA (Middle Easter/Latin American/African",
  "Other Ethnicity",
];

function getEthnicOptions() {
  return ethnicGroups.map((item) => {
    return (
      <MenuItem value={item} key={item}>
        <span>{item.toString()}</span>
      </MenuItem>
    );
  });
}

function getGenderOptions() {
  return genderOpttions.map((item) => {
    return (
      <MenuItem value={item} key={item}>
        <span>{item.toString()}</span>
      </MenuItem>
    );
  });
}

function getAgeOptions() {
  return ageGroups.map((item) => {
    return (
      <MenuItem value={item} key={item}>
        <span>{item.toString()}</span>
      </MenuItem>
    );
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 80px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },

    formControl: {
      width: "100%",
      marginBottom: "15px",
      minHeight: 60,
      marginTop: "4px",
      minWidth: "320px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "100%",
      },
    },

    loadingContainer: {
      width: "100%",

      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",

      "& > *": {
        margin: "auto",
        width: "calc(90vw)",
        maxWidth: "400px",
        marginBottom: "0px",
      },
      "& h5": {
        marginTop: "30px",
      },

      "& >:last-child": {
        marginTop: "40px",
      },
    },
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "40px",
      [theme.breakpoints.down("md")]: {
        marginTop: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
      },
    },

    policyContainer: {
      overflow: "scroll",
      maxWidth: "800px",
      maxHeight: "800px",
      width: "100%",
      margin: "auto",
    },

    wellbeingWheel: {
      width: "calc(90vw)",
      maxWidth: "450px",
      margin: "0px auto",
    },

    successImg: {
      width: "calc(90vw)",
      maxWidth: "450px",
      display: "flex",
    },

    welcomeContainer: {
      justifyContent: "center",

      "& >:first-child": {
        margin: "0px auto",
        maxWidth: "450px",
      },

      "& > *": {
        margin: "10px auto",
        maxWidth: "600px",

        "@media( max-width: 640px)": {
          maxWidth: "calc(100vw - 40px)",
        },
      },

      "& >:last-child": {
        marginTop: "40px",
      },
      "& button": {
        [theme.breakpoints.down("xs")]: {
          marginBottom: "20px",
          marginTop: "auto !important",
        },
      },

      [theme.breakpoints.down("md")]: {
        "& h2": {
          ...theme.typography.h3,
        },
      },

      [theme.breakpoints.down("sm")]: {
        "& h2": {
          ...theme.typography.h4,
        },
      },

      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
        marginTop: "0px",
        "& h2": {
          ...theme.typography.h5,
        },
      },
    },

    steps: {
      maxWidth: "800px",
      width: "100%",
      margin: "0px auto",
      display: "flex",
      position: "relative",
      "& > *": {
        margin: "auto",
      },
      "& button": {
        position: "absolute",
        left: "calc(50px)",

        "@media(max-width: 450px)": {
          left: "calc(20px)",
        },
        top: "0px",
      },
    },
    contactForm: {
      width: "100%",
      maxWidth: "400px",

      position: "relative",
      margin: "15px auto 25px auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",

      "& >:first-child": {
        margin: "20px auto 0px auto",
      },
      [theme.breakpoints.up("sm")]: {
        "& >:first-child": {
          margin: "20px auto 20px auto",
        },
      },

      "& button": {
        alignSelf: "center",
        marginTop: "auto",
        marginBottom: "0px",
      },

      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(80vw)",
        margin: "30px auto",
        height: "100%",

        "& >:first-child": {
          margin: "0px auto",
        },

        "& >:nth-child(2)": {
          margin: "10px auto",
        },
      },
    },

    nameForm: {
      width: "100%",
      maxWidth: "400px",

      position: "relative",
      margin: "25px auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",

      "& button": {
        alignSelf: "center",
        marginTop: "auto",
        marginBottom: "0px",
      },

      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(80vw)",
        margin: "30px auto",
        height: "100%",
      },
    },

    nameFormTitle: {
      margin: "15px auto 25px auto",
      [theme.breakpoints.down("xs")]: {
        margin: "30px auto 30px auto",
      },
    },

    fieldContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    loader: {
      margin: "auto",
    },
  })
);

const EthnicDropdown = new DropdownClass<string>(
  "Ethnicity (Optional)",
  false,
  "",
  getEthnicOptions,
  ethnicGroups
);
const GenderDropdown = new DropdownClass<string>(
  "Gender (Optional)",
  false,
  "",
  getGenderOptions,
  genderOpttions
);
const AgeDropdown = new DropdownClass<string>(
  "Age (Optional)",
  false,
  "",
  getAgeOptions,
  ageGroups
);

function OnBoarding(props: Props) {
  const classes = useStyles();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [firstNameInvalid, setFirstNameInvalid] = React.useState<boolean>(
    false
  );
  const [lastNameInvalid, setLastNameInvalid] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false);
  const [phoneInvalid, setPhoneInvalid] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const getFirstNameError = () => {
    return firstNameInvalid && !validName(firstName);
  };

  const getLastNameError = () => {
    return lastNameInvalid && !validName(lastName);
  };

  const getEmailError = () => {
    return emailInvalid && !validEmail(email);
  };

  const getPhoneError = () => {
    return phoneInvalid && !validPhone(phone);
  };

  const actions = useActions(AuthActions);
  const reduxState = useReduxState({ ...selectors });
  function getUrlParams(): URLSearchParams {
    if (!props.history.location.search) return new URLSearchParams();
    return new URLSearchParams(props.history.location.search);
  }
  function getSearchQuery() {
    const search = getUrlParams();
    const step = parseInt(search.get("step") || "0");
    return step;
  }
  const [step, setStep] = React.useState(getSearchQuery());

  function updateStep(_step: number) {
    if (_step === 2) {
      EthnicDropdown.value = "";
      GenderDropdown.value = "";
      AgeDropdown.value = "";
    }
    props.history.push({
      pathname: "/onboarding",
      search: queryString.stringify({ step: _step }),
    });
  }

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const next = () => {
    if (step >= 3) {
      props.history.push("/dashboard");
    } else {
      // setStep(step + 1);
      updateStep(step + 1);
    }
  };

  useEffect(() => {
    setStep(getSearchQuery());
    AgeDropdown.className = classes.formControl;
    EthnicDropdown.className = classes.formControl;
    GenderDropdown.className = classes.formControl;
    //eslint-disable-next-line
  }, [props.history.location.search]);

  function getWelcome() {
    return (
      <div
        className={`${classes.container} ${classes.welcomeContainer}`}
        id="fade-in-form"
      >
        <img alt="" src={WellbeingWheel} className={classes.wellbeingWheel} />
        <Typography variant="h2" align="center">
          Welcome to Ignite!
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textPrimary"
          style={{ textTransform: "none" }}
        >
          You are signing in as {reduxState.getEmail}
        </Typography>
        <Typography variant="body1" align="center">
          Let’s set up your profile so that you could get the most out of the
          Ignite platform. It will take around 1 minute.
        </Typography>
        <Button variant="contained" color="secondary" onClick={() => next()}>
          Get Started
        </Button>
      </div>
    );
  }

  function getLoadingScreen() {
    return (
      <div className={classes.loadingContainer} id="fade-in-form">
        <img alt="" src={SetupSuccess} className={classes.successImg} />

        <Typography variant="h5" align="center">
          {reduxState.accountSignupInfo.status === "Pending"
            ? "You are all set up! Just give us a second to load everything for you..."
            : "You're all set up!"}
        </Typography>

        {reduxState.accountSignupInfo.status === "Pending" ? (
          <CircularProgress
            size={64}
            color="primary"
            className={classes.loader}
          />
        ) : (
          <Button variant="contained" color="secondary" onClick={() => next()}>
            Finish
          </Button>
        )}
      </div>
    );
  }

  function onKeyDownLastName(
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    if (e.keyCode === 13) {
      e.currentTarget.blur();
      validateNames();
    }
  }

  function validateNames() {
    let failedPreValidation = false;

    if (!validName(firstName)) {
      failedPreValidation = true;
      setFirstNameInvalid(true);
    } else {
      setFirstNameInvalid(false);
    }

    if (!validName(lastName)) {
      failedPreValidation = true;
      setLastNameInvalid(true);
    } else {
      setLastNameInvalid(false);
    }

    if (!failedPreValidation) {
      next();
    }
  }

  function validateComms() {
    let failedPreValidation = false;

    if (!validEmail(email) && email.length > 0) {
      failedPreValidation = true;
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }

    if (!validPhone(phone) && phone.length > 0) {
      failedPreValidation = true;
      setPhoneInvalid(true);
    } else {
      setPhoneInvalid(false);
    }

    if (!failedPreValidation) {
      actions.updateSignupInfo({
        firstName,
        lastName,
        secondaryEmail: email,
        phone,
        ageGroup:
          ageGroups.indexOf(AgeDropdown.value) !== -1 ? AgeDropdown.value : "",
        ethnicity:
          ethnicGroups.indexOf(EthnicDropdown.value) !== -1
            ? EthnicDropdown.value
            : "",
        gender:
          genderOpttions.indexOf(GenderDropdown.value) !== -1
            ? GenderDropdown.value
            : "",
      });
      next();
    }
  }

  const onFocus = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.currentTarget.scrollIntoView();
  };

  const onBlur = (event: any, callback: Function) => {
    const val = event.target.value.trim();
    callback(val);
  };

  function getNameFields() {
    return (
      <div className={`${classes.container} `} id="fade-in-form">
        <div className={classes.steps}>
          <Button
            startIcon={<IconLeftArrow />}
            color="secondary"
            variant="text"
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>

          <Typography variant="subtitle1" align="center">
            step 2 of 3
          </Typography>
        </div>
        <div className={classes.nameForm}>
          <Typography
            variant="h5"
            align="center"
            className={classes.nameFormTitle}
          >
            Your details
          </Typography>

          <form className={classes.fieldContainer}>
            <FormInput
              label="First Name"
              required
              className={classes.formControl}
              value={firstName}
              onChange={handleFirstNameChange}
              error={getFirstNameError()}
              errorMsg={getNameErrorMsg(firstName)}
              onFocus={onFocus}
              onBlur={(e) => onBlur(e, setFirstName)}
              id="component-first-name"
              autoComplete="current-first-name"
            />

            <FormInput
              label="Last Name"
              className={classes.formControl}
              value={lastName}
              required
              onChange={handleLastNameChange}
              error={getLastNameError()}
              errorMsg={getNameErrorMsg(lastName)}
              onKeyDown={onKeyDownLastName}
              onFocus={onFocus}
              onBlur={(e) => onBlur(e, setLastName)}
              id="component-last-name"
              autoComplete="current-last-name"
            ></FormInput>

            {AgeDropdown.render()}
            {GenderDropdown.render()}
            {EthnicDropdown.render()}
          </form>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => validateNames()}
            endIcon={<IconRightArrow style={{ paddingRight: 8 }} />}
            style={{ paddingLeft: 5, paddingRight: 26, width: "195px" }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
  function getCommsFields() {
    return (
      <div className={`${classes.container} `} id="fade-in-form">
        <div className={classes.steps}>
          <Button
            startIcon={<IconLeftArrow />}
            color="secondary"
            variant="text"
            onClick={back}
          >
            Back
          </Button>

          <Typography variant="subtitle1" align="center">
            step 3 of 3
          </Typography>
        </div>

        <div className={classes.contactForm}>
          <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
            <Typography variant="h5" align="center">
              How Would You Like To Get In Touch With Support Providers?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              gutterBottom
              style={{ marginBottom: "24px" }}
            >
              You can choose your personal email and phone number to communicate
              with providers. This would help you <b />
              <b>keep your information more private.</b>
            </Typography>
          </Responsive>

          <Responsive displayIn={["MobileXS"]}>
            <AlertPopup
              title="Your personal contact details"
              message={
                <React.Fragment>
                  You can choose your personal email and phone number to
                  communicate with providers. <br />
                  <b>This will help you keep your information more private.</b>
                </React.Fragment>
              }
              open={openModal}
              onDismiss={() => setOpenModal(false)}
            />
            <Typography variant="h6" align="center">
              How Would You Like To Get In Touch With Support Providers?
            </Typography>

            <Typography
              variant="body1"
              style={{ textDecoration: "underline" }}
              onClick={() => setOpenModal(true)}
            >
              What is this for?
            </Typography>
          </Responsive>

          <form className={classes.fieldContainer}>
            <FormInput
              alwaysExpanded
              label="Personal Email (Optional)"
              className={classes.formControl}
              value={email}
              onChange={handleEmailChange}
              onFocus={onFocus}
              onBlur={(e) => onBlur(e, setEmail)}
              error={getEmailError()}
              errorMsg={getEmailErrorMsg(email)}
              id="component-email"
              autoComplete="current-email"
            />

            <FormInput
              alwaysExpanded
              label="Phone Number (Optional)"
              className={classes.formControl}
              value={phone}
              onChange={handlePhoneChange}
              error={getPhoneError()}
              onFocus={onFocus}
              onBlur={(e) => onBlur(e, setPhone)}
              errorMsg={getPhoneErrorMsg(phone)}
              type="tel"
              id="component-phone"
              autoComplete="current-phone"
            ></FormInput>
          </form>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => validateComms()}
          >
            Complete My Profile
          </Button>
        </div>
      </div>
    );
  }
  const back = () => {
    if (step <= 0) {
      setStep(0);
    } else {
      updateStep(step - 1);
      // setStep(step - 1);
    }
  };

  function getTerms() {
    return (
      <div id="fade-in" className={classes.container}>
        <div
          className={classes.steps}
          style={{
            boxShadow: "0px 4px 12px 16px white",
            zIndex: 9,
          }}
        >
          <Button
            startIcon={<IconLeftArrow />}
            color="secondary"
            variant="text"
            onClick={back}
          >
            Back
          </Button>

          <Typography variant="subtitle1" align="center">
            step 1 of 3
          </Typography>
        </div>

        <div className={classes.policyContainer}>
          <PrivacyPolicy
            innerOnly
            acceptable
            buttonProps={{
              endIcon: <IconRightArrow />,
              style: { paddingLeft: 5, paddingRight: 26, width: "195px" },
            }}
            buttonText={"Accept & Continue"}
            onClose={() => {
              next();
            }}
          />
        </div>
      </div>
    );
  }
  function getStep() {
    switch (step) {
      case 0:
        return getWelcome();
      case 1:
        return getTerms();
      case 2:
        return getNameFields();
      case 3:
        return getCommsFields();
      case 4:
        return getLoadingScreen();

      default:
        return getWelcome();
    }
  }

  return <div className={classes.root}>{getStep()}</div>;
}

export default withRouter(OnBoarding);
