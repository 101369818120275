import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import Banner from "ui/components/Banner";

import { Container } from "@material-ui/core";
import Quote from "assets/about/Quote.png";
import Connected from "assets/about/Connected.svg";
import Hand from "assets/about/Hand.svg";
import People from "assets/about/People.svg";
import Beliefs from "assets/about/Beliefs.svg";
import SectionBanner from "ui/compounds/SectionBanner";
import RequestDemoCard from "ui/compounds/RequestDemoCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    section1MainContainer: {},
    ourStoryContainer: {
      display: "flex",
      flexDirection: "row",
      // flexWrap: "wrap",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    ourStoryContentContainer: {
      alignSelf: "left",
      width: "50%",
      maxWidth: "500px",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        width: "100%",
        "& *": {
          textAlign: "center",
        },
      },
    },
    ourStoryVideoContainer: {
      position: "relative",
      display: "flex",
      alignSelf: "right",
      maxWidth: "50%",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "40px",
        maxWidth: "100%",
      },
    },
    thumbnailContainer: {
      display: "flex",
    },
    videoThumbnail: {
      alignSelf: "center",
      width: "100%",
      height: "auto",
      borderRadius: "8px",

      [theme.breakpoints.down("sm")]: {
        maxWiidth: "unset",
      },
    },
    videoPlayButton: {
      opacity: 0.5,
      cursor: "pointer",
      position: "absolute",
      height: "100px",
      width: "100px",
      left: "50%",
      marginLeft: "-50px",
      top: "50%",
      marginTop: "-50px",

      "&:hover": {
        transform: "scale(1.1)",
        opacity: 1,
      },

      transition: "all 1s ease",
    },
    ourValuesContainer: {
      display: "flex",
      flexDirection: "column",
    },
    tileGroupContainer: {
      display: "block",
      width: "100%",
      height: "100%",
      paddingTop: "50px",
    },
    tileGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    tileItem: {
      display: "flex",
      flexDirection: "column",
      minHeight: "200px",
      maxWidth: "40%",
      padding: "20px 10px",

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
    tileImage: {
      display: "block",
      width: "auto",
      float: "left",
    },
    tileTitle: {
      marginTop: "10px",
      marginBottom: "8px",
    },
    tileBody: {
      marginTop: "0px",
    },

    leadershipContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    leadershipProfileContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "20px",
    },
    profileContainer: {
      height: "580px",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      maxWidth: "310px",
      width: "auto",
      justifyContent: "flex-start",
      padding: "20px",

      [theme.breakpoints.down("xs")]: {
        maxWidth: "300px",
        width: "100%",
      },
    },
    profileImageContainer: {},
    profileImage: {
      width: "100%",
    },
    profileName: {
      marginTop: "20px",
    },
    profileRole: {
      color: theme.palette.primary.main,
    },

    profileBody: {
      marginTop: "8px",
    },
  })
);

const AboutUs: React.FC = () => {
  const classes = useStyles();

  const TileGroup: React.FC<{ title: string; src: string; body: string }> = (
    props
  ) => {
    return (
      <div className={classes.tileItem}>
        <div style={{ display: "blocK" }}>
          <img alt="" src={props.src} className={classes.tileImage} />
        </div>
        <Typography variant="h6" className={classes.tileTitle}>
          {props.title}
        </Typography>
        <Typography variant="body1" className={classes.tileBody}>
          {props.body}
        </Typography>
      </div>
    );
  };

  return (
    <div id="page" className={classes.root}>
      <Banner variant="primary">
        <div>Igniting Community Wellbeing</div>
        <p>
          We exist to create connections and opportunities that strengthen
          wellbeing. A world where everyone has the opportunity to realise their
          potential.
        </p>
      </Banner>
      <section className="padded-section white" id="story">
        <Container maxWidth="xl" className={classes.section1MainContainer}>
          <div className={classes.ourStoryContainer}>
            <div className={classes.ourStoryContentContainer}>
              <Typography variant="h3" gutterBottom>
                Our Story
              </Typography>
              <Typography variant="body1">
                Ignite Aotearoa is a wellbeing social enterprise, backed by
                Emerge Aotearoa - one of the largest independent mental health
                and social service organisations in New Zealand.
                <br />
                <br />
                Our kaupapa meets a vital need for mental-health and wellbeing
                offerings that are evidence-based, fast and easy to access,
                provide flexible options and cater to a range of budgets.
                Ignite’s solutions include education, a digital wellbeing
                platform, and specialised research and evaluation services.
                <br />
                <br />
                Currently, our focus is partnering with workplaces to enhance
                employee wellbeing. Over time we will work alongside other
                groups to deliver next-generation wellbeing solutions, including
                health providers, schools, iwi and whānau.
              </Typography>
            </div>
            <div className={classes.ourStoryVideoContainer}>
              <img className={classes.videoThumbnail} src={Quote} alt="" />
            </div>
          </div>
        </Container>
      </section>

      <section className="padded-section" id="values">
        <Container maxWidth="xl">
          <div className={classes.ourValuesContainer}>
            <Typography variant="h3">Our Values</Typography>
            <div className={classes.tileGroupContainer}>
              <div className={classes.tileGroup}>
                <TileGroup
                  title="Connecting with purpose - Whakawhanaunga"
                  body="We connect communities to effective resources and support. We consider our relationships with businesses, communities, iwi and whānau taonga."
                  src={Connected}
                />

                <TileGroup
                  title="Acting with integrity - Whakamana"
                  body="Our offerings are evidence-based and draw on personal experience and best-practice. We do what we say we will do."
                  src={Beliefs}
                />

                <TileGroup
                  title="Engaging with respect - Manaakitanga"
                  body="Everyone is welcome, our doors are open and we don't judge. We build people up and ensure every interaction is a positive one."
                  src={Hand}
                />

                <TileGroup
                  title="Walking and learning together - Ako"
                  body="We believe every experience is an opportunity to learn and share our knowledge. Collaboration comes first and continuous improvement matters."
                  src={People}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <SectionBanner style={{ minHeight: "0px", marginBottom: "-40px" }}>
        <section className="padded-section">
          <RequestDemoCard />
        </section>
      </SectionBanner>
    </div>
  );
};

export default AboutUs;
