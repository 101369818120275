import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Main from "./pages/Main";
import { Helmet } from "react-helmet";

const App: React.FC = () => {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ignite Aotearoa - Ignite Your People's Wellbeing</title>
        <meta
          name="description"
          content="Ignite Wellbeing is a modern online platform that gives people power to access their choice of support anywhere in NZ. Wellbeing support & mental health specialists. Request demo. "
        />
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>
      </Helmet>
      <Main />
    </Router>
  );
};

export default App;
