import React, { useEffect } from "react";

import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { useReduxState, useActions } from "re-reduced";

import Button from "@material-ui/core/Button";
import CardHeading from "ui/components/dashboard/CardHeading";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { BookingDetails } from "ui/compounds/providers/BookingConfirmation";
import { ReactComponent as EmptyCalendar } from "assets/calendar/EmptyCalendar.svg";
import Grid from "@material-ui/core/Grid";
import ProfileHeading from "ui/components/dashboard/ProfileHeading";
import IgniteToken from "assets/IgniteToken.svg";
import {
  getAllFutureAppointments,
  getAllPastAppointments,
  getAppointmentStatus,
  getNextGenericAppointment,
} from "domain/dashboard/reducer";
import { getProfile, getTokenBalance } from "domain/core/auth/selectors";
import { DashboardActions } from "domain/dashboard/actions";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import Card from "@material-ui/core/Card";
import { RouteComponentProps, withRouter } from "react-router";
import { FORMAT } from "utils/dates";
import Responsive from "ui/Responsive";
import { BookingType, GenericAppointment } from "domain/providers/types";
import TabletModal, {
  ModalCloseButton,
} from "ui/components/mobile/TabletModal";
import { ReactComponent as NoPastAppointmentImg } from "assets/states/success/NoPastAppointment.svg";
import { differenceInHours } from "date-fns/esm";
import Tokens from "ui/components/Tokens";
import { getCancelRequest } from "domain/dashboard/reducer";

const CANCELLATION_WINDOW = 24;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "calc(100%)",
      height: "100%",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",

      flexBasis: "100%",

      "& .MuiDivider-root": {
        margin: "20px 0px",
      },
    },
    noAppointment: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "auto",
      margin: "auto",

      "& svg": {
        marginLeft: "auto",
        marginRight: "auto",
      },

      "& h6": {
        marginBottom: "15px",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "20px",
      },

      "& p": {
        maxWidth: "400px",
      },

      "& button": {
        margin: "12px",
      },
    },
    historyItem: {
      display: "flex",
      flexDirection: "row",
    },
    viewDetails: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      justifyContent: "space-evenly",

      "& * .MuiButton-label": {
        width: "auto",
        marginLeft: "auto",
      },
    },

    popupTitle: {
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },

    previousAptCard: {
      width: "580px !important",
      height: "auto !important",
      minWidth: "320px",
      maxWidth: "calc(100vw) !important",

      backgroundColor: theme.palette.background.paper,

      [theme.breakpoints.down("xs")]: {
        height: "calc(100vh) !important",
      },
      [theme.breakpoints.down("sm")]: {
        "& *": {
          "& .MuiTypography-subtitle1": {
            fontSize: "14px",
          },
          "& .MuiTypography-body1": {
            fontSize: "14px",
          },

          "& #tokenIcon-inline": {
            width: "22px",
            height: "22px",
          },
        },
      },
    },

    rootContainer: {
      position: "relative",
      margin: theme.spacing(4, 4, 4, 4),
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
      },
    },
    browseButton: {
      width: "230px",
    },
    closeButton: {
      position: "absolute",
      right: "50px",
      top: "50px",
      display: "flex",
      width: "auto",
      height: "auto",
    },
    divider: {
      width: "100%",
      margin: "30px 0px",
    },
    section: {
      margin: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: "auto",
    },
    bookAgainButton: {
      margin: "auto",
    },

    tokenContainer: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      margin: "auto",

      "& h6": {
        marginBottom: "15px",
      },

      [theme.breakpoints.down("sm")]: {
        margin: "20px",
      },

      "& p": {
        maxWidth: "400px",
        margin: "auto",
      },

      "& button": {
        width: "230px",
        margin: "12px",
      },
    },

    tokenDisplay: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "10px auto 15px auto",

      "& > *": {
        margin: "5px",
      },

      "& img": {
        width: "60px",
        height: "60px",
      },

      "& .MuiTypography-root": {
        height: "50px",
        marginTop: "auto",
        marginBottom: "auto",
      },
    },

    cancelTextContainer: {
      display: "flex",
      flexDirection: "row",
      width: "calc(90% - 30px)",
      margin: "auto",
      height: "60px",
      padding: "15px",
      "& p": {
        height: "auto",
        margin: "auto",
      },

      [theme.breakpoints.down("xs")]: {
        height: "75px",

        "& p": {
          height: "60px",
        },
      },
    },
  })
);

interface NextAppointmentProps {
  appointment?: GenericAppointment;
}

export const AppointmentCard: React.FC<NextAppointmentProps> = (props) => {
  const classes = useStyles();

  const [cancellationOpen, setCancellationOpen] = React.useState(false);

  const reduxState = useReduxState({
    getCancelRequest,
    getTokenBalance,
  });

  const actions = useActions(DashboardActions);
  const viewCancellation = () => {
    setCancellationOpen(true);
  };

  const dismissModal = () => {
    setCancellationOpen(false);
  };

  const cancelAppointment = () => {
    if (props.appointment) {
      actions.cancelAppointment(props.appointment);
    }
  };

  const removeLocalApp = () => {
    if (props.appointment) {
      actions.removeAppointmentLocal(props.appointment);
    }
    dismissModal();
  };

  function getCancelInner(props: { appointment: GenericAppointment }) {
    return (
      <React.Fragment>
        <div className={classes.rootContainer}>
          <ModalCloseButton onClose={dismissModal} />
          <Grid container direction="column">
            <Grid item className={classes.section}>
              <Typography
                className={classes.popupTitle}
                variant="h4"
                gutterBottom
              >
                Your booking with {props.appointment.displayName}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            className={classes.divider}
            style={{ marginTop: 0 }}
          />
          <BookingDetails
            {...props.appointment}
            duration={props.appointment.duration}
            cost={props.appointment.unitsPerSession}
            type={props.appointment.bookingType as BookingType}
          ></BookingDetails>
        </div>
        <Divider variant="middle" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "80px",
            paddingTop: "30px",
            paddingBottom: "30px",
            marginTop: "auto",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#E74C3C",
              color: "white",
              margin: "auto",
              height: "40px",
              boxShadow: "none",
              marginBottom: "20px",
            }}
            onClick={cancelAppointment}
            color="inherit"
          >
            Cancel Appointment
          </Button>
          <div style={{ width: "auto", margin: "auto" }}>
            <Tokens align="right" light tokenStyle={{ margin: "0px" }}>
              You will be refunded <b>{props.appointment.unitsPerSession}</b>
            </Tokens>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function getCancelSuccessInner(props: { appointment: GenericAppointment }) {
    return (
      <React.Fragment>
        <div className={classes.rootContainer}>
          <ModalCloseButton onClose={dismissModal} />
        </div>
        <Grid container direction="column">
          <Grid item className={classes.section}>
            <Typography
              className={classes.popupTitle}
              variant="h4"
              gutterBottom
            >
              Appointment Cancelled
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2} alignItems="center">
              <Grid item lg={10} xs={10}>
                <Typography variant="body1" align="center">
                  {props.appointment.variant === "workshop" && (
                    <>
                      {" "}
                      Your workshop booking: {props.appointment.displayName} was
                      successfully cancelled.
                      <br></br>
                      <br></br>
                      We’ve refunded you {
                        props.appointment.unitsPerSession
                      }{" "}
                      tokens.
                    </>
                  )}
                  {props.appointment.variant === "provider" && (
                    <>
                      {" "}
                      Your appointment with {props.appointment.displayName} was
                      successfully cancelled.
                      <br></br>
                      <br></br>
                      We’ve refunded you {
                        props.appointment.unitsPerSession
                      }{" "}
                      tokens.
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ padding: 0 }}>
                <Tokens align="right" light tokenStyle={{ margin: "0px" }}>
                  Your updated balance is: {reduxState.getTokenBalance}
                </Tokens>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: 30, display: "flex" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={removeLocalApp}
          >
            Done
          </Button>
        </div>
      </React.Fragment>
    );
  }

  function getCancelPolicyInner(props: { appointment: GenericAppointment }) {
    return (
      <React.Fragment>
        <div className={classes.rootContainer}>
          <ModalCloseButton onClose={dismissModal} />
        </div>
        <Grid container direction="column">
          <Grid item className={classes.section}>
            <Typography
              className={classes.popupTitle}
              variant="h4"
              gutterBottom
            >
              Cancellation Policy
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2} alignItems="center">
              <Grid item lg={10} xs={10}>
                <Typography variant="body1" align="center">
                  You can cancel an appointment or workshop, up to 24 hours
                  before its due and receive a full token refund.<br></br>
                  <b>
                    You will not recieve a refund for cancellations that are
                    within 24 hours of the start time.
                  </b>
                  <br></br>
                  <br></br>If you would still like to cancel or ammend your
                  appointment, call Ignite support at{" "}
                  <a href="tel:09 834 0556">09 834 0556</a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: 30, display: "flex" }}>
          <Button variant="contained" color="secondary" onClick={dismissModal}>
            Done
          </Button>
        </div>
      </React.Fragment>
    );
  }

  function getInner(props: { appointment: GenericAppointment }) {
    if (
      differenceInHours(props.appointment.date, new Date()) >
      CANCELLATION_WINDOW
    ) {
      if (reduxState.getCancelRequest === "Idle") {
        return getCancelInner(props);
      } else if (reduxState.getCancelRequest === "Pending") {
        return (
          <Grid container direction="column">
            <Grid item className={classes.section}>
              <CircularIndeterminate />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item lg={10} xs={10}></Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      } else if (reduxState.getCancelRequest === "Fulfilled") {
        return getCancelSuccessInner(props);
      }
    } else {
      return getCancelPolicyInner(props);
    }

    return <></>;
  }

  function getAppointment() {
    if (props.appointment) {
      return (
        <React.Fragment>
          {cancellationOpen && (
            <TabletModal
              open={cancellationOpen}
              zIndex={3500}
              label="canellation-modal"
              onClose={dismissModal}
            >
              <Card className={classes.previousAptCard}>
                {getInner({ appointment: props.appointment })}
              </Card>
            </TabletModal>
          )}
          <ProfileHeading
            {...props.appointment}
            justify={
              props.appointment.variant === "workshop" ? "" : "space-evenly"
            }
          />
          <Divider />
          <BookingDetails
            {...props.appointment}
            type={props.appointment.bookingType}
            cost={props.appointment.unitsPerSession}
          />
        </React.Fragment>
      );
    }
  }

  function getCancel() {
    if (props.appointment) {
      if (
        differenceInHours(props.appointment.date, new Date()) >
        CANCELLATION_WINDOW
      ) {
        return (
          <React.Fragment>
            <Divider variant="middle" />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "60px",
              }}
            >
              <Button
                variant="text"
                style={{ color: "#E74C3C", margin: "auto", height: "40px" }}
                color="inherit"
                onClick={viewCancellation}
              >
                Cancel Booking
              </Button>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Divider variant="middle" />
            <div className={classes.cancelTextContainer}>
              <Typography variant="body2" align="center">
                You can cancel this booking, but you will{" "}
                <b>not recieve a refund</b>
                as it is within 24 hours of your appointment.
                <a href="#/" onClick={viewCancellation}>
                  Click here
                </a>{" "}
                for more options
              </Typography>
              <Button
                variant="text"
                style={{ color: "#E74C3C", margin: "auto", height: "40px" }}
                color="inherit"
                onClick={viewCancellation}
              >
                Cancel Anyway
              </Button>
            </div>
          </React.Fragment>
        );
      }
    }
  }

  return (
    <Card className={classes.card}>
      <div className={classes.root}>
        {props.children}
        {getAppointment()}
      </div>
      {getCancel()}
    </Card>
  );
};

interface Props extends RouteComponentProps {}

interface NoAppointmentProps extends RouteComponentProps {
  loading: boolean;
}
export const NoAppointments = ({ loading, history }: NoAppointmentProps) => {
  const classes = useStyles();

  const state = useReduxState({
    getProfile: getProfile,
  });

  function getLoadingOrCalendar() {
    if (loading) {
      return <CircularIndeterminate />;
    }
    return <EmptyCalendar />;
  }

  function getButtons() {
    if (
      state.getProfile.workshopsEnabled &&
      state.getProfile.providersEnabled
    ) {
      return (
        <>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.browseButton}
            onClick={() => history.push("/search")}
          >
            View Providers
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={classes.browseButton}
            onClick={() => history.push("/workshops")}
          >
            View Workshops
          </Button>
        </>
      );
    } else if (
      state.getProfile.workshopsEnabled &&
      !state.getProfile.providersEnabled
    ) {
      return (
        <>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.browseButton}
            onClick={() => history.push("/workshops")}
          >
            View Workshops
          </Button>
        </>
      );
    } else if (
      !state.getProfile.workshopsEnabled &&
      state.getProfile.providersEnabled
    ) {
      return (
        <>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.browseButton}
            onClick={() => history.push("/search")}
          >
            View Providers
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.browseButton}
            onClick={() => history.push("/workshops")}
          >
            View Resources
          </Button>
        </>
      );
    }
  }

  function getLoadingOrBookings() {
    if (loading) {
      return (
        <React.Fragment>
          <Typography variant="h6" align="center" gutterBottom>
            Loading bookings
          </Typography>

          <Typography
            variant="body1"
            align="center"
            style={{ maxWidth: "400px", margin: "auto" }}
          >
            We are retrieving your bookings
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingTop: "20px",
              margin: "auto",
              flexWrap: "wrap-reverse",
              justifyContent: "center",
            }}
          >
            {getButtons()}
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          No Bookings
        </Typography>

        <Typography
          variant="body1"
          align="center"
          style={{ maxWidth: "400px", margin: "auto" }}
        >
          When you book an appointment or workshop, the details of your next one
          will be shown here.
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "20px",
            margin: "auto",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
          }}
        >
          {getButtons()}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.noAppointment}>
      <div className={classes.tokenDisplay}>{getLoadingOrCalendar()}</div>
      {getLoadingOrBookings()}
    </div>
  );
};

export const NoPastAppointments = ({ loading }: NoAppointmentProps) => {
  const classes = useStyles();

  function getLoadingOrCalendar() {
    if (loading) {
      return <CircularIndeterminate />;
    }
    return <NoPastAppointmentImg />;
  }

  return (
    <div className={classes.noAppointment} style={{ minHeight: "330px" }}>
      <div className={classes.tokenDisplay}>{getLoadingOrCalendar()}</div>

      <Typography variant="h6" align="center" gutterBottom>
        {loading ? "Loading history..." : "No history"}
      </Typography>

      <Typography variant="body1" align="center" style={{ maxWidth: "400px" }}>
        After you attend an appointment or workshop, a record of it will be
        shown here.
      </Typography>
    </div>
  );
};

export const AppointmentTokens = (props: RouteComponentProps) => {
  const classes = useStyles();
  const reduxState = useReduxState({
    getTokenBalance,
  });

  return (
    <div className={classes.tokenContainer}>
      <div className={classes.tokenDisplay}>
        <Typography variant="h3">{reduxState.getTokenBalance}</Typography>
        <img alt=""  src={IgniteToken} />
      </div>
      <Typography variant="h6" align="center" gutterBottom>
        Exchange Tokens for Appointments &amp; Workshops
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        You have {reduxState.getTokenBalance} tokens! Exchange them to book
        appointments with support providers and attend workshops of your choice.
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          flexWrap: "wrap-reverse",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          className={classes.browseButton}
          onClick={() => props.history.push("/search")}
        >
          View Providers
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          className={classes.browseButton}
          onClick={() => props.history.push("/workshops")}
        >
          View Workshops
        </Button>
      </div>
    </div>
  );
};

interface AppointmentHistoryProps extends RouteComponentProps {
  previousAppointments: GenericAppointment[];
}
export const AppointmentHistory = ({
  previousAppointments,
  history,
}: AppointmentHistoryProps) => {
  const classes = useStyles();

  const [viewDetailsOpen, setViewDetailsOpen] = React.useState(false);

  const [
    appointment,
    setAppointment,
  ] = React.useState<GenericAppointment | null>(null);

  const dismissModal = () => {
    setViewDetailsOpen(false);
  };

  const viewDetails = (apt: GenericAppointment) => {
    setAppointment(apt);
    setViewDetailsOpen(true);
  };

  return (
    <Card className={classes.card}>
      {appointment !== null && (
        <TabletModal
          open={viewDetailsOpen}
          zIndex={3500}
          label="bookingConfirmation"
          onClose={dismissModal}
        >
          <Card className={classes.previousAptCard}>
            <div className={classes.rootContainer}>
              <ModalCloseButton onClose={dismissModal} />
              <Grid container direction="column">
                <Grid item className={classes.section}>
                  <Typography
                    className={classes.popupTitle}
                    variant="h4"
                    gutterBottom
                  >
                    Appointment Details
                  </Typography>
                </Grid>

                <Grid item>
                  <ProfileHeading
                    {...appointment}
                    justify={
                      appointment.variant === "workshop" ? "" : "space-evenly"
                    }
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" className={classes.divider} />

              <BookingDetails
                address={appointment.address}
                duration={appointment.duration}
                variant={appointment.variant}
                date={new Date(appointment.date)}
                cost={appointment.unitsPerSession}
                type={appointment.bookingType as BookingType}
              ></BookingDetails>
            </div>
          </Card>
        </TabletModal>
      )}

      <div className={classes.root}>
        {previousAppointments.map((item, index) => {
          return (
            <React.Fragment key={item.date + item.id}>
              <Responsive displayIn={["MobileXS"]}>
                <ProfileHeading
                  {...item}
                  typeOverride={FORMAT(item.date)}
                  disableButton
                  justify={item.variant === "workshop" ? "" : "space-evenly"}
                  button={
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => viewDetails(item)}
                    >
                      View Details
                    </Button>
                  }
                />
              </Responsive>
              <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ProfileHeading
                    {...item}
                    justify={item.variant === "workshop" ? "" : "space-evenly"}
                    disableButton
                    avatarSize="60px"
                  />
                  <div className={classes.viewDetails}>
                    <Typography variant="body2">{FORMAT(item.date)}</Typography>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => viewDetails(item)}
                    >
                      View details
                    </Button>
                  </div>
                </div>
              </Responsive>
              {previousAppointments.length - 1 !== index && (
                <Divider variant="middle" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Card>
  );
};

const NextAppointment = (props: Props) => {
  const reduxState = useReduxState({
    appointment: getNextGenericAppointment,
    future: getAllFutureAppointments,
    past: getAllPastAppointments,
    status: getAppointmentStatus,
    getTokenBalance,
  });

  const actions = useActions(DashboardActions);

  useEffect(() => {
    actions.getAppointments();
  }, [actions]);

  const neverBooked = () => {
    return (
      reduxState.status !== "Pending" &&
      reduxState.future.length === 0 &&
      reduxState.past.length === 0
    );
  };

  function getAppointment() {
    return (
      <AppointmentCard appointment={reduxState.appointment}>
        <CardHeading
          heading="Your Next Booking"
          action="View All"
          onClick={() => props.history.push("/appointments")}
          disableAction={neverBooked()}
        />
        <Divider />
        {reduxState.appointment === undefined && getNoAppointment()}
      </AppointmentCard>
    );
  }

  function getNoAppointment() {
    if (neverBooked()) {
      return <AppointmentTokens {...props} />;
    }
    return (
      <NoAppointments loading={reduxState.status === "Pending"} {...props} />
    );
  }

  return getAppointment();
};

export default withRouter(NextAppointment);
