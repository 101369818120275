import {
  Button,
  Card,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import IconListItem from "ui/components/filters/IconListItem";
import { ReactComponent as IgniteToken } from "assets/IgniteToken.svg";

import { getRequestTokenStatus } from "domain/core/auth/selectors";
import { useActions, useReduxState } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import { ModalCloseButton } from "ui/components/mobile/TabletModal";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { DropdownClass } from "ui/components/dropdown/Dropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    root: {
      width: "580px !important",
      height: "auto !important",
      minWidth: "320px",
      maxWidth: "calc(100vw) !important",
      minHeight: "568px",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      [theme.breakpoints.down("xs")]: {
        height: "calc(100vh) !important",
      },
      [theme.breakpoints.down("sm")]: {
        "& *": {
          "& .MuiTypography-subtitle1": {
            fontSize: "14px",
          },
          "& .MuiTypography-body1": {
            fontSize: "14px",
          },

          "& #tokenIcon-inline": {
            width: "22px",
            height: "22px",
          },
        },
      },
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "32px",
      width: "calc(100% - 64px)",
    },
    cardTitle: {},
    cartSubtitle: {
      marginBottom: "16px",
    },
    cardSubscript: {
      color: "#5F6272",
      marginBottom: "24px",
    },
    divider: {
      marginBottom: "0px",
    },
    dropdownContainer: {},
    formControlClass: {
      zIndex: 9999,
    },
    formControl: {
      width: 240,
      height: 100,
      marginTop: 60,
    },
    token: {
      width: 30,
      height: 30,
      paddingRight: 10,
    },
    radioFormControl: {
      marginBottom: 24,
      minHeight: 250,
    },
    radioLabel: {
      fontSize: 14,
      color: "black !important",
      transform: "translateX(5px)",
      fontFamily: "Gilroy Bold",
    },
    radioGroupError: {
      "& * .MuiRadio-root": {
        color: "red",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    loaderContainer: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    loader: {
      margin: "auto auto !important",
      padding: 50,
    },
    dropdown: {
      width: "100%",
      marginBottom: "15px",
      minHeight: 93,
      marginTop: "26px",
      minWidth: "320px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "100%",
      },
    },
  })
);

export const renderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <IconListItem
      option={option}
      selected={selected}
      key={option}
      onClick={callback}
    >
      <IgniteToken />
    </IconListItem>
  );
};

const tokenValueOptions = [100, 200, 300];

function getOptions() {
  return tokenValueOptions.map((item) => {
    return (
      <MenuItem value={item} key={item}>
        <IgniteToken
          style={{
            width: 30,
            height: 30,
            paddingRight: 10,
          }}
        />
        <span>{item.toString()}</span>
      </MenuItem>
    );
  });
}

const renderValue = (e: any) => {
  return (
    <MenuItem value={e} key={e} style={{ padding: "0px" }}>
      <IgniteToken
        style={{
          width: 30,
          height: 30,
          paddingRight: 10,
        }}
      />
      <span>{e.toString()}</span>
    </MenuItem>
  );
};

const TokenDropdown = new DropdownClass<number>(
  "How many tokens would you like?",
  true,
  100,
  getOptions,
  tokenValueOptions,
  "",
  renderValue
);

const RequestMoreTokens: React.FC<{ dismiss: () => void }> = (props) => {
  const classes = useStyles();

  const [reason, setReason] = React.useState("");

  // eslint-disable-next-line
  const { requestStatus } = useReduxState({
    requestStatus: getRequestTokenStatus,
  });

  const actions = useActions(AuthActions);

  const handleReason = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string;
    }>
  ) => {
    setReason(event.target.value);
    if (event.target.value && event.target.value !== "") {
      setRadioHasError(false);
    }
  };

  useEffect(() => {
    TokenDropdown.className = classes.dropdown;
    if (requestStatus.status === "Fulfilled") {
      props.dismiss();
    }
    //eslint-disable-next-line
  }, [props, requestStatus.status]);

  const [radioError, setRadioHasError] = React.useState(false);
  function submit() {
    if (reason === "") {
      setRadioHasError(true);
      return;
    } else {
      setRadioHasError(false);
      actions.requestTokens({
        tokenAmount: TokenDropdown.value,
        reason: reason,
      });
    }
  }

  return (
    <Card className={classes.root}>
      <div className={classes.cardContainer}>
        <ModalCloseButton onClose={props.dismiss} />

        {(requestStatus.status === "Idle" ||
          requestStatus.status === "Fulfilled") && (
          <React.Fragment>
            <Typography
              gutterBottom
              variant="h4"
              align="center"
              className={classes.cardTitle}
            >
              Request more tokens
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              className={classes.cartSubtitle}
            >
              Please let us know the main reason for your additional token
              request to help ignite gain approval from your organisation
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="caption"
              className={classes.cardSubscript}
            >
              This request comes to the Ignite team for review and is taken to
              your organisation anonymously for consideration. We do not share
              your name, only the number of tokens you have used and requested
              and your main reason. If your organisation requests further
              information, we will gain your consent before providing it.
            </Typography>
            <Divider className={classes.divider} />

            <div className={classes.dropdownContainer}>
              {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="tokens-select-outlined-label">
                  How many tokens would you like?
                </InputLabel> */}

              {TokenDropdown.render()}
              {/* <Select
                  MenuProps={menuProps}
                  labelId="tokens-select-outlined-label"
                  id="tokens-select-outlined"
                  variant="outlined"
                  IconComponent={iconComponent}
                  value={value}
                  onChange={handleChange}
                  className={classes.select}
                >
                  {getOptions()}
                </Select> */}
              {/* </FormControl> */}
              <FormControl
                component="fieldset"
                error={true}
                className={classes.radioFormControl}
              >
                <FormLabel component="legend" className={classes.radioLabel}>
                  Why would like more tokens?
                </FormLabel>
                <RadioGroup
                  aria-label="reason"
                  name="reason"
                  value={reason}
                  className={radioError ? classes.radioGroupError : ""}
                  onChange={handleReason}
                >
                  <FormControlLabel
                    value="To continue the support I have been getting"
                    control={<Radio color="primary" />}
                    label="To continue the support I have been getting"
                  />
                  <FormControlLabel
                    value="To book a different type of support or Provider"
                    control={<Radio color="primary" />}
                    label="To book a different type of support or Provider"
                  />
                  <FormControlLabel
                    value="Someone in my family needs to access support"
                    control={<Radio color="primary" />}
                    label="Someone in my family needs to access support"
                  />
                  <FormControlLabel
                    value="For an issue that has just come up"
                    control={<Radio color="primary" />}
                    label="For an issue that has just come up"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio color="primary" />}
                    label="Other"
                  />
                </RadioGroup>
                {radioError && (
                  <FormHelperText id="fade-in">
                    Please select your main reason for requesting more tokens
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <Button
              color="secondary"
              variant="contained"
              onClick={() => submit()}
            >
              Submit Request
            </Button>
          </React.Fragment>
        )}

        {requestStatus.status === "Pending" && (
          <>
            <CircularIndeterminate className={classes.loader} />
            <Typography variant="h4" align="center">
              We are sending your request
            </Typography>
          </>
        )}
      </div>
    </Card>
  );
};

export default RequestMoreTokens;
