export type Optional<T> = void | undefined | T;

export const REQUEST_STATUS = {
  Idle: "Idle",
  Pending: "Pending",
  Failed: "Failed",
  Fulfilled: "Fulfilled"
} as const;

export type RequestStatus = keyof typeof REQUEST_STATUS;

export interface RequestState<TError = Error> {
  status: RequestStatus;
  error?: TError;
  lastExecuted?: Date;
}

export interface AsyncCollection<TResult, TError = Error> {
  byId: { [id: string]: TResult };
  idList: string[];
  request: RequestState<TError>;
}

export interface Result<T> {
  items: T;
}

export type PaginationQuery = {
  pageIndex: number;
  pageSize: number;
};

export type PaginationState = PaginationQuery & {
  total: number;
  totalPages?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
};

export interface PaginatedResult<T> extends Result<T> {
  pagination: PaginationState;
}
