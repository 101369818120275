import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { withRouter, RouteComponentProps } from "react-router";

import A from "assets/Mood/Awful.svg";
import B from "assets/Mood/Bad.svg";
import C from "assets/Mood/Okay.svg";
import D from "assets/Mood/Good.svg";
import E from "assets/Mood/Great.svg";
import { DateAndMood } from "domain/dashboard/types";
import { getDay, isSameMonth } from "date-fns";
import { MONTH_NAMES } from "utils/dates";

interface Props extends RouteComponentProps {
  moods: DateAndMood[];
}

const graphStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100%)",
      /*  Causes everything to break on safari - go figure. It's safari. */
      // height: "calc(100%)"
      minWidth: "280px",
      minHeight: "110px",

      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
        minHeight: "300px",
      },

      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
        minHeight: "250px",
      },
    },
    graphTitle: {
      marginBottom: "15px",
      width: "100%",
      ...theme.typography.h6,
      textAlign: "center",
    },
    graphContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
    },
    graphContext: {
      display: "flex",
      width: "85px",
      position: "relative",
      height: "100%",
      flexDirection: "column-reverse",
      justifyContent: "space-around",

      [theme.breakpoints.down("xs")]: {
        width: 40,
      },
    },
    graphLine: {
      opacity: 0.3,
      /* ◇ Light Grey */
      border: "0.5px dashed #9297AD",
      width: "100%",
    },

    solidGraphLine: {
      opacity: 0.3,
      /* ◇ Light Grey */
      width: "100%",
      border: "0.5px solid #9297AD",
    },
    graphContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
      height: "100%",
      alignItems: "flex-end",
    },
    graphLegend: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
      height: "100%",
      alignItems: "flex-end",
    },

    graphFooter: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      // height: "100%",
      minHeight: "50px",
    },

    moodKey: {
      maxWidth: "16px",
      maxHeight: "16px",

      [theme.breakpoints.down("xs")]: {
        width: 13,
        height: 13,
      },
    },

    verticalEntry: {
      display: "flex",
      flexDirection: "column",
      width: "28px",
      height: "100%",
      position: "relative",
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        width: 17,
      },
    },
    moodBar: {
      width: "100%",
      backgroundColor: "#6020D4",
      transition: "all 2s ease",
      animation: "bar 1s ease 0s 1 normal forwards",
      position: "relative",

      [theme.breakpoints.down("xs")]: {
        width: 17,
      },
    },

    dayKeys: {
      fontFamily: "Gilroy SemiBold",
      fontSize: "14px",
      position: "absolute",
      bottom: "-20px",
      left: 0,
      right: 0,
      textAlign: "center",

      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
    dayNumber: {
      fontFamily: "Gilroy Regular",
      fontSize: "12px",
      position: "absolute",
      bottom: "-40px",
      letterSpacing: "0.4px",
      left: 0,
      right: 0,
      textAlign: "center",
    },
    moodHover: {},
  })
);
interface Props {}

const MoodGraph: React.FC<Props> = (props) => {
  const classes = graphStyles();

  const images = [A, B, C, D, E];

  const graphLine = (i: number) => {
    return (
      <div
        key={"dash-line-" + i.toString()}
        className={classes.graphLine}
        style={{
          position: "absolute",
          // top: (i + 1) * lineSpacing,
          bottom: `${20 * i}%`,
        }}
      />
    );
  };

  const graphLegendKey = (i: number) => {
    return (
      <img
        alt=""
        src={images[i]}
        key={"mood-key-" + i.toString()}
        className={classes.moodKey}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
    );
  };

  const dayAcronyms = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const graphBar = (date: Date, i: number, value: number) => {
    return (
      <div
        className={classes.verticalEntry}
        key={"mood-bar-" + i.toString() + "-" + value}
      >
        <div
          className={classes.moodBar}
          style={{
            height: `0`,
            zIndex: 1,
            top: "-2px",

            animation: `bar${20 * value} 1s ease ${
              0 + 0.2 * i
            }s normal forwards`,
          }}
        >
          <div
            key={"mood-bar-hover-" + i.toString() + "-" + value}
            className={classes.moodHover}
          ></div>
        </div>

        <div className={classes.dayKeys}>{dayAcronyms[getDay(date)]}</div>

        <div className={classes.dayNumber}>{date.getDate()}</div>
      </div>
    );
  };

  function getOverview() {
    return (
      <div className={classes.root}>
        <div className={classes.graphTitle}>
          {props.moods[0].date.getDate()}{" "}
          {!isSameMonth(
            props.moods[0].date,
            props.moods[props.moods.length - 1].date
          )
            ? MONTH_NAMES[props.moods[0].date.getMonth()] + " - "
            : " - "}
          {props.moods[props.moods.length - 1].date.getDate()}{" "}
          {MONTH_NAMES[props.moods[props.moods.length - 1].date.getMonth()]}
          {" Mood Trend"}
        </div>
        <div className={classes.graphContainer}>
          <div
            key={"dash-line-0"}
            className={classes.solidGraphLine}
            style={{
              position: "absolute",
              bottom: `0%`,
            }}
          />
          {[1, 2, 3, 4].map((index) => graphLine(index))}
          <div className={classes.graphContext}>
            {[0, 1, 2, 3, 4].map((index) => graphLegendKey(index))}
          </div>
          <div className={classes.graphContent}>
            {props.moods.map((value, index) =>
              graphBar(value.date, index, value.mood)
            )}
          </div>
        </div>
        <div className={classes.graphFooter}>
          <div className={classes.graphContext} />
          <div className={classes.graphLegend}></div>
        </div>
      </div>
    );
  }
  return getOverview();
};

export default withRouter(MoodGraph);
