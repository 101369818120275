import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { Divider } from "@material-ui/core";

import LifeIllustration from "assets/illustrations/Life.svg";
import HealthIllustration from "assets/illustrations/Health.svg";
import MeaningIllustration from "assets/illustrations/Meaning.svg";

import MobileLifeIllustration from "assets/illustrations/MobileLife.svg";
import MobileHealthIllustration from "assets/illustrations/MobileHealth.svg";
import MobileMeaningIllustration from "assets/illustrations/MobileMeaning.svg";

import { DomainKey, domains } from "domain/core/app/types";
import DomainIcon from "ui/components/DomainIcon";

const domainCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",

      "& .MuiDivider-root": {
        margin: "15px 40px",
      },
    },
    container: {
      display: "flex",
      margin: "30px",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
      },
    },
    fourty: {
      display: "flex",
      width: "46%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    sixty: {
      display: "flex",
      width: "54%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    title: {
      display: "flex",
      margin: "auto",
      padding: "30px",
      width: "auto",
      ...theme.typography.h4,
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },
    body: {
      padding: "30px",
      margin: "auto",
      width: "auto",
      ...theme.typography.body1,
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },

      "& span": {
        fontFamily: "Gilroy Bold",
      },
    },
  })
);

const domainSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    image: {
      padding: "30px 30px 30px 0px",
      width: "46%",
      "@media(max-width: 980px)": {
        // padding: "30px 0px 30px 0px",
        "& img": {
          width: "100%",
          height: "100%",
          minWidth: "298px",
          minHeight: "315px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    mobileImageContainer: {
      "& img": {
        width: "100%",
        height: "100%",
        minWidth: "298px",
        minHeight: "315px",
      },
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    mobileImg: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    desktopImg: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    contentGrid: {
      marginTop: "30px",

      paddingRight: "20px",
      "@media(max-width: 980px)": {
        marginTop: "0px",
        paddingRight: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
        "& *": {
          textAlign: "center",
        },
      },
    },
    contentContainer: {
      padding: "30px",
      paddingBottom: "10px",
      height: "fit-content",
      margin: "auto",
      width: "54%",
      "@media(max-width: 980px)": {
        width: "100%",
      },
    },
    svg: {},
    tabletHeading: {
      "@media(min-width: 980px)": {
        display: "none",
      },
    },
    tabletTitle: {
      ...theme.typography.h4,
      margin: "auto",
      width: "80%",
      textAlign: "center",
      padding: "10px 10px 10px 0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    tabletDescription: {
      ...theme.typography.body1,
      margin: "auto",
      width: "calc(100% - 80px)",
      textAlign: "center",
      padding: "10px 0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    desktopTitle: {
      ...theme.typography.h4,
      padding: "10px 10px 10px 0px",
      "@media(max-width: 980px)": {
        display: "none",
      },
    },
    desktopDescription: {
      ...theme.typography.body1,
      padding: "10px 10px 10px 0px",
      "@media(max-width: 980px)": {
        display: "none",
      },
    },
  })
);

const domainItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "15px",
      marginBottom: "15px",
      "@media(max-width: 980px)": {
        marginTop: "0px",
        marginBottom: "30px",
        maxWidth: "calc(100% - 60px)",
      },
    },
    button: {
      [theme.breakpoints.up("sm")]: {
        "& .MuiButton-label": {
          alignItems: "inherit",
          justifyContent: "flex-start",
        },
      },
    },
    icon: {
      marginLeft: "-8px",
      [theme.breakpoints.down("xs")]: {
        margin: "auto",
      },
    },
    domainCouple: {
      flexDirection: "row",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        margin: "auto",
      },
      "& .MuiTypography-root": {
        height: "25px",
        margin: "auto 0px",
      },
    },
  })
);

interface MegaDomainDescription {
  title: string;
  description: string;
  imgSrc?: string;
  mobileImgSrc?: string;
}
interface IDictionary {
  [index: string]: MegaDomainDescription;
}

const map: IDictionary = {
  life: {
    title: "My Life - Taku\xA0Oranga",
    description:
      "Lifestyle and relationships at work and home give us the ability to flourish and grow, reaping the fruit and flowers of life.",
    imgSrc: LifeIllustration,
    mobileImgSrc: MobileLifeIllustration,
  },
  health: {
    title: "My Health - Taku\xA0Hauora",
    description:
      "Mental and physical health, habits and hobbies strengthen and hold us strong.",
    imgSrc: HealthIllustration,
    mobileImgSrc: MobileHealthIllustration,
  },
  meaning: {
    title: "My Meaning - Taku\xA0Tikanga",
    description:
      "Our meaning provides us with a solid foundation from which to grow our wellbeing.",
    imgSrc: MeaningIllustration,
    mobileImgSrc: MobileMeaningIllustration,
  },
};

const domainMap: IDictionary = {
  mind: {
    title: "Mind",
    description:
      "Mind health considers feelings of stress, anxiety, depression, grief and your overall mindset.",
  },
  body: {
    title: "Body",
    description:
      "Body health considers your physical health, nutrition, exercise and health conditions",
  },
  habits: {
    title: "Habits",
    description:
      "Habits consider things you do that affect your overall wellbeing when out of balance like alcohol, drugs, gambling and sleep patterns.",
  },
  home: {
    title: "Home",
    description:
      "Home considers your living situation and conditions such as affordability, crowding, condition and choice about where you live.",
  },
  relationships: {
    title: "Relationships",
    description:
      "Relationships consider friendships, family and household relationships, parenting, intimate relationships and break-ups.",
  },
  occupation: {
    title: "Occupation",
    description:
      "Occupation considers your education, training, career planning and jobs that fill your day.",
  },
  finances: {
    title: "Finances",
    description:
      "Finances consider budgeting, management of debt, savings and investments, planning for retirement and your financial comfort.",
  },
  recreation: {
    title: "Recreation",
    description:
      "Recreation considers activities that bring you joy such as sports, hobbies and interests.",
  },
  identity: {
    title: "Identity",
    description:
      "Identity considers how you see yourself in the world. It covers aspects of who you are, like ethnicity, gender and sexuality.",
  },
  beliefs: {
    title: "Beliefs",
    description:
      "Beliefs consider your connection to meaning and purpose in your life, through philosophy, religion, spirituality or even nature.",
  },
  culture: {
    title: "Culture",
    description:
      "Culture considers your connection to and understanding of ethnicity, whakapapa (heritage), cultural identity and practices (tikanga).",
  },
  connectedness: {
    title: "Connectedness",
    description:
      "Connectedness considers your connection with community groups, friends and family, iwi, churches and interest groups.",
  },
};

interface DomainItemProps {
  domain: string;
}
const DomainItem = (props: DomainItemProps) => {
  const classes = domainItemStyles();

  return (
    <Grid
      item
      className={classes.root}
      xl={5}
      lg={5}
      md={5}
      sm={12}
      xs={12}
      style={{ height: "fit-content" }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.domainCouple}>
          <DomainIcon
            domain={domainMap[props.domain].title}
            className={classes.icon}
          />
          <Typography variant="h6">{domainMap[props.domain].title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {domainMap[props.domain].description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
interface DomainSectionProps {
  domain: DomainKey;
}

export const DomainsSection = (props: DomainSectionProps) => {
  const classes = domainSectionStyles();

  function getDomains() {
    return domains[props.domain].map((item) => (
      <DomainItem domain={item.toLowerCase()} key={item} />
    ));
  }
  const domain = map[props.domain];

  if (domain) {
    return (
      <div className={classes.root}>
        <div className={classes.mobileImageContainer}>
          <img alt="" className={classes.mobileImg} src={domain.mobileImgSrc} />
        </div>
        <div className={classes.tabletHeading}>
          <div className={classes.tabletTitle}>{map[props.domain].title}</div>
          <div className={classes.tabletDescription}>{domain.description}</div>
        </div>
        <div className={classes.container}>
          <div className={classes.image}>
            <img alt="" className={classes.desktopImg} src={domain.imgSrc} />
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.desktopTitle}>{domain.title}</div>
            <div className={classes.desktopDescription}>
              {domain.description}
            </div>

            <Grid
              container
              justify="space-between"
              className={classes.contentGrid}
            >
              {getDomains()}
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
};

const DomainsCard = () => {
  const classes = domainCardStyles();
  return (
    <Card className={classes.root}>
      <div className={classes.container}>
        <div className={classes.fourty}>
          <div className={classes.title}>
            Approach to Whole of Life Wellbeing
          </div>
        </div>
        <div className={classes.sixty}>
          <p className={classes.body}>
            There are four domains within{" "}
            <span style={{ color: "#F34258" }}>My Life</span>,{" "}
            <span style={{ color: "#49DBA3" }}>My Health</span>,{" "}
            <span style={{ color: "#04AAF0" }}>My Meaning</span> that look at
            areas of our life that contribute to supporting us to thrive. By
            focussing on actions that improve and strengthen these domains, we
            enhance our overall wellbeing, including our mental-health and
            resilience which supports us to live our best life.
          </p>
        </div>
      </div>
      <Divider variant="middle" />

      <DomainsSection domain="life" key="life-section" />
      <Divider variant="middle" />
      <DomainsSection domain="health" key="health-section" />
      <Divider variant="middle" />
      <DomainsSection domain="meaning" key="meaning-section" />
    </Card>
  );
};

export default DomainsCard;
