import React from "react";

import Filter from "./Filter";
import CheckboxListItem from "./CheckboxListItem";
import { FilterProps } from "ui/components/filters/Filter";
import { EnquiryTypes } from "domain/core/app/types";

export const renderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <CheckboxListItem
      key={option}
      option={option}
      selected={selected}
      onClick={callback}
    />
  );
};

const EnquiryFilter = (props: FilterProps) => {
  function getOptions(): string[] {
    return EnquiryTypes;
  }

  return (
    <Filter
      error={props.error}
      formControlClass={props.formControlClass}
      border={false}
      values={props.values}
      onFilterChange={props.onFilterChange}
      disableWrapper
      options={getOptions()}
      disableInput
      forceDesktop
      singular
      title="Type of Enquiry *"
      renderOptions={renderOption}
      placeHolder="General"
      id="enquiry-type-filter"
    />
  );
};

export default EnquiryFilter;
