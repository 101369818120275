import { combineReducers, AnyAction } from "redux";

import core from "domain/core/reducer";
import providers from "domain/providers/reducer";
import content from "domain/content/reducer";
import wellbeing from "domain/wellbeing/reducer";
import dashboard from "domain/dashboard/reducer";
import workshops from "domain/workshops/reducer";

import * as App from "domain/core/app/reducer";
import * as Providers from "domain/providers/reducer";
import * as Content from "domain/content/reducer";
import * as Wellbeing from "domain/wellbeing/reducer";
import * as Dashboard from "domain/dashboard/reducer";
import * as Workshops from "domain/workshops/reducer";
import { setToken } from "lib/apiClient/api";

const appReducer = combineReducers({
  core,
  providers,
  content,
  wellbeing,
  dashboard,
  workshops,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "CORE/AUTH/LOGOUT_SUCCESS") {
    state.core.app = App.initialState;
    state.content = Content.initialState;
    state.dashboard = Dashboard.initialState;
    state.wellbeing = Wellbeing.initialState;
    state.providers = Providers.initialState;
    state.workshops = Workshops.initialState;

    setToken("");
  }

  return appReducer(state, action);
};

export default rootReducer;
