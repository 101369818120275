import React, { useEffect } from "react";
import { Route, RouteComponentProps, withRouter, Switch } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles, Card, Link } from "@material-ui/core";
import background from "assets/login/LoginBackgroundTinted.jpg";
import { ROUTES } from "./routes";
import Login from "ui/compounds/login/Login";
import ForgotPassword from "ui/compounds/login/ForgotPassword";
import Onboarding from "ui/compounds/login/Onboarding";
import * as selectors from "domain/core/selector";
import { useReduxState } from "re-reduced";
import { ReactComponent as AlertCircle } from "assets/icons/inform.svg";
import Typography from "@material-ui/core/Typography";
import IgniteLogoWithText from "assets/Logo/IgniteLogoWithText.svg";
import TabletModal, {
  ModalCloseButton,
} from "ui/components/mobile/TabletModal";
import Responsive from "ui/Responsive";

export const useUnAuthedStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${background})`,
      border: 0,
      height: "100vh",
      margin: 0,
      display: "flex",
      padding: 0,
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    },
    logo: {
      margin: "24px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        margin: "24px auto",
      },
    },
    logoMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    signUpWrap: {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      fontFamily: "Gilroy Bold",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    imgWrap: {
      marginLeft: "-100px",
    },
    loginWrap: {
      backgroundColor: theme.palette.background.paper,
      width: "636px",
      display: "flex",
      overflow: "scroll",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-around",
      "@media(max-width: 636px)": {
        width: "100vw",
      },

      transition: "all 1s ease",
    },
    loginWrapToOnboarding: {
      width: "900px",
    },
    loginFormWrapper: {
      margin: "auto",
      maxHeight: "800px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "636px",
      alignItems: "center",
      animationName: "fadeIn",
      animationDuration: "450ms",
      animationDelay: "0s",
      animationTimingFunction: "linear",
      animationFillMode: "forwards",

      "@media(max-width: 636px)": {
        width: "100vw",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
    },
    loginForm: {
      margin: "auto",
      maxHeight: "800px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "461px",
      alignItems: "center",
      height: "100%",

      "& > *": {
        margin: theme.spacing(1),
      },

      [theme.breakpoints.down("lg")]: {
        justifyContent: "center",

        "& p, h2": {
          margin: "5px 0px",
        },
        "& button ": {
          margin: "20px 0px",
        },
        "& h2": {
          fontSize: "35px !important",
        },

        width: "calc(100% - 50px)",
        margin: "0px 25px",
      },
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    formControl: {
      width: "100%",
      maxWidth: "461px",
      margin: theme.spacing(1),
      transition: "all 1s ease",
      height: "auto",
      display: "flex",
    },
    expandedError: {
      maxHeight: "150px",
    },
    forgotPassword: {
      margin: "12px 0px",
      width: "120px",
      textAlign: "center",

      color: theme.palette.text.primary,
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
    },
    footContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "30px",
      position: "relative",
      background: theme.palette.background.default,
      justifyContent: "space-around",
      "& * ": {
        textAlign: "center",
      },
    },
    footMessage: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      "& h6": {
        height: "24px",
        margin: "auto 0px",
      },
    },
    card: {
      height: "100% !important",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.default,
      "& > *": {
        padding: "30px",
        maxWidth: "calc(100vw)",
      },
    },
  })
);

interface AlertProps {
  title: string;
  message: JSX.Element;
  onDismiss: () => void;
  open: boolean;
}
export const AlertPopup = (props: AlertProps) => {
  const classes = useUnAuthedStyles();

  const setOpenModal = (close: boolean) => {
    props.onDismiss();
  };
  return (
    <TabletModal
      label="Signup Modal"
      open={props.open}
      onClose={() => setOpenModal(false)}
    >
      <Card className={classes.card}>
        <ModalCloseButton onClose={() => setOpenModal(false)} />
        <div className={classes.footContainer}>
          <div className={classes.footMessage}>
            <AlertCircle />
            <Typography variant="h6">{props.title}</Typography>
          </div>
          <Typography variant="body2">{props.message}</Typography>
        </div>
      </Card>
    </TabletModal>
  );
};

interface Props extends RouteComponentProps {
  lastLocation: string;
}

const UnAuthed: React.FC<Props> = (props) => {
  const classes = useUnAuthedStyles();

  const state = useReduxState({
    ...selectors,
  });

  useEffect(() => {
    if (state.getIsAuthenticated === true) {
      if (state.getIsOnboarded) {
        props.history.push(props.lastLocation);
      } else if (props.location.pathname !== "/onboarding") {
        props.history.push("/onboarding");
      }
    }
  }, [state.getIsAuthenticated, state.getIsOnboarded, props]);

  const [openModal, setOpenModal] = React.useState(false);

  function getFooter() {
    return (
      <div className={classes.footContainer}>
        <div className={classes.footMessage}>
          <AlertCircle />
          <Typography variant="h6">Don't have login details?</Typography>
        </div>
        <Typography variant="body2">
          <br />
          Ignite partners with workplaces to provide mental-health and wellbeing
          support. You can request your employer to collaborate with Ignite and
          give you access to our Support Providers.
        </Typography>
      </div>
    );
  }
  return (
    <div id="page">
      <div className={classes.background}>
        <div
          className={`${classes.loginWrap} ${
            props.location.pathname === "/onboarding"
              ? classes.loginWrapToOnboarding
              : ""
          }`}
        >
          <a href="/home">
            <img
              className={classes.logo}
              src={IgniteLogoWithText}
              alt="logo"
            />
          </a>

          <Switch>
            <Route exact path={ROUTES.forgotPassword}>
              <ForgotPassword />
            </Route>

            <Route exact path={ROUTES.onboarding}>
              <Onboarding />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
          {props.location.pathname !== "/onboarding" && (
            <React.Fragment>
              <Responsive displayIn={["MobileXS"]}>
                <Link
                  onClick={() => setOpenModal(true)}
                  style={{ marginBottom: "auto", marginTop: "0px" }}
                >
                  Don't have login details?
                </Link>

                <TabletModal
                  label="Signup Modal"
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                >
                  <Card className={classes.card}>
                    <ModalCloseButton onClose={() => setOpenModal(false)} />
                    {getFooter()}
                  </Card>
                </TabletModal>
              </Responsive>
              <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
                {getFooter()}
              </Responsive>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(UnAuthed);
