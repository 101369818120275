import authClient from "lib/apiClient/authApi";
import apiClient from "lib/apiClient/api";

import { ENDPOINTS } from "domain/core/configuration";
import {
  LoginPayload,
  LoginResponse,
  UserProfile,
  ContactInfo,
  Name,
  RequestTokenPayload,
  TokenRequestStatus,
  Demographics,
} from "domain/core/auth/types";

export const setProfile = apiClient.put<UserProfile, UserProfile>(
  ENDPOINTS.profile
);

export const getProfile = apiClient.get<
  UserProfile & { userOnboarded: number },
  void,
  UserProfile
>(ENDPOINTS.profile, {
  transformResult: (result: UserProfile) => {
    return {
      ...result,
      userOnboarded: ((result.userOnboarded as unknown) as number) === 1,
    };
  },
});

export const login = authClient.post<
  LoginResponse,
  LoginPayload & {
    grant_type?: string;
    username?: string;
    password?: string;
    audience?: string;
    realm?: string;
    client_id?: string;
    client_secret?: string;
  }
>(ENDPOINTS.login, {
  transformPayload: (payload) => {
    return {
      grant_type: "http://auth0.com/oauth/grant-type/password-realm",
      audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
      client_id: process.env.REACT_APP_AUTH_0_CLIENT_ID,
      realm: process.env.REACT_APP_AUTH_0_CONNECTION,
      username: payload.username,
      password: payload.password,
      scope: "openid",
    };
  },
});

export const forgotPassword = authClient.post<void, { email: string }>(
  ENDPOINTS.forgotPassword,
  {
    transformPayload: (payload) => {
      return {
        client_id: process.env.REACT_APP_AUTH_0_CLIENT_ID,
        email: payload.email,
        connection: process.env.REACT_APP_AUTH_0_CONNECTION,
        realm: process.env.REACT_APP_AUTH_0_CONNECTION,
      };
    },
  }
);

export const dismissTokenExplanation = apiClient.post<
  void,
  { tokenExplanation: boolean }
>(ENDPOINTS.dismissTokenExplanation);

export const updateName = apiClient.post<UserProfile, Name>(
  ENDPOINTS.updateName
);

export const updateContactInfo = apiClient.post<UserProfile, ContactInfo>(
  ENDPOINTS.updateContactInfo
);

export const updateSignupInfo = apiClient.post<
  UserProfile,
  Name & ContactInfo & Demographics
>(ENDPOINTS.updateContactInfo);

export const getAppointments = apiClient.get<void, void>(
  ENDPOINTS.getAppointments
);

export const requestTokens = apiClient.post<void, RequestTokenPayload>(
  ENDPOINTS.requestTokens
);

export const getRequestTokenStatusAll = apiClient.get<
  TokenRequestStatus[],
  void
>(ENDPOINTS.requestTokens);
