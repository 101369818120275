import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { DomainKey } from "domain/core/app/types";

import Wedge from "ui/components/Wedge";
import { domainToColor } from "domain/core/app/utils";
import { useTheme } from "@material-ui/core";

import Responsive from "ui/Responsive";
import { capitalize } from "lib/string";

interface DomainScoreProps {
  score: number;
  max: number;
  domain: string;
}

const domainScoreStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      minWidth: "220px",
      margin: "8px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "10px",
    },
  })
);

const DomainScore: React.FC<DomainScoreProps> = (props) => {
  const classes = domainScoreStyles();
  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <Wedge
          percent={props.score / props.max}
          fill={domainToColor[props.domain as DomainKey].light}
          size={60}
        />
      </div>
      <div className={classes.column}>
        <Typography variant="subtitle1" color="textSecondary">
          My {capitalize(props.domain)} Score
        </Typography>
        <Typography variant="h5">
          {props.score.toString()} / {props.max}
        </Typography>
      </div>
    </div>
  );
};

const largeScoreStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      minWidth: "350px",
      margin: "8px",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        height: "250px",
        margin: "auto",
        minWidth: "0px",
        width: "100%",

        "& * h6": {
          textAlign: "center",
        },
      },
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "10px",
    },
    wedgeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "10px",
    },
    h4: {
      alignSelf: "flex-end",
    },
  })
);

interface LargeDomainScoreProps {
  score: number;
  max: number;
  text: string;
  svgStyle?: string;
}

export const LargeDomainScore: React.FC<LargeDomainScoreProps> = (props) => {
  const classes = largeScoreStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <div className={classes.wedgeContainer}>
        <Responsive displayIn={["Desktop", "Tablet"]}>
          <Wedge
            style={props.svgStyle}
            percent={props.score / props.max}
            fill={theme.palette.primary.light}
            size={130}
          />
        </Responsive>
        <Responsive displayIn={["Mobile", "MobileXS"]}>
          <Wedge
            style={props.svgStyle}
            percent={props.score / props.max}
            fill={theme.palette.primary.light}
            size={110}
          />
        </Responsive>
      </div>
      <div className={classes.column}>
        <Typography variant="subtitle1" color="textSecondary">
          {props.text}
        </Typography>
        <div className={classes.row}>
          <Typography variant="h1">{props.score.toString()}</Typography>
          <Typography variant="h4" className={classes.h4}>
            / {props.max}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DomainScore;
