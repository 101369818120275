import { ContentState } from "./types";
import { REQUEST_STATUS } from "lib/types";
import { createReducer, match } from "re-reduced";
import { ContentActions } from "./actions";
import { createSelector } from "reselect";
import { IgniteState } from "domain/types";
import { uniqueArray } from "domain/core/app/utils";

export const initialState: ContentState = {
  searchResults: {
    items: [],
    pagination: { pageIndex: 0, pageSize: 0, total: 0 },
  },
  resultStatus: { status: REQUEST_STATUS.Idle },
};

export const getContentsState = (state: IgniteState) => state;

export const getResultsStatus = createSelector(
  getContentsState,
  (contents) => contents.content.resultStatus.status
);

export const getSearchResults = createSelector(getContentsState, (content) => {
  return content.content.searchResults;
});

export default createReducer<ContentState>(
  [
    match(ContentActions.fetchContentSearchResults.request, (state) => {
      return {
        ...state,
        resultStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    match(ContentActions.fetchContentSearchResults.failure, (state) => {
      return {
        searchResults: state.searchResults,
        resultStatus: { status: REQUEST_STATUS.Failed },
      };
    }),
    match(
      ContentActions.fetchContentSearchResults.success,
      (prevState, payload) => {
        const items = payload.searchResults.items.map((item) => {
          item.domains = uniqueArray(item.domains);

          return item;
        });

        payload.searchResults.items = items;

        if (
          payload.searchResults.pagination == null ||
          payload.searchResults.pagination.pageIndex === 1
        ) {
          return {
            searchResults: payload.searchResults,
            resultStatus: payload.resultStatus,
          };
        } else {
          return {
            searchResults: {
              items: [
                ...prevState.searchResults.items,
                ...payload.searchResults.items,
              ],
              pagination: payload.searchResults.pagination,
            },
            resultStatus: payload.resultStatus,
          };
        }
      }
    ),
  ],
  initialState
);
