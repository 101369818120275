import { SagaIterator } from "redux-saga";
import { put, takeLatest, all, call } from "redux-saga/effects";
import { ContentActions } from "./actions";
import * as api from "./api";
import { ContentSearchResult, ContentSearchPayload } from "./types";
import { REQUEST_STATUS, PaginatedResult } from "lib/types";
import { Action } from "re-reduced";

export default function* sagaWatcher() {
  yield all([
    takeLatest(
      ContentActions.fetchContentSearchResults.type,
      searchContentProviders
    )
  ]);
}

export function* searchContentProviders(
  action: Action<ContentSearchPayload>
): SagaIterator {
  yield put(ContentActions.fetchContentSearchResults.request());
  try {
    const result: PaginatedResult<ContentSearchResult> = yield call(
      api.fetchContentSearchResults,
      action.payload
    );

    yield put(
      ContentActions.fetchContentSearchResults.success({
        searchResults: result,
        resultStatus: { status: REQUEST_STATUS.Fulfilled }
      })
    );
  } catch (error) {
    yield put(ContentActions.fetchContentSearchResults.failure(error));
  }
}
