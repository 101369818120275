import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Section Domain Priority
import DomainIcon from "ui/components/DomainIcon";
import { capitalize } from "lib/string";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

const priorityStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "24px",
      position: "relative",
      paddingBottom: "15px",

      "& * button": {
        marginTop: "15px",
        marginBottom: "15px",
        width: "calc(100% - 30px)"
      },

      "& .MuiTypography-root": {
        height: "25px",
        margin: "auto"
      },

      [theme.breakpoints.down("sm")]: {
        position: "unset",
        paddingBottom: "0px",
        flexDirection: "row",
        margin: "15px 0px 15px 10px",
        marginLeft: "0px",
        "& .MuiTypography-root": {
          marginLeft: "0px"
        },
        "& *": {
          marginLeft: "0px"
        }
      }
    },
    absolute: {
      position: "absolute",
      bottom: "0px",
      [theme.breakpoints.down("sm")]: {
        position: "unset",
        bottom: "unset"
      }
    }
  })
);

interface PriorityProps extends TypographyProps {
  size?: "sm" | "md" | "lg" | "xl";
  domain: string;
}
const DomainPriority = (props: PriorityProps) => {
  const classes = priorityStyles();

  return (
    <div className={classes.container}>
      <DomainIcon borderless={true} domain={props.domain} size={props.size} />
      <Typography className={classes.absolute} {...props}>
        {capitalize(props.domain)}
      </Typography>
    </div>
  );
};

export default DomainPriority;
