import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import format from "date-fns/format";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { getBookingStatus } from "domain/providers/reducer";
import { BookingType } from "domain/providers/types";
import Responsive from "ui/Responsive";
import TabletModal from "ui/components/mobile/TabletModal";
import { useReduxState } from "re-reduced";
import IgniteToken from "assets/IgniteToken.svg";
import * as selectors from "domain/core/selector";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { addMinutes } from "date-fns";
import RequestMoreTokens from "./RequestMoreTokens";
import { Session, WorkshopProfile } from "domain/workshops/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "382px",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",
    },
    rootContainer: {
      margin: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      "& >:first-child": {
        marginTop: "0px",
      },
      "& >:last-child": {
        marginBottom: "0px",
      },
      "& > *": {
        marginBottom: theme.spacing(2),
      },
    },
    section: {
      width: "auto",
    },
    availableTimes: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "300px",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    title: {
      textAlign: "center",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      marginBottom: "16px",
      marginTop: "16px",

      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    appointmentChip: {
      width: "105px",
      "& span": {
        overflow: "visible",
        textOverflow: "unset",
      },
    },
    timeChip: {
      width: "4.5rem",
      "& span": {
        overflow: "visible",
        textOverflow: "unset",
      },
    },
    chip: {
      margin: "5px 0px 0px 0px",
      borderRadius: "5px",
      backgroundColor: "#E4E8F4",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
      cursor: "pointer",
      textAlign: "left",
      "&:hover": {
        backgroundColor: "#c9bfee",
      },

      "&:hover div": {
        backgroundColor: "#7E49DD64",
      },
    },
    typeChip: {
      "& span": {
        margin: "auto",
        marginLeft: "0px",
      },
    },
    selectedChip: {
      backgroundColor: "#c9bfee",
    },
    icon: {
      margin: "auto",
    },
    bookButton: {
      fontFamily: "Gilroy Bold",
      fontSize: "14px",
      textTransform: "inherit",
      color: "white",
      backgroundColor: "#04aaf0",
      shadow: "none",
      boxShadow: "none",
      height: "40px",
      width: "189px",
    },
    bookingChipContainer: {
      marginBottom: "5px",
      marginTop: "5px",
      maxHeight: "90px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "center",

        "& .MuiGrid-item": {
          padding: "4px",
        },
      },
    },
    divider: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        marginRight: "0px",
      },
    },
    tokenText: {
      margin: "5px",
      fontFamily: "Gilroy Bold",
      fontSize: "16px",
      color: "black",
    },

    desktop: {
      display: "flex",
      "@media(max-width: 810px)": {
        display: "none",
      },
    },
    loader: {
      margin: "auto",
    },
    tokenContainer: {
      display: "flex",
      margin: "auto 10px",
      maxHeight: "35px",
    },
    tokenTextContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "auto 15px",
      maxHeight: "35px",
      height: "24px",
    },
    tokenCost: {
      margin: "auto",
      ...theme.typography.h5,
      fontFamily: "Gilroy Bold",
      color: "#030822",
    },
    tokenBalance: {
      margin: "auto",
      ...theme.typography.subtitle1,
      fontFamily: "Gilroy Bold",
      color: "#030822",
      marginBottom: "0px",
    },
    tokenNotEnough: {
      color: theme.palette.error.main,
      marginTop: "8px",
    },

    mobileFooter: {
      backgroundColor: theme.palette.background.paper,
      display: "none",
      height: 75,
      width: 320,
      zIndex: 999,
      justifyContent: "space-between",
      boxShadow: theme.shadows[8],
      "@media (max-width: 810px)": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100vw",

        "& button": {
          marginRight: "30px",
        },

        "& >:first-child": {
          marginLeft: "30px",
        },
      },
    },
    mobileFooterAndError: {
      backgroundColor: theme.palette.background.paper,
      display: "none",
      zIndex: 999,
      height: 120,
      width: 320,
      justifyContent: "space-between",
      boxShadow: theme.shadows[8],
      "@media (max-width: 810px)": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100vw",
      },
    },
    footerContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      margin: "auto",
      width: "100%",
      paddingTop: "10px",

      "& button": {
        margin: "auto 30px auto auto ",
        [theme.breakpoints.down("xs")]: {
          maxHeight: "40px",
          marginRight: "10px",
          // marginTop: "10px",
        },
      },

      "& >:first-child": {
        marginLeft: "30px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "10px",
        },
      },
    },
    footerErrors: {
      margin: "10px",
      marginLeft: "30px",
      marginRight: "30px",

      [theme.breakpoints.down("sm")]: {
        marginLeft: "30px",
        marginRight: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "10px",
        marginRight: "10px",
      },
      display: "flex",
      "& > *": {},
      flexDirection: "column",
    },
  })
);

interface BookingTypeProps {
  image: string;
  onClick(): void;
  selected?: boolean;
}

export function getSlots(slots: number, available: number) {
  const el = [];
  const scale = slots > 18 ? 4 : 1;
  for (let i = 0; i < (slots - available) / scale; i++) {
    el.push(
      <div
        key={i}
        style={{
          height: 20,
          width: 4,
          borderRadius: 5,
          backgroundColor: "#6020D4",
          marginRight: 5,
        }}
      ></div>
    );
  }

  for (let i = 0; i < available / scale; i++) {
    el.push(
      <div
        key={i + slots}
        style={{
          height: 20,
          width: 4,
          borderRadius: 5,
          backgroundColor: "rgba(96, 32, 212, 0.25)",
          marginRight: 5,
        }}
      ></div>
    );
  }
  return el;
}
interface Props {
  profile: Omit<WorkshopProfile, "sessions">;
  session: Session;
  onConfirmBooking: (date: Date, type: BookingType) => void;
  onClose?: () => void;
}

export function WorkshopSingleCard({
  profile,
  session,
  onConfirmBooking,
  onClose,
  disabled,
}: Props & { disabled: boolean }) {
  const classes = useStyles();

  const {
    tokenBalance,
    hasActiveTokenRequest,
    tokenRequestAmount,
    activeTokenRequestStatus,
  } = useReduxState({
    bookingStatus: getBookingStatus,
    tokenBalance: selectors.getTokenBalance,
    tokenRequestAmount: selectors.getLatestRequestAmount,
    hasActiveTokenRequest: selectors.getHasPendingTokenRequest,
    activeTokenRequestStatus: selectors.getTokenRequestsStatus,
  });

  //eslint-disable-next-line
  const [openBookingCard, setOpenBookingCard] = React.useState(false);

  function confirmBooking() {
    onConfirmBooking(session.time, profile.appointmentType as BookingType);
    setOpenBookingCard(false);
  }

  //eslint-disable-next-line
  const bookingModal = () => {
    return (
      <TabletModal
        onClose={() => setRequestMoreOpen(false)}
        label="Request More Tokens"
        zIndex={1200}
        open={requestMoreOpen}
      >
        {renderCard()}
      </TabletModal>
    );
  };

  function getFooter() {
    if (profile !== null && tokenBalance < profile.unitsPerSession) {
      return (
        <React.Fragment>
          <div className={classes.mobileFooterAndError}>
            <div className={classes.footerContainer}>
              {renderTokens()}
              {getBookingButton()}
            </div>

            <div className={classes.footerErrors}>
              {!hasActiveTokenRequest && (
                <React.Fragment>
                  <Responsive displayIn={["Desktop", "Tablet", "Mobile"]}>
                    <Typography
                      variant="caption"
                      className={classes.tokenNotEnough}
                    >
                      You do not have enough tokens for booking this appointment
                    </Typography>
                  </Responsive>
                  <Responsive displayIn={["MobileXS"]}>
                    <Typography
                      variant="caption"
                      className={classes.tokenNotEnough}
                    >
                      You do not have enough tokens for a booking
                    </Typography>
                  </Responsive>
                  <div style={{ display: "block", width: "100%" }}>
                    <Button
                      variant="text"
                      style={{ marginTop: "8px" }}
                      color="secondary"
                      onClick={() => setRequestMoreOpen(true)}
                    >
                      Request More
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {hasActiveTokenRequest && (
                <Typography
                  variant="caption"
                  className={classes.tokenNotEnough}
                >
                  You do not have enough tokens, but your request for{" "}
                  {tokenRequestAmount} tokens is currently under review.
                </Typography>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (disabled) {
      return (
        <React.Fragment>
          <div
            className={classes.mobileFooterAndError}
            style={{ height: "fit-content" }}
          >
            <div
              className={classes.footerContainer}
              style={{ paddingTop: 0, marginBottom: "0px", marginTop: "16px" }}
            >
              {renderTokens()}
              {getBookingButton()}
            </div>

            <div className={classes.footerErrors}>
              <Typography variant="caption" color="error" align="right">
                You've already booked this workshop!
              </Typography>
            </div>
          </div>
        </React.Fragment>
      );
    } else
      return (
        <React.Fragment>
          <div className={classes.mobileFooter}>
            {renderTokens()}
            {getBookingButton()}
          </div>
        </React.Fragment>
      );
  }
  const theme = useTheme();
  //eslint-disable-next-line
  const Mobile = useMediaQuery(theme.breakpoints.down("sm"));
  //eslint-disable-next-line
  const MobileXS = useMediaQuery(theme.breakpoints.down("xs"));

  function getBookingButton() {
    return (
      <Button
        variant="contained"
        color="secondary"
        className={classes.bookButton}
        onClick={confirmBooking}
        disabled={
          tokenBalance < profile.unitsPerSession ||
          disabled ||
          session.slotsAvailable <= 0
        }
      >
        Book Workshop
      </Button>
    );
  }

  function renderTokens() {
    return (
      <div className={classes.tokenContainer}>
        <img
          style={{ width: 32, height: 32 }}
          src={IgniteToken}
          alt="tokens"
        />
        <div className={classes.tokenTextContainer}>
          <Typography
            className={`${classes.tokenCost} ${
              tokenBalance < profile.unitsPerSession && classes.tokenNotEnough
            }`}
            variant="h5"
          >
            {profile.unitsPerSession + "/"}
          </Typography>
          <Typography
            className={`${classes.tokenBalance} ${
              tokenBalance < profile.unitsPerSession && classes.tokenNotEnough
            }`}
            variant="subtitle1"
          >
            {tokenBalance}
          </Typography>
        </div>
      </div>
    );
  }
  const [requestMoreOpen, setRequestMoreOpen] = React.useState(false);

  const renderCard = () => {
    return (
      <Card className={`${classes.root}`}>
        <div className={classes.rootContainer}>
          <Typography
            className={classes.title}
            style={{ marginTop: "14px" }}
            variant="h5"
            align="center"
          >
            Make a Booking
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Date & Time
          </Typography>
          <div>
            <Typography variant="body1">
              {format(new Date(session.time), "iii, d MMMM yyyy")}
            </Typography>
            <Typography variant="body1">
              {format(new Date(session.time), "h:mm aa - ") +
                format(
                  addMinutes(new Date(session.time), session.duration),
                  "h:mm aa"
                )}
            </Typography>
          </div>
          <Divider />
          <Typography variant="subtitle1">Availability</Typography>
          <div style={{ display: "flex" }}>
            {getSlots(session.slots, session.slotsAvailable)}
            <Typography variant="body1" style={{ paddingLeft: "12px" }}>
              {session.slotsAvailable} Available!
            </Typography>
          </div>
          <Divider />
          <Typography variant="subtitle1">Quantity: 1</Typography>

          <Grid
            className={`${classes.section} ${classes.desktop}`}
            container
            direction="row"
            alignItems="center"
            style={{ height: 90, margin: "0px" }}
          >
            <Grid container direction="row" alignItems="center" spacing={2}>
              {renderTokens()}
            </Grid>
            <Grid item style={{ alignSelf: "flex-end" }}>
              {getBookingButton()}
            </Grid>
          </Grid>
          <Responsive displayIn={["Tablet", "Desktop"]}>
            {disabled ? (
              <Typography variant="caption" className={classes.tokenNotEnough}>
                You've already booked this workshop!
              </Typography>
            ) : tokenBalance < profile.unitsPerSession ? (
              !hasActiveTokenRequest &&
              activeTokenRequestStatus.status === "Fulfilled" ? (
                <div style={{ display: "block", width: "100%" }}>
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.tokenNotEnough}
                  >
                    You do not have enough tokens to book this appointment
                  </Typography>
                  <br />
                  <Button
                    variant="text"
                    style={{ marginTop: "8px" }}
                    color="secondary"
                    onClick={() => setRequestMoreOpen(true)}
                  >
                    Request More
                  </Button>
                </div>
              ) : (
                <div
                  style={{ display: "block", width: "100%" }}
                  className={classes.tokenNotEnough}
                >
                  <Typography variant="caption" color="error">
                    You do not have enough tokens to book this appointment. Good
                    news, your request for {tokenRequestAmount} tokens is
                    currently under review.
                  </Typography>
                </div>
              )
            ) : null}
          </Responsive>
        </div>
      </Card>
    );
  };
  return (
    <React.Fragment>
      <TabletModal
        onClose={() => setRequestMoreOpen(false)}
        label="Request More Tokens"
        zIndex={1200}
        open={requestMoreOpen}
      >
        <RequestMoreTokens dismiss={() => setRequestMoreOpen(false)} />
      </TabletModal>
      <Responsive displayIn={["Tablet", "Desktop"]}>{renderCard()}</Responsive>
      <Responsive displayIn={["Mobile", "MobileXS"]}>{getFooter()}</Responsive>
    </React.Fragment>
  );
}

export default WorkshopSingleCard;
