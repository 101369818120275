import TagManager from "react-gtm-module";

import { PERSIST_KEY } from "store/configuration";
import { UserProfile } from "domain/core/auth/types";

class IgniteLayer {
  defaultArguments: Object;

  constructor() {
    this.defaultArguments = {};

    let defaultProfile = window.localStorage.getItem(
      `persist:@user:${PERSIST_KEY}:core/auth`
    );

    if (defaultProfile !== null) {
      let authState = JSON.parse(defaultProfile);
      if (authState !== null && authState !== undefined && authState.profile) {
        let userProfile = JSON.parse(authState.profile) as UserProfile;

        if (userProfile) {
          this.defaultArguments = {
            organisation: userProfile.organisation
              ? userProfile.organisation
              : "unknown",
          };
        }
      }
    }
  }

  setDefaultArgs(defaultArguments: Object) {
    this.defaultArguments = defaultArguments;
  }

  dataLayer(tagManagerArgs: any) {
    tagManagerArgs["dataLayer"]["details"] = {
      ...tagManagerArgs["dataLayer"]["details"],
      ...this.defaultArguments,
    };

    TagManager.dataLayer(tagManagerArgs);
  }
}

const IgniteTagManager = new IgniteLayer();

export function setDefaultArgs(defaultArguments: Object) {
  IgniteTagManager.setDefaultArgs(defaultArguments);
}

export default IgniteTagManager;
