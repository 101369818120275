import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Checkbox } from "ui/components/checkbox/Checkbox";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface Props {
  option: string;
  selected: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiselectItem: {
      display: "flex",
      width: "100%",
      "&:hover": {
        "& * .makeStyles-checkBoxOutline-418": {
          backgroundColor: "transparent",
          stroke: "#6020D4",
          cursor: "pointer",
        },
      },
    },
    checkBoxOutline: {
      stroke: "#B1B6CB",
    },
    checkBoxChecked: {},
    multiselectTypography: {
      marginLeft: "0px",
      margin: "12px",
      textAlign: "left",
    },
    multiselectCheckbox: {
      marginRight: "8px",
      marginLeft: "auto",
    },
  })
);

const CheckboxListItem = ({
  option,
  selected,
  onClick,
  style,
}: Props & { style?: CSSProperties }) => {
  const classes = useStyles();
  return (
    <div className={classes.multiselectItem} onClick={onClick} style={style}>
      <Typography variant="body1" className={classes.multiselectTypography}>
        {option}
      </Typography>
      <Checkbox checked={selected} />
    </div>
  );
};

export const ListItem = ({ option, onClick }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.multiselectItem} onClick={onClick}>
      <Typography variant="body1" className={classes.multiselectTypography}>
        {option}
      </Typography>
    </div>
  );
};

export default CheckboxListItem;
