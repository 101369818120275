import { WellbeingTestState } from "./types";
import { REQUEST_STATUS } from "lib/types";
import { createReducer, match, reduce, composeReducers } from "re-reduced";
import { WellbeingActions } from "./actions";
import { createSelector } from "reselect";
import { IgniteState } from "domain/types";

import always from "ramda/src/always";

export const initialState: WellbeingTestState = {
  answers: [],
  testResult: {
    focus: [],
    content: [],
    providers: [],
  },
  requests: {
    answersStatus: { status: REQUEST_STATUS.Idle },
    resultStatus: { status: REQUEST_STATUS.Idle },
  },
};

export const getWellbeingState = (state: IgniteState) => state;

export const getResultStatus = createSelector(
  getWellbeingState,
  (wellbeing) => {
    if (wellbeing.wellbeing.requests.resultStatus !== undefined) {
      return wellbeing.wellbeing.requests.resultStatus.status;
    }

    return { status: REQUEST_STATUS.Idle };
  }
);

export const getSaveStatus = createSelector(getWellbeingState, (wellbeing) => {
  if (wellbeing.wellbeing.requests.answersStatus !== undefined) {
    return wellbeing.wellbeing.requests.answersStatus.status;
  }

  return { status: REQUEST_STATUS.Idle };
});

export const getTestResult = createSelector(getWellbeingState, (wellbeing) => {
  return wellbeing.wellbeing.testResult;
});

export const getAnswers = createSelector(getWellbeingState, (wellbeing) => {
  return wellbeing.wellbeing.answers;
});

const clearTest = createReducer(
  [reduce(WellbeingActions.clearTest, always(initialState))],
  initialState
);

const clearStatus = createReducer(
  [
    reduce(
      WellbeingActions.clearStatus,
      (prevState: WellbeingTestState, payload) => {
        return {
          ...prevState,
          requests: {
            answersStatus: prevState.requests.answersStatus,
            resultStatus: { status: REQUEST_STATUS.Idle },
          },
        };
      }
    ),
  ],
  initialState
);

const reprioritise = createReducer(
  [
    reduce(
      WellbeingActions.reprioritise,
      (prevState: WellbeingTestState, payload) => {
        return {
          ...prevState,
          answers: payload.answers.map((item) => {
            let reset = item;
            reset.priority = 0;
            return {
              ...reset,
            };
          }),

          requests: {
            resultStatus: { status: REQUEST_STATUS.Idle },
            answersStatus: { status: REQUEST_STATUS.Fulfilled },
          },
        };
      }
    ),
  ],
  initialState
);

const baseReducer = createReducer<WellbeingTestState>(
  [
    match(WellbeingActions.getWellbeingResults.request, (state) => {
      return {
        ...state,
        requests: {
          answersStatus: state.requests.answersStatus,
          resultStatus: { status: REQUEST_STATUS.Pending },
        },
      };
    }),
    match(WellbeingActions.getWellbeingResults.failure, (state) => {
      return {
        answers: state.answers,
        testResult: state.testResult,
        requests: {
          ...state.requests,
          resultStatus: { status: REQUEST_STATUS.Failed },
        },
      };
    }),
    match(
      WellbeingActions.getWellbeingResults.success,
      (prevState, payload) => {
        return {
          answers: payload.answers,
          testResult: payload.testResult,
          requests: {
            answersStatus: prevState.requests.answersStatus,
            resultStatus: payload.requests.resultStatus,
          },
        };
      }
    ),
    match(WellbeingActions.fetchWellbeingResult.request, (state) => {
      return {
        ...state,
        requests: {
          resultStatus: { status: REQUEST_STATUS.Pending },
        },
      };
    }),
    match(WellbeingActions.fetchWellbeingResult.failure, (state) => {
      return {
        answers: state.answers,
        testResult: state.testResult,
        requests: {
          ...state.requests,
          resultStatus: { status: REQUEST_STATUS.Failed },
        },
      };
    }),
    match(
      WellbeingActions.fetchWellbeingResult.success,
      (prevState, payload) => {
        return {
          answers: prevState.answers,
          testResult: payload.testResult,
          requests: {
            ...prevState.requests,
            resultStatus: { status: REQUEST_STATUS.Fulfilled },
          },
        };
      }
    ),

    match(WellbeingActions.saveTest.request, (state) => {
      return {
        ...state,
        requests: {
          answersStatus: { status: REQUEST_STATUS.Pending },
        },
      };
    }),
    match(WellbeingActions.saveTest.failure, (state) => {
      return {
        ...state,
        requests: {
          answersStatus: { status: REQUEST_STATUS.Failed },
        },
      };
    }),
    match(WellbeingActions.saveTest.success, (prevState, payload) => {
      return {
        ...prevState,
        answers: payload.answers,

        requests: {
          resultStatus: prevState.requests.resultStatus,
          answersStatus: { status: REQUEST_STATUS.Fulfilled },
        },
      };
    }),
  ],
  initialState
);

export default composeReducers(
  baseReducer,
  clearTest,
  clearStatus,
  reprioritise
);
