import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";
import Container from "@material-ui/core/Container";
import Banner from "ui/components/Banner";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Chip } from "@material-ui/core";
import DomainIcon from "ui/components/DomainIcon";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: "5rem",
    },
    subtext: {
      fontFamily: "Gilroy Regular",
      fontSize: "28px",
    },
    flexContainer: {
      display: "flex",
    },
    divider: {
      marginTop: "25px",
      marginBottom: "25px",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      "& >:last-child": {
        margin: "0px",
      },
    },
    listItem: {
      margin: "0px 0px 36px 0px",
    },
    showMD: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
    },
    hideMD: {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    button: {
      width: "225px",
      height: "50px",
      margin: "auto",
      marginBottom: "20px",
      "& span": {
        fontSize: "14px",
        fonatFamily: "Gilroy Bold",
      },
    },
    card: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
      position: "relative",

      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      flexWrap: "wrap",

      backgroundColor: theme.palette.background.paper,
    },
    container: {
      "@media(max-width: 1300px)": {
        justifyContent: "center",
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column-reverse",
        "&:first-child": {
          order: 2,
        },
        "&:nth-child(2)": {
          order: 1,
        },
      },
    },
    descriptorCard: {
      width: "800px",
      height: "auto",

      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto",
      },
    },
    emergencyContainer: {},

    coronaCard: {
      position: "relative",
      width: "800px",

      marginBottom: "16px",

      [theme.breakpoints.down("md")]: {
        width: "100%",
        minHeight: "180px",
        height: "auto",
      },
    },
    igniteBanner: {
      width: "100%",
      height: "58px",
      marginBottom: "20px",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexDirection: "row",
    },
    listContainer: {
      margin: "35px",
    },
    coronaBanner: {
      width: "100%",
      height: "58px",
      marginBottom: "20px",
      backgroundColor: "#FF7A00",
      display: "flex",
      flexDirection: "row",
    },
    coronaChip: {
      ...theme.typography.h5,
      fontSize: "20px !important",
      margin: "auto auto auto 24px",
      border: "none",
      background: "transparent",
      stroke: "white",
      height: "50px",
      color: "white",
      " & *": {
        stroke: "white",
        color: "white",
      },

      "& .MuiChip-icon ": {
        width: "40px !important",
        height: "40px !important",
      },
      "& svg ": {
        width: "40px !important",
        height: "40px !important",
      },
    },
    emergencyCard: {
      width: "380px",
      height: "170px",

      "& > p": {
        fontSize: "18px",
      },

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },

      "@media(max-width: 415px)": {
        height: "auto",
        "& *": {
          textAlign: "center",
          fontSize: "14px",
        },
      },
    },
    header: {
      ...theme.typography.h6,
      lineHeight: "24px",
      letterSpacing: "0.44px",
      marginBottom: "5px",
    },
    mainHeading: {
      ...theme.typography.h5,
      marginBottom: "16px",
    },
    text: {
      ...theme.typography.body1,
      lineHeight: "24px",
      letterSpacing: "0.44px",
      "& b": {
        fontFamily: "Gilroy Bold",
      },
    },
    pageContentContainer: {
      marginTop: "-110px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-50px",
      },
    },
  })
);

interface Props extends RouteComponentProps {}

function CrisisPage(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Banner variant="secondary">
        <div>Need help now?</div>
        <p>
          If you need to talk to someone urgently, please contact one of the
          helplines below.
        </p>
      </Banner>
      <div className={classes.pageContentContainer}>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            spacing={2}
            className={classes.container}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="space-around"
                spacing={4}
                className={`${classes.showMD}`}
              >
                <Grid item>
                  <Typography variant="body1" align="center">
                    Not urgent, but you’d like some support? <br />
                    Our curated resources are here to help.
                  </Typography>
                </Grid>
                <Grid item>
                  <div className={classes.flexContainer}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={() => props.history.push("/search")}
                    >
                      Browse Providers
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Card className={`${classes.coronaCard}`}>
                <div className={classes.coronaBanner}>
                  <Chip
                    className={classes.coronaChip}
                    variant="outlined"
                    icon={
                      <DomainIcon
                        key={"COVID-19"}
                        domain={"COVID-19-WHITE"}
                        borderless={true}
                      />
                    }
                    label="COVID-19 Help"
                  />
                </div>

                <div className={classes.listContainer}>
                  <div className={classes.list}>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        Official COVID-19 NZ Government Information
                      </div>
                      <div className={classes.text}>
                        Visit{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://covid19.govt.nz"
                        >
                          covid19.govt.nz
                        </a>
                        <br />
                        to find out what help is available, FAQs and get the
                        latest news.
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Ministry of Health</div>
                      <div className={classes.text}>
                        Visit{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.health.govt.nz/our-work/diseases-and-conditions/covid-19-novel-coronavirus"
                        >
                          www.health.govt.nz/our-work/diseases-and-conditions/covid-19-novel-coronavirus
                        </a>
                        <br />
                        for latest updates, information and advice on COVID-19
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        Suspect you have COVID-19?
                      </div>
                      <div className={classes.text}>
                        Call Healthline (for free) on{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="tel:0800 358 5453 "
                        >
                          0800 358 5453{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>

              <Card className={`${classes.card} ${classes.descriptorCard}`}>
                <div className={classes.listContainer}>
                  <div className={classes.list}>
                    <div className={classes.mainHeading}>
                      24/7 Professional Support (confidential)
                    </div>

                    <div className={classes.text}>
                      To immediately speak to a trained Counsellor, please free
                      call or txt '1737'
                    </div>
                  </div>

                  <Divider className={classes.divider} />
                  <div className={classes.listItem}>
                    <div className={classes.mainHeading}>
                      Other Wellbeing Support Networks
                    </div>
                  </div>
                  <div className={classes.list}>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Lifeline (24/7)</div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 543 354">0800 543 354</a>
                        <br /> or free text: 4357
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        Suicide Crisis Helpline
                      </div>
                      <div className={classes.text}>
                        Call <a href="tel:0508 828 865">0508 828 865</a>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Youthline</div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 376 633">0800 376 633</a> <br />{" "}
                        or free text: 234
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Kidsline (under 18s)</div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 543 754">0800 543 754</a>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        What's up (5 - 18yrs)
                      </div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 942 8787">0800 942 8787</a>{" "}
                        (Midday - 11pm weekdays, 3pm - 11pm weekends) <br /> or
                        chat online at:{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://whatsup.co.nz"
                        >
                          whatsup.co.nz
                        </a>{" "}
                        (3pm - 10pm, 7 days a week)
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        Depression Helpline (Trained Counsellors)
                      </div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 111 757">0800 111 757</a> <br />{" "}
                        or free text: 4202
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        OUTLine NZ (Confidential Telephone Support)
                      </div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 688 5463"> 0800 688 5463</a>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>
                        Healthline (Registered Nurses)
                      </div>
                      <div className={classes.text}>
                        {" "}
                        Call <a href="tel:0800 611 116">0800 611 116</a>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Asian Helpline</div>
                      <div className={classes.text}>
                        Call <a href="tel:0800 862 342">0800 862 342</a>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.header}>Samaritans Helpline</div>
                      <div className={classes.text}>
                        {" "}
                        Call <a href="tel:0800 726 666">0800 726 666</a>
                      </div>
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.listItem}>
                    <div className={classes.mainHeading}>
                      Unsure of what to do?
                    </div>
                    <div className={classes.text}>
                      <br />
                      Don’t wait. If you need to talk to someone when you’re not
                      feeling well, always remember to reach out to a support
                      professional, trusted friend, or whānau member. Sharing
                      lifes challenges or your feelings with others does help.
                      <br />
                      <br />
                      If you need to talk to someone right away, please contact
                      one of the helplines above.
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justify="space-around"
                spacing={4}
                className={classes.emergencyContainer}
              >
                <Grid item>
                  <Card className={`${classes.card} ${classes.emergencyCard}`}>
                    <div className={classes.listContainer}>
                      <div className={classes.listItem}>
                        <div
                          className={classes.header}
                          style={{ color: "red" }}
                        >
                          Emergency? Call 111
                        </div>
                        <div className={classes.text}>
                          If you or someone your care about are in an
                          unsafe/emergency situation, please <b>call 111</b>{" "}
                          urgently.
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item className={classes.hideMD}>
                  <Typography variant="body1" align="center">
                    Not urgent, but you’d like some support? <br />
                    Our support providers are here to help.
                  </Typography>
                </Grid>
                <Grid item className={classes.hideMD}>
                  <div className={classes.flexContainer}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={() => props.history.push("/search")}
                    >
                      Browse Providers
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default withRouter(CrisisPage);
