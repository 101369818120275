import { createActions } from "re-reduced";

import { APIErrorResponse } from "lib/apiClient/createClient";
import { TransformedWellbeing, MoodDiaryResponse, DateAndMood } from "./types";
import {
  AggregatedAppoinments,
  GenericAppointment,
} from "domain/providers/types";
import { ContentProfile } from "domain/content/types";

export const DashboardActions = createActions("DASHBOARD", (create) => ({
  getWellbeingOverviewList: create.asyncAction<
    TransformedWellbeing,
    void,
    APIErrorResponse
  >(),
  getAppointments: create.asyncAction<
    AggregatedAppoinments,
    void,
    APIErrorResponse
  >(),
  getHotContent: create.asyncAction<ContentProfile, void>(),
  getMoodList: create.asyncAction<MoodDiaryResponse, void, APIErrorResponse>(),
  setMood: create.asyncAction<DateAndMood, DateAndMood, APIErrorResponse>(),
  cancelAppointment: create.asyncAction<
    GenericAppointment,
    GenericAppointment,
    APIErrorResponse
  >(),
  removeAppointmentLocal: create.action<GenericAppointment>(),
  resetDashboardState: create.action(),
}));
