import React, { useEffect } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { withRouter, RouteComponentProps } from "react-router";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "assets/icons/linkedin-box.svg";
import { Button, Link } from "@material-ui/core";
import { ReactComponent as IgniteLogo } from "assets/Logo/IgniteLogoColored.svg";
import TabletModal from "ui/components/mobile/TabletModal";
import PrivacyPolicy from "ui/compounds/PrivacyPolicy";
import { removeHash, setHash } from "domain/core/app/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      bottom: 0,

      backgroundColor: "white",
      paddingBottom: "40px",
      marginTop: "40px",
    },
    container: {
      marginTop: "90px",
      paddingLeft: "24px",
      paddingRight: "24px",

      "@media(max-width: 850px)": {
        paddingLeft: "18px",
        paddingRight: "18px",
      },
    },

    table: {},

    logo: {
      padding: "10px 0px",
      width: "150px",
      height: "42px",
    },

    floatBottom: {},

    brandItem: {
      minWidth: "300px",
      maxWidth: "300px",
      paddingRight: "75px",
      paddingBottom: "75px",

      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
      },
      display: "flex",
      flexDirection: "column",
    },
    listContainerItem: {
      minWidth: "220px",
      "& > *": {
        marginBottom: "15px",
      },
      "& h6": {
        marginBottom: "12px",
      },
    },

    supportContainer: {
      "& > *": {
        marginBottom: "15px",
      },
    },

    upperFooter: {
      display: "flex",
      flexDirection: "row",

      "@media(max-width: 850px)": {
        flexDirection: "column",
      },
    },

    link: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      textDecoration: "none",
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      cursor: "pointer",

      "& svg": {
        fill: "#5F6272",
      },

      "&:hover": {
        color: theme.palette.secondary.light,

        "& svg": {
          fill: theme.palette.secondary.light,
        },
      },
    },
    divider: {
      margin: "10px 0px",
    },
    button: {
      height: "30px",
      width: "165px",
      paddingTop: 0,
      paddingBottom: 0,
      position: "relative",
    },

    item1: {
      order: 1,
      "@media(max-width: 1279px)": {
        order: 3,
      },
    },
    item2: {
      order: 2,
      "@media(max-width: 1279px)": {
        order: 2,
      },
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },
    item3: {
      order: 3,
      "@media(max-width: 1279px)": {
        order: 4,
      },
    },
    item4: {
      order: 4,
      "@media(max-width: 1279px)": {
        order: 2,
      },
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },

    privacyPolicyCard: {
      "& .MuiPaper-root": {
        maxWidth: "1000px",
        position: "relative",
        maxHeight: "900px",
        width: "calc(100vw - 80px)",
        height: "calc(100vh - 80px)",
        backgroundColor: "white",
        borderRadius: "8px",

        [theme.breakpoints.down("xs")]: {
          left: 0,
          top: 0,
          position: "absolute",
          borderRadius: "0px",
          width: "100vw",
          height: "calc(100vh)",
        },
      },
    },
  })
);

interface LinkButtonProps {
  text: string;
  url: string;
  icon?: React.ReactElement;
  newTab?: boolean;
}

const LinkButton = ({ text, url, icon, newTab }: LinkButtonProps) => {
  const classes = useStyles();
  if (newTab) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {icon}
        {text}
      </a>
    );
  }
  return (
    <a href={url} className={classes.link}>
      {icon}
      {text}
    </a>
  );
};

const Footer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const [privacyPolicy, setPrivacyPolicyOpen] = React.useState(false);

  const setPrivacyPolicyModal = (state: boolean) => {
    if (state) {
      setHash("terms");
    } else {
      removeHash();
    }
    setPrivacyPolicyOpen(state);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash.length) {
      if (hash === "#terms") {
        setPrivacyPolicyOpen(true);
      }
    }
  }, []);

  function getExplore() {
    return (
      <Grid
        xl={"auto"}
        item
        md={6}
        sm={6}
        xs={12}
        lg={6}
        className={`${classes.listContainerItem} ${classes.item1}`}
      >
        <Typography variant="h6">Explore</Typography>
        <LinkButton text="Resources" url="/learn" />
      </Grid>
    );
  }

  function getToKnowUs() {
    return (
      <Grid
        item
        xl={"auto"}
        md={6}
        lg={6}
        xs={12}
        sm={6}
        className={`${classes.listContainerItem} ${classes.item2}`}
      >
        <Typography variant="h6">Get to know us</Typography>
        <LinkButton text="About us" newTab url="/about" />
        <LinkButton text="Our Approach" newTab url="/approach" />
        <LinkButton text="News" url="https://news.ignite.org.nz" newTab />
        <LinkButton text="Get in Touch" url="/contact" newTab />
      </Grid>
    );
  }

  function getSocialMediaContent() {
    return (
      <Grid
        item
        xl={"auto"}
        lg={6}
        md={6}
        xs={12}
        sm={6}
        className={`${classes.listContainerItem} ${classes.item3}`}
      >
        <Typography variant="h6">Connect With Us</Typography>
        <LinkButton
          text="Facebook"
          newTab
          url="https://www.facebook.com/igniteaotearoa/"
          icon={<Facebook />}
        />
        <LinkButton
          text="LinkedIn"
          newTab
          url="https://www.linkedin.com/company/igniteaotearoa/"
          icon={<LinkedIn />}
        />
      </Grid>
    );
  }

  function getNeedHelpNow() {
    return (
      <Grid
        xl={"auto"}
        lg={6}
        item
        md={6}
        sm={6}
        xs={12}
        className={`${classes.listContainerItem} ${classes.item4}`}
      >
        <div className={classes.supportContainer}>
          <Typography variant="h6">Need help right now?</Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => props.history.push("/crisis")}
          >
            Helplines
          </Button>
          <Typography variant="body2">24/7 Professional Support</Typography>
        </div>
      </Grid>
    );
  }

  return (
    <footer className={classes.root} id={"ignite-footer"}>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.upperFooter}>
          <div className={classes.brandItem}>
            <IgniteLogo className={classes.logo} />
            <Typography variant="body2">
              Ignite is proud to be part of the
              <br />
              <Link href="https://emergeaotearoa.org.nz/" target="_blank">
                Emerge Aotearoa Group
              </Link>
            </Typography>
            <img
              alt="shielded"
              id="shielded-logo"
              src="https://ignitecontent.blob.core.windows.net/websitecontent/shielded-logo-ignite.png"
              height="60"
              width="60"
              style={{
                cursor: "pointer",
                marginTop: "20px",
                display: "inherit",
              }}
            ></img>
          </div>
          <Grid container direction="row" justify="space-between" spacing={4}>
            {getExplore()}
            {getToKnowUs()}
            {getSocialMediaContent()}
            {getNeedHelpNow()}
          </Grid>
        </div>

        <Divider className={classes.divider} />
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography variant="body2">
              © 2020 by Ignite Aotearoa Limited
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Link onClick={() => setPrivacyPolicyModal(true)}>
                  Terms and Conditions
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <TabletModal
        onClose={() => setPrivacyPolicyModal(false)}
        cardClass={classes.privacyPolicyCard}
        open={privacyPolicy}
        label={"Privacy Policy"}
        zIndex={9999}
      >
        <PrivacyPolicy onClose={() => setPrivacyPolicyModal(false)} />
      </TabletModal>
    </footer>
  );
};

export default withRouter(Footer);
