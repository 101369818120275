import { createActions } from "re-reduced";
import { APIErrorResponse } from "lib/apiClient/createClient";

import {
  LoginPayload,
  AuthState,
  ForgotPasswordPayload,
  UserProfile,
  ContactInfo,
  Name,
  RequestTokenPayload,
  TokenRequestStatus,
  Demographics,
} from "domain/core/auth/types";

export default createActions("CORE/AUTH", (create) => ({
  login: create.asyncAction<AuthState, LoginPayload, APIErrorResponse>(),
  setProfile: create.asyncAction<UserProfile, UserProfile, APIErrorResponse>(),
  getProfile: create.asyncAction<UserProfile, void, APIErrorResponse>(),
  dismissTokenExplanation: create.asyncAction(),
  forgotPassword: create.asyncAction<
    void,
    ForgotPasswordPayload,
    APIErrorResponse
  >(),
  logout: create.asyncAction(),
  clear: create.action<void, AuthState>(),
  updateBalance: create.action<number, AuthState>(),
  updateName: create.asyncAction<UserProfile, Name, APIErrorResponse>(),
  updateContactInfo: create.asyncAction<
    UserProfile,
    ContactInfo,
    APIErrorResponse
  >(),
  updateSignupInfo: create.asyncAction<
    UserProfile,
    Name & ContactInfo & Demographics,
    APIErrorResponse
  >(),
  getAppointments: create.asyncAction<void, void, APIErrorResponse>(),
  requestTokens: create.asyncAction<
    RequestTokenPayload,
    RequestTokenPayload,
    APIErrorResponse
  >(),
  getRequestTokenStatusAll: create.asyncAction<
    TokenRequestStatus[],
    void,
    APIErrorResponse
  >(),
}));
