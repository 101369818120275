import { createActions } from "re-reduced";
import { APIErrorResponse } from "lib/apiClient/createClient";

import {
  ProviderSearchPayload,
  PlatformBookingPayload,
  ProviderBookResponse,
  ProviderSearchResult,
  Calendar,
  CalendarPayload,
  ProfileExtendedInfo,
} from "./types";
import { PaginatedResult } from "lib/types";

export const ProviderActions = createActions("PROVIDERS", (create) => ({
  fetchProviderSearchResults: create.asyncAction<
    PaginatedResult<ProviderSearchResult>,
    ProviderSearchPayload
  >(),
  putConfirmationBooking: create.asyncAction<
    ProviderBookResponse,
    PlatformBookingPayload,
    APIErrorResponse
  >(),
  getProviderById: create.asyncAction<
    ProfileExtendedInfo,
    { providerId: string },
    APIErrorResponse
  >(),
  fetchCalendar: create.asyncAction<
    Calendar,
    CalendarPayload,
    APIErrorResponse
  >(),
  fetchCalendarMonth: create.asyncAction<
    Calendar,
    CalendarPayload,
    APIErrorResponse
  >(),
  fetchCalendarDay: create.asyncAction<
    Calendar,
    CalendarPayload,
    APIErrorResponse
  >(),
  clearBookingStatus: create.action(),
}));
