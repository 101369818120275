import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/content/configuration";
import {
  ContentSearchPayload,
  ContentSearchResult,
} from "domain/content/types";
import { PaginatedResult } from "lib/types";

export const fetchContentSearchResults = client.post<
  PaginatedResult<ContentSearchResult>,
  ContentSearchPayload,
  PaginatedResult<ContentSearchResult>,
  {
    domains: string[];
    contentTypes: string[];
    tags: string[];
    searchText: string[];
    page: number;
    pageLength: number;
  }
>(ENDPOINTS.fetchContentSearchResults, {
  transformPayload: (payload) => {
    return {
      page: payload.page,
      pageLength: payload.pageLength,
      domains: payload.domainsOfSupport,
      contentTypes: payload.contentType,
      tags: payload.tags,
      searchText: payload.searchText,
    };
  },
});
