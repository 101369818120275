import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { REQUEST_STATUS } from "lib/types";

import { WorkshopActions } from "domain/workshops/actions";

import { useActions, useReduxState } from "re-reduced";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import Banner from "ui/components/Banner";
import TokenExplanation from "ui/compounds/providers/TokenExplanation";
import {
  getWorkshops,
  getWorkshopsResultStatus,
} from "domain/workshops/reducer";
import WorkshopProfileCard from "ui/compounds/Models/WorkshopCard";
import { Session, WorkshopProfile } from "domain/workshops/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    loaderContainer: {
      height: "300px",
    },
    chipContainer: {
      padding: "20px 0px 20px 0px",
    },
  })
);

interface Props extends RouteComponentProps {}
const Workshops: React.FC<Props> = (props) => {
  const [spacing] = React.useState<GridSpacing>(4);
  const classes = useStyles();
  const actions = useActions(WorkshopActions);

  const reduxState = useReduxState({
    searchResults: getWorkshops,
    resultStatus: getWorkshopsResultStatus,
  });

  const [s, ss] = useState(false);
  useEffect(() => {
    if (!s) {
      actions.getWorkshops();
      ss(true);
    }
  }, [s, actions, ss]);

  function getLoading() {
    if (reduxState.resultStatus.status === REQUEST_STATUS.Idle) {
      return (
        <Grid item>
          <Grid
            container
            className={classes.loaderContainer}
            alignContent="space-around"
          >
            <CircularIndeterminate />
          </Grid>
        </Grid>
      );
    }
  }
  function onSelectProfile(profile: WorkshopProfile, session: Session) {
    const path = generatePath("/workshop/:id/:time", {
      id: profile.workshopId,
      time: session.time.toUTCString(),
    });
    props.history.push({
      pathname: path,
      state: { from: props.history.location.pathname },
    });
  }

  function renderWorkshopCards() {
    if (reduxState.searchResults) {
      const workshops = reduxState.searchResults;

      return (
        <React.Fragment>
          {workshops.map((w) =>
            w.sessions.map((s, index) => (
              <Grid item key={w.workshopId + "idx:" + index}>
                <WorkshopProfileCard
                  profile={w}
                  session={s}
                  onSelect={onSelectProfile}
                />
              </Grid>
            ))
          )}
        </React.Fragment>
      );
    }
    return <div></div>;
  }
  return (
    <React.Fragment>
      <Banner
        src={require("assets/banner/WorkshopsBanner.png")}
        styleSheetClass="workshops-banner"
        align="right"
      >
        <div>Upcoming Workshops</div>
        <p>Education to support mental health and wellbeing in the workplace</p>
      </Banner>
      <Container maxWidth="xl" style={{ paddingTop: "60px" }}>
        <Grid
          className={classes.root}
          container
          justify="center"
          alignItems="flex-start"
          spacing={spacing}
        >
          {getLoading()}
          {renderWorkshopCards()}
        </Grid>
      </Container>
      <TokenExplanation />
    </React.Fragment>
  );
};

export default withRouter(Workshops);
