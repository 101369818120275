import React from "react";
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  SelectProps,
  Theme,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { grey } from "@material-ui/core/colors";
import { ReactComponent as UpArrow } from "assets/icons/chevron-down.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: 240,
      height: 100,
      marginTop: 60,
    },
    select: {
      height: "55px",
      marginTop: "16px",

      "& .MuiSelect-root": {
        borderRadius: "4px !important",
        backgroundColor: "transparent !important",
        border: `1px solid ${theme.palette.divider} !important`,

        "& :hover": {
          border: `1px solid ${theme.palette.secondary.main} !important`,
        },
        paddingTop: 6,
        paddingBottom: 6,
        height: "43px",
        display: "flex",
        "& span": {
          margin: "auto 5px",
          fontFamily: "Gilroy Regular",
          fontSize: 16,
        },
      },
    },
    placeholder: {
      color: "#5F6272 !important",
    },
    selected: {
      color: "#030822 !important",
    },
  })
);

const useOutlineSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      display: "flex",
      minWidth: 240,
      background: "white",
      borderStyle: "none",
      borderRadius: 8,
      paddingLeft: 24,
      paddingTop: 4,
      paddingBottom: 4,
      boxShadow: "none",
      "&:focus": {
        borderRadius: 8,
        background: "white",
      },
      '&[aria-expanded="true"]': {
        background: grey[50],
      },
      "& > div": {
        display: "inline-flex", // this shows the icon in the SelectInput but not the dropdown
      },
    },
    icon: {
      color: blue[500],
      right: 12,
      position: "absolute",
      userSelect: "none",
      pointerEvents: "none",
    },
    paper: {
      borderRadius: 4,
      marginTop: 1,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      background: "white",
      "& li": {
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 8 * 2,
        paddingLeft: 8 * 2,
      },

      "& li.Mui-selected": {
        color: "black",
        background: "white",
      },
    },
    listIcon: {
      minWidth: 32,
      display: "none", // hide the ListItemIcon in the dropdown
    },
  })
);

interface Props<T> {
  placeholder: string;
  value: () => T;
  handleChange: (
    e: React.ChangeEvent<{ name?: string | undefined; value: T }>
  ) => void;
  getOptions: () => JSX.Element[];
  options: any[];
  shrink?: boolean;
  className?: string;
  renderValue?: (e: any) => JSX.Element;
}

const Dropdown: React.FC<Props<any>> = (props) => {
  const classes = useStyles();
  const outlineSelectClasses = useOutlineSelectStyles();
  const iconComponent = (props: any) => {
    return <UpArrow className={props.className + " MuiSvgIcon-root"} />;
  };

  // moves the menu below the select input
  const menuProps = {
    classes: {
      paper: outlineSelectClasses.paper,
      list: outlineSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  } as SelectProps["MenuProps"];

  const [value, setValue] = React.useState(props.value());
  //eslint-disable-next-line
  const [initialValue, setInitialValue] = React.useState(props.value());

  return (
    <FormControl
      variant="outlined"
      className={props.className ? props.className : classes.formControl}
    >
      <InputLabel
        id="tokens-select-outlined-label"
        htmlFor="tokens-select-outlined"
        shrink={props.shrink || value !== initialValue}
        variant="standard"
        margin="dense"
      >
        {props.placeholder}
      </InputLabel>
      <Select
        MenuProps={menuProps}
        labelId="tokens-select-outlined-label"
        id="tokens-select-outlined"
        variant="outlined"
        IconComponent={iconComponent}
        // <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
        //   <input
        //     aria-invalid="false"
        //     id="component-last-name"
        //     placeholder="Last Name"
        //     type="text"
        //     className="MuiInputBase-input MuiInput-input"
        //     value=""
        //   />
        // </div>
        // inputComponent="text"
        renderValue={(e: any) => {
          if (props.renderValue) {
            return props.renderValue(e);
          }
          return (
            <span
              className={
                props.options.indexOf(e) !== -1
                  ? classes.selected
                  : classes.placeholder
              }
              style={{
                padding: "6px 0px 7px",
                boxSizing: "content-box",
              }}
            >
              {e}
            </span>
          );
        }}
        value={value}
        onChange={(
          e: React.ChangeEvent<{ name?: string | undefined; value: any }>
        ) => {
          setValue(e.target.value);
          props.handleChange(e);
        }}
        className={classes.select}
      >
        {props.getOptions()}
      </Select>
    </FormControl>
  );
};

export class DropdownClass<T> {
  public value: T;
  public getOptions: () => JSX.Element[];
  public className: string | undefined;
  public options: any[];
  public renderValue?: (e: any) => JSX.Element;
  public placeholder: string;
  public shrink: boolean;

  constructor(
    placeholder: string,
    shrink: boolean,
    value: T,
    getOptions: () => JSX.Element[],
    options: any[],
    className?: string,
    renderValue?: (e: any) => JSX.Element,
    validator?: () => void
  ) {
    this.placeholder = placeholder;
    this.options = options;
    this.value = value;
    this.getOptions = getOptions;
    this.renderValue = renderValue;
    this.className = className;
    this.shrink = shrink;
  }

  handleChange(e: React.ChangeEvent<{ name?: string | undefined; value: T }>) {
    this.value = e.target.value;
  }

  public render() {
    return (
      <FormControl
        className={`${this.className}`}
        error={false}
        required={false}
      >
        <Dropdown
          placeholder={this.placeholder}
          value={() => this.value}
          getOptions={this.getOptions}
          shrink={this.shrink}
          options={this.options}
          className={this.className}
          renderValue={this.renderValue}
          handleChange={(
            e: React.ChangeEvent<{ name?: string | undefined; value: T }>
          ) => this.handleChange(e)}
        />
      </FormControl>
    );
  }

  public getValues() {
    return this.value;
  }
}

export default DropdownClass;
