import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as RightArrow } from "assets/icons/RightArrow.svg";
import { ReactComponent as LeftArrow } from "assets/icons/LeftArrow.svg";
import { MONTH_NAMES } from "utils/dates";
import { CTA } from "domain/core/app/utils";

const sliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      maxWidth: "100%",
    },
    left: {
      display: "flex",
      height: "60px",
      flexGrow: 1,
      width: "20px",
      border: "2px solid white",
      boxShadow: "0px 11px 9px 7px white",
      zIndex: 1,
      "& svg": {
        margin: "auto auto auto 0px",
      },
    },
    right: {
      display: "flex",
      height: "60px",
      flexGrow: 1,
      width: "20px",
      border: "2px solid white",
      boxShadow: "0px 0px 9px 7px white",
      zIndex: 1,
      "& svg": {
        margin: "auto 0px auto auto",
      },
    },
    dateContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      overflow: "hidden",
      flexWrap: "nowrap",
      width: "100%",
      position: "relative",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "220px",
      },
    },
    scrollContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row-reverse",
      flexWrap: "nowrap",
      overflow: "scroll",
      // overflowX: "hidden",

      // "&::-webkit-scrollbar": {
      //   display: "none",
      // },
    },

    date: {
      display: "flex",
      flexDirection: "column",
      columnWidth: "150px",
      justifyContent: "space-evenly",
      margin: "auto 8px",
      maxWidth: "128px",
      minWidth: "108px",
      minHeight: "61px",
      maxHeight: "68px",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: "8px",
      transition: "all .25s ease",
      paddingTop: "15px",
      marginTop: "0px",

      "&:hover": {
        cursor: "pointer",
        "& h6": {
          color: CTA,
        },
      },

      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        margin: "8px",
      },

      "& h6": {
        textAlign: "center",
        color: "#9297AD",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
    },
    selectedDate: {
      borderRadius: "0px",
      borderTop: "5px solid #04AAF0",
      paddingTop: "10px",

      "& h6": {
        color: CTA,
      },
    },
  })
);

interface DateSliderProps {
  list: Date[];
  selectedDate: Date;
  visibleDaysLength?: number;
  onChange?: (selectedDate: Date) => void;
}

const DateSlider = (props: DateSliderProps) => {
  const classes = sliderStyles();

  function handleSlotClick(date: Date) {
    if (!!props.onChange) {
      props.onChange(date);

      let index = props.list.indexOf(date);
      if (index >= 0 && index <= props.list.length - 1) {
        if (scrollRef.current !== null) {
          let child = scrollRef.current.children[index] as HTMLElement;

          let value =
            scrollRef.current.scrollWidth -
            scrollRef.current.clientWidth -
            (index * (child.clientWidth + 16) -
              scrollRef.current.clientWidth / 2 +
              (child.clientWidth + 16) / 2);

          let scrollToOptions = {
            left: value,
            top: 0,
            behavior: "smooth",
          } as ScrollToOptions;
          scrollRef.current.scrollTo(scrollToOptions);
        }
      }
    }
  }

  const scrollRef = React.useRef<HTMLDivElement>(null);

  function scrollTo(direction: number) {
    if (!!props.onChange) {
      let index = props.list.indexOf(props.selectedDate);
      if (
        index + direction >= 0 &&
        index + direction <= props.list.length - 1
      ) {
        if (scrollRef.current !== null) {
          let child = scrollRef.current.children[
            index + direction
          ] as HTMLElement;

          let value =
            scrollRef.current.scrollWidth -
            scrollRef.current.clientWidth -
            ((index + direction) * (child.clientWidth + 16) -
              scrollRef.current.clientWidth / 2 +
              (child.clientWidth + 16) / 2);

          let scrollToOptions = {
            left: value,
            top: 0,
            behavior: "smooth",
          } as ScrollToOptions;
          scrollRef.current.scrollTo(scrollToOptions);
        }

        props.onChange(props.list[index + direction]);
      }
    }
  }

  function getTimelineButton(date: Date) {
    return (
      <div
        className={`${
          props.selectedDate === date ? classes.selectedDate : ""
        } ${classes.date}`}
        key={date.toString() + Math.random() * 10}
        onClick={() => handleSlotClick(date)}
      >
        <Typography variant="subtitle1">
          {date.getDate()} {MONTH_NAMES[date.getMonth()]}
        </Typography>
        <Typography variant="subtitle1">{date.getFullYear()}</Typography>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <div className={classes.left} onClick={() => scrollTo(1)}>
        <LeftArrow
          style={{
            stroke:
              props.list.indexOf(props.selectedDate) === props.list.length - 1
                ? "gray"
                : CTA,
          }}
        />
      </div>
      <div className={classes.scrollContainer} ref={scrollRef}>
        {props.list.map((item) => getTimelineButton(item))}
      </div>
      <div className={classes.right} onClick={() => scrollTo(-1)}>
        <RightArrow
          style={{
            stroke: props.list.indexOf(props.selectedDate) === 0 ? "gray" : CTA,
          }}
        />
      </div>
    </div>
  );
};

export default DateSlider;
