import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import banner from "assets/banner/PurpleBanner.png";
import hero from "assets/banner/HeroBanner.jpg";
import sectionLeftGon from "assets/banner/SectionPatternLeft.svg";
import sectionRightGon from "assets/banner/SectionPatternRight.svg";
import sectionLeftGonBlue from "assets/banner/SectionPatternLeftBlue.svg";
import sectionRightGonBlue from "assets/banner/SectionPatternRightBlue.svg";
import SqaureToTheRight from "assets/banner/Square-to-the-right.svg";
import RightSquareMobile from "assets/banner/RightSquareMobile.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    leftGon: {
      zIndex: "inherit",
      position: "absolute",
      height: "100%",
      left: 0,
      bottom: 0,
    },
    rightGon: {
      zIndex: "inherit",
      position: "absolute",
      right: 0,
      top: 0,
      height: "100%",

      [theme.breakpoints.down("xs")]: {
        height: "50%",
        // display: "none",
      },
    },
    searchMessage: {
      width: "100%",
      height: "auto",
      margin: "auto",

      "& div": {
        fontFamily: "Gilroy ExtraBold",
        fontWeight: 800,
        fontSize: 75,
        letterSpacing: -1.171,
        color: "white",
        display: "table-cell",
        verticalAlign: "bottom",

        [theme.breakpoints.up("lg")]: {
          fontSize: "65px",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: "50px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        fontFamily: "Gilroy Bold",
        fontStyle: "normal",
        fontWeight: 500,
        marginTop: "8px",
        fontSize: 34,
        letterSpacing: -0.171,
        color: "white",

        [theme.breakpoints.down("lg")]: {
          fontSize: "22px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          marginTop: "4px",
        },
      },
    },
    bannerBackground: {
      [theme.breakpoints.down("sm")]: {
        background:
          "linear-gradient(90deg, #6020D4 0%, #7E49DD 97.4%)  !important",
      },
    },
    bannerBackgroundBlue: {
      [theme.breakpoints.down("sm")]: {
        background:
          "linear-gradient(90deg, #43BFF3 0%, #0095D4 100%) !important",
      },
    },
    searchMessageContainer: {
      display: "flex",
      height: "500px",

      "& img": {
        margin: "auto",
        marginBottom: "110px",
        marginLeft: "35px",
        maxWidth: "500px",
        maxHeight: "130px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "500px",
        width: "100%",
      },
      [theme.breakpoints.down("lg")]: {
        height: "500px",
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        height: "fit-content",
        paddingTop: "40px",
        minHeight: "250px",
        width: "100%",
      },
    },

    mobileXS: {
      display: "none",
      zIndex: "inherit",
      position: "absolute",
      right: 0,
      top: 0,
      [theme.breakpoints.down("xs")]: {
        display: "inherit",
      },
    },
    wrap: {
      display: "flex",
      height: "auto",
      width: "100%",
      position: "relative",
    },
  })
);

interface Props {
  variant?: "primary" | "secondary" | "hero" | undefined;
  src?: string;
  align?: "right" | "left" | "center";
  styleSheetClass?: string;
}

const Banner: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const imageSrc = () => {
    let image = null;
    if (props.src) {
      image = props.src;
      return {
        background: `url("${image}") no-repeat ${
          props.align ? props.align : "right"
        }`,
      };
    }

    switch (props.variant) {
      case "primary":
        return {
          background: "linear-gradient(90deg, #6020D4 0%, #7E49DD 97.4%)",
        };

      case "secondary":
        return {
          background: "linear-gradient(90deg, #43BFF3 0%, #0095D4 100%)",
        };

      case "hero":
        image = hero;
        break;
      default:
        image = banner;
        break;
    }

    return { background: `url("${image}") no-repeat center` };
  };

  const isColorVariant = () => {
    if (props.src) {
      return (
        <React.Fragment>
          <img alt="" className={classes.leftGon} src={SqaureToTheRight} />
          <img
            alt=""
            className={`${classes.mobileXS}`}
            src={RightSquareMobile}
          />
        </React.Fragment>
      );
    } else if (props.variant === "primary") {
      return (
        <React.Fragment>
          <img alt="" className={classes.leftGon} src={sectionLeftGon} />
          <img alt="" className={classes.rightGon} src={sectionRightGon} />
        </React.Fragment>
      );
    } else if (props.variant === "secondary") {
      return (
        <React.Fragment>
          <img alt="" className={classes.leftGon} src={sectionLeftGonBlue} />
          <img alt="" className={classes.rightGon} src={sectionRightGonBlue} />
        </React.Fragment>
      );
    }
    return null;
  };
  return (
    <div
      id="stage"
      className={`${
        window.innerWidth < 1000
          ? props.variant === "secondary"
            ? classes.bannerBackgroundBlue
            : classes.bannerBackground
          : classes.bannerBackground
      }${classes.root} ${props.variant !== "secondary" ? "banner-root" : ""} ${
        props.styleSheetClass
      }`}
      style={{
        ...imageSrc(),
        maxWidth: "1920px",
        overflow: "hidden",
      }}
    >
      <div className={classes.wrap}>
        {isColorVariant()}
        <div className={classes.wrap}>
          <Container maxWidth="xl" className={classes.searchMessageContainer}>
            <div className={classes.searchMessage}>{props.children}</div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Banner;
