import { createReducer, reduce, match, composeReducers } from "re-reduced";
import { persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { REQUEST_STATUS } from "lib/types";
import { PERSIST_KEY } from "store/configuration";
import { AuthState } from "domain/core/auth/types";
import actions from "domain/core/auth/actions";
import always from "ramda/src/always";

export const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  login: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },
  forgotPassword: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },
  accountInfoName: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },
  accountSignupInfo: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },
  accountInfoContact: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },
  requestTokens: {
    status: REQUEST_STATUS.Idle,
    error: undefined,
  },

  tokenRequests: {
    requestStatus: {
      status: REQUEST_STATUS.Idle,
      error: undefined,
    },
    requests: [],
  },
  profile: {
    userId: "",
    auth0Id: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    secondaryEmail: "",
    phone: "",
    availableTokens: 0,
    tokenExplanation: false,
    userOnboarded: false,
    workshopsEnabled: false,
    providersEnabled: false,
  },
};

const clearReducer = createReducer(
  [reduce(actions.clear, always(INITIAL_STATE))],
  INITIAL_STATE
);

const reducer = createReducer<AuthState>(
  [
    match(actions.updateBalance, (state, payload) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          availableTokens: payload,
        },
      };
    }),
    match(actions.dismissTokenExplanation, (state, _) => ({
      ...state,
      profile: {
        ...state.profile,
        tokenExplanation: true,
      },
    })),
    match(actions.login.request, (state, _) => ({
      ...state,
      login: {
        status: REQUEST_STATUS.Pending,
      },
    })),
    match(actions.login.success, (state, { profile, accessToken }) => ({
      ...state,
      profile: {
        ...profile,
      },
      isAuthenticated: true,

      login: {
        status: REQUEST_STATUS.Fulfilled,
      },
      accessToken: accessToken,
    })),
    match(actions.login.failure, (_, error) => {
      return {
        ...INITIAL_STATE,

        login: {
          status: REQUEST_STATUS.Failed,
          error: error,
        },
      };
    }),
    match(actions.logout.request, () => {
      window.location.href = "/login";
      return INITIAL_STATE;
    }),
    match(actions.logout.success, () => {
      return INITIAL_STATE;
    }),
  ],
  INITIAL_STATE
);

const accountInfo = createReducer<AuthState>(
  [
    match(actions.updateContactInfo.request, (state, _) => ({
      ...state,
      accountInfoContact: {
        status: "Pending",
      },
    })),
    match(actions.updateContactInfo.success, (state, payload) => ({
      ...state,
      accountInfoContact: {
        status: "Fulfilled",
      },
      profile: {
        ...state.profile,
        ...payload,
      },
    })),
    match(actions.updateContactInfo.failure, (state, error) => ({
      ...state,
      accountInfoContact: {
        status: "Failed",
      },
    })),
    match(actions.updateSignupInfo.request, (state, _) => ({
      ...state,
      accountSignupInfo: {
        status: "Pending",
      },
    })),
    match(actions.updateSignupInfo.success, (state, payload) => ({
      ...state,
      accountSignupInfo: {
        status: "Fulfilled",
      },
      profile: {
        ...state.profile,
        ...payload,
      },
    })),
    match(actions.updateSignupInfo.failure, (state, error) => ({
      ...state,
      accountSignupInfo: {
        status: "Failed",
      },
    })),

    match(actions.updateName.request, (state, _) => ({
      ...state,
      accountInfoName: {
        status: "Pending",
      },
      forgotPassword: {
        status: "Idle",
      },
    })),
    match(actions.updateName.success, (state, payload) => ({
      ...state,
      accountInfoName: {
        status: "Fulfilled",
      },
      profile: {
        ...state.profile,
        ...payload,
      },
    })),
    match(actions.updateName.failure, (state, error) => ({
      ...state,
      accountInfoName: {
        status: "Failed",
      },
    })),
  ],
  INITIAL_STATE
);

const forgotPassword = createReducer<AuthState>(
  [
    match(actions.forgotPassword.request, (state, _) => ({
      ...state,
      forgotPassword: { status: REQUEST_STATUS.Pending },
    })),
    match(actions.forgotPassword.success, (state, payload) => ({
      ...state,
      forgotPassword: { status: REQUEST_STATUS.Fulfilled },
    })),
    match(actions.forgotPassword.failure, (state, error) => ({
      ...state,
      forgotPassword: { status: REQUEST_STATUS.Failed, error: error },
    })),
  ],
  INITIAL_STATE
);

const requestTokens = createReducer<AuthState>(
  [
    match(actions.requestTokens.request, (state, _) => ({
      ...state,
      requestTokens: { status: REQUEST_STATUS.Pending },
    })),
    match(actions.requestTokens.success, (state, payload) => ({
      ...state,
      requestTokens: { status: REQUEST_STATUS.Fulfilled },
      tokenRequests: {
        requestStatus: { status: REQUEST_STATUS.Fulfilled },
        requests: [
          {
            tokenAmount: payload.tokenAmount,
            reason: payload.reason,
            dateCreated: new Date(),
            id: "unknown",
            status: "Requested",
          },
        ],
        hasPendingRequest: true,
      },
    })),
    match(actions.requestTokens.failure, (state, error) => ({
      ...state,
      requestTokens: { status: REQUEST_STATUS.Failed, error: error },
    })),

    match(actions.getRequestTokenStatusAll.request, (state, _) => ({
      ...state,
      tokenRequests: {
        requestStatus: { status: REQUEST_STATUS.Pending },
        requests: [],
      },
    })),
    match(actions.getRequestTokenStatusAll.success, (state, payload) => ({
      ...state,
      tokenRequests: {
        requestStatus: { status: REQUEST_STATUS.Fulfilled },
        requests: payload,
        hasPendingRequest:
          payload.find((item) => item.status === "Requested") !== undefined,
      },
    })),
    match(actions.getRequestTokenStatusAll.failure, (state, error) => ({
      ...state,
      tokenRequests: {
        requestStatus: { status: REQUEST_STATUS.Failed, error: error },
        requests: [],
      },
    })),
  ],
  INITIAL_STATE
);

export const persistConfig: PersistConfig<AuthState> = {
  storage,
  key: `@user:${PERSIST_KEY}:core/auth`,
  version: 2,
  blacklist: [
    "requests",
    "login",
    "forgotPassword",
    "accountInfoName",
    "accountInfoContact",
    "requestTokens",
  ],
};

export default persistReducer(
  persistConfig,
  composeReducers(
    reducer,
    forgotPassword,
    clearReducer,
    accountInfo,
    requestTokens
  )
);
