export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTH_ACRONYMS = [
  "Jan.",
  "Feb.",
  "Mar.",
  "Apr.",
  "May.",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sep.",
  "Oct.",
  "Nov.",
  "Dec.",
];

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const FORMAT = (date: Date) => {
  return (
    DAYS[date.getDay()] +
    ", " +
    date.getDate() +
    " " +
    MONTH_ACRONYMS[date.getMonth()] +
    " " +
    date.getFullYear()
  );
};
