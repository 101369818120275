export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

export const ENDPOINTS = {
  fetchWellbeingOverview: "/wellbeing",
  getAppointments: "/user/appointments",
  getHotContent: "/resource/search",
  getMoodDiary: "/user/mood",
  setMood: "/user/mood",
  cancelAppointment: "appointment/cancel",
} as const;
