import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { breakpointValues } from "theme";

export const Circle = styled.div<{ color: string; border: string }>`
  width: 18px;
  height: 18px;
  margin: auto;
  border-radius: 50%;
  background-color: ${props => props.color};
  border: 2px solid ${props => props.border};
  display: flex;
  position: relative;

  @media (max-width: ${breakpointValues.sm}px) {
    width: 14px;
    height: 14px;
  }
`;

export const StepWrapper = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-items: center;
  align-items: center;

  @media (max-width: ${breakpointValues.sm}px) {
    width: 60px;
    height: 60px;
  }

  & h6 {
    margin-top: 10px;

    @media (max-width: ${breakpointValues.sm}px) {
      margin-top: 2px;
    }
  }
`;

export const InnerCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 50%;
  background-color: ${props => props.color};
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 1;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: absolute;
    z-index: 0;
    animation: pulse 2s ease 0s 1 normal forwards;
  }

  @media (max-width: ${breakpointValues.sm}px) {
    width: 14px;
    height: 14px;

    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: ${props => props.color};
      position: absolute;
      z-index: 0;
      animation: pulse 2s ease 0s 1 normal forwards;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    80% {
      opacity: 0;
      transform: scale(2);
    }
    100% {
      opacity: 0.5;
      transform: scale(2);
    }
  }
`;
interface StepCircleProps {
  color: string;
  complete: boolean;
  active: boolean;
  label: string;
}

function StepCircle({ color, complete, active, label }: StepCircleProps) {
  function getCircle() {
    if (complete) {
      return <Circle color={color} border={color}></Circle>;
    }

    if (active) {
      return <InnerCircle color={`${color}`}></InnerCircle>;
    }

    return <Circle color={"transparent"} border={"#B1B6CB"}></Circle>;
  }

  return (
    <StepWrapper key={label}>
      {getCircle()}
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
    </StepWrapper>
  );
}

export default StepCircle;
