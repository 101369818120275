import React, { useEffect } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

interface WedgeProps {
  percent: number;
  fill: string;
  size: number;
  style?: string;
}
const TWO_PI = 2 * Math.PI;

const coordinatesForPercent = (percent: number) => ({
  x: Math.cos(TWO_PI * percent),
  y: Math.sin(TWO_PI * percent)
});

function wedgePath(percent: number, startPercent = 0, scale = 1) {
  const start = coordinatesForPercent(startPercent);
  const end = coordinatesForPercent(startPercent + percent);

  const largeArcFlag = percent > 0.5 ? 1 : 0;
  const pathData = [
    `M ${start.x} ${start.y}`,
    `A 1 1 0 ${largeArcFlag} 1 ${end.x} ${end.y}`,
    `L 0 0`
  ].join(" ");

  return pathData;
}

//eslint-disable-next-line
function wedgeStartPath(percent: number, startPercent = 0, scale = 1) {
  const start = coordinatesForPercent(startPercent);

  const largeArcFlag = percent > 0.5 ? 1 : 0;
  const pathData = [
    `M ${start.x} ${start.y}`,
    `A 1 1 0 ${0} ${largeArcFlag} ${start.x} ${start.y}`,
    `L 0 0`
  ].join(" ");

  return pathData;
}

//eslint-disable-next-line
const Plain = (props: WedgeProps) => {
  return (
    <svg
      width={100}
      height={100}
      style={{
        transform: `rotate(-90deg) scale(${props.size / 100})`,
        margin: "auto"
      }}
    >
      <circle r={45} cx={50} cy={50} fill={`${props.fill}55`} />
      <circle r={25} cx={50} cy={50} id={"pie"} stroke={`${props.fill}`} />
    </svg>
  );
};

//eslint-disable-next-line
const ArcWedge = (props: WedgeProps) => {
  return (
    <svg
      viewBox="-1 -1 2 2"
      width={props.size}
      height={props.size}
      className={props.style}
      style={{ transform: "rotate(-90deg)", margin: "auto" }}
    >
      <svg style={{ overflow: "visible" }}>
        <path
          fill={`${props.fill}55`}
          d={wedgePath(1, 0)}
          style={{ transform: "scale(0.9)" }}
        />
      </svg>
    </svg>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& circle": {
        strokeWidth: 50,
        strokeDasharray: "0 158",
        transition:
          "stroke-dasharray 1s  cubic-bezier(0.455, 0.03, 0.515, 0.955)"
      }
    }
  })
);

const Wedge = (props: WedgeProps) => {
  const classes = useStyles();

  const [stroke, setStroke] = React.useState("0 158");

  useEffect(() => {
    let animation = setTimeout(() => {
      var percent = props.percent * 100;
      var total = 158;
      var result = (percent * total) / 100;
      setStroke(`${result.toString()} 158`);
    }, 100);

    return () => {
      clearTimeout(animation);
    };
  });

  return (
    <div
      className={classes.root}
      style={{
        width: (100 * props.size) / 100,
        height: (100 * props.size) / 100
      }}
    >
      <svg
        width={100}
        height={100}
        style={{
          transform: `rotate(-90deg) scale(${props.size / 100})`,
          margin: `${-(100 - (100 * props.size) / 100) / 2}px`
        }}
      >
        <circle r={45} cx={50} cy={50} fill={`${props.fill}55`} />
        <circle
          r={25}
          cx={50}
          cy={50}
          fill="none"
          style={{ strokeDasharray: stroke }}
          stroke={`${props.fill}`}
        />
      </svg>
    </div>
  );
};

export default Wedge;
