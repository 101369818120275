import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      maxWidth: "461px",
      margin: theme.spacing(1),

      height: "auto",
      "& * .MuiFormHelperText-root": {
        marginLeft: "2px",
      },
    },
    container: {
      transition: "all 1s ease",
      maxHeight: "79px",
      "& .MuiInputBase-root": {
        width: "100%",
      },
    },
    noError: {
      maxHeight: "80px",
      minHeight: "80px",
    },
    expandedError: {
      maxHeight: "150px",
      minHeight: "93px",
      height: "auto",
    },
  })
);

type KeyboardEvent = (
  event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
) => void;

type InputEvent = (event: React.ChangeEvent<HTMLInputElement>) => void;
interface Props {
  id: string;
  label: string;
  className?: string;
  shrink?: boolean;
  error?: boolean;
  type?: string;
  endAdornment?: React.ReactNode;
  alwaysExpanded?: boolean;
  errorMsg?: string;
  required?: boolean;
  value: string;
  onFocus?:
    | ((
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void)
    | undefined;
  onBlur?:
    | ((
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void)
    | undefined;
  onChange?: InputEvent;
  onKeyDown?: KeyboardEvent;
  autoComplete?: string;
  disabled?: boolean;
}

const FormInput: React.FC<Props> = (props) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <FormControl
      className={`${props.className ? props.className : classes.formControl} `}
      ref={ref}
      error={!!props.error}
      required={props.required}
    >
      <div
        className={`${classes.container} ${
          !!props.error && !!props.errorMsg
            ? classes.expandedError
            : classes.noError
        } ${props.alwaysExpanded ? classes.expandedError : ""}`}
      >
        <InputLabel
          shrink={props.shrink}
          htmlFor={props.id}
          variant="standard"
          margin="dense"
        >
          {props.label}
        </InputLabel>
        <Input
          id={props.id}
          placeholder={props.label}
          disableUnderline={true}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          type={props.type}
          endAdornment={props.endAdornment}
          disabled={props.disabled}
        />
        {!!props.error ? (
          <FormHelperText id="fade-in">{props.errorMsg}</FormHelperText>
        ) : (
          <FormHelperText> </FormHelperText>
        )}
      </div>

      {props.children}
    </FormControl>
  );
};

export default FormInput;
