import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { keys as domainKeys, domainToColor } from "domain/core/app/utils";
import { capitalize } from "lib/string";
import LinearProgress from "@material-ui/core/LinearProgress";
import StepCircle from "ui/components/assessment/Stepper";
import Button from "@material-ui/core/Button";

const progressStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBar: {
      display: "flex",
      flexDirection: "row",
      height: "132px",
      width: "100%",
      transition: "all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
      background: "#ffffff",
      boxShadow:
        " 0px 2px 4px rgba(68, 53, 86, 0.05), 0px 1px 10px rgba(68, 53, 86, 0.05), 0px 4px 5px rgba(68, 53, 86, 0.04)",

      [theme.breakpoints.down("sm")]: {
        maxHeight: "71px",
      },

      animation: "linearProgressDrop 1s ease",
    },
    progress: {
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#6020D4",
      },
    },
    menuButton: {
      position: "absolute",
      left: "10px",
      top: "10px",
    },
    desktopOnly: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    stepsContainer: {
      alignContent: "center",
      justifyItems: "center",
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      width: "auto",
      height: "100%",
      animation: "fadein 2s ease",
    },
    button: {
      position: "absolute",
      top: 20,
      right: 20,
    },
  })
);

interface ProgressBarProps extends RouteComponentProps {
  getActiveIndex: () => number;
  progress: number;
}
function DomainProgressBar(props: ProgressBarProps) {
  const classes = progressStyles();

  function getProgress() {
    return (
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={props.progress}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={classes.topBar}>
        <div className={classes.desktopOnly}>
          <Button
            variant="text"
            color="secondary"
            className={classes.button}
            onClick={() => {
              props.history.length >= 0
                ? props.history.goBack()
                : props.history.push("/dashboard");
            }}
          >
            Back
          </Button>
        </div>
        <div style={{ margin: "auto", width: "300px", height: "100%" }}>
          <div className={classes.stepsContainer}>
            {domainKeys.map((domain, index) => {
              return (
                <StepCircle
                  key={domain}
                  label={capitalize(domain)}
                  color={domainToColor[domain].lightest}
                  active={props.getActiveIndex() === index}
                  complete={props.getActiveIndex() > index}
                ></StepCircle>
              );
            })}
          </div>
        </div>
      </div>
      {getProgress()}
    </React.Fragment>
  );
}

export default withRouter(DomainProgressBar);
