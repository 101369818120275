import React from "react";
import styled from "styled-components";
import { ReactComponent as InPerson } from "assets/booking/In Person.svg";
import { ReactComponent as Video } from "assets/booking/Video.svg";
import { ReactComponent as Phone } from "assets/booking/Phone.svg";
import { createStyles, makeStyles, Typography, Theme } from "@material-ui/core";

const BookingIconWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 200px;
  margin: auto 5px auto 5px;
  box-sizing: border-box;
  & * > + *: {
    margin: auto;
  }
`;

interface Props {
  type: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconTypography: {
      height: "auto",
      margin: theme.spacing(1),
    },

    detailsRow: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      margin: "auto 0",
    },

    textContainer: {
      display: "flex",
      flexDirection: "row",
      height: "40px",
      "& p": {
        lineHeight: "auto",
        margin: "auto 0px",
      },

      "& .MuiChip-root": {
        margin: "auto 10px",
      },
    },
    icon: {
      marginLeft: 0,
      // width: "auto",
    },
  })
);

export const BookingTypePair = ({ type, className }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.textContainer}>
      <div className={classes.detailsRow}>
        <BookingTypeIconRound type={type} className={classes.icon} />
        <Typography className={classes.iconTypography} variant="body1">
          {type}
        </Typography>
      </div>
    </div>
  );
};

const BookingTypeIconRound = ({ type, className }: Props) => {
  function getStyle() {
    return { margin: "auto" };
  }
  function getInner() {
    switch (type) {
      case "In Person":
        return <InPerson style={getStyle()} />;
      case "Video":
        return <Video style={getStyle()} />;
      case "Phone":
        return <Phone style={getStyle()} />;
      default:
        return <InPerson style={getStyle()} />;
    }
  }

  return (
    <BookingIconWrapper className={className}>{getInner()}</BookingIconWrapper>
  );
};

export default BookingTypeIconRound;
