import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import { ReactComponent as IgniteLogo } from "assets/Logo/Logo.svg";
import { ReactComponent as IgniteLogoColored } from "assets/Logo/IgniteLogoColored.svg";
import { Button } from "@material-ui/core";
import { ROUTES } from "ui/pages/routes";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AlertCircle from "assets/icons/alert-circle.svg";
import * as selectors from "domain/core/selector";
import { useReduxState } from "re-reduced";
import AvatarMenu, { MenuItems } from "ui/components/AvatarMenu";
import { dissoc } from "ramda";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "1920px",
      marginRight: "auto",
      left: "auto",
      right: "auto",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      color: "black",
    },
    drawerList: {
      display: "flex",
      flexDirection: "column",
      width: "304px",
      height: "100%",

      "& #drawerList-header": {
        "& * img": {
          maxHeight: "25px",
        },
      },

      "& button": {
        margin: "auto",
        marginBottom: "15px",
        width: 220,
      },

      "& .MuiListItem-root": {
        paddingLeft: "20px",
        paddingTop: 4,
        paddingBottom: 4,
      },

      "& >:first-child": {
        padding: "20px",
        paddingBottom: "50px",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",

        "& *": {
          color: "black",
        },

        "& >:last-child": {
          marginRight: "5px",
        },
      },
    },

    drawerLink: {
      "& span": {
        fontFamily: "Gilroy Bold",
        fontSize: "16px",
      },
    },

    mobileSecondary: {
      "& span": {
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
        color: "#9297AD",
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    notScrolled: {
      boxShadow: "none",
      transition: "all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
      background: "transparent",
      color: "white",

      "& a": {
        textDecoration: "none",
        "& span": {
          color: "white",
        },
      },

      "& .MuiIconButton-label": {
        color: "white",
      },
    },
    scrolled: {
      boxShadow: theme.shadows[4],
      transition: "all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
      background: "#ffffff",

      "& a": {
        textDecoration: "none",
        "& span": {
          color: "black",
        },
      },
    },
    noBoxShadow: {
      boxShadow: "none",
    },
    settings: {
      fontFamily: "Gilroy SemiBold",
      fontWeight: "bold",
      fontSize: "16px",
      margin: "auto",
      marginRight: "30px",
      color: "black",
      "&:hover": {
        cursor: "pointer",
        "& span": {
          color: "#04AAF0",
        },
      },
    },
    crisisSettingsHover: {
      "&:hover": {
        cursor: "pointer",
        "& span": {
          color: "#6020D4",
        },
      },
    },

    navLink: {
      width: "180px",
      height: "70px",
      color: "black",
      "& h6": {
        fontFamily: "Gilroy SemiBold",
        fontWeight: "bold",
        fontSize: "16px",
      },

      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        "& h6": {
          color: "#04AAF0",
        },
      },

      textTransform: "none",
    },
    hoverCrisis: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        "& h6": {
          color: "#6020D4",
        },
      },
    },
    selectedLink: {
      borderTop: "5px solid #04AAF0",
      borderRadius: "0px",
      "& h6": {
        fontFamily: "Gilroy ExtraBold",
      },
    },
    selectedCrisisLink: {
      borderTop: "5px solid #6020D4",
      borderRadius: "0px",
      "& h6": {
        fontFamily: "Gilroy ExtraBold",
        color: "#6020D4",
      },
    },

    selectedScrolled: {
      color: "#04AAF0",
    },
    nil: {},
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200,
      },
    },
    growOnMobile: {
      flexGrow: 0,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
    },
    growOnDesktop: {
      flexGrow: 0,
      [theme.breakpoints.up("lg")]: {
        flexGrow: 1,
      },
    },
    sectionDesktop: {
      display: "none",

      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    sectionDesktopEnd: {
      display: "none",
      float: "right",
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },

    menuItemsContainer: {
      paddingBottom: "20px",
    },
    loginButton: {
      borderColor: "white !important",
      color: "white !important",
      "& span": {
        color: "white !important",
      },
    },
    loginButtonAlt: {
      borderColor: "#04AAF0",
      color: `#04AAF0 !important`,
      "& span": {
        color: `#04AAF0 !important`,
      },
    },
    removeGutter: { padding: "0px !important" },
    IEBannerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    IEBanner: {
      display: "flex",
      width: "100%",
      minHeight: "50px",
      backgroundColor: theme.palette.secondary.main,
    },
    IEText: {
      fontFamily: "Gilroy Regular",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 20px",
      },
    },
    IEInnerContainer: {
      display: "flex",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    IELink: {
      color: "white !important",
      paddingLeft: "5px",
      paddingRight: "5px",
      textDecoration: "underline !important",
    },
    mobileNavButton: {
      height: "40px",
      width: "calc(100% - 40px)",
      margin: "10px 20px",
    },
  })
);

interface Props extends RouteComponentProps {
  changeOnScroll: boolean;
  noShadow?: boolean;
  static?: boolean;
}

interface PageConfig {
  title: string;
  routeMatch: string[];
  routeTo: string;
  icon: JSX.Element;
  navType: "scroll" | "hidden";
  showIfAuthorised?: boolean;
  hideIfAuthorised?: boolean;
}
interface IDictionary {
  [index: string]: PageConfig;
}

const AlwaysDensePages = /(book|workshop\/|appointments|assessment|prioritise)/;
const SecondaryThemedPages = /(crisis|account)/;

const UnAuthenticated: IDictionary = {
  Resources: {
    title: "Resources",
    routeMatch: [ROUTES.learn],
    routeTo: ROUTES.learn,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },
  OurApproach: {
    title: "Our Approach",
    routeMatch: [ROUTES.ourApproach],
    routeTo: ROUTES.ourApproach,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },

  Contact: {
    title: "Get in Touch",
    routeMatch: [ROUTES.contact],
    routeTo: ROUTES.contact,
    icon: <SearchIcon></SearchIcon>,
    navType: "scroll",
  },
};

const Authenticated: IDictionary = {
  MyDashboard: {
    title: "My Dashboard",
    routeMatch: [ROUTES.dashboard],
    routeTo: ROUTES.dashboard,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },
  WellbeingTool: {
    title: "Wellbeing Plan",
    routeMatch: [
      ROUTES.assessmentResults,
      ROUTES.assessment,
      ROUTES.prioritise,
    ],
    routeTo: ROUTES.assessment,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },
  Providers: {
    title: "Support",
    routeMatch: [ROUTES.search, ROUTES.book],
    routeTo: ROUTES.search,
    icon: <SearchIcon></SearchIcon>,
    navType: "scroll",
  },
  Workshops: {
    title: "Workshops",
    routeMatch: [ROUTES.workshops],
    routeTo: ROUTES.workshops,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },
  Resources: {
    title: "Resources",
    routeMatch: [ROUTES.learn],
    routeTo: ROUTES.learn,
    icon: <MenuIcon></MenuIcon>,
    navType: "scroll",
  },
};

function Appbar(props: Props) {
  const state = useReduxState({ ...selectors });

  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const [scrolled, setScrolled] = React.useState(!props.changeOnScroll);

  const isSecondary = SecondaryThemedPages.test(props.location.pathname);

  const Config = React.useCallback(() => {
    if (state.getIsAuthenticated) {
      let config = Authenticated;
      if (!state.getProfile.workshopsEnabled) {
        config = dissoc<IDictionary>("Workshops", config);
      }
      if (!state.getProfile.providersEnabled) {
        config = dissoc<IDictionary>("Providers", config);
      }
      return config;
    } else {
      return UnAuthenticated;
    }
  }, [
    state.getIsAuthenticated,
    state.getProfile.workshopsEnabled,
    state.getProfile.providersEnabled,
  ]);

  const handleScroll = (event: any) => {
    setScrolled(window.pageYOffset > 5);
  };

  const wrapPush = (route: string) => {
    if (route.indexOf("https://") !== -1) {
      window.open(route, "_blank");
    } else {
      props.history.push(route);
    }
  };

  useEffect(() => {
    if (props.changeOnScroll) {
      window.addEventListener("scroll", handleScroll);
    }

    const listener = props.history.listen(() => {
      setScrolled(AlwaysDensePages.test(props.history.location.pathname));
    });

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
      listener();
    };
  }, [props.changeOnScroll, props.history]);

  const renderMenu = (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={() => setDrawerOpen(false)}
      onClick={() => setTimeout(setDrawerOpen(false), 500)}
    >
      <List className={classes.drawerList}>
        <div id="drawerList-header">
          <IgniteLogoColored />
        </div>
        <div className={classes.growOnDesktop}></div>
        {Object.keys(Config()).map((key) => {
          const config = Config()[key];
          const selected = config.routeMatch.find(
            (route: string) => props.location.pathname.indexOf(route) !== -1
          );
          return (
            <ListItem
              key={config.title}
              onClick={() => wrapPush(config.routeTo)}
              className={selected && classes.selectedScrolled}
            >
              <ListItemText
                primary={config.title}
                className={classes.drawerLink}
              />
            </ListItem>
          );
        })}
        <div className={classes.grow}></div>
        <div className={classes.menuItemsContainer}>
          <MenuItems />
        </div>
        {!state.getIsAuthenticated && (
          <React.Fragment>
            <Button
              className={classes.mobileNavButton}
              variant="outlined"
              color="secondary"
              href="/login"
            >
              Login
            </Button>
            <Button
              className={classes.mobileNavButton}
              variant="contained"
              color="secondary"
              href="/request-demo"
            >
              Request Demo
            </Button>
          </React.Fragment>
        )}
      </List>
    </Drawer>
  );

  function isIE() {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIE = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return isIE;
  }

  return (
    <React.Fragment>
      <AppBar
        className={`
        ${classes.root} ${
          !props.changeOnScroll
            ? classes.scrolled
            : scrolled
            ? classes.scrolled
            : classes.notScrolled
        }
          ${props.noShadow === true ? classes.noBoxShadow : ""}  `}
        position={
          props.static ? "static" : props.changeOnScroll ? "fixed" : "sticky"
        }
        id="app-bar"
      >
        <Toolbar
          className={scrolled ? classes.removeGutter : classes.removeGutter}
        >
          <div className={classes.IEBannerContainer}>
            {isIE() && (
              <div className={classes.IEBanner}>
                <div className={classes.IEInnerContainer}>
                  <img alt="" src={AlertCircle} />
                  <p className={classes.IEText}>
                    We do not support Internet Explorer. For the best experience
                    we recommend using the latest version of{" "}
                    <a
                      className={classes.IELink}
                      href="https://www.google.com/chrome/"
                    >
                      Chrome
                    </a>
                    {" or "}
                    <a
                      className={classes.IELink}
                      href="https://www.microsoft.com/en-us/edge"
                    >
                      Microsoft Edge
                    </a>
                  </p>
                </div>
              </div>
            )}
            <div className={"MuiToolbar-gutters"} style={{ display: "flex" }}>
              {!scrolled !== false ? (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  disableRipple
                  onClick={() => props.history.push("/")}
                >
                  <IgniteLogo />{" "}
                </IconButton>
              ) : (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  disableRipple
                  aria-label="open drawer"
                  onClick={() => props.history.push("/")}
                >
                  <IgniteLogoColored />{" "}
                </IconButton>
              )}

              <div className={classes.grow}></div>
              <div className={classes.sectionDesktop}>
                {Object.keys(Config()).map((key) => {
                  const config = Config()[key];
                  const selected = config.routeMatch.find(
                    (route: string) =>
                      props.location.pathname.indexOf(route) !== -1
                  );
                  return (
                    <NavLink
                      to={config.routeTo}
                      key={config.routeTo}
                      onClick={() => wrapPush(config.routeTo)}
                    >
                      <Button
                        className={`${classes.navLink} ${
                          selected ? classes.selectedLink : classes.nil
                        } ${isSecondary && !scrolled ? classes.hoverCrisis : ""}
                    ${
                      isSecondary && selected && !scrolled
                        ? classes.selectedCrisisLink
                        : ""
                    }
                    `}
                      >
                        <Typography
                          variant="h6"
                          className={
                            selected && scrolled
                              ? classes.selectedScrolled
                              : classes.nil
                          }
                        >
                          {config.title}
                        </Typography>
                      </Button>
                    </NavLink>
                  );
                })}
              </div>
              <div className={classes.grow}></div>

              {state.getIsAuthenticated && (
                <React.Fragment>
                  <div className={classes.sectionDesktopEnd}>
                    <AvatarMenu scrolled={scrolled} />
                  </div>
                </React.Fragment>
              )}

              {!state.getIsAuthenticated && (
                <div className={classes.sectionDesktop}>
                  <Button
                    className={
                      scrolled ? classes.loginButtonAlt : classes.loginButton
                    }
                    style={{ margin: "auto", marginRight: "15px" }}
                    variant="outlined"
                    color="secondary"
                    href="/login"
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => props.history.push("/request-demo")}
                  >
                    Request Demo
                  </Button>
                </div>
              )}

              <IconButton
                edge="start"
                className={`${classes.menuButton} ${classes.sectionMobile}`}
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </React.Fragment>
  );
}

export default withRouter(Appbar);
