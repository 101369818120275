import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { WellbeingActions } from "domain/wellbeing/actions";
import { useActions, useReduxState } from "re-reduced";
import {
  getAnswers,
  getSaveStatus,
  getResultStatus,
  getTestResult,
} from "domain/wellbeing/reducer";
import Wedge from "ui/components/Wedge";
import * as selectors from "domain/core/auth/selectors";
import { REQUEST_STATUS } from "lib/types";
import { Typography, Checkbox, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  CheckboxIcon,
  CheckBoxOutlineBlankIcon,
} from "ui/components/checkbox/Checkbox";
import styled from "styled-components";
import DomainIcon from "ui/components/DomainIcon";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { keys, domainToColor } from "domain/core/app/utils";
import { ReactComponent as Chevron } from "assets/icons/Icon-Left-Arrow.svg";
import { WellbeingQuestion } from "domain/wellbeing/types";
import DomainProgressBar from "ui/compounds/DomainProgressBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
    },
    grow: {
      flexGrow: 1,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    card: {
      width: "750px",
      height: "400px",
    },
    topBar: {
      display: "flex",
      flexDirection: "row",
      height: "83px",
      width: "100%",
      boxShadow:
        "0px 2px 4px rgba(68, 53, 86, 0.05), 0px 1px 10px rgba(68, 53, 86, 0.05), 0px 4px 5px rgba(68, 53, 86, 0.04)",
      transition: "all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)",
      background: "#ffffff",
    },
    icon: {
      margin: "auto 15px auto 15px",
    },
    checkBox: {
      margin: "auto",
      marginRight: "0px",
    },
    checkBoxOutline: {},
    checkBoxChecked: {},
    questionContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    loader: {
      height: "300px",
      width: "100%",
    },
    itemText: {
      margin: "auto",
      marginLeft: "0px",
      height: "23px",
    },
    priorityItem: {
      borderRadius: "8px",
      margin: "auto",
      backgroundColor: "white",
      width: "223px",
      height: "70px",
      display: "flex",
      flexDirection: "column",
      transition: "all .5s ease",
      boxShadow:
        " 0px 2px 4px rgba(68, 53, 86, 0.05), 0px 1px 10px rgba(96, 32, 212, 0.04), 0px 4px 5px rgba(68, 53, 86, 0.04)",
    },
    borderlessBackButton: {
      "& span": {
        textTransform: "none",
        justifyContent: "left",
      },
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      minHeight: "20px",
    },
    enabled: {
      "@media (hover: hover)": {
        "&:hover": {
          transform: "scale(1.05)",

          "& * .makeStyles-checkBoxOutline-418": {
            backgroundColor: "transparent",
            color: "#6020D4",
          },
        },
      },
    },
    disabled: {
      opacity: "0.5",
      "&:hover": {
        transform: "unset",
      },
    },

    header: {
      margin: "50px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        width: "100%",
        margin: "20px 0px",
      },
    },
    priorityItemUpper: {
      height: "48px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "row",
    },
    progress: {
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#6020D4",
      },
    },
    menuButton: {
      position: "absolute",
      left: "10px",
      top: "10px",
    },
  })
);

const RatingBar = styled.div<{ value: number; color: string }>`
  display: flex;
  height: 22px;
  border-radius: 0;
  background-color: ${(props) => props.color};

  transition: all 1s ease;

  max-width: ${(props) => props.value}%;
  width: 0%;

  & p {
    color: white;
    margin: auto;
    margin-left: 10px;
    font-family: Gilroy Bold;
    font-size: 14px;
  }

  animation-name: transitionIn;
  animation-duration: ${(props) => props.value * 10}ms;

  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @keyframes transitionIn {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`;

const Progress = styled.div<{ value: number; percent: number; color: string }>`
  display: flex;
  height: 22px;
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;
  background-color: ${(props) => props.color};
  box-shadow: none;
  width: 100%;
`;

interface Props extends RouteComponentProps<{ id: string }> {}

interface QuestionMap {
  [index: string]: PrioritiseConfig;
}
interface PrioritiseConfig {
  color: {
    primary: string;
    darkest: string;
    dark: string;
    light: string;
    lightest: string;
  };
  label: string;
  questions: WellbeingQuestion[];
}

const Prioritise: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const actions = useActions(WellbeingActions);

  const reduxState = useReduxState({
    name: selectors.getName,
    answers: getAnswers,
    saveStatus: getSaveStatus,
    resultStatus: getResultStatus,
    testResult: getTestResult,
  });

  const [questionaire] = useState(deconstructAnswers());

  function sortNullLast() {
    return function (a: number, b: number) {
      if (a === 0) return 1;
      if (b === 0) return -1;
      return a - b;
    };
  }
  function deconstructAnswers() {
    let answers = reduxState.answers.map((item) => {
      if (item.rating === -1) {
        item.rating = 0;
        return item;
      }
      return item;
    });

    let config: QuestionMap = {};
    let emptyConfig: PrioritiseConfig = {
      color: { light: "", dark: "", primary: "", lightest: "", darkest: "" },
      label: "",
      questions: [],
    };

    keys.map((key) => {
      let domain = { ...emptyConfig };

      domain.questions = answers
        .filter((item) => item.key === key)
        .sort((a, b) => sortNullLast()(a.rating, b.rating));
      domain.color = domainToColor[key];
      domain.label = key.charAt(0).toUpperCase() + key.slice(1, key.length);

      config[key] = domain;

      return null;
    });

    return config;
  }

  const [focused, setFocused] = useState<string[]>([]);

  useEffect(() => {
    if (reduxState.resultStatus === REQUEST_STATUS.Fulfilled) {
      let path = generatePath("/assessmentResults/:id", {
        id: reduxState.testResult.id,
      });
      props.history.push(path);
    }
    if (reduxState.answers.length === 0) {
      props.history.push("/assessment");
    }
  }, [reduxState, props.history]);

  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckboxIcon />;

  function getProgress(value: number, color: string) {
    return (
      <Progress value={100} percent={(100 / 5) * value} color={color + "55"}>
        <RatingBar value={(100 / 5) * value} color={color}>
          <p>{value}</p>
        </RatingBar>
      </Progress>
    );
  }

  function getPercentageForDomain(domain: string): number {
    let total = 0;
    let filteredByDomain = reduxState.answers.filter(
      (item) => item.key === domain
    );
    let length = filteredByDomain.length;
    filteredByDomain.map((a) =>
      a.rating > 0 ? (total += a.rating) : (total += 0)
    );

    return total / (length * 5);
  }

  function setChecked(key: string) {
    let current = [...focused];

    let index = current.indexOf(key);
    if (index >= 0) {
      current.splice(index, 1);
      setFocused(current);
    } else if (current.length < 3) {
      current.push(key);
      setFocused(current);
    }
  }

  function getEnabled(label: string) {
    return focused.indexOf(label) >= 0 || focused.length < 3;
  }

  function getChecked(label: string) {
    return focused.indexOf(label) >= 0;
  }

  function sendTestAndGetResults() {
    let answers = reduxState.answers;
    focused.forEach((item) => {
      let priority = answers.find((answer) => answer.label === item);
      if (priority !== undefined) {
        priority.priority = 5;
      }
    });
    actions.getWellbeingResults({
      answers: answers,
    });
  }

  function getLoader() {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.questionContainer}>
          <div className={classes.questionContainer}>
            <Typography variant="h5" align="center">
              {" "}
              Loading your plan...{" "}
            </Typography>
          </div>
          <div className={`${classes.questionContainer} ${classes.loader}`}>
            <CircularIndeterminate />
          </div>
        </div>
      </Container>
    );
  }

  function getPrioritisationChips(results: PrioritiseConfig) {
    return results.questions.map((item) => (
      <Grid item key={item.label}>
        {getChip(
          item.rating,
          results.color.primary,
          item.label,
          focused.indexOf(item.label) >= 0
        )}
      </Grid>
    ));
  }

  function getChip(
    value: number,
    color: string,
    label: string,
    checked?: boolean
  ) {
    return (
      <div
        className={`${classes.priorityItem} 
        ${getEnabled(label) ? classes.enabled : classes.disabled}
        `}
        onClick={() => setChecked(label)}
      >
        <div className={classes.priorityItemUpper}>
          <div className={classes.container}>
            <DomainIcon className={classes.icon} domain={label}></DomainIcon>
            <Typography className={classes.itemText} variant="subtitle1">
              {label}
            </Typography>
            <Checkbox
              disableFocusRipple
              disableTouchRipple
              disableRipple
              className={classes.checkBox}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={getChecked(label)}
            />
          </div>
        </div>
        {getProgress(value, color)}
      </div>
    );
  }

  return (
    <React.Fragment>
      <DomainProgressBar progress={95} getActiveIndex={() => 3} />
      {reduxState.resultStatus === REQUEST_STATUS.Pending ? (
        getLoader()
      ) : (
        <Container maxWidth="xl" className={classes.root}>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.borderlessBackButton}
              variant="text"
              color="secondary"
              onClick={() => {
                actions.clearStatus();
                actions.clearTest();
                props.history.push("/assessment");
              }}
              startIcon={<Chevron />}
            >
              Back to Questionaire
            </Button>
          </div>
          <Typography className={classes.header} variant="h4" align="center">
            Below are your results. <br /> Select the 3 areas you'd most like to
            improve.
          </Typography>
          <Grid container justify="center" spacing={8}>
            {keys.map((key) => {
              return (
                <Grid item key={key}>
                  <Grid
                    container
                    justify="center"
                    direction="column"
                    spacing={2}
                  >
                    <Grid item>
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        spacing={2}
                      >
                        <Grid item>
                          <div
                            style={{
                              display: "flex",
                              width: "150px",
                              height: "150px",
                              margin: "auto",
                            }}
                          >
                            <Wedge
                              percent={getPercentageForDomain(key)}
                              fill={questionaire[key].color.primary}
                              size={150}
                            />
                          </div>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ marginBottom: "20px" }}
                            variant="h5"
                            align="center"
                          >
                            My {questionaire[key].label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {getPrioritisationChips(questionaire[key])}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid container justify="center" direction="column" spacing={2}>
            <div style={{ margin: "auto", padding: "50px" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  sendTestAndGetResults();
                }}
                disabled={focused.length !== 3}
              >
                See my plan
              </Button>
            </div>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
};
export default withRouter(Prioritise);
