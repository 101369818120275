import { createActions } from "re-reduced";

import { WellbeingTestState, WellbeingTestPayload } from "./types";

export const WellbeingActions = createActions("WELLBEING", (create) => ({
  getWellbeingResults: create.asyncAction<
    WellbeingTestState,
    WellbeingTestPayload
  >(),
  fetchWellbeingResult: create.asyncAction<WellbeingTestState, string>(),
  saveTest: create.asyncAction<WellbeingTestState, WellbeingTestPayload>(),
  clearTest: create.action(),
  clearStatus: create.action<void, WellbeingTestState>(),
  reprioritise: create.action<WellbeingTestPayload, void>(),
}));
