import React, { useEffect, useState } from "react";
import { specialities } from "domain/core/app/types";
import axios from "axios";
import Filter from "./Filter";
import CheckboxListItem from "./CheckboxListItem";
import { FilterProps } from "./Filter";
import { fetchSpecialities } from "domain/providers/api";
export const renderOption = (option: string, selected: boolean) => {
  return <CheckboxListItem option={option} selected={selected} />;
};
const SpecialityFilter = (props: FilterProps) => {
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await fetchSpecialities();
        setSpecialities(response);
      } catch (error) {
        console.error("Error fetching specialities:", error);
      } finally {
        setLoading(false);
      }
    };

    getSpecialities();
  }, []);
  if (loading) {
    return <div></div>;
  }
  return (
    <Filter
      border={true}
      values={props.values}
      onFilterChange={props.onFilterChange}
      options={specialities}
      title="Speciality area..."
      renderOptions={renderOption}
      disableInputBorder
      forceDesktop={true}
      placeHolder="Choose speciality areas"
      id="speciality-filter"
    />
  );
};

export default SpecialityFilter;
