export const validName = (name: string) => {
  return name.length > 1 && /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g.test(name);
};

export const validPhone = (phone: string) => {
  return /^[0-9]*$/gm.test(phone);
};

export const validEmail = (email: string) => {
  const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function getNameErrorMsg(value: string) {
  if (!validName(value)) {
    if (value.length === 0) {
      return "Please enter your name";
    }
    const re = /[!@#$%^&*(),.?":{}|<>]/g;
    if (re.test(value)) {
      return "Name can not contain: " + re.exec(value);
    }
    if (/[0-9]+/gm.test(value)) {
      return "Name can not contain numbers";
    }
  }

  return "";
}

export function getPhoneErrorMsg(phone: string) {
  if (!phone || phone.length === 0) {
    return "Enter contact phone number";
  }
  if (phone.length < 8) {
    return "Phone number is not long enough";
  }
  if (!validPhone(phone)) {
    return "Enter phone number in a correct format, such as 0227555211 or 097001234";
  }
}
export function getEmailErrorMsg(email: string) {
  if (!validEmail(email)) {
    if (email.length === 0) {
      return "Please enter your email";
    }
    if (email.indexOf("@") === -1) {
      return "An email must contain a single @";
    }
    if (email.indexOf("@") === 0) {
      return "An email cannot start with @";
    }
    if (email.indexOf(".") === -1) {
      return "An email must contain a single '.' e.g '.com'";
    }
  }
  const re = /[!#$%^&*(),?":{}|<>]/;
  if (re.test(email)) {
    return "Email cannot contain: " + re.exec(email);
  }

  return "";
}
