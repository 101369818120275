import { domains, DomainKey } from "./types";
import { colorGuide } from "theme";

export const keys = Object.keys(domains) as DomainKey[];

export function itemToDomainKey(option: string): DomainKey {
  const key = keys.filter((key) => domains[key].indexOf(option) > -1)[0];

  return key || "health";
}

export const scrollToRef = (ref: HTMLDivElement) => {
  window.scrollTo(0, ref.offsetTop);
};

export const CTA = "#04AAF0";

export const domainToColor = {
  health: colorGuide.fern,
  life: colorGuide.rasberry,
  meaning: colorGuide.ocean,
};

function getDomains(): string[] {
  const list = keys.map((key) =>
    domains[key].sort((a: string, b: string) => -b[0].localeCompare(a[0]))
  );

  const flattened = ([] as string[]).concat(...list);

  return flattened;
}

export const uniqueArray = function (arrArg: string[]) {
  return arrArg.filter(function (elem, pos, arr) {
    return arr.indexOf(elem) === pos;
  });
};

export const flattened = getDomains();

export const kvp = flattened.map((item) => {
  return {
    key: itemToDomainKey(item),
    label: item,
  };
});

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function shuffle(array: any[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const setHash = (hash: string) => {
  if (window.history && window.history.pushState) {
    window.history.pushState(null, document.title, `#${hash}`);
  } else {
    window.location.hash = `#${hash}`;
  }
};

export const removeHash = () => {
  if (window.history && window.history.replaceState) {
    window.history.replaceState(null, document.title, " ");
  } else {
    window.location.hash = "";
  }
};
