import React from "react";
import styled from "styled-components";
import { ReactComponent as Icon1 } from "assets/icons/Read.svg";
import { ReactComponent as Icon2 } from "assets/icons/Watch.svg";
import { ReactComponent as Icon3 } from "assets/icons/Do.svg";

interface Props {
  size: number;
  medium: string;
  align?: Alignment;
}

interface Dict {
  [index: string]: JSX.Element;
}

type Alignment = "left" | "right" | "auto";

interface Alignments {
  [index: string]: string;
}

const alignments: Alignments = {
  left: "auto 0px auto -8px",
  auto: "auto 0px",
  right: "auto -8px auto 0px"
};

const IconWrapper = styled.div<{ size: number }>`
  display: flex;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};

  border-radius: 200px;
  margin: auto 9px;

  & * > + *: {
    margin: auto;
  }
`;

function getStyle(size: number, align: Alignment | undefined) {
  if (align) {
    let alignment = alignments[align];

    return {
      margin: alignment,
      width: size + "px",
      height: size + "px"
    };
  }

  return {
    width: size + "px",
    height: size + "px"
  };
}

const MediumIcon: React.FC<Props> = props => {
  function getIcon() {
    switch (props.medium) {
      case "Read":
        return <Icon1 style={getStyle(props.size, props.align)} />;
      case "Watch":
        return <Icon2 style={getStyle(props.size, props.align)} />;
      case "Do":
        return <Icon3 style={getStyle(props.size, props.align)} />;
      default:
        return <Icon1 style={getStyle(props.size, "auto")} />;
    }
  }
  return <IconWrapper size={props.size}>{getIcon()}</IconWrapper>;
};

export default MediumIcon;
