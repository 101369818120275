import React, { useEffect, useState } from "react";
import ProfileCard from "ui/compounds/Models/ProfileCard/ProfileCard";
import { withRouter, RouteComponentProps, generatePath } from "react-router";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { REQUEST_STATUS } from "lib/types";
import { ShortProfile } from "domain/providers/types";
import { getResultsStatus, getSearchResults } from "domain/providers/reducer";

import { ProviderActions } from "domain/providers/actions";

import { useActions, useReduxState } from "re-reduced";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { Typography } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
import queryString from "query-string";
import Banner from "ui/components/Banner";
import FilterContainer from "ui/components/filters/FilterContainer";
import DomainFilter from "ui/components/filters/DomainFilter";
import SpecialityFilter from "ui/components/filters/SpecialityFilter";
import BookingTypeFilter from "ui/components/filters/BookingTypeFilter";
import DomainIcon from "ui/components/DomainIcon";
import BookingTypeIconRound from "ui/components/BookingTypeIcon";
import FilterChip from "ui/components/filters/FilterChip";

import IgniteTagManager from "lib/analytics";
import TokenExplanation from "ui/compounds/providers/TokenExplanation";
import LocationFilter from "ui/components/filters/LocationFilter";
import { ReactComponent as LocationChipIcon } from "assets/icons/LocationChipIcon.svg";

interface Props extends RouteComponentProps<{ pageLength: string }> {}

interface SearchQuery extends FilterState {
  _pageSize: number;
}

export interface FilterState {
  domainFilters: string[];
  specialityFilters: string[];
  bookingTypeFilters: string[];
  locationFilters: string[];
}

export const INITIAL_STATE: FilterState = {
  domainFilters: [],
  specialityFilters: [],
  bookingTypeFilters: [],
  locationFilters: [],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    loaderContainer: {
      height: "300px",
    },
    chipContainer: {
      padding: "20px 0px 20px 0px",
    },
  })
);

const Search = (props: Props) => {
  const [spacing] = React.useState<GridSpacing>(4);
  const classes = useStyles();
  const actions = useActions(ProviderActions);

  const [page, setPage] = useState(1);

  function getUrlParams(): URLSearchParams {
    if (!props.location.search) return new URLSearchParams();
    return new URLSearchParams(props.location.search);
  }

  function toArray(obj: string | string[] | null | undefined): string[] {
    if (Array.isArray(obj)) {
      return obj;
    } else if (obj) {
      return new Array(obj) as string[];
    } else {
      return [];
    }
  }

  const {
    _pageSize,
    domainFilters,
    specialityFilters,
    bookingTypeFilters,
    locationFilters,
  } = getSearchQuery();

  function getSearchQuery(): SearchQuery {
    const ps = queryString.parse(props.location.search);
    let _pageSize = 12;

    const search = getUrlParams();
    _pageSize = parseInt(search.get("pageSize") || "12");
    const domainFilters = toArray(ps.domainFilters);
    const specialityFilters = toArray(ps.specialityFilters);
    const bookingTypeFilters = toArray(ps.bookingTypeFilters);
    const locationFilters = toArray(ps.locationFilters);
    return {
      _pageSize,
      domainFilters,
      specialityFilters,
      bookingTypeFilters,
      locationFilters,
    };
  }
  const [pageSize] = React.useState<number>(_pageSize);
  const [filters, setFilters] = React.useState<FilterState>({
    domainFilters: domainFilters || [],
    specialityFilters: specialityFilters || [],
    bookingTypeFilters: bookingTypeFilters || [],
    locationFilters: locationFilters || [],
  });

  const reduxState = useReduxState({
    searchResults: getSearchResults,
    resultStatus: getResultsStatus,
  });

  const onSubmitSearch = React.useCallback(() => {
    actions.fetchProviderSearchResults({
      page: page,
      pageLength: pageSize,
      appointmentType: filters.bookingTypeFilters,
      domainsOfSupport: filters.domainFilters,
      specialitiesOfSupport: filters.specialityFilters,
      cities: filters.locationFilters,
    });
  }, [page, pageSize, filters, actions]);

  useEffect(() => {
    onSubmitSearch();
    props.history.replace({
      pathname: "/search",
      search: queryString.stringify(filters),
    });
  }, [onSubmitSearch, filters, page, props.history]);

  function onSelectProfile(profile: ShortProfile) {
    const path = generatePath("/book/:id", { id: profile.providerId });
    props.history.push({
      pathname: path,
      state: { from: props.history.location.pathname },
    });
  }

  function renderFilterChip() {
    return filters.domainFilters
      .map((domain) => {
        return (
          <FilterChip
            label={domain}
            key={domain}
            onDelete={() => {
              setFilters({
                ...filters,
                domainFilters: filters.domainFilters.filter(
                  (entry) => entry !== domain
                ),
              });
            }}
            icon={<DomainIcon key={domain} domain={domain} size="sm" />}
          />
        );
      })
      .concat(
        filters.specialityFilters.map((speciality) => {
          return (
            <FilterChip
              label={speciality}
              key={speciality}
              onDelete={() => {
                setFilters({
                  ...filters,
                  specialityFilters: filters.specialityFilters.filter(
                    (entry) => entry !== speciality
                  ),
                });
              }}
            />
          );
        })
      )
      .concat(
        filters.bookingTypeFilters.map((type) => {
          return (
            <FilterChip
              label={type}
              key={type}
              onDelete={() => {
                setFilters({
                  ...filters,
                  bookingTypeFilters: filters.bookingTypeFilters.filter(
                    (entry) => entry !== type
                  ),
                });
              }}
              icon={<BookingTypeIconRound type={type} />}
            />
          );
        })
      )
      .concat(
        filters.locationFilters.map((location) => {
          return (
            <FilterChip
              label={location}
              key={location}
              icon={<LocationChipIcon style={{ transform: "scale(0.8)" }} />}
              onDelete={() =>
                setFilters({
                  ...filters,
                  locationFilters: filters.locationFilters.filter(
                    (entry) => entry !== location
                  ),
                })
              }
            />
          );
        })
      );
  }

  function trackFilter(event: string, delta: string) {
    const tagManagerArgs = {
      dataLayer: {
        event,
        details: {
          domainType: delta,
        },
      },
    };
    IgniteTagManager.dataLayer(tagManagerArgs);
  }

  function onDomainFilterChange(values: string[]) {
    const delta = values.filter(
      (item) => filters.domainFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ProviderFilterDomainAdded", delta[0]);
    }

    setFilters({ ...filters, domainFilters: values });
    setPage(1);
  }
  function onSpecialityFilterChange(values: string[]) {
    const delta = values.filter(
      (item) => filters.specialityFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ProviderFilterSpecialityAdded", delta[0]);
    }

    setFilters({ ...filters, specialityFilters: values });
    setPage(1);
  }
  function onBookingTypeFilterChange(values: string[]) {
    const delta = values.filter(
      (item) => filters.bookingTypeFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ProviderFilterBookingTypeAdded", delta[0]);
    }

    setFilters({ ...filters, bookingTypeFilters: values });
    setPage(1);
  }

  function onLocationFilterChannge(values: string[]) {
    const delta = values.filter(
      (item) => filters.locationFilters.indexOf(item) === -1
    );

    if (delta.length !== 0) {
      trackFilter("ProviderFilterLocationAdded", delta[0]);
    }

    setFilters({ ...filters, locationFilters: values });
    setPage(1);
  }

  // function onClickLoadMore() {
  //   setPage(page + 1);
  // }

  //eslint-disable-next-line
  function getLoadingOrLoadMore() {
    if (reduxState.resultStatus === REQUEST_STATUS.Pending) {
      return (
        <Grid item>
          <Grid
            container
            className={classes.loaderContainer}
            alignContent="space-around"
          >
            <CircularIndeterminate />
          </Grid>
        </Grid>
      );
    }

    // if (
    //   reduxState.searchResults.pagination != null &&
    //   reduxState.searchResults.pagination.total >
    //     reduxState.searchResults.pagination.pageSize *
    //       reduxState.searchResults.pagination.pageIndex +
    //       reduxState.searchResults.pagination.pageSize
    // ) {
    //   return (
    //     <Grid item>
    //       <Grid
    //         container
    //         className={classes.loaderContainer}
    //         alignContent="space-around"
    //       >
    //         <Button
    //           variant="outlined"
    //           color="secondary"
    //           onClick={() => onClickLoadMore()}
    //         >
    //           Load More
    //         </Button>
    //       </Grid>
    //     </Grid>
    //   );
    // }

    return <div></div>;
  }

  function renderSearchResults() {
    if (reduxState.resultStatus === REQUEST_STATUS.Failed) {
      return (
        <Grid item>
          <Grid
            container
            className={classes.loaderContainer}
            alignContent="space-around"
          >
            <Typography color="error" variant="button">
              Error. Could not contact server
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return reduxState.searchResults.items.map((value, index) => {
      return (
        <Grid key={value.providerId} item>
          <ProfileCard profile={value} onSelect={onSelectProfile} />
        </Grid>
      );
    });
  }

  return (
    <React.Fragment>
      <Banner
        src={require("assets/banner/ProvidersBanner-2.png")}
        styleSheetClass="provider-banner"
        align="right"
      >
        <div>Support Providers</div>
        <p>Browse local wellbeing professionals & book an appointment</p>
      </Banner>
      <FilterContainer onSubmit={onSubmitSearch}>
        <DomainFilter
          onFilterChange={onDomainFilterChange}
          values={filters.domainFilters}
        />
        <SpecialityFilter
          onFilterChange={onSpecialityFilterChange}
          values={filters.specialityFilters}
        />
        <BookingTypeFilter
          onFilterChange={onBookingTypeFilterChange}
          values={filters.bookingTypeFilters}
        />
        <LocationFilter
          onFilterChange={onLocationFilterChannge}
          values={filters.locationFilters}
        />
      </FilterContainer>
      <Container maxWidth="xl">
        <div className={classes.chipContainer}>{renderFilterChip()}</div>

        <Grid
          className={classes.root}
          container
          justify="center"
          alignItems="flex-start"
          spacing={spacing}
        >
          {renderSearchResults()}
        </Grid>
        <Grid
          className={classes.root}
          container
          justify="center"
          spacing={spacing}
        >
          <Grid item>{getLoadingOrLoadMore()}</Grid>
        </Grid>
      </Container>
      <TokenExplanation />
    </React.Fragment>
  );
};

export default withRouter(Search);
