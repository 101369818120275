import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { ShortProfile } from "domain/providers/types";
import LocationIcon from "assets/icons/LocationIcon.svg";
import { Button } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { ReactComponent as Chevron } from "assets/icons/Icon-Left-Arrow.svg";
import AvatarDefault from "assets/icons/AvatarDefault.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "790px",
      backgroundColor: theme.palette.background.paper,
      transition: "all 1s ease",

      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        borderRadius: 0,
      },
    },
    cardInner: {
      margin: "30px",
    },
    responsive: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    locationContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    centerOnMobile: {
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        textAlign: "center",
      },
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      borderColor: "#B1B6CB",
      height: "31px",
      fontFamily: "Gilroy Regular",
      fontSize: "15px",
    },
    chip2: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      backgroundColor: "#E1E8F7",
      fontFamily: "Gilroy Regular",
      fontSize: "15px",
    },
    avatar: {
      width: "107px",
      height: "107px",
    },
    header: {
      margin: theme.spacing(2, 0),
    },
    times: {
      margin: theme.spacing(2, 0),
    },
    credentials: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    specialties: {
      minHeight: "100px",
      margin: theme.spacing(2, 0),
    },
    bio: {
      margin: theme.spacing(2, 0),
    },
    sectionBody: {
      marginTop: "10px",
      height: "auto",
      paddingBottom: "25px",
    },
    bullets: {
      margin: "0px",
      paddingInlineStart: "20px",
      [theme.breakpoints.down("sm")]: {
        listStyleType: "none",
      },
    },

    bulletItem: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    divider: {
      marginBottom: "25px",
      marginLeft: "0px",
      marginRight: "0px",
    },
    iconContainer: {
      display: "flex",
      margin: "5px",
      marginTop: "-1px",
    },
    uppercase: {
      textTransform: "uppercase",
    },
    icon: {
      marginTop: 5,
      marginBottom: "auto",
      position: "relative",
      top: "-1px",
      left: "-5px",

      [theme.breakpoints.down("sm")]: {},
    },
    backContainer: {
      display: "none",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "10px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    biography: {
      whiteSpace: "pre-line",
      overflow: "hidden",
      transition: "max-height 1s ease-in-out",
      maxHeight: "unset",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "310px",
      },
    },
    biographyExpanded: {
      maxHeight: "1000px",
    },
    showMore: {
      margin: "auto",
      display: "none",
      padding: "5px",
      boxShadow: "0px -10px 15px white",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      width: "100%",
    },
    showLess: {
      boxShadow: "none !important",
    },
    profileDetails: {
      height: "125px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
  })
);

interface Props extends RouteComponentProps {
  profile: ShortProfile;
}

function ProviderProfile({ profile, history }: Props) {
  const [showMore, setShowMore] = useState(false);
  function renderCredentials(_qualifications: string[]) {
    let qualifications = _qualifications;
    if (!Array.isArray(_qualifications)) {
      try {
        qualifications = (_qualifications as string).split("\n");
      } catch (error) {
        qualifications = new Array(_qualifications) as string[];
      }
    }
    return (
      <ul className={classes.bullets}>
        {qualifications.map((item, index) => {
          return item !== null ? (
            <li
              className={classes.bulletItem}
              key={profile.providerId + "spec-chip" + index}
            >
              <Typography variant="body1">{item.trim()}</Typography>
            </li>
          ) : null;
        })}
      </ul>
    );
  }

  function renderSpecialtyChips(specialties: string[]) {
    if (
      specialties == null ||
      specialties === undefined ||
      specialties.length === 0
    ) {
      specialties = [];
    }
    return specialties.map((item, index) => {
      return (
        <Chip
          key={profile.providerId + "spec-chip" + index}
          className={classes.chip}
          label={item}
          variant="outlined"
        />
      );
    });
  }

  const classes = useStyles();

  const shortAddress = (city: string, suburb: string, start: string) => {
    suburb = suburb == null || suburb.length === 0 ? "" : suburb + ", ";
    city = city == null ? "" : city;
    if ((suburb + city).length > 0) {
      return start + suburb + city;
    }
    return suburb + city;
  };

  function getAddress() {
    if (
      profile.address !== null &&
      profile.address !== undefined &&
      profile.address.length > 1
    ) {
      return profile.address + shortAddress(profile.city, profile.suburb, ", ");
    } else {
      return shortAddress(profile.city, profile.suburb, "");
    }
  }

  function backToSearch() {
    if (history.location.state && history.location.state.from === "/search") {
      history.goBack();
    } else {
      history.push("/search");
    }
  }

  return (
    <Card className={classes.root}>
      <div className={classes.cardInner}>
        <div className={classes.header}>
          <Grid
            container
            alignItems="flex-start"
            direction="row"
            spacing={2}
            className={classes.responsive}
          >
            <Grid item className={classes.backContainer}>
              <Button
                variant="text"
                color="secondary"
                onClick={backToSearch}
                startIcon={<Chevron />}
              >
                Back to Support
              </Button>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              <Avatar
                alt={profile.firstName}
                className={classes.avatar}
                src={profile.avatar}
              >
                <img alt="" src={AvatarDefault} style={{ width: "100%" }} />
              </Avatar>
            </Grid>
            <Grid
              item
              className={`${classes.centerOnMobile} ${classes.profileDetails}`}
            >
              <Grid
                container
                justify="space-between"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h5">
                    {profile.firstName} {profile.lastName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {profile.type}
                    {" | "}
                    {profile.qualifications}
                  </Typography>
                </Grid>
                {/* Address */}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    className={classes.locationContainer}
                  >
                    <Grid item className={classes.iconContainer}>
                      <img
                        className={classes.icon}
                        alt="location"
                        src={LocationIcon}
                      />
                      <Typography variant="body1">{getAddress()}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} variant="middle" />
        <div className={classes.specialties}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Specialities
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              {renderSpecialtyChips(profile.specialties)}
            </Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} variant="middle" />

        <div className={classes.bio}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Biography
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              <Typography
                className={`${classes.biography} ${
                  showMore && classes.biographyExpanded
                }`}
                variant="body1"
              >
                {profile.biography.split("\\n\\n").join("\n\n").trim()}
              </Typography>
              <Button
                className={`${classes.showMore} ${
                  showMore && classes.showLess
                }`}
                variant="text"
                color="secondary"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </Button>
            </Grid>
          </Grid>
        </div>

        <Divider className={classes.divider} variant="middle" />

        <div className={classes.credentials}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={2}
          >
            <Grid item className={classes.centerOnMobile}>
              <Typography color="textSecondary" variant="subtitle1">
                Qualifications
              </Typography>
            </Grid>
            <Grid item className={classes.centerOnMobile}>
              {renderCredentials(profile.qualificationsExtended)}
            </Grid>
          </Grid>
        </div>
      </div>
    </Card>
  );
}

export default withRouter(ProviderProfile);
