import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import Banner from "ui/components/Banner";

import { Container } from "@material-ui/core";

import SectionBanner from "ui/compounds/SectionBanner";
import RequestDemoCard from "ui/compounds/RequestDemoCard";
import DomainsCard from "ui/compounds/DomainsCard";
import MethodImage from "assets/approach/Domains.svg";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    section1MainContainer: {},

    methodContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",

      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        justifyContent: "center",
      },
    },
    methodContentContainer: {
      display: "flex",
      flexDirection: "column",
      width: "40%",
      height: "fit-content",
      margin: "auto",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },

    methodImageContainer: {
      display: "flex",
      width: "55%",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
        marginBottom: "40px",
      },
    },

    methodImage: {
      width: "100%",
    },
    methodTitle: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 34,
      },
    },
    methodBody: {
      "& span": {
        ...theme.typography.body1,
        fontFamily: "Gilroy Bold",
      },
    },
  })
);

const OurApproach: React.FC = () => {
  const classes = useStyles();

  return (
    <div id="page" className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Ignite Aotearoa - Whole of Life Wellbeing - Our Methodology
        </title>
        <meta
          name="description"
          content="Ignite believe in a whole-of-life approach to our wellbeing practices. We have adopted three core themes: My Life, My Health & My Meaning. Learn more here."
        />
      </Helmet>
      <Banner variant="primary">
        <div>Whole of Life Wellbeing</div>
        <p>
          “Hapaitia te ara tika pumau ai te rangatiratanga mo nga uri whakatipu”
          We aim to foster the pathway of knowledge to strength, independence
          and growth for future generations.
        </p>
      </Banner>

      <section className="padded-section white">
        <Container maxWidth="xl">
          <div className={classes.methodContainer}>
            <div className={classes.methodContentContainer}>
              <Typography
                variant="h3"
                gutterBottom
                className={classes.methodTitle}
              >
                Our Methodology
              </Typography>
              <Typography variant="body1" className={classes.methodBody}>
                We believe in a whole-of-life approach. Informed by several
                models of health and wellbeing used in Aotearoa which
                acknowledge the inter-relatedness of our life on our overall
                wellbeing, we have adopted three core themes:
                <br />
                <span style={{ color: "#F34258" }}>My Life</span>,{" "}
                <span style={{ color: "#49DBA3" }}>My Health</span>,{" and "}
                <span style={{ color: "#04AAF0" }}>My Meaning</span>.
                <br />
                <br />
                It is important for us to be aware of the importance of each
                area and think about how we can enhance our overall wellbeing by
                paying attention to and strengthening each area.
              </Typography>
            </div>
            <div className={classes.methodImageContainer}>
              <img src={MethodImage} className={classes.methodImage} alt="" />
            </div>
          </div>
        </Container>
      </section>
      <section className="padded-section">
        <Container maxWidth="xl">
          <DomainsCard />
        </Container>
      </section>

      <SectionBanner style={{ minHeight: "0px", marginBottom: "-40px" }}>
        <section className="padded-section">
          <RequestDemoCard />
        </section>
      </SectionBanner>
    </div>
  );
};

export default OurApproach;
