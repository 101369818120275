import { SagaIterator } from "redux-saga";
import { put, takeEvery, takeLatest, all, call } from "redux-saga/effects";
import { ProviderActions } from "./actions";
import AuthActions from "domain/core/auth/actions";
import * as api from "./api";
import {
  ProviderSearchPayload,
  ProviderSearchResult,
  ProfileExtendedInfo,
  CalendarPayload,
  PlatformBookingPayload,
} from "./types";
import { PaginatedResult } from "lib/types";
import { Action } from "re-reduced";
import IgniteTagManager from "lib/analytics";

export default function* sagaWatcher() {
  yield all([
    takeLatest(
      ProviderActions.fetchProviderSearchResults.type,
      searchProviders
    ),
    takeLatest(ProviderActions.getProviderById.type, getProviderById),
    takeLatest(ProviderActions.putConfirmationBooking.type, putBooking),
    takeEvery(ProviderActions.fetchCalendar.type, fetchCalendar),
    takeEvery(ProviderActions.fetchCalendarMonth.type, fetchCalendarMonth),
    takeEvery(ProviderActions.fetchCalendarDay.type, fetchCalendarDay),
  ]);
}

export function* searchProviders(
  action: Action<ProviderSearchPayload>
): SagaIterator {
  yield put(ProviderActions.fetchProviderSearchResults.request());
  try {
    const result: PaginatedResult<ProviderSearchResult> = yield call(
      api.fetchProvidersSearchResults,
      action.payload
    );

    yield put(ProviderActions.fetchProviderSearchResults.success(result));
  } catch (error) {
    yield put(ProviderActions.fetchProviderSearchResults.failure(error));
  }
}

export function* getProviderById(
  action: Action<{ providerId: string }>
): SagaIterator {
  yield put(ProviderActions.getProviderById.request());
  try {
    const result: ProfileExtendedInfo = yield call(
      api.getProviderById,
      action.payload
    );

    yield put(ProviderActions.getProviderById.success(result));

    const tagManagerArgs = {
      dataLayer: {
        event: "ProviderViewed",
        details: {
          id: result.providerId,
          firstName: result.firstName,
          lastName: result.lastName,
          type: result.type,
          specialties: result.specialties,
          qualifications: result.qualifications,
          domains: result.domains,
          bookingTypes: result.bookingTypesAvailable,
          calendarId: result.calendarID,
          cost: result.unitsPerSession,
        },
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    yield put(ProviderActions.getProviderById.failure(error));
  }
}

export function* fetchCalendar(action: Action<CalendarPayload>): SagaIterator {
  yield put(ProviderActions.fetchCalendar.request());
  try {
    const result: string[] = yield call(api.fetchCalendar, action.payload);

    yield put(
      ProviderActions.fetchCalendar.success({ availableTimes: result })
    );
  } catch (error) {
    yield put(ProviderActions.fetchCalendar.failure(error));
  }
}

export function* fetchCalendarMonth(
  action: Action<CalendarPayload>
): SagaIterator {
  yield put(ProviderActions.fetchCalendarMonth.request());

  try {
    const result: string[] = yield call(api.fetchCalendarMonth, action.payload);
    yield put(
      ProviderActions.fetchCalendarMonth.success({ availableTimes: result })
    );
  } catch (error) {
    yield put(ProviderActions.fetchCalendarMonth.failure(error));
  }
}

export function* fetchCalendarDay(
  action: Action<CalendarPayload>
): SagaIterator {
  yield put(ProviderActions.fetchCalendarDay.request());
  try {
    const result: string[] = yield call(api.fetchCalendarDay, action.payload);

    yield put(
      ProviderActions.fetchCalendarDay.success({ availableTimes: result })
    );
  } catch (error) {
    yield put(ProviderActions.fetchCalendarDay.failure(error));
  }
}

export function* putBooking(
  action: Action<PlatformBookingPayload>
): SagaIterator {
  yield put(ProviderActions.putConfirmationBooking.request());

  try {
    const result: number = yield call(api.putConfirmationBooking, {
      ...action.payload,
    });

    yield put(
      ProviderActions.putConfirmationBooking.success({
        balance: result,
        datetime: action.payload.datetime,
      })
    );
    yield put(AuthActions.updateBalance(result));
  } catch (error) {
    yield put(ProviderActions.putConfirmationBooking.failure(error));
  }
}
