import { SagaIterator } from "redux-saga";
import { put, takeLatest, all, call } from "redux-saga/effects";
import { WorkshopActions } from "./actions";
import * as api from "./api";
import { WorkshopProfile } from "./types";
import { Action } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import { PlatformBookingPayload } from "domain/providers/types";
import { DashboardActions } from "domain/dashboard/actions";
import { store } from "store/configureStore";

export default function* sagaWatcher() {
  yield all([takeLatest(WorkshopActions.getWorkshops.type, getWorkshops)]);
  yield all([takeLatest(WorkshopActions.bookWorkshop.type, putBooking)]);
}

export function* getWorkshops(action: Action<void>): SagaIterator {
  yield put(WorkshopActions.getWorkshops.request());
  try {
    const result: WorkshopProfile[] = yield call(api.getWorkshops);

    yield put(WorkshopActions.getWorkshops.success(result));
  } catch (error) {
    yield put(WorkshopActions.getWorkshops.failure(error));
  }
}

export function* putBooking(
  action: Action<PlatformBookingPayload>
): SagaIterator {
  yield put(WorkshopActions.bookWorkshop.request());

  try {
    const result: number = yield call(api.bookWorkshop, {
      ...action.payload,
    });

    store.dispatch(DashboardActions.getAppointments());

    yield put(
      WorkshopActions.bookWorkshop.success({
        balance: result,
        datetime: action.payload.datetime,
        calendarID: action.payload.calendarID,
      })
    );
    yield put(AuthActions.updateBalance(result));
  } catch (error) {
    yield put(WorkshopActions.bookWorkshop.failure(error));
  }
}
