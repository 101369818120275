import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardDatePicker,
  DatePicker as MuiDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import WeekDatePicker from "./dates/WeekDatePicker";
import isValid from "date-fns/isValid";
// import { IconButton } from "@material-ui/core";
import {
  // addDays,
  addYears,
  // closestIndexTo,
  // format,
  // getDaysInMonth,
  // getWeeksInMonth,
  isSameDay,
  // startOfMonth,
} from "date-fns";

// const utils = new DateFnsUtils();
interface Props {
  // shouldDisableDate: (date: Date) => boolean;
  onDateChange: (date: Date) => void;
  selectedDate: Date;
  startDate: Date;
  availableDates: Date[];
  onRangeChange: (dateStart: Date) => void;
  type?: "week" | "month";
}

function DatePicker(props: Props) {
  // function handleCalendarDateChangeCUST(date: Date) {
  //   props.onDateChange(date);
  // }

  function handleCalendarDateChange(date: MaterialUiPickersDate) {
    props.onDateChange(date as Date);
  }

  function handleWeekDateChange(date: Date) {
    props.onDateChange(date);
  }

  //eslint-disable-next-line
  function getMonthView() {
    return (
      // <MuiPickersUtilsProvider utils={DateFnsUtils}>
      getCalendar()
      // </MuiPickersUtilsProvider>
    );
  }

  // const CalendarDay: React.FC<{
  //   day: Date;
  //   selected: boolean;
  //   disabled: boolean;
  //   isCurrentMonth: boolean;
  //   onClick: (e: Date) => void;
  // }> = (props) => {
  //   return (
  //     <div role="presentation">
  //       <IconButton
  //         role="cell"
  //         onClick={() => props.onClick(props.day)}
  //         className={`MuiPickersDay-day ${
  //           props.selected ? "MuiPickersDay-daySelected" : ""
  //         } ${props.disabled ? "MuiPickersDay-dayDisabled" : ""}
  //          ${
  //            props.isCurrentMonth
  //              ? "MuiPickersDay-hidden MuiPickersDay-dayDisabled"
  //              : ""
  //          }`}
  //         style={
  //           props.isCurrentMonth
  //             ? {
  //                 visibility: "hidden",
  //               }
  //             : {}
  //         }
  //       >
  //         {props.children}
  //       </IconButton>
  //     </div>
  //   );
  // };

  // const CalendarWeek: React.FC = (props) => {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       {props.children}
  //     </div>
  //   );
  // };

  // const shouldDisable = (e: Date) => {
  //   const f = props.availableDates.find((item) => {
  //     return isSameDay(new Date(item), e as Date);
  //   });

  //   return f === undefined;
  // };

  // interface IDictionary {
  //   [index: string]: number;
  // }

  // const dow: IDictionary = {
  //   Sunday: 0,
  //   Monday: 1,
  //   Tuesday: 2,
  //   Wednesday: 3,
  //   Thursday: 4,
  //   Friday: 5,
  //   Saturday: 6,
  // };

  // const CalendarMonth: React.FC<{
  //   day: Date;
  //   selected: Date;
  //   availableDates: Date[];
  // }> = (props) => {
  //   const som = startOfMonth(props.day);
  //
  //   const rows = getWeeksInMonth(props.day, { weekStartsOn: som.getDay() });

  //   // function getDays() {
  //   //   let elements = [];

  //   //   let skip = dow[format(som, "EEEE")];

  //   //   for (let s = 0; s < skip; s++) {
  //   //     elements.push(
  //   //       <CalendarDay
  //   //         day={new Date()}
  //   //         selected={false}
  //   //         disabled={true}
  //   //         isCurrentMonth={false}
  //   //         onClick={(e: Date) => {}}
  //   //       ></CalendarDay>
  //   //     );
  //   //   }

  //   //   const dim = getDaysInMonth(som);
  //   //   for (let i = 0; i < dim; i++) {
  //   //     const day = addDays(som, i);
  //   //     elements.push(
  //   //       <CalendarDay
  //   //         key={`day-${i}`}
  //   //         day={day}
  //   //         selected={isSameDay(day, props.selected)}
  //   //         disabled={shouldDisable(day)}
  //   //         isCurrentMonth={true}
  //   //         onClick={handleCalendarDateChangeCUST}
  //   //       >
  //   //         {day.getDate()}
  //   //       </CalendarDay>
  //   //     );
  //   //   }

  //   //   for (let i = 0; i < rows * 7 - dim; i++) {
  //   //     elements.push(
  //   //       <CalendarDay
  //   //         day={new Date()}
  //   //         selected={false}
  //   //         disabled={true}
  //   //         isCurrentMonth={false}
  //   //         onClick={(e: Date) => {}}
  //   //       ></CalendarDay>
  //   //     );
  //   //   }

  //   //   return elements;
  //   // }
  //   // function getWeeks() {
  //   //   let elements = [];
  //   //   const all_days = getDays();

  //   //   const chunk = 7;
  //   //   const length = all_days.length;
  //   //   for (let i = 0; i < length; i += chunk) {
  //   //     const temp = all_days.slice(i, chunk);
  //   //     elements.push(<CalendarWeek key={`week-${i}`}>{temp}</CalendarWeek>);
  //   //   }

  //   //   return elements;
  //   // }
  //   const currentMonthNumber = utils.getMonth(props.day);

  //   return (
  //     <div role="grid" className={"MyCalendarContainer"}>
  //       {utils.getWeekArray(som).map((week) => (
  //         <div
  //           role="row"
  //           key={`week-${week[0]}`}
  //           className={"MyWeekCalendar"}
  //           style={{ display: "flex", justifyContent: "center" }}
  //         >
  //           {week.map((day) => {
  //             const disabled = shouldDisable(day);
  //             const isDayInCurrentMonth =
  //               utils.getMonth(day) === currentMonthNumber;

  //             return (
  //               <CalendarDay
  //                 key={`day-${day.getDate()}`}
  //                 day={day}
  //                 selected={utils.isSameDay(props.selected, day)}
  //                 disabled={disabled}
  //                 isCurrentMonth={isDayInCurrentMonth}
  //                 onClick={handleCalendarDateChangeCUST}
  //               >
  //                 {day.getDate()}
  //               </CalendarDay>
  //             );
  //           })}
  //         </div>
  //       ))}
  //     </div>
  //   );
  //   // return <React.Fragment>{getWeeks()}</React.Fragment>;
  // };

  function getCalendar() {
    // return (
    //   <CalendarMonth
    //     day={props.selectedDate}
    //     availableDates={props.availableDates}
    //     selected={props.selectedDate}
    //   ></CalendarMonth>
    // );
    return (
      <MuiDatePicker
        animateYearScrolling={false}
        showTodayButton={true}
        disableToolbar
        maxDate={addYears(new Date(), 1)}
        minDate={new Date()}
        variant="static"
        format="dd-mm-yyyy"
        margin="normal"
        id="date-picker-static"
        label="Date picker static"
        disablePast={true}
        value={props.selectedDate}
        shouldDisableDate={(e: MaterialUiPickersDate) => {
          // return false;
          const f = props.availableDates.find((item) => {
            return isSameDay(new Date(item), e as Date);
          });

          return f === undefined;
        }}
        onMonthChange={(date: MaterialUiPickersDate) =>
          props.onRangeChange(new Date(date as Date))
        }
        onChange={handleCalendarDateChange}
      />
    );
  }

  return (
    <div>
      {props.type === "month" ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {getMonthView()}
        </MuiPickersUtilsProvider>
      ) : (
        <WeekDatePicker
          start={props.startDate ? props.startDate : new Date()}
          shouldDisableDate={(e: Date) => {
            const f = props.availableDates.find((item) => {
              return isSameDay(new Date(item), e as Date);
            });

            return f === undefined;
          }}
          selectedDate={
            isValid(props.selectedDate) ? props.selectedDate : new Date()
          }
          onChange={handleWeekDateChange}
          onRangeChange={props.onRangeChange}
        />
      )}
    </div>
  );
}

export default DatePicker;
