import React, { useEffect } from "react";

import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { useReduxState, useActions } from "re-reduced";

import CardHeading from "ui/components/dashboard/CardHeading";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import { getHotContent, getHotContentStatus } from "domain/dashboard/reducer";
import { DashboardActions } from "domain/dashboard/actions";
import CircularIndeterminate from "ui/components/CircularIndeterminate";
import { ContentCardDetails } from "ui/compounds/Models/ContentCard/ContentCard";
import { withRouter, RouteComponentProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      paddingBottom: "0px",
      minHeight: "328px",
      height: "calc(100% - 20px)",

      "& .MuiDivider-root": {
        marginTop: "20px",
      },
    },
    noAppointment: {
      height: "260px",
    },
    imgContainer: {
      margin: "auto auto",
      width: "100%",
      height: "calc(100% - 16px)",
      minHeight: "200px",

      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },

      position: "relative",
      backgroundSize: "cover",
      objectFit: "cover",
      overflow: "hidden",
    },
    container: {
      width: "100%",
      margin: "0px",
      "& .MuiGrid-item": {
        display: "flex",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    item: {
      // [theme.breakpoints.down("sm")]: {
      //   marginTop: "15px",
      // },
    },

    clickable: {
      display: "flex",
      margin: "auto",
      height: "100%",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
      },
    },

    image: {
      paddingLeft: "0px !important",
      // paddingTop: "0px !important",
      // paddingBottom: "0px !important",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px !important",
        marginTop: "15px",
      },
    },
  })
);

const HotContent = (props: RouteComponentProps) => {
  const classes = useStyles();

  const reduxState = useReduxState({
    hotContent: getHotContent,
    status: getHotContentStatus,
  });

  const actions = useActions(DashboardActions);

  useEffect(() => {
    actions.getHotContent();
  }, [actions]);

  function getContent() {
    if (
      reduxState.status === "Fulfilled" &&
      reduxState.hotContent !== undefined &&
      reduxState.hotContent !== null
    ) {
      return (
        <div
          className={classes.clickable}
          onClick={() =>
            window.open(
              reduxState.hotContent ? reduxState.hotContent.url : "",
              "_blank"
            )
          }
        >
          <Grid
            container
            direction="row"
            className={classes.container}
            spacing={2}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className={`${classes.item} ${classes.image}`}
            >
              <div
                className={classes.imgContainer}
                style={{
                  background:
                    `url("` +
                    reduxState.hotContent.imgUrl +
                    `") no-repeat left`,
                  backgroundSize: "cover",
                }}
              ></div>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className={classes.item}
            >
              <ContentCardDetails
                titleSize="h6"
                noMargin
                {...reduxState.hotContent}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return getLoading();
  }

  function getLoading() {
    return (
      <div style={{ display: "flex", margin: "auto" }}>
        <CircularIndeterminate />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CardHeading
        heading="Content Of The Week"
        action="See more"
        onClick={() => props.history.push("/learn")}
      />
      <Divider />

      {getContent()}
    </div>
  );
};

export default withRouter(HotContent);
