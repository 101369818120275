import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useActions, useReduxState } from "re-reduced";
import AuthActions from "domain/core/auth/actions";
import { getForgotPasswordRequest } from "domain/core/auth/selectors";
import { ReactComponent as EmailSuccess } from "assets/states/success/EmailSuccess.svg";
import { useUnAuthedStyles } from "ui/pages/UnAuthed";
import FormInput from "ui/components/Input";
import Link from "@material-ui/core/Link";

function ForgotPassword(props: RouteComponentProps) {
  const [email, setEmail] = React.useState("");
  const classes = useUnAuthedStyles();
  const actions = useActions(AuthActions);
  const reduxState = useReduxState({
    forgotPassword: getForgotPasswordRequest,
  });

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const [invalidEmail, setInvalidEmail] = React.useState(false);

  const onSubmit = (event: React.ChangeEvent<EventTarget>) => {
    event.preventDefault();
    if (!validEmail()) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
      actions.forgotPassword({ username: email });
    }
  };

  const validEmail = () => {
    const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  function handleKeydownEmail(
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    if (e.keyCode === 13) {
      actions.forgotPassword({ username: email });
    }
  }

  function getEmailErrorMsg() {
    if (!validEmail()) {
      if (email.length === 0) {
        return "Please enter your email";
      }
      if (email.indexOf("@") === -1) {
        return "An email must contain a single @";
      }
      if (email.indexOf("@") === 0) {
        return "An email cannot start with @";
      }
      if (email.indexOf(".") === -1) {
        return "An email must contain a single '.' e.g '.com'";
      }
    }
    if (reduxState.forgotPassword.status === "Failed") {
      return "Wrong email or password";
    } else {
      return " ";
    }
  }

  function getEmailInput() {
    return (
      <FormInput
        id="component-email"
        label="Email Address"
        value={email}
        error={invalidEmail}
        errorMsg={getEmailErrorMsg()}
        onChange={handleChangeEmail}
        onKeyDown={handleKeydownEmail}
        autoComplete="current-email"
      />
    );
  }

  function getSubmitButton() {
    const isLoading = reduxState.forgotPassword.status === "Pending";

    return (
      <Button variant="contained" color="secondary" type="submit">
        {isLoading ? <CircularProgress size={18} color="inherit" /> : "Submit"}
      </Button>
    );
  }

  function getContent() {
    if (reduxState.forgotPassword.status === "Fulfilled") {
      return getSuccess();
    }

    return getForm();
  }

  function getForm() {
    return (
      <div className={classes.loginFormWrapper} id="fade-in">
        <form className={classes.loginForm} onSubmit={onSubmit}>
          <Typography variant="h2">Kia Ora!</Typography>
          <Typography variant="body1" align="center">
            {reduxState.forgotPassword.status === "Fulfilled"
              ? "We've just sent an email to reset your password"
              : "Enter your email address to reset your password"}
          </Typography>

          {getEmailInput()}
          {getSubmitButton()}

          <Typography variant="body2" align="center">
            Remember your password?{" "}
            <b
              className={classes.signUpWrap}
              onClick={() => props.history.push("/search")}
            >
              Sign In
            </b>
          </Typography>
        </form>
      </div>
    );
  }

  const reset = (event: React.SyntheticEvent) => {
    event.preventDefault();
    actions.clear();
  };

  function getSuccess() {
    return (
      <div className={classes.loginFormWrapper}>
        <form className={classes.loginForm} id="fade-in-form">
          <EmailSuccess />
          <Typography variant="body1" align="center">
            Sweet as! We&apos;ve sent an email with instructions to
            <b style={{ fontFamily: "Gilroy Bold" }}>{email}</b>
          </Typography>
          <Link onClick={reset}>Got email wrong? Retry</Link>
          <Typography variant="body2" align="center">
            Remembered your password?
            <b
              className={classes.signUpWrap}
              onClick={() => props.history.push("/search")}
            >
              Sign In
            </b>
          </Typography>
        </form>
      </div>
    );
  }

  return getContent();
}

export default withRouter(ForgotPassword);
