import decodeJWT from "jwt-decode";
import parse from "date-fns/parse";
import isAfter from "date-fns/isAfter";

import compose from "ramda/src/compose";
import split from "ramda/src/split";
import join from "ramda/src/join";
import map from "ramda/src/map";
import head from "ramda/src/head";

import { capitalize } from "lib/string";

interface JWTData {
  exp: number;
  siteid: string;
}

export const decodeJWTData = (token: string): JWTData | undefined => {
  try {
    return decodeJWT<JWTData>(token);
  } catch (error) {
    return undefined;
  }
};

export function isTokenValidAndNotExpired(
  token: string,
  now: Date = new Date()
) {
  const jwtData = decodeJWTData(token);

  if (jwtData) {
    const expiry = String(jwtData.exp);
    const tokenExpiryDate = parse(expiry, "t", new Date());

    return isAfter(tokenExpiryDate, now);
  }

  return false;
}

export const reverseString = (string: string) =>
  string
    .split("")
    .reverse()
    .join("");

export const encodeToken = reverseString;

export const decodeToken = reverseString;

export const parseSiteNameFromEmail = compose(
  join(" "),
  map(capitalize),
  split("-"),
  head,
  split("@")
);
