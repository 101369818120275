import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "ui/components/Banner";
import Container from "@material-ui/core/Container";
import { getFirstName } from "domain/core/auth/selectors";
import { useReduxState } from "re-reduced";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import NextAppointment from "ui/compounds/dashboard/NextAppointment";
import HotContent from "ui/compounds/dashboard/HotContent";
import WellbeingOverview from "ui/compounds/dashboard/WellbeingOverview";
import MoodDiary from "ui/compounds/dashboard/MoodDiary";

import IgniteTagManager from "lib/analytics";

interface Props extends RouteComponentProps {}
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "5rem",
  },
  halfCard: {
    width: "580px",
    height: "350px",
  },
  card: {
    width: "calc(100%)",
    height: "100%",
    transition: "all 1s ease",
  },
  container: {
    transition: "all 1s ease",
    marginTop: "-110px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px",
    },
  },
}));

const tagManagerArgs = {
  dataLayer: {
    event: "DashboarViewed",
  },
};

IgniteTagManager.dataLayer(tagManagerArgs);

function Dashboard(props: Props) {
  const classes = useStyles();

  const reduxState = useReduxState({
    getFirstName,
  });
  return (
    <div className={classes.root}>
      <Banner variant="primary">
        <div>
          Kia Ora,{" "}
          {reduxState.getFirstName !== undefined &&
          reduxState.getFirstName.length < 20
            ? reduxState.getFirstName
            : ""}
        </div>
        <p>Welcome to your Wellbeing Dashboard</p>
      </Banner>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container justify="center" direction="row" spacing={2}>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <Card className={classes.card}>
              <MoodDiary />
            </Card>
          </Grid>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <NextAppointment />
          </Grid>
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <Card className={classes.card}>
              <WellbeingOverview />
            </Card>
          </Grid>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <Card className={classes.card}>
              <HotContent />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withRouter(Dashboard);
