import React from "react";
import { domains, DomainKey, Domains } from "domain/core/app/types";
import IconListItem from "./IconListItem";
import Filter from "./Filter";
import DomainIcon from "ui/components/DomainIcon";
import { FilterProps } from "ui/components/filters/Filter";

export interface ResourceDomainFilter extends Domains {
  recommended: string[];
}

export const ResourceDomains: ResourceDomainFilter = {
  recommended: ["COVID-19", "Wellplace.nz"],
  ...domains,
};

export const domainRenderOption = (
  option: string,
  selected: boolean,
  callback?: () => void
) => {
  return (
    <IconListItem
      option={option}
      selected={selected}
      onClick={callback}
      key={option}
    >
      <DomainIcon domain={option} />
    </IconListItem>
  );
};

const DomainFilter = (props: FilterProps & { enableCovid?: boolean }) => {
  const keys = props.enableCovid
    ? (Object.keys(ResourceDomains) as DomainKey[])
    : (Object.keys(domains) as DomainKey[]);

  const options = props.enableCovid ? ResourceDomains : domains;

  function groupDomain(option: string) {
    const group = keys.filter(
      (key) => ResourceDomains[key].indexOf(option) > -1
    )[0] as string;
    if (group === "recommended") {
      return "Recommended";
    }
    return "My " + group.charAt(0).toUpperCase() + group.substring(1);
  }

  function getDomains(): string[] {
    const list = keys.map((key) =>
      options[key].sort((a: string, b: string) => -b[0].localeCompare(a[0]))
    );

    const flattened = ([] as string[]).concat(...list);

    return flattened;
  }

  return (
    <Filter
      border={true}
      values={props.values}
      onFilterChange={props.onFilterChange}
      options={getDomains()}
      title="I want support with..."
      renderOptions={domainRenderOption}
      disableInput={true}
      disableInputBorder
      groupBy={groupDomain}
      placeHolder="View all support areas"
      id="domain-filter"
    />
  );
};

export default DomainFilter;
