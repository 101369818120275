import { createActions } from "re-reduced";

import { APIErrorResponse } from "lib/apiClient/createClient";

import { ContactUsPayload, AppState } from "domain/core/app/types";
export default createActions("CORE/APP", (create) => ({
  submitContact: create.asyncAction<void, ContactUsPayload, APIErrorResponse>(),
  clear: create.action<void, AppState>(),
  getLocations: create.asyncAction<string[], void, APIErrorResponse>(),
  setRegexRoute: create.action<
    { workshopsEnabled: boolean; providersEnabled: boolean },
    string
  >(),
}));
