import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import DomainIcon from "ui/components/DomainIcon";
import OverflowChipContainer from "ui/components/OverflowChipContainer";
import Chip from "@material-ui/core/Chip";
import MediumIcon from "ui/components/MediumIcon";
import { capitalize } from "lib/string";
import { ContentProfile } from "domain/content/types";
import ContentPlaceholder from "assets/placeholder/ContentPlaceholder.png";
import IgniteTagManager from "lib/analytics";

import WorkshopsImage from "assets/workshops/WorkshopsCardImage.png";
import WellplaceLogo from "assets/domains/WellplaceLogo.svg";

import { RouteComponentProps, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      animation: "fadein 0.5s ease 0s 1 normal forwards",

      width: "382px",

      minHeight: "560px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 8px 10px 1px rgba(68,53,86,0.04), 0 3px 14px 2px rgba(68,53,86,0.05), 0 5px 5px -3px rgba(68,53,86,0.05)",

      "@media( max-width: 410px)": {
        width: "calc(100vw - 32px)",
      },

      transition: "all 0.25s linear",

      "@media (hover: hover)": {
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
    chip: {
      margin: "5px",
      marginLeft: "0",
      borderRadius: "5px",
      fontFamily: "Gilroy Regular",
      fontSize: "14px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      margin: "15px 15px 10px 15px",
    },
    img: {
      width: "100%",
      margin: "0 auto",
      height: "230px",
      backgroundSize: "cover",
      objectFit: "cover",
      overflow: "hidden",
      position: "absolute",
      left: 0,
      top: 0,
    },
    imgContainer: {
      width: "100%",
      margin: "0 auto",
      position: "relative",
      height: "230px",
      backgroundSize: "cover",
      objectFit: "cover",
      overflow: "hidden",
      background: `url(${ContentPlaceholder}) no-repeat center`,
    },
    iconContainer: {
      width: "calc(100% + 16px)",
      height: "40px",
      margin: "5px 0px 0px 0px",
    },
    overflowContainer: {
      maxWidth: "calc(100%)",
      height: "40px",
      overflowY: "hidden",
    },

    title: {
      display: "inline-block",
      width: "calc(100% - 32px)",
      "& *": {
        display: "block",
        width: "100%",
      },
      margin: "5px 0px 10px 0px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    medium: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      "& >:first-child": {
        marginLeft: "-5px",
        marginRight: "0px",
      },
    },
    contentContainer: {
      padding: "5px",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    coronaBanner: {
      width: "100%",
      height: "50px",
      position: "absolute",
      bottom: 0,
      backgroundColor: "#FF7A00",
      display: "flex",
      flexDirection: "row",
    },
    coronaChip: {
      margin: "auto auto auto 12px",
      border: "none",
      background: "transparent",
      stroke: "white",
      color: "white",
      " & *": {
        stroke: "white",
        color: "white",
        fontFamily: "Gilroy Bold",
        fontSize: "14px",
      },
    },
    wellplaceBanner: {
      width: "100%",
      height: "50px",
      position: "absolute",
      bottom: 0,
      backgroundColor: "#1FA6CE",
      display: "flex",
      flexDirection: "row",
      " & img": {
        padding: "15px",
      },
    },
  })
);

export default function ContentCard(props: ContentProfile) {
  const classes = useStyles();

  const [errored, setErrored] = React.useState(false);

  const onClickCard = (): void => {
    const tagManagerArgs = {
      dataLayer: {
        event: "ResourceCardClicked",
        details: props,
      },
    };

    IgniteTagManager.dataLayer(tagManagerArgs);
  };

  function onErrored() {
    setErrored(true);
  }

  return (
    <a
      href={props.url}
      className="link-no-visit"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClickCard}
    >
      <Card className={classes.root}>
        <div className={classes.imgContainer}>
          {!errored && (
            <img
              src={props.imgUrl}
              alt=""
              onError={onErrored}
              className={classes.img}
            />
          )}
          {props.tags != null && props.tags.indexOf("covid-19") !== -1 && (
            <div className={classes.coronaBanner}>
              <Chip
                className={classes.coronaChip}
                variant="outlined"
                icon={
                  <DomainIcon
                    key={"COVID-19"}
                    domain={"COVID-19-WHITE"}
                    borderless={true}
                  />
                }
                label="COVID-19"
              />
            </div>
          )}
          {props.tags != null && props.tags.indexOf("wellplace.nz") !== -1 && (
            <div className={classes.wellplaceBanner}>
              <img src={WellplaceLogo} alt="Wellplace.nz" />
            </div>
          )}
        </div>
        <div className={classes.contentContainer}>
          <ContentCardDetails {...props} />
        </div>
      </Card>
    </a>
  );
}

function WorkshopCard(props: RouteComponentProps) {
  const classes = useStyles();

  const [errored, setErrored] = React.useState(false);

  function onErrored() {
    setErrored(true);
  }

  function getDomainChip(item: string): JSX.Element {
    return (
      <Chip
        key={item}
        className={classes.chip}
        label={item}
        variant="outlined"
        icon={<DomainIcon key={item} domain={item} borderless={true} />}
      />
    );
  }

  return (
    <Card className={classes.root}>
      <div className={classes.imgContainer}>
        {!errored && (
          <img
            src={WorkshopsImage}
            alt=""
            onError={onErrored}
            className={classes.img}
          />
        )}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Typography
            variant="body2"
            style={{
              hyphens: "auto",
              fontSize: "14px",
            }}
          >
            <div className={classes.title}>
              <Typography variant="h5">
                Ready to start building a healthy workplace?
              </Typography>
            </div>
            Ignite is the new home of Wellplace. Resources originally created by
            Te Hiringa Hauora can now be found by selecting wellplace.nz in the
            “I want support with” search field above. Here you can browse ideas
            and start building a healthy, safe workplace.
          </Typography>
          <div className={classes.iconContainer} style={{ marginTop: "auto" }}>
            <OverflowChipContainer
              className={classes.overflowContainer}
              listIdPrefix="tags"
              chipContent={["Mind", "Body", "Habits", "Occupation"]}
              displayItem={getDomainChip}
            ></OverflowChipContainer>
          </div>
        </div>
      </div>
    </Card>
  );
}

export const WorkshopsCard = withRouter(WorkshopCard);

export const ContentCardDetails = ({
  url,
  imgUrl,
  medium,
  domains,
  description,
  noMargin,
  title,
  titleSize,
}: ContentProfile & {
  noMargin?: boolean;
  titleSize?: "h4" | "h5" | "h6";
}) => {
  const classes = useStyles();

  function getDomainChip(item: string): JSX.Element {
    return (
      <Chip
        key={item}
        className={classes.chip}
        label={item}
        variant="outlined"
        icon={<DomainIcon key={item} domain={item} borderless={true} />}
      />
    );
  }

  return (
    <div
      className={classes.content}
      style={noMargin ? { margin: "15px 0px" } : {}}
    >
      <div className={classes.medium}>
        {<MediumIcon medium={medium} size={32} />}
        <div style={{ margin: "auto 0px" }}>
          <Typography variant="subtitle1" color="textSecondary">
            {medium}
          </Typography>
        </div>
      </div>
      <div className={classes.title}>
        <Typography variant={titleSize ? titleSize : "h5"}>{title}</Typography>
      </div>

      <Typography variant="body2" style={{ hyphens: "auto" }}>
        {description}
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        style={{
          marginTop: "10px",
        }}
      >
        Source:{" "}
        {capitalize(
          url
            .replace("https://", "")
            .replace("http://", "")
            .replace("www.", "")
            .split("/")[0]
        )}
      </Typography>

      <div className={classes.iconContainer} style={{ marginTop: "auto" }}>
        <OverflowChipContainer
          className={classes.overflowContainer}
          listIdPrefix="tags"
          chipContent={domains}
          displayItem={getDomainChip}
        ></OverflowChipContainer>
      </div>
    </div>
  );
};
