import { WorkshopState } from "./types";
import { REQUEST_STATUS } from "lib/types";
import { createReducer, reduce } from "re-reduced";
import { WorkshopActions } from "./actions";
import { createSelector } from "reselect";
import { IgniteState } from "domain/types";
import { assoc, dissoc } from "ramda";

export const initialState: WorkshopState = {
  workshops: undefined,
  workshopsRequestStatus: { status: REQUEST_STATUS.Idle },
  bookingStatus: { status: REQUEST_STATUS.Idle },
};

export const getWorkshopState = (state: IgniteState) => state.workshops;

export const getWorkshops = createSelector(
  getWorkshopState,
  (state) => state.workshops
);

export const getWorkshopsResultStatus = createSelector(
  getWorkshopState,
  (state) => state.workshopsRequestStatus
);

export const getWorkshopBookingStatus = createSelector(
  getWorkshopState,
  (state) => state.bookingStatus
);

export const getSelectedWorkshop = createSelector(
  getWorkshopState,
  (state) => state.selectedWorkshop
);

export const getSelectedSession = createSelector(
  getWorkshopState,
  (state) => state.selectedSession
);

export default createReducer<WorkshopState>(
  [
    reduce(WorkshopActions.getWorkshops.request, (state) => {
      return {
        ...state,
        resultStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    reduce(WorkshopActions.getWorkshops.failure, (state) => {
      return {
        ...state,
        workshops: state.workshops,
        workshopsRequestStatus: { status: REQUEST_STATUS.Failed },
      };
    }),
    reduce(WorkshopActions.getWorkshops.success, (prevState, payload) => {
      return {
        ...prevState,
        workshops: payload.map((t) => {
          return {
            ...t,
            sessions: t.sessions.map((s) => {
              return {
                ...s,
                time: new Date(s.time),
              };
            }),
          };
        }),
        workshopsRequestStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    reduce(WorkshopActions.bookWorkshop.request, (state, _) => {
      return {
        ...state,
        bookingStatus: { status: REQUEST_STATUS.Pending },
      };
    }),
    reduce(WorkshopActions.bookWorkshop.success, (state, payload) => {
      // Strip booked item out
      const workshops = state.workshops;
      if (state.workshops) {
        state.workshops.forEach((t) => {
          t.sessions.forEach((s, index) => {
            if (
              s.calendarId === payload.calendarID &&
              s.time.toUTCString() === new Date(payload.datetime).toUTCString()
            ) {
              s.slotsAvailable -= 1;
            }
          });
        });
      }
      return {
        ...state,
        workshops: workshops,
        bookingStatus: { status: REQUEST_STATUS.Fulfilled },
      };
    }),
    reduce(WorkshopActions.bookWorkshop.failure, (state, error) => {
      return {
        ...state,
        bookingStatus: { status: REQUEST_STATUS.Failed, error: error },
      };
    }),

    reduce(WorkshopActions.setSelectedSession, (state, session) =>
      assoc("selectedSession", session, state)
    ),

    reduce(WorkshopActions.setSelectedWorkshop, (state, session) =>
      assoc("selectedSession", session, state)
    ),

    reduce(WorkshopActions.clearSelected, (state, _) =>
      dissoc("selectedWorkshop", dissoc("selectedSession", state))
    ),
  ],
  initialState
);
