import React from "react";
import Error404View from "assets/illustrations/404.svg";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import styled from "styled-components";

const Wrapper404 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  justify-content: center;

  & * {
    margin: 0px auto;
  }

  & h5 {
    margin: 24px auto;
  }

  @media (max-width: 600px) {
    & h5 {
      font-size: 18px;
    }
  }

  & img {
    width: calc(100% - 40px);
    max-width: 900px;
  }

  & button {
    margin: 0px auto;
  }
`;

const Error404 = (props: RouteComponentProps) => {
  return (
    <Wrapper404>
      <img alt="" src={Error404View} />
      <Typography variant="h5" align="center">
        Oops, this page does not exist
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => props.history.push("/home")}
      >
        Go Home
      </Button>
    </Wrapper404>
  );
};

export default withRouter(Error404);
